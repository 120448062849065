import classNames from "classnames"
import { isFunction } from "lodash"
import { TableSubRow } from "@design-system/Table/types"
import { useTable } from "@design-system/Table/hooks/useTable"
import { Table } from ".."

type ChildFunction<T> = (props: { item: T }) => JSX.Element
type ChildrenProp<T> = React.ReactNode | ChildFunction<T>

interface Props<ChildrenRow> {
  item: TableSubRow<ChildrenRow>
  className?: string
  children: ChildrenProp<ChildrenRow>
}

export const TableInnerRowChildren = <ChildrenRow = {},>({
  item,
  children,
  className = "",
}: Props<ChildrenRow>) => {
  const { state, toggleRowChecked } = useTable()
  const { checkedRows, withCheckedChildren, withChecked } = state

  const isChecked = withCheckedChildren && checkedRows.has(item.id)

  const onChecked = () => {
    toggleRowChecked({ key: item.id })
  }

  const classes = classNames(
    "w-full flex items-center gap-8 py-3 px-6 transition duration-300 ease-in-out",
    {
      "border-gray-100": !isChecked,
      "bg-indigo-50 border-indigo-100": isChecked,
    },
    className,
  )

  return (
    <div className={classes}>
      <div
        className={classNames("block h-full", {
          "pl-[44px]": withChecked && withCheckedChildren,
          "pl-[64px]": withChecked && !withCheckedChildren,
          "pl-8": !withChecked,
        })}
      >
        {withCheckedChildren && (
          <Table.Checkbox
            isChecked={isChecked}
            onChange={onChecked}
            id={String(item.id)}
          />
        )}
      </div>
      {isFunction(children) ? children({ item: item }) : children}
    </div>
  )
}
