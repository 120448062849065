import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders"
import { getPrivateApiOneProjectVisualRegressionCancelEditPath } from "@/Project/api/routes-visual-regression-private"

export const cancelEditSetting = async ({
  projectId,
}: {
  projectId: number
}) => {
  const headers = await getFetchPrivateHeaders()

  const result = await fetch(
    getPrivateApiOneProjectVisualRegressionCancelEditPath({ projectId }),
    {
      method: "POST",
      headers: headers,
    },
  )

  return await result.json()
}
