import { SingleProjectDetailModelQuery } from "@/Core/Models/Projects/SingleProjectDetailModel"
import { isNil } from "lodash"
import qs from "query-string"

/**
 * @returns /api/admin/projects/:projectId
 */
export const getProjectAdminPath = ({
  projectId,
}: {
  projectId: number
}): string => {
  return `/api/admin/projects/${projectId}`
}

/**
 * @returns /api/admin/projects/:projectId/wordpress/sizes
 */
export const getProjectWordPressSizesAdminPath = ({
  projectId,
}: {
  projectId: number
}): string => {
  return `${getProjectAdminPath({ projectId })}/wordpress/sizes`
}

/**
 *
 * @returns /api/admin/projects/:projectId/overview
 */
export const getProjectAdminOverviewPath = ({
  projectId,
}: {
  projectId: number
}) => {
  return `${getProjectAdminPath({ projectId })}/overview`
}

/**
 * @returns /api/admin/projects/:projectId/backups
 */
export const getProjectBackupsAdminPath = ({
  projectId,
}: {
  projectId: number
}) => {
  return `${getProjectAdminPath({ projectId })}/backups`
}

/**
 * @returns /api/projects/:projectId
 */
export const getProjectPath = (
  idProject: number | string,
  params?: SingleProjectDetailModelQuery,
): string => {
  let path = `/api/projects/${idProject}`

  if (!isNil(params)) {
    path = qs.stringifyUrl({
      url: path,
      query: params,
    })
  }

  return path
}

/**
 * @returns /api/projects/details
 */
export const getProjectsDetailPath = (): string => {
  return `/api/projects/details`
}

/**
 * @returns /api/projects/:idProject/details
 */
export const getProjectDetailPath = (idProject: number | string): string => {
  return `/api/projects/${idProject}/details`
}

/**
 * @returns /api/projects/:projectId/security
 */
export const getProjectSecurityPath = ({
  projectId,
}: {
  projectId: number | string
}): string => {
  return `${getProjectPath(projectId)}/security`
}

/**
 * @returns /api/projects/:projectId/backups/running-restore-process
 */
export const getProjectBackupsRunningRestoreProcess = ({
  projectId,
}: {
  projectId: number | string
}): string => {
  return `${getProjectPath(projectId)}/backups/running-restore-process`
}

/**
 * @returns /api/projects/by-ids
 */
export const getProjectsByIdsPath = (): string => {
  return `/api/projects/by-ids`
}

/**
 * @returns /api/projects/:projectId/issues
 */
export const getProjectPhpIssueDataPath = ({
  projectId,
}: {
  projectId: number
}) => `${getProjectPath(projectId)}/issues`

/**
 * @returns /api/projects/:projectId/issues/:id
 */
export const getProjectOnePhpIssueDataPath = ({
  projectId,
  phpIssueId,
}: {
  projectId: number
  phpIssueId: string
}) => `${getProjectPhpIssueDataPath({ projectId })}/${phpIssueId}`
