import { createContext, useEffect, useState } from "react"
import useLocalStorage from "../../../hooks/useLocalStorage"

type Pagination = {
  values: {
    page: number
    perPage: number
    total: number
    optionsPerPage?: Array<{ value: number; label: string }>
  }
  actions: {
    setPage: Function
    setPerPage: Function
    setTotal: Function
  }
}

export const GenericPaginationContext = createContext<Pagination>({
  values: {
    page: 0,
    perPage: 10,
    total: 0,
    optionsPerPage: [],
  },
  actions: {
    setPage: () => {},
    setPerPage: () => {},
    setTotal: () => {},
  },
})

interface Props {
  children: (props: { page: number; perPage: number }) => JSX.Element
  keyStorage: string
  optionsPerPage?: Array<{ value: number; label: string }>
  perPage?: number
}

export const GenericPaginationProvider = ({
  children,
  keyStorage,
  optionsPerPage,
  perPage: initialPerPage = 10,
}: Props) => {
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useLocalStorage(keyStorage, initialPerPage)

  useEffect(() => {
    setPage(1)
  }, [perPage])

  return (
    <GenericPaginationContext.Provider
      value={{
        values: {
          page,
          perPage,
          total,
          optionsPerPage,
        },
        actions: {
          setPage,
          setPerPage,
          setTotal,
        },
      }}
    >
      {children({
        page,
        perPage,
      })}
    </GenericPaginationContext.Provider>
  )
}

export default {
  Context: GenericPaginationContext,
  Provider: GenericPaginationProvider,
}
