import {
  StateGenericApplication,
  ActionGenericApplication,
} from "@design-system/Context/GenericApplication/types"
import { Reducer } from "react"

export const genericApplicationReducer: Reducer<
  StateGenericApplication,
  ActionGenericApplication
> = (state: StateGenericApplication, action: ActionGenericApplication) => {
  switch (action.type) {
    case "SET_CONTAINER_CLASSNAME": {
      return {
        ...state,
        container: {
          className: action.payload.className,
        },
      }
    }

    default:
      return state
  }
}
