import Config from "@environments/data"
import qs from "qs"
import qsUrl from "query-string"

export const startVerification = async (user: {
  email: string
  password: string
}) => {
  const { email, password } = user

  const path = qsUrl.stringifyUrl({
    url: `${Config.base_url}/api/login/start-verification`,
  })

  try {
    const response = await fetch(path, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: qs.stringify({
        username: email,
        password,
        client_id: Config.api.client_id,
        grant_type: "password",
        client_secret: Config.api.client_secret,
        scope: Config.api.scope,
      }),
    })

    const { success, result } = await response.json()

    return {
      success,
      result,
    }
  } catch (error) {
    return {
      success: false,
    }
  }
}
