import FetcherProcess from "@/Process/Fetcher"
import { DataClearCache } from "@/Repositories/Process/createProcessWithTasks"
import { TableRowProcess } from "@design-system/Table/types"
import { ProcessTasksType } from "@prisma/client"
import { get, isNil } from "lodash"

export interface ResponseCreateProcessTaskUpdatePlugin {
  code: string
  data: {
    id: string
    ProcessTask: Array<{
      projectId: number
      retry: number
      type: ProcessTasksType
      entities?: any
      state?: any
    }>
  }
  rowProcessings: Set<TableRowProcess>
}

export enum PluginUpdateType {
  QUICK_UPDATE = "QUICK_UPDATE",
  SAFE_UPDATE = "SAFE_UPDATE",
  ADVANCED_SAFE_UPDATE = "ADVANCED_SAFE_UPDATE",
}

export enum AdvancedSafeUpdateValidation {
  MANUAL = "manual",
  AUTOMATIC = "automatic",
}

export const createProcessTaskUpdatePlugin = async ({
  data,
  projectsClearCache,
}: {
  data: {
    entities: {
      id: string
      is_active: boolean
      plugin: string
      name: string
      old_version: string
      version: string
      update_type: PluginUpdateType
      advanced_safe_update_validation?: AdvancedSafeUpdateValidation
    }
    projectId: number
  }
  projectsClearCache: Array<DataClearCache>
}): Promise<ResponseCreateProcessTaskUpdatePlugin> => {
  const response = await FetcherProcess.createProcess({
    tasks: [
      {
        ...data,
        type: ProcessTasksType.UPDATE_PLUGIN,
      },
    ],
    data: {
      clear_cache: projectsClearCache,
      can_send_notification: true,
    },
  })

  let code = null
  if (!isNil(response)) {
    code = response.code
  }

  const lines = new Set<TableRowProcess>()
  if (code === "success") {
    lines.add({
      key: data.entities.id,
      type: ProcessTasksType.UPDATE_PLUGIN,
      task: {
        finished_at: null,
        state: "waiting",
        code: null,
        entities: data.entities,
      },
    })
  }

  return {
    code: response.code,
    data: get(response, "data", null),
    rowProcessings: lines,
  }
}
