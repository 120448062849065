import FetchApiProcess from "@/FetchApi/Process"
import { DataClearCache } from "@/Repositories/Process/createProcessWithTasks"
import { TableRowProcess } from "@design-system/Table/types"
import { ProcessTasksType } from "@prisma/client"
import { get, isNil } from "lodash"

interface Response {
  code: string
  data: {
    id: string
    ProcessTask: Array<{
      id: string
      projectId: number
      retry: number
      type: ProcessTasksType
      entities?: any
      state?: any
    }>
  }
  rowProcessings: Set<TableRowProcess>
}

export const createProcessTaskAddPlugins = async ({
  data,
  projectsClearCache,
}: {
  data: {
    type: ProcessTasksType
    entities: {
      id: string
      name: string
      plugin: string
      version: string
      must_be_activate: boolean
      plugin_uri: string
      from: string
    }
    projectId: number
  }[]
  projectsClearCache: Array<DataClearCache>
}): Promise<Response> => {
  const response = await FetchApiProcess.createProcess({
    tasks: data,
    data: {
      clear_cache: projectsClearCache,
    },
  })

  let code = null
  if (!isNil(response)) {
    code = response.code
  }

  const lines = new Set<TableRowProcess>()
  if (code === "success") {
    data.forEach((item) => {
      lines.add({
        key: item.entities.id,
        type: ProcessTasksType.ADD_PLUGIN,
        task: {
          finished_at: null,
          state: "waiting",
          code: null,
          entities: item.entities,
        },
      })
    })
  }

  return {
    code: response.code,
    data: get(response, "data", null),
    rowProcessings: lines,
  }
}
