import { getProjectPath } from "."

export const getProjectWebhookSlack = ({ id }: { id: number }) =>
  `${getProjectPath(id)}/webhook-slack`

export const updateProjectWebhookSlack = async (
  params: { idProject: number },
  data: {
    url: string
  },
) => {
  const result = await fetch(getProjectWebhookSlack({ id: params.idProject }), {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })

  return await result.json()
}
