import { getProjectPath } from "."
import { ProjectEmailNotification } from "../../Types/project"

export const getProjectEmailNotificationPath = ({ id }: { id: number }) =>
  `${getProjectPath(id)}/notifications`

export const createProjectEmailsNotification = async (
  idProject: number,
  data: {
    emails: Array<ProjectEmailNotification>
  },
) => {
  const result = await fetch(
    getProjectEmailNotificationPath({ id: idProject }),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    },
  )

  return await result.json()
}
