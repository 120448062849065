import classNames from "classnames"
import { Fragment, ReactNode } from "react"
import { System } from "@design-system/index"
import ReactTooltip from "react-tooltip"
import { useTable } from "@design-system/Table/hooks/useTable"
import { Table } from ".."

interface Props {
  className?: string
  children: ReactNode
  isIndeterminate?: boolean
  isChecked?: boolean
  isAllExpanded?: boolean
}

export const TableHeader = ({
  children,
  className = "",
  isChecked,
  isAllExpanded,
  isIndeterminate,
}: Props) => {
  const { state, checkAllRows, clearCheckedRows, expandAllRows, closeAllRows } =
    useTable()

  const {
    withChecked,
    withExpanded,
    withExpandCloseAll,
    rowKeys,
    rowChildrenKeys,
    expandedRows,
    withCheckedChildren,
    checkedRows,
  } = state

  const onCheckAll = () => {
    if (!withChecked) {
      return
    }

    const size = withCheckedChildren ? rowChildrenKeys.size : rowKeys.size

    if (checkedRows.size !== size) {
      checkAllRows()
    } else {
      clearCheckedRows()
    }
  }

  const onExpandCloseAll = () => {
    if (!withExpandCloseAll) {
      return
    }

    if (expandedRows.size !== rowKeys.size) {
      expandAllRows()
    } else {
      closeAllRows()
    }
  }

  const hasChecked = !!withChecked

  const classes = classNames(
    "pb-4 px-4 w-full gap-4 transition duration-300 ease-in-out",
    {
      "grid items-center": className.includes("grid-cols-"),
      "flex items-center": !className.includes("grid-cols-"),
    },
    className,
  )

  return (
    <div className={classes}>
      {(hasChecked || withExpanded) && (
        <div
          className={classNames("flex items-center gap-4", {
            "pl-12": hasChecked && withExpanded && !withExpandCloseAll,
            "pl-8": withExpanded && !hasChecked && !withExpandCloseAll,
            "pl-0": withExpanded && !hasChecked && withExpandCloseAll,
          })}
        >
          {withExpandCloseAll && (
            <Fragment>
              <System.Button.Default
                onClick={onExpandCloseAll}
                iconOnly
                className="-ml-2"
                data-tip
                data-for="expand_all"
              >
                {isAllExpanded ? (
                  <System.Svg.CloseAll className="h-4 w-4" />
                ) : (
                  <System.Svg.ExpandAll className="h-4 w-4" />
                )}
              </System.Button.Default>
              <ReactTooltip id="expand_all" effect="solid">
                {isAllExpanded ? <>Close all</> : <>Expand all</>}
              </ReactTooltip>
            </Fragment>
          )}
          {hasChecked && (
            <Table.Checkbox
              isChecked={isChecked}
              isIndeterminate={isIndeterminate}
              onChange={onCheckAll}
            />
          )}
        </div>
      )}
      {children}
    </div>
  )
}
