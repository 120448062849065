import { memoize } from "lodash"
import { TableKey } from "../types"

export const allKeysAreSelected = memoize(
  ({ selected, keys }: { selected: Set<TableKey>; keys: Set<TableKey> }) => {
    if (selected.size === 0) {
      return false
    }
    if (keys.size === 0) {
      return false
    }
    return Array.from(keys).every((key) => selected.has(key))
  },
)
