export enum TabsFilteringProjectsInstallPlugins {
  ALL = "ALL",
  ELIGIBLE_ONLY = "ELIGIBLE_ONLY",
}

export const TABS_FILTERING_PROJECTS_INSTALL_PLUGINS: {
  value: TabsFilteringProjectsInstallPlugins
  label: string
}[] = [
  {
    value: TabsFilteringProjectsInstallPlugins.ALL,
    label: "All",
  },
  {
    value: TabsFilteringProjectsInstallPlugins.ELIGIBLE_ONLY,
    label: "Eligible Only",
  },
]
