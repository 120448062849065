import { useContext } from "react"
import {
  ProgressTasksContext,
  ProgressTasksContextType,
} from "../ProgressTasksContext"

export const useProgressTasksContext = (): ProgressTasksContextType => {
  const context = useContext(ProgressTasksContext)

  if (!context) {
    throw new Error(
      "useProgressTasksContext must be used within a ProcessPendingAndLastFinishedContext",
    )
  }

  return context
}
