import React, { useState } from "react"
import qs from "query-string"
import { System } from "@design-system/index"
import { useTable } from "@design-system/Table/hooks/useTable"
import { PluginsModal } from "@components/Plugin/components/Modal"
import { SVG } from "@svg/index"

import { useFilteringBulkInstallPlugins } from "@components/Plugin/hooks/useFilteringBulkInstallPlugins"
import useNotifications from "@hooks/notifications/useNotifications"
import { ProcessTasksType } from "@prisma/client"

import { PluginsAction } from "@components/Plugin/actions"
import { createEntityIdFromPluginProcess } from "@components/Plugin/helpers/createEntityIdFromPluginProcess"
import { BulkInstallPlugin } from "@components/Plugin/contexts/BulkInstallPluginsContext"
import { mutate } from "swr"
import { getPrivateApiProcessesPendingWithLastFinishedPath } from "@/Process/api/routes-private"
import { SINCE_MS_FINISHED_PLUGIN_TASKS } from "@layouts/SingleProject/constants/pending-api-constant"

export function BulkSitesSelectorIntallPluginFromRepositoryByStep() {
  const { state: statePluginsSelected, resetPlugins } =
    useFilteringBulkInstallPlugins()
  const [modalData, setModalData] = useState({
    open: false,
  })

  const { handleNotifySuccess } = useNotifications()

  const { state, clearCheckedRows } = useTable()
  const { checkedRows } = state

  if (checkedRows.size === 0) {
    return null
  }

  const handleConfirmAddPlugins = async ({
    mustClearCache,
    mustBeActivate,
  }) => {
    const data = []

    statePluginsSelected.plugins.map((plugin: BulkInstallPlugin) => {
      state.checkedRows.forEach((projectId) => {
        data.push({
          type: ProcessTasksType.ADD_PLUGIN,
          entities: {
            id: createEntityIdFromPluginProcess({
              slug: plugin.slug,
              projectId: Number(projectId),
            }),
            name: plugin.name,
            plugin: plugin.slug,
            version: plugin.version,
            must_be_activate: mustBeActivate,
            plugin_uri: plugin.plugin_uri,
            from: plugin.from,
          },
          projectId: projectId,
        })
      })
    })

    const projectsClearCache = []
    if (mustClearCache) {
      state.checkedRows.forEach((projectId) => {
        projectsClearCache.push({
          projectId: projectId,
        })
      })
    }

    const response = await PluginsAction.addPlugins({
      data,
      projectsClearCache: projectsClearCache,
    })

    handleNotifySuccess({
      title: "We have started the installation",
      message: <>We will inform you when it’s done.</>,
    })

    mutate(
      qs.stringifyUrl({
        url: getPrivateApiProcessesPendingWithLastFinishedPath(),
        query: {
          types: [ProcessTasksType.ADD_PLUGIN],
          sinceMs: SINCE_MS_FINISHED_PLUGIN_TASKS,
        },
      }),
    )

    if (response.code === "success") {
      resetPlugins()
      clearCheckedRows()
    }
  }

  const ACTIONS = [
    {
      label: (
        <>
          <SVG.Download className="h-4 w-4" />
          Install
        </>
      ),
      key: "install",
      onClick: async () => {
        setModalData({
          open: true,
        })
      },
    },
  ]

  return (
    <>
      <System.Table.BulkActions.List
        actions={ACTIONS}
        isShowing={checkedRows.size > 0}
      />
      {modalData.open && (
        <PluginsModal.ConfirmInstallPlugin
          open={modalData.open}
          setOpen={() => {
            setModalData({ open: false })
          }}
          handleConfirm={handleConfirmAddPlugins}
          pluginsSelected={statePluginsSelected.plugins}
        />
      )}
    </>
  )
}
