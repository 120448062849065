import { SvgProps } from "@design-system/Icon/types"

export const Sort = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M6.29289 4.29289C6.68342 3.90237 7.31658 3.90237 7.70711 4.29289L11.7071 8.29289C12.0976 8.68342 12.0976 9.31658 11.7071 9.70711C11.3166 10.0976 10.6834 10.0976 10.2929 9.70711L8 7.41421V19C8 19.5523 7.55228 20 7 20C6.44772 20 6 19.5523 6 19V7.41421L3.70711 9.70711C3.31658 10.0976 2.68342 10.0976 2.29289 9.70711C1.90237 9.31658 1.90237 8.68342 2.29289 8.29289L6.29289 4.29289Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M17 4C17.5523 4 18 4.44772 18 5V16.5858L20.2929 14.2929C20.6834 13.9024 21.3166 13.9024 21.7071 14.2929C22.0976 14.6834 22.0976 15.3166 21.7071 15.7071L17.7071 19.7071C17.3166 20.0976 16.6834 20.0976 16.2929 19.7071L12.2929 15.7071C11.9024 15.3166 11.9024 14.6834 12.2929 14.2929C12.6834 13.9024 13.3166 13.9024 13.7071 14.2929L16 16.5858V5C16 4.44772 16.4477 4 17 4Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
