import { createSchema } from "morphism"
import { SourceProjectPluginType } from "../../Plugins/types"

export interface TargetProjectPluginsTableModel {
  id: number
  name: string
  slug: string
  is_active: boolean
  key: string
  version: string
  require_wp_version: string
  require_php_version: string
  created_at: string
  need_update?: {
    id: string
    url: string
    slug: string
    plugin: string
    tested: string
    package: string
    new_version: string
    requires_php: boolean
    compatibility: string
  }
}

export const SchemaProjectPluginsModel = createSchema<
  TargetProjectPluginsTableModel,
  SourceProjectPluginType
>({
  id: "id",
  name: "name",
  slug: "slug",
  is_active: "is_active",
  key: "key",
  version: "version",
  require_wp_version: "require_wp_version",
  require_php_version: "require_php_version",
  created_at: "created_at",
  need_update: "need_update",
})

class ProjectPluginsTableModel implements TargetProjectPluginsTableModel {
  name: string
  slug: string
  is_active: boolean
  key: string
  version: string
  require_wp_version: string
  require_php_version: string
  created_at: string
  need_update?: {
    id: string
    url: string
    slug: string
    plugin: string
    tested: string
    package: string
    new_version: string
    requires_php: boolean
    compatibility: string
  }
  id: number
}

export default ProjectPluginsTableModel
