import { useRef, useEffect } from "react"

const usePrevious = <T,>(value: any): T => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export default usePrevious
