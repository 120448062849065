import { User } from "./types"
import Config from "@environments/data"

export const register = async (user: User) => {
  try {
    const data = await fetch(`${Config.base_url}/api/customers/create`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(user),
    })
    const result = await data.json()

    return result
  } catch (error) {
    return {
      success: false,
      code: "unknow_error",
    }
  }
}
