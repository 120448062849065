import {
  IPluginUpdateStep,
  ProgressTaskStatus,
} from "@/Manager/Processes/types/ProgressTask"
import { Link, System, Tag, TagIcon, tagVariants } from "@design-system/index"
import { ProcessTasksType } from "@prisma/client"
import { VariantProps } from "class-variance-authority"
import { isNil } from "lodash"

const getUpdateTagVariant = (
  state: ProgressTaskStatus | null,
): VariantProps<typeof tagVariants>["variant"] => {
  switch (state) {
    case "active":
      return "default"
    default:
      return "darkGrey"
  }
}

interface SafeUpdatePluginStatusProps {
  task: {
    state: ProgressTaskStatus | null
    progress?: number
    projectId?: number
  }
  type: ProcessTasksType
  steps: IPluginUpdateStep[]
}

export const SafeUpdatePluginStatus = ({
  task,
  steps,
  type,
}: SafeUpdatePluginStatusProps) => {
  const { state, projectId } = task

  if (state === "failed") {
    if (!projectId) {
      return null
    }

    return (
      <div className="flex items-center gap-4">
        <Tag size="sm" variant="darkGrey">
          Attention needed
        </Tag>
        <Link
          variant="neutral"
          data-for={`go_to_${projectId}`}
          size="sm"
          className="gap-2"
          href={`/projects/${projectId}/wordpress/login`}
          target="_blank"
        >
          <System.Svg.WordPress className="h-4 w-4 opacity-50" />
          Admin
          <System.Svg.External className="h-4 w-4 opacity-50" />
        </Link>
      </div>
    )
  }

  if (state === "completed") {
    return (
      <span className="inline-flex items-center gap-2 py-2 text-body-sm font-medium text-success-base">
        <System.Svg.Check size={16} />
        {type === "UPDATE_PLUGIN" && "Updated"}
        {type === "ROLLBACK_PLUGIN" && "Rollback completed"}
        {type === "DEACTIVATE_PLUGIN" && "Deactivated"}
        {type === "ACTIVATE_PLUGIN" && "Activated"}
        {type === "DELETE_PLUGIN" && "Deleted"}
      </span>
    )
  }

  const { progress } = task
  const currentStepIndex = getCurrentStepIndex(steps)
  return (
    <Tag
      size="sm"
      className="text-nowrap"
      variant={getUpdateTagVariant(state)}
      style={
        // if the state is active, we want to show the progress
        // in the tag with a background color
        state === "active" && !isNil(progress)
          ? {
              background: `linear-gradient(90deg, hsl(var(--primary-lighter-200)) ${progress}%, hsl(var(--primary-lightest)) ${progress}%)`,
              color: "hsl(var(--primary-darkest))",
            }
          : undefined
      }
    >
      {state === "active" && (
        <TagIcon size="sm" icon="Loader" className="animate-spin" />
      )}
      {steps[currentStepIndex]?.title}
      {state === "active" && (
        <span className="rounded-2xl bg-main-white px-1 py-0.5 text-body-xs font-semibold text-secondary-base">
          {currentStepIndex + 1}/{steps.length}
        </span>
      )}
    </Tag>
  )
}

const getCurrentStepIndex = (steps: IPluginUpdateStep[]): number => {
  for (let i = steps.length - 1; i >= 0; i--) {
    const stepStatus = steps[i].status.type

    if (stepStatus === "active" || stepStatus === "failed") {
      return i
    }
  }
  return 0
}
