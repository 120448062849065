"use client"

import FetchApiUser from "@/FetchApi/User"
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormRootError,
  Input,
  System,
} from "@design-system/index"
import Config from "@environments/data"
import { validatePassword } from "@helpers/validatePassword"
import { zodResolver } from "@hookform/resolvers/zod"
import * as Fathom from "fathom-client"
import cookie from "js-cookie"
import { getCsrfToken, signIn } from "next-auth/react"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"

const formSchema = z.object({
  email: z.string().email(),
  password: z.string().refine((password) => validatePassword(password), {
    message:
      "Password must contain at least 8 characters, one special character and one number",
  }),
})

export const SignUpForm = () => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  })

  const [csrfToken, setCsrfToken] = useState<string | undefined>(undefined)

  const onSubmit = async ({ email, password }: z.infer<typeof formSchema>) => {
    const { success, code } = await FetchApiUser.register({
      email,
      password,
      terms: true,
      newsletters: true,
      tid: cookie.get("_fprom_tid"),
      ref: cookie.get("userRef"),
    })

    if (!success) {
      let errorMessage = ""
      switch (code) {
        case "bad_parameters":
          errorMessage = "The email or password is not valid."
          break
        case "not_available":
          errorMessage = "The identifiers are not correct."
          break
        case "missed_parameters":
          errorMessage =
            "Whoops! Looks like you forgot to fill out a mandatory field. Please fill it in to complete your account creation."
          break
        default:
          errorMessage =
            "An unreferenced error has occurred. Please contact our support at: support@wp-umbrella.com"
          break
      }
      form.setError("root", {
        message: errorMessage,
      })
      return
    }

    setCsrfToken(await getCsrfToken())

    if (!Config.production) {
      return
    }
    const userRef = cookie.get("userRef")
    if (!userRef || userRef.length === 0) {
      return
    }
    Fathom.trackEvent("register", {
      _value: Number(userRef),
    })
  }

  useEffect(() => {
    if (!csrfToken) {
      return
    }

    signIn("credentials", {
      email: form.getValues("email"),
      password: form.getValues("password"),
      csrfToken,
    })
  }, [csrfToken])

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-10">
        <input name="csrfToken" type="hidden" defaultValue={csrfToken} hidden />
        <input
          name="fprom_tid"
          type="hidden"
          defaultValue={cookie.get("_fprom_tid")}
          hidden
        />
        <div className="space-y-6">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email Address</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter your email"
                    type="email"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter your password"
                    showPasswordValidityTooltip
                    type="password"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormRootError />

        <Button
          type="submit"
          className="w-full"
          disabled={
            form.formState.isSubmitting || form.formState.isSubmitSuccessful
          }
        >
          {form.formState.isSubmitting && <System.Loader />}
          Sign up
        </Button>
      </form>
    </Form>
  )
}
