import { TableKey, TableRowProcess } from "@design-system/Table/types"

export type TableAction =
  | { type: "SET_ROW_KEYS"; payload: { keys: Set<TableKey> } }
  | { type: "SET_ROW_CHILDREN_KEYS"; payload: { keys: Set<TableKey> } }
  | { type: "CLOSE_ALL_ROWS"; payload: {} }
  | { type: "EXPAND_ALL_ROWS"; payload: {} }
  | { type: "CHECK_ALL_ROWS"; payload: {} }
  | { type: "ADD_SET_CHECKED_ROWS"; payload: { keys: Set<TableKey> } }
  | { type: "REMOVE_SET_CHECKED_ROWS"; payload: { keys: Set<TableKey> } }
  | { type: "CLEAR_ALL_CHECKED_ROWS"; payload: {} }
  | { type: "TOGGLE_ROW_CHECKED"; payload: { key: TableKey } }
  | { type: "TOGGLE_ROW_EXPANDED"; payload: { key: TableKey } }
  | {
      type: "SET_ROW_PROCESSING_KEYS"
      payload: Set<TableRowProcess>
    }
  | {
      type: "TOGGLE_ROW_PROCESSING"
      payload: TableRowProcess
    }
  | {
      type: "ADD_ROW_PROCESSING_KEYS"
      payload: Set<TableRowProcess>
    }

export type TableState = {
  withExpandCloseAll: boolean
  withChecked: boolean
  withCheckedChildren: boolean
  withExpanded: boolean
  isStacked: boolean
  withFormContext: boolean
  rowKeys: Set<TableKey>
  rowChildrenKeys: Set<TableKey>
  checkedRows: Set<TableKey>
  expandedRows: Set<TableKey>
  rowProcessingKeys: Set<TableRowProcess>
}

export const tableReducer = (state: TableState, action: TableAction) => {
  switch (action.type) {
    case "SET_ROW_PROCESSING_KEYS": {
      const newRowProcessingKeys = new Set(action.payload)
      return {
        ...state,
        rowProcessingKeys: newRowProcessingKeys,
      }
    }
    case "ADD_ROW_PROCESSING_KEYS": {
      const newRowProcessingKeys = new Set([
        ...state.rowProcessingKeys,
        ...action.payload,
      ])
      return {
        ...state,
        rowProcessingKeys: newRowProcessingKeys,
      }
    }
    case "TOGGLE_ROW_PROCESSING": {
      const rowKey = action.payload.key
      const exist = Array.from(state.rowProcessingKeys).find(
        (item) => item.key === rowKey,
      )

      let newRowProcessingKeys
      if (!exist) {
        newRowProcessingKeys = new Set(
          [...state.rowProcessingKeys].filter((item) => item.key !== rowKey),
        )
      } else {
        newRowProcessingKeys = new Set([
          ...state.rowProcessingKeys,
          action.payload,
        ])
      }

      return {
        ...state,
        rowProcessingKeys: newRowProcessingKeys,
      }
    }
    case "SET_ROW_KEYS": {
      const newRowsKeys = new Set(action.payload.keys)
      return { ...state, rowKeys: newRowsKeys }
    }
    case "CHECK_ALL_ROWS": {
      const rows = state.withCheckedChildren
        ? state.rowChildrenKeys
        : state.rowKeys
      const newCheckedRows = new Set(rows)
      return { ...state, checkedRows: newCheckedRows }
    }
    case "CLEAR_ALL_CHECKED_ROWS": {
      const newCheckedRows = new Set([])
      return { ...state, checkedRows: newCheckedRows }
    }
    case "ADD_SET_CHECKED_ROWS": {
      return {
        ...state,
        checkedRows: new Set([...state.checkedRows, ...action.payload.keys]),
      }
    }
    case "REMOVE_SET_CHECKED_ROWS": {
      const newSet = new Set([...state.checkedRows])
      action.payload.keys.forEach((item) => newSet.delete(item))

      return {
        ...state,
        checkedRows: newSet,
      }
    }

    case "TOGGLE_ROW_CHECKED": {
      const rowKey = action.payload.key
      const newCheckedRows = new Set(state.checkedRows)
      if (newCheckedRows.has(rowKey)) {
        newCheckedRows.delete(rowKey)
      } else {
        newCheckedRows.add(rowKey)
      }
      return {
        ...state,
        checkedRows: newCheckedRows,
      }
    }
    case "TOGGLE_ROW_EXPANDED": {
      const rowKey = action.payload.key
      const newExpandedRows = new Set(state.expandedRows)

      if (newExpandedRows.has(rowKey)) {
        newExpandedRows.delete(rowKey)
      } else {
        newExpandedRows.add(rowKey)
      }

      return {
        ...state,
        expandedRows: newExpandedRows,
      }
    }
    case "EXPAND_ALL_ROWS": {
      const newExpandedRows = new Set(state.rowKeys)

      return {
        ...state,
        expandedRows: newExpandedRows,
      }
    }
    case "CLOSE_ALL_ROWS": {
      const newExpandedRows = new Set([])

      return {
        ...state,
        expandedRows: newExpandedRows,
      }
    }
    case "SET_ROW_CHILDREN_KEYS": {
      const newRowChildrenKeys = new Set(action.payload.keys)
      return {
        ...state,
        rowChildrenKeys: newRowChildrenKeys,
      }
    }

    default:
      return state
  }
}
