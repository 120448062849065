import { SystemButtonTypes } from "@design-system/Button/types"
import { useGenericModal } from "@design-system/Context/GenericModal/hooks/useGenericModal"
import { System } from "@design-system/index"
import useNotifications from "@hooks/notifications/useNotifications"
import { WordPressPluginComponents } from "../.."
import { FetcherProject } from "@/Project/Fetcher"
import { isNil } from "lodash"

interface Props {
  plugin: {
    id: string
    isIgnoredUpdate: boolean
  }
  projectId: number
  onSuccess?: (props: any) => void
  children: React.ReactNode
  button?: SystemButtonTypes
  className?: string
}

export const ToggleIgnoreUpdatePluginAction = ({
  plugin,
  projectId,
  onSuccess,
  children,
  button = "Primary",
  className = "",
}: Props) => {
  const Button = System.Button[button]

  const { openModal, closeModal } = useGenericModal()
  const { handleNotifySuccess } = useNotifications()

  const onSubmit = async () => {
    const response = await FetcherProject.Plugin.toggleIgnorePluginUpdate({
      pluginId: plugin.id,
      projectId: projectId,
    })

    const isIgnore = response.data?.ignore
    handleNotifySuccess({
      title: isIgnore ? "Update excluded" : "Update reconsidered",
      message: isIgnore
        ? "This plugin will not be updated."
        : "You will be able to update this plugin again.",
    })

    if (response.code === "success" && !isNil(onSuccess)) {
      onSuccess(response)
    }

    closeModal()
  }

  return (
    <>
      <Button
        onClick={() => {
          openModal({
            component: (
              <WordPressPluginComponents.Modal.IgnoreUpdatePlugin
                onConfirm={onSubmit}
                isIgnoredUpdate={plugin.isIgnoredUpdate}
              />
            ),
          })
        }}
        className={className}
      >
        {children}
      </Button>
    </>
  )
}
