import { DataRedisManagePlugins } from "@/Manager/Processes/types/DataRedisManagePlugins"
import { DataRedisManageThemes } from "@/Manager/Processes/types/DataRedisManageThemes"
import { DataRedisManageWordPressCore } from "@/Manager/Processes/types/DataRedisManageWordPressCore"
import { ProcessPendingAndLastFinishedContext } from "@components/Processes/contexts/ProcessPendingAndLastFinishedContext/ProcessPendingAndLastFinishedContext"
import { useProcessesInProgressWithLastFinished } from "@components/Processes/hooks/useProcessesInProgressWithLastFinished"
import { TableRowProcess } from "@design-system/Table/types"
import { ProcessTasksType } from "@prisma/client"
import { get, isEmpty, isNil } from "lodash"
import { useEffect, useState } from "react"
import { KeyedMutator } from "swr"

interface Props {
  types: ProcessTasksType[]
  sinceMs?: number
  children: (props: {
    rowsProcessing: Set<TableRowProcess>
    error: any
    mutate: KeyedMutator<{
      pending: Array<
        | DataRedisManagePlugins
        | DataRedisManageThemes
        | DataRedisManageWordPressCore
      >
      finished: Array<
        | DataRedisManagePlugins
        | DataRedisManageThemes
        | DataRedisManageWordPressCore
      >
    }>
    isValidating: boolean
    isLoading: boolean
  }) => React.ReactNode
  getItemsProcessing?: (props: {
    processes: Array<
      | DataRedisManagePlugins
      | DataRedisManageThemes
      | DataRedisManageWordPressCore
    >
    projectId?: number
  }) => Set<TableRowProcess>
  projectId?: number
  options?: {
    refreshInterval?: number
  }
}

const REFRESH_INTERVAL_DURING_PENDING = 5000

export const FetchPendingProcesses = ({
  getItemsProcessing,
  types,
  children,
  sinceMs = 60000,
  options,
  projectId,
}: Props) => {
  const baseRefreshInterval = options?.refreshInterval ?? 25000
  const [refreshInterval, setRefreshInterval] =
    useState<number>(baseRefreshInterval)

  const { data, ...rest } = useProcessesInProgressWithLastFinished<{
    pending: Array<
      | DataRedisManagePlugins
      | DataRedisManageThemes
      | DataRedisManageWordPressCore
    >
    finished: Array<
      | DataRedisManagePlugins
      | DataRedisManageThemes
      | DataRedisManageWordPressCore
    >
  }>({
    types: types,
    sinceMs: sinceMs,
    projectId: projectId,
    options: {
      refreshInterval: refreshInterval,
    },
  })

  useEffect(() => {
    if (
      isEmpty(get(data, "pending")) &&
      refreshInterval !== baseRefreshInterval
    ) {
      setRefreshInterval(baseRefreshInterval)
      return
    }

    if (
      !isEmpty(get(data, "pending")) &&
      refreshInterval !== REFRESH_INTERVAL_DURING_PENDING
    ) {
      setRefreshInterval(REFRESH_INTERVAL_DURING_PENDING)
      return
    }
  }, [data])

  const finished = get(data, "finished", [])
  const pending = get(data, "pending", [])

  let rowsProcessingFinished = new Set<TableRowProcess>()
  if (!isEmpty(finished) && !isNil(getItemsProcessing)) {
    rowsProcessingFinished = getItemsProcessing({
      processes: finished,
      projectId,
    })
  }

  let rowsProcessingPending = new Set<TableRowProcess>()
  if (!isEmpty(pending) && !isNil(getItemsProcessing)) {
    rowsProcessingPending = getItemsProcessing({
      processes: pending,
      projectId,
    })
  }

  const rowsProcessing = new Set<TableRowProcess>([
    ...rowsProcessingFinished,
    ...rowsProcessingPending,
  ])
  return (
    <>
      <ProcessPendingAndLastFinishedContext.Provider
        value={{
          pending: pending,
          finished: finished,
          mutate: rest.mutate,
        }}
      >
        {children({
          rowsProcessing,
          ...rest,
        })}
      </ProcessPendingAndLastFinishedContext.Provider>
    </>
  )
}
