import { TimelineItemEmail, UptimeTimelineEmail } from "../UptimeTimelineEmail"
import {
  TimelineItemPaused,
  UptimeTimelinePaused,
} from "@design-system/Uptime/UptimeTimelinePaused"
import {
  TimelineItemStatus,
  UptimeTimelineStatus,
} from "../UptimeTimelineStatus"
import {
  TimelineItemSwitchedMonitor,
  UptimeTimelineSwitchedMonitor,
} from "../UptimeTimelineSwitchedMonitor"

export enum TimelineType {
  STATUS = "status",
  PAUSED = "paused",
  EMAIL = "email",
  SWITCHED_MONITOR = "switched_monitor",
}

type TimelineItemData =
  | TimelineItemStatus
  | TimelineItemPaused
  | TimelineItemEmail
  | TimelineItemSwitchedMonitor

interface TimelineItem {
  id: number
  type: TimelineType
  data: TimelineItemData
}

interface Props {
  items: Array<TimelineItem>
}

const COMPONENTS = {
  [TimelineType.STATUS]: UptimeTimelineStatus,
  [TimelineType.PAUSED]: UptimeTimelinePaused,
  [TimelineType.EMAIL]: UptimeTimelineEmail,
  [TimelineType.SWITCHED_MONITOR]: UptimeTimelineSwitchedMonitor,
}

export function UptimeTimeline({ items }: Props) {
  return (
    <div role="list" className="-mb-8">
      {items.map((activityItem) => {
        const Component = COMPONENTS[activityItem.type]

        return (
          Component !== null && (
            <article className="relative pb-8" key={activityItem.id}>
              <span
                className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-100"
                aria-hidden="true"
              />
              {/*  @ts-ignore */}
              <Component data={activityItem.data} />
            </article>
          )
        )
      })}
    </div>
  )
}
