import React, { useEffect } from "react"
import { isEmpty } from "lodash"
import EmptyDefault from "@components/Empty/EmptyDefault"
import usePagination from "@components/Pagination/hooks/usePagination"
import useWordPressRepositoryPlugins from "@components/Plugin/hooks/useWordPressRepositoryPlugins"
import WordPressRepositoryPluginModel from "@/Core/Models/Plugins/model"

interface Props {
  children: (props: {
    plugins: WordPressRepositoryPluginModel[]
    total: number
  }) => React.ReactNode
  search: string
}

export function FromWordPressRepository({ children, search = "" }: Props) {
  const { page, actions: actionsPagination } = usePagination()
  const { data } = useWordPressRepositoryPlugins({ page, search })

  useEffect(() => {
    if (isEmpty(data)) {
      return
    }
    actionsPagination.setTotal(data.total)
  }, [data])

  if (isEmpty(data)) {
    return (
      <EmptyDefault>
        We do not have any data about the WordPress plugins repository
      </EmptyDefault>
    )
  }

  return (
    <>
      {children({
        plugins: data.items,
        total: data.total,
      })}
    </>
  )
}
