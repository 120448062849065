import { SvgProps } from "@design-system/Icon/types"

export const Coin = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M8.17317 2.7612C9.38642 2.25866 10.6868 2 12 2C13.3132 2 14.6136 2.25866 15.8268 2.7612C17.0401 3.26375 18.1425 4.00035 19.0711 4.92893C19.9997 5.85752 20.7362 6.95991 21.2388 8.17317C21.7413 9.38642 22 10.6868 22 12C22 13.3132 21.7413 14.6136 21.2388 15.8268C20.7362 17.0401 19.9997 18.1425 19.0711 19.0711C18.1425 19.9997 17.0401 20.7362 15.8268 21.2388C14.6136 21.7413 13.3132 22 12 22C10.6868 22 9.38642 21.7413 8.17317 21.2388C6.95991 20.7362 5.85752 19.9997 4.92893 19.0711C4.00035 18.1425 3.26375 17.0401 2.7612 15.8268C2.25866 14.6136 2 13.3132 2 12C2 10.6868 2.25866 9.38642 2.7612 8.17317C3.26375 6.95991 4.00035 5.85752 4.92893 4.92893C5.85752 4.00035 6.95991 3.26375 8.17317 2.7612ZM12 4C10.9494 4 9.90914 4.20693 8.93853 4.60896C7.96793 5.011 7.08601 5.60028 6.34315 6.34315C5.60028 7.08601 5.011 7.96793 4.60896 8.93853C4.20693 9.90914 4 10.9494 4 12C4 13.0506 4.20693 14.0909 4.60896 15.0615C5.011 16.0321 5.60028 16.914 6.34315 17.6569C7.08601 18.3997 7.96793 18.989 8.93853 19.391C9.90914 19.7931 10.9494 20 12 20C13.0506 20 14.0909 19.7931 15.0615 19.391C16.0321 18.989 16.914 18.3997 17.6569 17.6569C18.3997 16.914 18.989 16.0321 19.391 15.0615C19.7931 14.0909 20 13.0506 20 12C20 10.9494 19.7931 9.90914 19.391 8.93853C18.989 7.96793 18.3997 7.08602 17.6569 6.34315C16.914 5.60028 16.0321 5.011 15.0615 4.60896C14.0909 4.20693 13.0506 4 12 4Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M12.9849 7C13.5223 6.98523 14.054 7.11517 14.5242 7.3764C14.9998 7.64062 15.3946 8.02921 15.6664 8.50057C15.9422 8.97904 15.7779 9.59052 15.2994 9.86635C14.821 10.1422 14.2095 9.97791 13.9336 9.49943C13.8431 9.34231 13.7115 9.21279 13.5529 9.12471C13.3944 9.03664 13.2149 8.99333 13.0336 8.99943C13.0224 8.99981 13.0112 9 13 9H11C10.7348 9 10.4804 9.10536 10.2929 9.29289C10.1054 9.48043 10 9.73478 10 10C10 10.2652 10.1054 10.5196 10.2929 10.7071C10.4804 10.8946 10.7348 11 11 11H13C13.7957 11 14.5587 11.3161 15.1213 11.8787C15.6839 12.4413 16 13.2043 16 14C16 14.7957 15.6839 15.5587 15.1213 16.1213C14.5587 16.6839 13.7957 17 13 17H11.0151C10.4777 17.0148 9.94598 16.8848 9.47578 16.6236C9.00017 16.3594 8.60538 15.9708 8.33365 15.4994C8.05782 15.021 8.22209 14.4095 8.70057 14.1336C9.17904 13.8578 9.79052 14.0221 10.0664 14.5006C10.1569 14.6577 10.2885 14.7872 10.4471 14.8753C10.6056 14.9634 10.7851 15.0067 10.9664 15.0006C10.9776 15.0002 10.9888 15 11 15H13C13.2652 15 13.5196 14.8946 13.7071 14.7071C13.8946 14.5196 14 14.2652 14 14C14 13.7348 13.8946 13.4804 13.7071 13.2929C13.5196 13.1054 13.2652 13 13 13H11C10.2043 13 9.44129 12.6839 8.87868 12.1213C8.31607 11.5587 8 10.7957 8 10C8 9.20435 8.31607 8.44129 8.87868 7.87868C9.44129 7.31607 10.2043 7 11 7H12.9849Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M12 6C12.5523 6 13 6.44772 13 7V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V7C11 6.44772 11.4477 6 12 6Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
