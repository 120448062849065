import { Button, System } from "@design-system/index"
import { Menu, Transition } from "@headlessui/react"
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid"
import classNames from "classnames"
import { Fragment } from "react"

interface Props {
  size?: "xsmall" | "small" | "medium" | "large"
  buttons: {
    render: Function
  }[]
  index?: number
  children?: React.ReactNode
  className?: string
  iconOnly?: boolean
}

export function ButtonDropdown({
  buttons,
  children,
  index = 1,
  size = "small",
  className = "",
  iconOnly = false,
}: Props) {
  return (
    <div className={classNames(className, "relative w-[max-content]")}>
      <Menu as="div">
        <Menu.Button>
          {!iconOnly && (
            <System.Button.Outlined element="div">
              {children || "Actions"}
              <EllipsisHorizontalIcon className="h-4 w-4" />
            </System.Button.Outlined>
          )}

          {iconOnly && (
            <Button size={"icon"} variant={"ghost"} className="gap-2" asChild>
              <div>
                <System.Svg.Actions size={17} />
              </div>
            </Button>
          )}
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={classNames(
              {
                "w-48": size === "xsmall",
                "w-64": size === "small",
                "w-72": size === "medium",
                "w-96": size === "large",
              },
              "absolute right-0 -mr-1 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
            )}
            style={{
              zIndex: 500 - index,
            }}
          >
            <div className="py-1">
              {buttons.map((button, index) => {
                return (
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <div
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full px-4 py-2 text-sm",
                        )}
                      >
                        {button.render()}
                      </div>
                    )}
                  </Menu.Item>
                )
              })}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
