import { System } from "@design-system/index"
import { isNil } from "lodash"

interface Props {
  children: React.ReactNode
  articleId?: string
  className?: string
}

const ContactSupportOrDocumentation = ({
  children,
  articleId,
  className,
}: Props) => {
  if (isNil(articleId)) {
    return (
      <System.Link href="/support" className={className}>
        {children}
      </System.Link>
    )
  }

  return (
    <span
      className="cursor-pointer text-body-sm font-semibold text-primary-base hover:underline"
      onClick={(e) => {
        //@ts-ignore
        Beacon("article", articleId, {
          type: "sidebar",
        })
        e.stopPropagation()
      }}
    >
      {children}
    </span>
  )
}

export default ContactSupportOrDocumentation
