import { cn } from "@helpers/utils"
import * as React from "react"

interface AdditionalProps {
  as?:
    | "div"
    | "table"
    | "thead"
    | "tbody"
    | "tfoot"
    | "tr"
    | "th"
    | "td"
    | "caption"
}

const ElementOrDiv = React.forwardRef<
  any,
  React.HTMLAttributes<HTMLElement> & AdditionalProps
>(({ as: Component = "div", ...props }, ref) => {
  return <Component ref={ref} {...props} />
})

const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement> & AdditionalProps
>(({ className, as = "table", ...props }, ref) => (
  <ElementOrDiv
    as={as}
    ref={ref}
    className={cn("w-full caption-bottom text-sm", className)}
    {...props}
  />
))
Table.displayName = "Table"

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement> & AdditionalProps
>(({ className, as = "thead", ...props }, ref) => (
  <ElementOrDiv as={as} ref={ref} className={className} {...props} />
))
TableHeader.displayName = "TableHeader"

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement> & AdditionalProps
>(({ className, as = "tbody", ...props }, ref) => (
  <ElementOrDiv
    as={as}
    ref={ref}
    className={cn("[&_tr:last-child]:border-0", className)}
    {...props}
  />
))
TableBody.displayName = "TableBody"

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement> & AdditionalProps
>(({ className, as = "tfoot", ...props }, ref) => (
  <ElementOrDiv
    as={as}
    ref={ref}
    className={cn(
      "border-t bg-muted/50 font-medium [&>tr]:last:border-b-0",
      className,
    )}
    {...props}
  />
))
TableFooter.displayName = "TableFooter"

const TableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement> &
    AdditionalProps & {
      backgroundOnSelectOrHover?: boolean
    }
>(
  (
    { className, as = "tr", backgroundOnSelectOrHover = true, ...props },
    ref,
  ) => (
    <ElementOrDiv
      as={as}
      ref={ref}
      className={cn(
        className,
        "border-b border-b-main-grey-100 transition-colors",
        {
          "hover:bg-primary-lightest/50 data-[state=selected]:bg-primary-lightest":
            backgroundOnSelectOrHover,
        },
      )}
      {...props}
    />
  ),
)
TableRow.displayName = "TableRow"

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement> & AdditionalProps
>(({ className, as = "th", ...props }, ref) => (
  <ElementOrDiv
    as={as}
    ref={ref}
    className={cn(
      "h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0",
      className,
    )}
    {...props}
  />
))
TableHead.displayName = "TableHead"

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement> & AdditionalProps
>(({ className, as = "td", ...props }, ref) => (
  <ElementOrDiv
    as={as}
    ref={ref}
    className={cn("p-4 align-middle [&:has([role=checkbox])]:pr-0", className)}
    {...props}
  />
))
TableCell.displayName = "TableCell"

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement> & AdditionalProps
>(({ className, as = "caption", ...props }, ref) => (
  <ElementOrDiv
    as={as}
    ref={ref}
    className={cn("mt-4 text-sm text-muted-foreground", className)}
    {...props}
  />
))
TableCaption.displayName = "TableCaption"

export {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
}
