import { useEffect, useRef } from "react"
import { System } from "@design-system/index"
import { isNil } from "lodash"

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  isDisabled?: boolean
  isChecked?: boolean
  isIndeterminate?: boolean
  register?: any
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const TableCheckbox = ({
  className = "",
  isDisabled = false,
  isIndeterminate = false,
  isChecked = false,
  onChange,
  ...rest
}: Props) => {
  const checkboxRef = useRef(null)

  useEffect(() => {
    if (isNil(checkboxRef.current)) {
      return
    }

    if (isChecked && !isIndeterminate) {
      checkboxRef.current.checked = true
      checkboxRef.current.indeterminate = false
    } else if (isIndeterminate) {
      checkboxRef.current.checked = false
      checkboxRef.current.indeterminate = true
    } else {
      checkboxRef.current.checked = false
      checkboxRef.current.indeterminate = false
    }
  }, [isChecked, isIndeterminate])

  if (!isNil(rest.register)) {
    return (
      <System.Form.Input
        className={className}
        type="checkbox"
        isDisabled={isDisabled}
        {...rest}
      />
    )
  }

  return (
    <System.Form.Input
      ref={checkboxRef}
      className={className}
      type="checkbox"
      isDisabled={isDisabled}
      isChecked={isChecked}
      onChange={onChange}
      {...rest}
    />
  )
}
