import { PluginChangelog } from "@components/Plugin/components/Changelog"
import { PluginImage } from "@components/Plugin/components/PluginImage"
import {
  Button,
  Checkbox,
  System,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tag,
} from "@design-system/index"
import { useBulkPlugins } from "./useBulkPluginsStore"
import { BulkPluginGroupTable } from "./BulkPluginGroupTable"
import React from "react"
import { PluginGroup } from "@components/Plugin/components/Table/AllPlugins"
import { cn } from "@helpers/utils"
import { Loader } from "@design-system/Loader"
import { useBulkPluginProcessesStore } from "@components/Plugin/components/Table/AllPlugins/useBulkPluginProcessesStore"

interface BulkTablePluginsProps {
  pluginGroups: PluginGroup[]
}

export const BulkTablePlugins = ({ pluginGroups }: BulkTablePluginsProps) => {
  const {
    getIsPluginGroupExpanded,
    togglePluginGroupExpanded,
    getIsAllPluginGroupsExpanded,
    toggleExpandAllPluginGroups,

    getIsAllPluginInstancesSelected,
    getIsSomePluginInstancesSelected,
    toggleAllPluginInstancesSelected,

    togglePluginGroupSelected,
    getIsPluginGroupSelected,

    getHasPluginGroupUpdateAvailable,
  } = useBulkPlugins()

  const { getOngoingProcessesForRowCount } = useBulkPluginProcessesStore()

  return (
    <Table as="div" className="row-auto grid grid-cols-1">
      <TableHeader
        as="div"
        className="grid grid-cols-subgrid grid-rows-subgrid"
      >
        <TableRow as="div" className="grid w-full grid-cols-[2rem,_2rem,_auto]">
          <TableHead className="flex items-center justify-end p-0" as="div">
            <Button
              size="icon"
              variant="minimal"
              type="button"
              onClick={toggleExpandAllPluginGroups}
            >
              {!getIsAllPluginGroupsExpanded() && (
                <System.Svg.ExpandAll
                  size={16}
                  className="text-primary-darkest"
                />
              )}
              {getIsAllPluginGroupsExpanded() && (
                <System.Svg.CloseAll
                  size={16}
                  className="text-primary-darkest"
                />
              )}
            </Button>
          </TableHead>

          <TableHead className="flex w-8 items-center" as="div">
            <Checkbox
              checked={
                (getIsAllPluginInstancesSelected() ||
                  (getIsSomePluginInstancesSelected() && "indeterminate")) as
                  | boolean
                  | "indeterminate"
              }
              onCheckedChange={toggleAllPluginInstancesSelected}
              aria-label="Select all"
            />
          </TableHead>

          <TableHead
            className="flex items-center text-body-sm leading-none text-main-grey-400"
            as="div"
          >
            Name
          </TableHead>
        </TableRow>
      </TableHeader>

      <TableBody as="div" className="row-auto grid grid-cols-1 bg-white">
        {pluginGroups.length &&
          pluginGroups.map(({ id, slug, name, key, children }) => (
            <TableRow as="div" key={id} backgroundOnSelectOrHover={false}>
              <div
                className={cn(
                  "grid-rows-auto sticky top-16 z-50 grid w-full grid-cols-[2rem,_2rem,_400px,_1fr,_1fr] bg-white",
                  {
                    "shadow-md shadow-primary-lightest":
                      getIsPluginGroupExpanded(id),
                  },
                )}
              >
                <TableCell
                  as="div"
                  className="flex items-center justify-end p-0"
                >
                  <Button
                    size="icon"
                    variant="minimal"
                    type="button"
                    onClick={() => togglePluginGroupExpanded(id)}
                  >
                    {!getIsPluginGroupExpanded(id) && (
                      <System.Svg.ChevronDown
                        size={16}
                        className="text-primary-darkest"
                      />
                    )}
                    {getIsPluginGroupExpanded(id) && (
                      <System.Svg.ChevronUp
                        size={16}
                        className="text-primary-darkest"
                      />
                    )}
                  </Button>
                </TableCell>

                <TableCell as="div" className="flex w-8 items-center">
                  <Checkbox
                    checked={getIsPluginGroupSelected(id)}
                    onCheckedChange={() => togglePluginGroupSelected(id)}
                  />
                </TableCell>

                <TableCell as="div" className="flex items-center gap-2">
                  <span
                    className="cursor-pointer"
                    onClick={() => togglePluginGroupSelected(id)}
                  >
                    <PluginImage slug={slug} />
                  </span>

                  <div className="flex flex-col items-start">
                    <div className="flex items-start gap-2">
                      <span
                        className="cursor-pointer select-none text-sm font-bold text-primary-darkest"
                        onClick={() => togglePluginGroupSelected(id)}
                      >
                        {name}
                      </span>

                      <Tag
                        size="sm"
                        variant="lightGrey"
                        className="text-nowrap"
                      >
                        {`${children.length} ${
                          children.length > 1 ? "sites" : "site"
                        }`}
                      </Tag>
                    </div>

                    <PluginChangelog
                      plugin={{
                        key,
                        name,
                        slug,
                      }}
                    />
                  </div>
                </TableCell>

                <TableCell as="div" className="flex items-center">
                  {getHasPluginGroupUpdateAvailable(id) && (
                    <Tag size="sm">
                      <System.Svg.Update size={13} className="shrink-0" />

                      <span className="text-nowrap">Update Available</span>
                    </Tag>
                  )}
                </TableCell>

                {getOngoingProcessesForRowCount(id) > 0 && (
                  <TableCell as="div" className="flex items-center">
                    <Tag size="sm">
                      <Loader size={16} />
                      {`${getOngoingProcessesForRowCount(id)} ${
                        getOngoingProcessesForRowCount(id) > 1
                          ? "Processes"
                          : "Process"
                      } in Progress`}
                    </Tag>
                  </TableCell>
                )}
              </div>
              <div
                hidden={!getIsPluginGroupExpanded(id)}
                className="!pb-2 !pl-[3.5rem] !pr-0"
              >
                <BulkPluginGroupTable data={children} />
              </div>
            </TableRow>
          ))}

        {!pluginGroups.length && (
          <TableRow as="div">
            <TableCell colSpan={4} className="h-24 text-center" as="div">
              No results.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
