import classNames from "classnames"
import { MouseEventHandler } from "react"
import {
  getDefaultClassNameLabel,
  getDefaultClassNameLabelDescription,
} from "../../../../ui/System"

interface PropsDescription {
  className?: string
  children: React.ReactNode
}

export const LabelDescription = ({
  children,
  className = "",
}: PropsDescription) => {
  return (
    <p className={classNames(getDefaultClassNameLabelDescription(), className)}>
      {children}
    </p>
  )
}

interface Props {
  htmlFor: string
  className?: string
  withoutBold?: boolean
  required?: boolean
  children: React.ReactNode
  onClick?: MouseEventHandler<HTMLLabelElement>
}

export const Label = ({
  htmlFor,
  className = "",
  withoutBold = false,
  required = false,
  children,
  ...rest
}: Props) => {
  return (
    <label
      htmlFor={htmlFor}
      className={classNames(
        getDefaultClassNameLabel({ withoutBold, required }),
        className,
      )}
      {...rest}
    >
      {children}
    </label>
  )
}

export default Label
