import { TABLE_ID_SEPARATOR } from "@design-system/Table/constants/table-id-separator"

interface Props {
  slug: string
  projectId: number | string
}

/**
 * @returns {string}
 */
export const createEntityIdFromPluginProcess = ({ slug, projectId }: Props) => {
  return `${slug}${TABLE_ID_SEPARATOR}${projectId}`
}
