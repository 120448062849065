import { SvgProps } from "@design-system/Icon/types"

export const Key = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          strokeWidth={strokeWidth}
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15.4615 5.76446C16.196 5.76446 16.9003 6.05622 17.4197 6.57555C17.939 7.09488 18.2308 7.79924 18.2308 8.53369M21 8.53369C21.0001 9.34149 20.8235 10.1395 20.4826 10.8719C20.1417 11.6042 19.6447 12.2531 19.0265 12.7731C18.4083 13.293 17.6839 13.6715 16.9039 13.8819C16.124 14.0922 15.3075 14.1295 14.5117 13.9909C13.992 13.9014 13.4418 14.0149 13.0689 14.3878L10.6154 16.8414H8.53846V18.9183H6.46154V20.9952H3V18.394C3 17.8429 3.21877 17.314 3.60831 16.9254L9.60738 10.9263C9.98031 10.5534 10.0938 10.0032 10.0043 9.48354C9.87341 8.72769 9.90096 7.95289 10.0852 7.20825C10.2694 6.46361 10.6064 5.76535 11.0746 5.15774C11.5428 4.55014 12.1322 4.04642 12.8053 3.67852C13.4784 3.31063 14.2206 3.08657 14.9849 3.02055C15.7491 2.95453 16.5188 3.04799 17.245 3.29501C17.9712 3.54202 18.6382 3.93721 19.2038 4.45552C19.7693 4.97383 20.2209 5.60396 20.5301 6.30598C20.8393 7.008 20.9993 7.76659 21 8.53369Z"
        />
      </g>
    </svg>
  )
}
