import { System } from "@design-system/index"
import classNames from "classnames"
import { TextProps } from "@design-system/Text"

interface Props extends TextProps {
  style?: React.CSSProperties
}

export const Strong = ({
  children,
  as = "strong",
  className = "",
  ...rest
}: Props) => {
  const classes = classNames("text-gray-900 font-semibold", className)

  // @ts-ignore
  return (
    <System.Text as={as} className={classes} {...rest}>
      {children}
    </System.Text>
  )
}
