import { isEmpty } from "lodash"
import { SVGChip } from "@svg/Chip"
import React, { useState } from "react"

interface Props {
  slug: string
}
export const PluginImage = ({ slug }: Props) => {
  const [imageUrl, setImageUrl] = useState(
    `https://ps.w.org/${slug}/assets/icon-256x256.png`,
  )

  const onErrorImage = () => {
    setImageUrl("")
  }

  return !isEmpty(imageUrl) ? (
    <img
      className="h-8 w-8 flex-none rounded-full"
      src={imageUrl}
      onError={onErrorImage}
    />
  ) : (
    <div className="background-gray-400 flex h-8 w-8 flex-none items-center justify-center rounded-full border">
      <SVGChip />
    </div>
  )
}
