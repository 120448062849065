import { getProcess } from "./getProcess"
import { createProcess } from "./createProcess"
import Config from "@environments/data"

export const getPrivateApiProcessPath = (id: string): string => {
  return `${Config.private_api_url}/processes/${id}`
}

const FetchApiProcess = {
  getProcess,
  createProcess,
}

export default FetchApiProcess
