import { createContext, useReducer } from "react"
import { STRATEGY_INSTALL_PLUGIN } from "constants/bulk-install-plugins"

export interface BulkInstallPlugin {
  name: string
  requires?: number
  requires_php?: number
  tested?: number
  version?: string | number
  slug: string
  php_version_outdated?: boolean
  is_installed?: boolean
  plugin_uri: string
  from: STRATEGY_INSTALL_PLUGIN
}

export interface StateInstallPlugin {
  from: STRATEGY_INSTALL_PLUGIN
  plugins: BulkInstallPlugin[]
}

export enum TypeInstallPluginsAction {
  SET_FROM,
  SET_PLUGINS,
  SET_PROJECTS,
  RESET_PLUGINS,
}

export type InstallPluginActions =
  | {
      type: TypeInstallPluginsAction.SET_FROM
      payload: STRATEGY_INSTALL_PLUGIN
    }
  | {
      type: TypeInstallPluginsAction.SET_PLUGINS
      payload: BulkInstallPlugin[]
    }
  | {
      type: TypeInstallPluginsAction.RESET_PLUGINS
      payload: null
    }

const initialState: StateInstallPlugin = {
  from: STRATEGY_INSTALL_PLUGIN.WORDPRESS_REPOSITORY,
  plugins: [],
}

const reducer: React.Reducer<StateInstallPlugin, InstallPluginActions> = (
  state: StateInstallPlugin,
  action: InstallPluginActions,
) => {
  switch (action.type) {
    case TypeInstallPluginsAction.SET_PLUGINS:
      return {
        ...state,
        plugins: action.payload,
      }
    case TypeInstallPluginsAction.SET_FROM:
      return {
        ...state,
        from: action.payload,
      }
    case TypeInstallPluginsAction.RESET_PLUGINS:
      return {
        ...state,
        plugins: [],
      }
    default:
      return state
  }
}

export const BulkInstallPluginsContext = createContext<{
  state: StateInstallPlugin
  dispatch: React.Dispatch<InstallPluginActions>
}>({
  state: initialState,
  dispatch: () => null,
})

interface Props {
  children: React.ReactNode
  plugins?: BulkInstallPlugin[]
}

export const BulkInstallPluginsContextProvider = ({
  children,
  plugins = [],
}: Props) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    plugins,
  })

  return (
    <BulkInstallPluginsContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <>{children}</>
    </BulkInstallPluginsContext.Provider>
  )
}

export default {
  Context: BulkInstallPluginsContext,
  Provider: BulkInstallPluginsContextProvider,
}
