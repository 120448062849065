import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders"
import { getPrivateApiBackupDownloadLinkDatabasePath } from "@/Project/api/routes-backup-private"
import { RequestDownloadBackupType } from "@prisma/client"

export const getBackupDownloadLinkDatabase = async ({
  projectId,
  backupId,
  type,
}: {
  projectId: number
  backupId: string
  type?: RequestDownloadBackupType
}) => {
  const headers = await getFetchPrivateHeaders()

  const result = await fetch(
    getPrivateApiBackupDownloadLinkDatabasePath({ projectId, backupId }),
    {
      method: "GET",
      headers: headers,
    },
  )

  return await result.json()
}
