import { PluginsAction } from "@components/Plugin/actions"
import { ResponseCreateProcessTaskUpdatePlugin } from "@components/Plugin/actions/createProcessTaskUpdatePlugin"
import { SystemButtonTypes } from "@design-system/Button/types"
import { useGenericModal } from "@design-system/Context/GenericModal/hooks/useGenericModal"
import { System } from "@design-system/index"
import useNotifications from "@hooks/notifications/useNotifications"
import { isNil } from "lodash"
import { WordPressPluginComponents } from "../.."
import { RequestCacheClearFormInputs } from "@components/Processes/components/Form/types"

interface Props {
  plugin: {
    id: string
    plugin: string
    key_plugin: string
    name: string
    version: string
  }
  entities?: {
    [key: string]: any
  }
  projectId: number
  onSuccess?: (props: ResponseCreateProcessTaskUpdatePlugin) => void
  children: React.ReactNode
  button?: SystemButtonTypes
  className?: string
}

export const DeletePluginAction = ({
  plugin,
  projectId,
  onSuccess,
  entities,
  children,
  button = "Primary",
  className = "",
}: Props) => {
  const Button = System.Button[button]

  const { openModal, closeModal } = useGenericModal()
  const { handleNotifySuccess } = useNotifications()

  const onSubmit = async (values: RequestCacheClearFormInputs) => {
    const response = await PluginsAction.deletePlugin({
      data: {
        entities: {
          id: plugin.id,
          plugin: plugin.key_plugin,
          name: plugin.name,
          version: plugin.version,
          ...entities,
        },
        projectId: projectId,
      },
      projectsClearCache: values.needCacheClear
        ? [
            {
              projectId: projectId,
            },
          ]
        : [],
    })

    handleNotifySuccess({
      title: "We have started the deactivation process",
      message: <>We will inform you when it’s done.</>,
    })

    if (response.code === "success" && !isNil(onSuccess)) {
      onSuccess(response)
    }

    closeModal()
  }

  return (
    <>
      <Button
        onClick={() => {
          openModal({
            component: (
              <WordPressPluginComponents.Modal.DeletePlugin
                pluginName={`${plugin.name} ${plugin.version}`}
                onConfirm={onSubmit}
              />
            ),
          })
        }}
        className={className}
      >
        {children}
      </Button>
    </>
  )
}
