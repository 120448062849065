import { SVGToggleCross } from "@svg/ToggleCross"
import { SVGToggleValid } from "@svg/ToggleValid"
import classNames from "classnames"
import { MouseEventHandler } from "react"

interface Props {
  onClick?: MouseEventHandler<HTMLButtonElement>
  active?: boolean
}

export const ButtonToggle = ({ onClick, active = false }: Props) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={classNames(
        {
          "bg-indigo-600": active,
          "bg-gray-200": !active,
        },
        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
      )}
      aria-pressed="false"
    >
      <span
        className={classNames(
          {
            "translate-x-5": active,
            "translate-x-0": !active,
          },
          "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
        )}
      >
        <span
          className={classNames(
            {
              "opacity-0 duration-100 ease-out": active,
              "opacity-100 duration-200 ease-in": !active,
            },
            "absolute inset-0 flex h-full w-full items-center justify-center opacity-100 transition-opacity duration-200 ease-in",
          )}
          aria-hidden="true"
        >
          <SVGToggleCross />
        </span>
        <span
          className={classNames(
            {
              "opacity-100 duration-200 ease-in": active,
              "opacity-0 duration-100 ease-out": !active,
            },
            "absolute inset-0 flex h-full w-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out",
          )}
          aria-hidden="true"
        >
          <SVGToggleValid />
        </span>
      </span>
    </button>
  )
}
