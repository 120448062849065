export const passwordSpecialCharacters = /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/
export const passwordMinLength = 8
export const passwordHasNumber = /\d/

export const validatePassword = (password: string) => {
  const hasSpecialChar = passwordSpecialCharacters.test(password)
  const isValidLength = password.length >= passwordMinLength
  const hasNumber = passwordHasNumber.test(password)
  return hasSpecialChar && isValidLength && hasNumber
}
