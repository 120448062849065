import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders"
import { getPrivateApiOneProjectScanLighthousePath } from "@/Project/api/routes-private"

interface Props {
  projectId: string | number
}

export const requestScan = async ({ projectId }: Props) => {
  const headers = await getFetchPrivateHeaders()
  try {
    const response = await fetch(
      getPrivateApiOneProjectScanLighthousePath({ projectId }),
      {
        method: "POST",
        headers: headers,
      },
    )

    return await response.json()
  } catch (e) {
    return null
  }
}
