import { JSONProjectPluginApiData } from "@/Plugin/types/plugin-from-api"
import { System } from "@design-system/index"
import { useBulkPlugins } from "@design-system/New/BulkTable/BulkTablePlugins/useBulkPluginsStore"
import classNames from "classnames"
import { useMemo } from "react"

interface Props {
  className?: string
  plugins?: JSONProjectPluginApiData[]
}

export const BulkActionsCheckedRows = ({ className = "", plugins }: Props) => {
  const classes = classNames("text-sm text-gray-600 flex-1", className)

  const {
    selectedPluginInstances,
    selectAllPluginInstances,
    deselectAllPluginInstances,
  } = useBulkPlugins()

  const selectedRows = useMemo(
    () =>
      Object.entries(selectedPluginInstances).reduce((acc, [key, value]) => {
        if (value) {
          acc.add(key)
        }
        return acc
      }, new Set<string>()),
    [selectedPluginInstances],
  )

  return (
    <System.Content.BulkActions.CheckedRows
      checkAllRows={selectAllPluginInstances}
      checkedRows={selectedRows}
      clearCheckedRows={deselectAllPluginInstances}
      rowKeys={new Set(plugins?.map((plugin) => plugin.id))}
      className={classes}
    />
  )
}
