import classNames from "classnames"
import { TableKey } from "@design-system/Table/types"
import { ExclamationCircleIcon } from "@heroicons/react/24/outline"
import useLocalStorage from "@hooks/useLocalStorage"
import { SVGWordPress } from "@svg/WordPress"
import { isEmpty } from "lodash"
import { useEffect } from "react"
import ReactTooltip from "react-tooltip"
import { System } from "@design-system/index"

interface Props {
  id: TableKey
  text?: string
  tooltip?: string
  projectId?: number
  withOneClickAdmin?: boolean
  className?: string
}

export const CellProcessFailed = ({
  id,
  text = "Process failed!",
  tooltip = null,
  projectId = null,
  withOneClickAdmin = false,
  className = "",
}: Props) => {
  const KEY = `progress_${id}`
  const [progress, setProgress, removeProgress] = useLocalStorage(KEY, 0)

  useEffect(() => {
    removeProgress()

    return () => {
      removeProgress()
    }
  }, [])

  return (
    <>
      <div className={classNames("flex w-full items-center gap-2", className)}>
        <ExclamationCircleIcon className="h-4 w-4 text-red-600" />
        <div>
          <System.Text className="text-red-600">
            <strong>{text}</strong>
          </System.Text>
          {!isEmpty(tooltip) && (
            <>
              <p
                className="cursor-pointer text-xs underline"
                data-tip
                data-for={`cell_process_${id}`}
              >
                View details
              </p>
              <ReactTooltip
                id={`cell_process_${id}`}
                effect="solid"
                className="w-[20%]"
              >
                <span>{tooltip}</span>
              </ReactTooltip>
            </>
          )}
        </div>
        {projectId && withOneClickAdmin && (
          <div className="ml-auto">
            <System.Button.Outlined
              href={`/projects/${projectId}/wordpress/login`}
              target="_blank"
              className="flex cursor-pointer items-center gap-2 rounded border bg-white p-2 text-xs hover:shadow"
            >
              <SVGWordPress className="h-3 w-3" />
              WP Admin
            </System.Button.Outlined>
          </div>
        )}
      </div>
    </>
  )
}
