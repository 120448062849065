import NextHead from "next/head"
import { useHeadTitle } from "../../contexts/PageDependenciesContext"
import Config from "../../environments"

const Head = (props) => {
  const { title } = useHeadTitle()
  return (
    <NextHead>
      <meta charSet="UTF-8" />
      <title>{title}</title>
      <meta name="description" content={props.description || ""} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {Config.production ? (
        <link rel="icon" type="image/png" href="/images/logo-background.svg" />
      ) : (
        <link rel="icon" type="image/png" href="/images/logo-noir.svg" />
      )}
    </NextHead>
  )
}

export default Head
