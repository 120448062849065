import { TargetWordPressRepositoryPlugin } from "./schemas"
import versionCompare from "@helpers/versionCompare"

class WordPressRepositoryPluginModel
  implements TargetWordPressRepositoryPlugin
{
  name: string
  active_installs: number
  author: string
  author_profile: string
  homepage: string
  rating: number
  requires: number
  requires_php: number
  short_description: number
  tested: number
  sections: {
    description: string
    changelog: string
  }
  version: number
  slug: string
  wordpress_version_outdated?: boolean
  php_version_outdated?: boolean
  is_installed?: boolean
  versions: {
    [key: string]: string
  }

  public setWordPressVersionIsOutdated(value) {
    this.wordpress_version_outdated = value
    return this
  }

  public setPhpVersionIsOutdated(value) {
    this.php_version_outdated = value
    return this
  }

  public setIsInstalled(value) {
    this.is_installed = value
    return this
  }

  public getLatestVersions({ maximum = 1000 }: { maximum?: number }) {
    if (!this.versions) {
      return []
    }

    const entries = Object.entries(this.versions)
    return entries
      .filter(([key]) => key !== "trunk")
      .sort(([keyA], [keyB]) => {
        return versionCompare(keyA, keyB, ">") ? 1 : -1
      })
      .slice(-maximum)
      .reverse()
      .map(([version]) => {
        return version
      })
  }
}

export default WordPressRepositoryPluginModel
