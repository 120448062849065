import { SystemConstant } from "@design-system/constants"
import classNames from "classnames"
import { PropsWithChildren } from "react"

interface Props extends PropsWithChildren {
  className?: string
  withIcon?: boolean
}

export const ContentHeader = ({
  children,
  className = "",
  withIcon = false,
  ...rest
}: Props) => {
  const classes = classNames(
    {
      "flex items-center gap-4": withIcon,
    },
    "w-full",
    SystemConstant.PaddingY,
    SystemConstant.PaddingX,
    className,
  )
  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  )
}
