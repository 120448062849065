import { SuspenseFallbackDefault } from "./Default"
import { SuspenseFallbackFull } from "./Full"
import { SuspenseFallbackTable } from "./Table"
import { SuspenseFallbackSingle } from "./Single"
import { SuspenseFallbackSecurity } from "./Security"

export const SuspenseFallback = {
  Default: SuspenseFallbackDefault,
  Full: SuspenseFallbackFull,
  Table: SuspenseFallbackTable,
  Single: SuspenseFallbackSingle,
  Security: SuspenseFallbackSecurity,
}
