import { FetcherProjectBackups } from "./Backup"
import { FetcherProjectLighthouse } from "./Lighthouse"
import { FetcherProjectRestoration } from "./Restore"
import { FetcherProjectSecurity } from "./Security"
import { FetcherProjectNote } from "./Note"
import { FetcherSingleProject } from "./SingleProject"
import { FetcherSingleProjectPlugin } from "./Plugin"
import { FetcherProjectWordPressUsers } from "./WordPressUser"
import { FetcherSingleProjectTheme } from "./Theme"
import { FetcherProjectMaintenanceReport } from "./MaintenanceReport"
import { FetcherProjectVisualRegression } from "./VisualRegression"

export const FetcherProject = {
  Security: FetcherProjectSecurity,
  Lighthouse: FetcherProjectLighthouse,
  Backup: FetcherProjectBackups,
  Restoration: FetcherProjectRestoration,
  Note: FetcherProjectNote,
  SingleProject: FetcherSingleProject,
  Plugin: FetcherSingleProjectPlugin,
  Theme: FetcherSingleProjectTheme,
  MaintenanceReport: FetcherProjectMaintenanceReport,
  WordPress: {
    Users: FetcherProjectWordPressUsers,
  },
  VisualRegression: FetcherProjectVisualRegression,
}
