import { System } from "@design-system/index"
import { TableKey } from "@design-system/Table/types"
import useLocalStorage from "@hooks/useLocalStorage"
import useWindowUnloadEffect from "@hooks/useWindowUnloadEffect"
import Loader from "@ui/Icons/Loader"
import classNames from "classnames"
import { random } from "lodash"
import { useEffect } from "react"

interface Props {
  id: TableKey
  task: {
    finished_at?: string | null
    progress?: number
    state: string
  }
  text?: string
  className?: string
}

export const CellInProgress = ({
  id,
  task,
  text = "Processing...",
  className = "",
}: Props) => {
  const KEY = `progress_${id}`
  const [progress, setProgress, removeProgress] = useLocalStorage(KEY, 0)

  useWindowUnloadEffect(() => {
    removeProgress()
  }, true)

  useEffect(() => {
    const finishedAt = task?.finished_at
    const isTaskWaiting = task?.state === "waiting"

    let intervalId = null

    if (!!finishedAt || isTaskWaiting) {
      clearInterval(intervalId)
      removeProgress()
      return
    }

    intervalId = setInterval(() => {
      let progress = window.localStorage.getItem(KEY)

      if (!progress) {
        //@ts-ignore
        progress = 0
      }
      if (Number(progress) >= 85) {
        clearInterval(intervalId)
        return
      }

      const newProgress = Number(progress) + random(1, 10)

      setProgress(newProgress)
    }, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [task])

  return (
    <>
      <div className={classNames("flex w-full items-center gap-8", className)}>
        <System.Text className="flex items-center gap-2">
          <Loader color="#4f46e5" className="min-w-[15px]" />
          {text}
        </System.Text>
        {task.state !== "waiting" && (
          <div className="w-full flex-1 overflow-hidden rounded-full bg-gray-200">
            <div
              className="h-2 rounded-full bg-indigo-600 transition-all"
              style={{
                width: `${task.progress ?? progress}%`,
              }}
            />
          </div>
        )}
      </div>
    </>
  )
}
