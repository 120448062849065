import { getProjectPath } from "."

export const getProjectBackupSettingsPath = ({ id }: { id: number }) =>
  `${getProjectPath(id)}/backups`

interface Props {
  projectId: number
  data: {
    exclude_files: Array<string>
    exclude_tables: Array<string>
    is_scheduled?: boolean
    suffix?: string | number
    sql: boolean
    files: boolean
    incremental_date?: string
    process_version?: string
  }
}

export const postBackupNow = async ({ projectId, data }: Props) => {
  const result = await fetch(getProjectBackupSettingsPath({ id: projectId }), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })

  return await result.json()
}
