import classNames from "classnames"
import { System } from "@design-system/index"
import { ButtonOrLinkProps } from "@design-system/Button/Default"

export const FormSubmit = ({
  children,
  className = "",
  ...rest
}: ButtonOrLinkProps) => {
  const classes = classNames("relative focus:z-10", className)

  return (
    <System.Button.Primary type="submit" className={classes} {...rest}>
      {children}
    </System.Button.Primary>
  )
}
