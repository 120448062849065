import { useContext } from "react"
import {
  ProcessPendingAndLastFinishedContext,
  ProcessPendingAndLastFinishedContextType,
} from "../ProcessPendingAndLastFinishedContext"

export const useProcessPendingAndLastFinishedContext =
  (): ProcessPendingAndLastFinishedContextType => {
    const context = useContext(ProcessPendingAndLastFinishedContext)

    if (!context) {
      throw new Error(
        "useProcessPendingAndLastFinishedContext must be used within a ProcessPendingAndLastFinishedContext",
      )
    }

    return context
  }
