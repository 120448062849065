import { getTextPluginProcessing } from "@components/Plugin/helpers/getTextPluginProcessing"
import { TableRowProcess } from "@design-system/Table/types"
import { System } from "@design-system/index"

interface Props {
  itemInProcess: TableRowProcess
  item: {
    id: number | string
  }
  projectId?: number
}

export const TableRowProcessing = ({
  itemInProcess,
  item,
  projectId,
}: Props) => {
  return (
    <System.Table.RowProcessing
      item={item}
      projectId={projectId}
      itemInProcess={itemInProcess}
      texts={{
        failed: getTextPluginProcessing({
          state: "failed",
          type: itemInProcess.type,
        }),
        inProgress: getTextPluginProcessing({
          state: "progress",
          type: itemInProcess.type,
        }),
        success: getTextPluginProcessing({
          state: "success",
          type: itemInProcess.type,
        }),
      }}
    />
  )
}
