import { IProgressTask } from "@/Manager/Processes/types/ProgressTask"
import { ProgressTasksContext } from "@components/Processes/contexts/ProgressTasksContext/ProgressTasksContext"
import { useProcessesProgressTasks } from "@components/Processes/hooks/useProcessesProgressTasks"
import { TableRowProcess } from "@design-system/Table/types"
import { isEmpty } from "lodash"
import { useEffect, useState } from "react"
import { KeyedMutator } from "swr"

interface Props {
  children: (props: {
    rowsProcessing?: Set<TableRowProcess>
    error: any
    mutate: KeyedMutator<{
      code: string
      data: {
        [key: string]: IProgressTask[]
      }
    }>
    isValidating: boolean
    isLoading: boolean
  }) => React.ReactNode
  projectId?: number
  options?: {
    refreshInterval?: number
  }
}

const REFRESH_INTERVAL_DURING_PENDING = 5000

export const FetchProgressTasks = ({ children, options, projectId }: Props) => {
  const baseRefreshInterval = options?.refreshInterval ?? 25000
  const [refreshInterval, setRefreshInterval] =
    useState<number>(baseRefreshInterval)

  const { data, ...rest } = useProcessesProgressTasks({
    projectId: projectId,
    options: {
      refreshInterval: refreshInterval,
    },
  })

  useEffect(() => {
    if (isEmpty(data) && refreshInterval !== baseRefreshInterval) {
      setRefreshInterval(baseRefreshInterval)
      return
    }

    if (!isEmpty(data) && refreshInterval !== REFRESH_INTERVAL_DURING_PENDING) {
      setRefreshInterval(REFRESH_INTERVAL_DURING_PENDING)
      return
    }
  }, [data])

  const rowsProcessing = new Set<TableRowProcess>()

  if (!isEmpty(data)) {
    Object.values(data).forEach((processes) => {
      processes.forEach((process) => {
        rowsProcessing.add({
          key: process.details.id,
          type: process.type,
          task: {
            entities: process.details,
            detailsResult: process?.detailsResult,
            finished_at: process.finished_at,
            state: process.status,
            id: process.processId,
            projectId: process.projectId,
            code: process.codeError,
            steps: process.tasks,
            progress: process.progress,
          },
        })
      })
    })
  }

  return (
    <>
      <ProgressTasksContext.Provider
        value={{
          mutate: rest.mutate,
        }}
      >
        {children({
          ...rest,
          rowsProcessing,
        })}
      </ProgressTasksContext.Provider>
    </>
  )
}
