import { System } from "@design-system/index"
import { UptimeTimelineContainer } from "../UptimeTimelineContainer"
import { UptimeStatus } from "@design-system/Uptime/UptimeStatus"

export interface TimelineItemStatus {
  status: UptimeStatus
  date: string
}

interface Props {
  data: TimelineItemStatus
}

export function UptimeTimelineStatus({ data }: Props) {
  const { status, date } = data

  switch (status) {
    case UptimeStatus.UP:
      return (
        <UptimeTimelineContainer>
          <System.Uptime.Status
            className="bg-white px-1.5 ring-4 ring-white"
            status={status}
          />
          <System.Text>
            Your site is <System.Strong>operational</System.Strong> since
          </System.Text>
          <System.Text className="whitespace-nowrap">{date}</System.Text>
        </UptimeTimelineContainer>
      )

    case UptimeStatus.DOWN:
      return (
        <UptimeTimelineContainer>
          <System.Uptime.Status
            className="bg-white px-1.5 ring-4 ring-white"
            status={status}
          />
          <System.Text>
            Your site is <System.Strong>down</System.Strong> since
          </System.Text>
          <System.Text className="whitespace-nowrap">{date}</System.Text>
        </UptimeTimelineContainer>
      )

    case UptimeStatus.PAUSED:
      return (
        <UptimeTimelineContainer>
          <System.Uptime.Status
            className="bg-white px-1.5 ring-4 ring-white"
            status={status}
          />
          <System.Text>Your site is paused since</System.Text>
          <System.Text className="whitespace-nowrap">{date}</System.Text>
        </UptimeTimelineContainer>
      )
  }

  return null
}
