import { isEmpty } from "lodash"
import React from "react"
import { System } from "@design-system/index"

interface Props {
  wordpress: number | string
  php: number | string
}

export function Requirements({ wordpress, php }: Props) {
  return (
    <div className="flex items-center gap-4">
      {!isEmpty(wordpress) && (
        <div className="inline-flex items-center gap-2 rounded-full bg-gray-100 px-2 py-1">
          <System.Svg.WordPress className="h-4 w-4" />
          <System.Text>
            <System.Strong>{wordpress}</System.Strong>
          </System.Text>
        </div>
      )}
      {!isEmpty(php) && (
        <div className="inline-flex items-center gap-2 rounded-full bg-gray-100 px-2 py-1">
          <System.Svg.Php className="h-4 w-4" />
          <System.Text>
            <System.Strong>{php}</System.Strong>
          </System.Text>
        </div>
      )}
    </div>
  )
}
