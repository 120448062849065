import { isNil } from "lodash"

const PREMIUM_PLUGINS = [
  {
    key: "wp-rocket/wp-rocket.php",
    changelog: "https://wp-rocket.me/changelog/",
  }, // WP Rocket
  {
    key: "advanced-custom-fields-pro/acf.php",
    changelog: "https://www.advancedcustomfields.com/changelog/",
  }, // ACF
  {
    key: "wp-seopress-pro/seopress-pro.php",
    changelog: "https://www.seopress.org/support/changelogs/seopress-free-pro/",
  }, // SEOPress Pro
  {
    key: "bb-ui-enhancements-1.1.1/bb-ui-enhancements.php",
    changelog: "https://github.com/badabingbreda/bb-ui-enhancements/releases",
  }, // BB UI Enhancements
  {
    key: "buddyboss-platform-pro/buddyboss-platform-pro.php",
    changelog:
      "https://www.buddyboss.com/resources/buddyboss-platform-releases/",
  }, // BuddyBoss Pro
  {
    key: "buddyboss-platform/bp-loader.php",
    changelog:
      "https://www.buddyboss.com/resources/buddyboss-platform-releases/",
  }, // BuddyBoss Pro ; change slug
  {
    key: "bb-code-settings-master/bb-code-settings.php",
    changelog: "https://github.com/beaverbuilder/bb-code-settings",
  }, // Beaver Builder Code Settings
  {
    key: "bb-plugin/fl-builder.php",
    changelog: "https://www.wpbeaverbuilder.com/change-logs",
  }, // Beaver Builder Plugin (Standard Version) & Beaver Themer
  {
    key: "happyfiles-pro/happyfiles-pro.php",
    changelog: "https://happyfiles.io/changelog",
  }, // HappyFiles Pro
  {
    key: "perfmatters/perfmatters.php",
    changelog: "https://perfmatters.io/docs/changelog",
  }, // Perfmatters
  {
    key: "bbpowerpack/bb-powerpack.php",
    changelog: "https://wpbeaveraddons.com/change-logs",
  }, // PowerPack for Beaver Builder
  {
    key: "wpcodebox/wpcodebox.php",
    changelog: "https://wpcodebox.com/releases",
  }, // WP CodeBox
  {
    key: "wp-job-manager/wp-job-manager.php",
    changelog: "https://wpjobmanager.com/changelog/wp-job-manager-applications",
  }, // WP Job Manager - Applications
  {
    key: "elementor-pro/elementor-pro.php",
    changelog: "https://elementor.com/pro/changelog/",
  }, // Elementor Pro
  {
    key: "presto-player-pro/presto-player-pro.php",
    changelog: "https://prestoplayer.com/product/presto-player-pro/",
  }, // Presto Player Pro
  {
    key: "yaymail-pro/yaymail.php",
    changelog: "https://docs.yaycommerce.com/yaymail/other-links/changelog",
  }, // YayMail Pro - WooCommerce Email Customizer
  {
    key: "yaymail-pro/yaymail.php",
    changelog: "https://www.learndash.com/changelog",
  }, // YayMail Pro - WooCommerce Email Customizer
  {
    key: "learndash-course-grid/learndash_course_grid.php",
    changelog: "https://www.learndash.com/changelog",
  }, // LearnDash LMS - Course Grid
  {
    key: "learndash-certificate-builder/learndash-certificate-builder.php",
    changelog: "https://www.learndash.com/changelog",
  }, // LearnDash LMS - Certificate Builder
  {
    key: "learndash-propanel/learndash_propanel.php",
    changelog: "https://www.learndash.com/changelog",
  }, // LearnDash LMS - ProPanel
  {
    key: "learndash-woocommerce/learndash_woocommerce.php",
    changelog: "https://www.learndash.com/changelog",
  }, // LearnDash LMS - WooCommerce Integration
  {
    key: "learndash-achievements/learndash-achievements.php",
    changelog: "https://www.learndash.com/changelog",
  }, // LearnDash LMS - Achievements
  {
    key: "learndash-notifications/learndash-notifications.php",
    changelog: "https://www.learndash.com/changelog",
  }, // LearnDash LMS - Notifications
  {
    key: "learndash-stripe/learndash-stripe.php",
    changelog: "https://www.learndash.com/changelog",
  }, // LearnDash LMS - Stripe Integration
  {
    key: "learndash-zapier/learndash_zapier.php",
    changelog: "https://www.learndash.com/changelog",
  }, // LearnDash LMS - Zapier Integration
  {
    key: "learndash-bbpress/learndash-bbpress.php",
    changelog: "https://www.learndash.com/changelog",
  }, // LearnDash LMS - bbPress Integration
  {
    key: "learndash-integrity/learndash-integrity.php",
    changelog: "https://www.learndash.com/changelog",
  }, // LearnDash LMS - Integrity
  {
    key: "learndash-memberpress/learndash-memberpress.php",
    changelog: "https://www.learndash.com/changelog",
  }, // LearnDash LMS - MemberPress Integration
  {
    key: "learndash-paidmemberships/learndash-paidmemberships.php",
    changelog: "https://www.learndash.com/changelog",
  }, // LearnDash LMS - Paid Memberships Pro
  {
    key: "learndash-samcart/learndash-samcart.php",
    changelog: "https://www.learndash.com/changelog",
  }, // LearnDash LMS - Samcart Integration
  {
    key: "learndash-samcart/learndash-samcart.php",
    changelog: "https://www.learndash.com/changelog",
  }, // LearnDash LMS - The Events Calendar Integration
  {
    key: "learndash-ld-tec-bb06eaa90523/learndash-tec.php",
    changelog: "https://www.learndash.com/changelog",
  }, // LearnDash LMS - The Events Calendar Integration
  {
    key: "learndash-elementor/learndash-elementor.php",
    changelog: "https://www.learndash.com/changelog",
  }, // LearnDash LMS - Elementor
  {
    key: "learndash-event-espresso/event-espresso.php",
    changelog: "https://www.learndash.com/changelog",
  }, // LearnDash LMS - Event Espresso
  {
    key: "sfwd-lms/sfwd_lms.php",
    changelog: "https://www.learndash.com/changelog",
  }, // LearnDash LMS
  {
    key: "woocommerce-subscriptions/woocommerce-subscriptions.php",
    changelog:
      "https://dzv365zjfbd8v.cloudfront.net/changelogs/woocommerce-subscriptions/changelog.txt",
  }, // WooCommerce Subscriptions:
  {
    key: "wp-social-ninja-pro/wp-social-ninja-pro.php",
    changelog: "https://wpsocialninja.com/docs/change-log/",
  }, // WP Social Ninja Pro:
  {
    key: "use-your-drive/use-your-drive.php",
    changelog:
      "https://www.wpcloudplugins.com/wp-content/plugins/use-your-drive/_documentation/index.html#releasenotes",
  }, // WP Cloud Plugin Use-your-Drive (Google Drive)
  {
    key: "custom-facebook-feed-pro/custom-facebook-feed.php",
    changelog: "https://smashballoon.com/doc/custom-facebook-feed-change-log/",
  }, //  Custom Facebook Feed Pro Personal
  {
    key: "wpforms/wpforms.php",
    changelog:
      "https://wpforms.com/docs/how-to-view-recent-changes-to-the-wpforms-plugin-changelog/#Changelog",
  }, //  WPForms
  {
    key: "event-tickets-plus/event-tickets-plus.php",
    changelog:
      "https://www.theeventscalendar.com/category/release-notes/event-tickets-plus/",
  }, //  Event Ticket plus
]

export const getPremiumPluginIfIsPremium = (
  key: string,
): { key: string; changelog: string } => {
  const plugin = PREMIUM_PLUGINS.find((plugin) => plugin.key === key)
  if (isNil(plugin)) {
    return null
  }

  return plugin
}
