import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders"
import { getPrivateApiOneProjectWordPressUsersPath } from "@/Project/api/routes-private"

export const getUsers = async ({ projectId }: { projectId: number }) => {
  const headers = await getFetchPrivateHeaders()

  const result = await fetch(
    getPrivateApiOneProjectWordPressUsersPath({ projectId }),
    {
      headers: headers,
    },
  )

  return await result.json()
}
