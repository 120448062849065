import { cn } from "@helpers/utils"
import { PropsWithChildren } from "react"

interface WhatsNewCardProps {
  className?: string
  children?: React.ReactNode
}

export const WhatsNewCard = ({ className, children }: WhatsNewCardProps) => {
  return (
    <div
      className={cn(
        "flex flex-col gap-6 rounded-lg border-2 border-primary-lighter bg-primary-lightest p-10",
        className,
      )}
    >
      <span className="text-title-h4 text-primary-base">What's New?</span>
      <div className="flex flex-col gap-2">{children}</div>
    </div>
  )
}

export const WhatsNewCardTags = ({ children }: PropsWithChildren) => (
  <div className="flex gap-2">{children}</div>
)

export const WhatsNewCardContent = ({ children }: PropsWithChildren) => (
  <div className="flex flex-col gap-2">{children}</div>
)

export const WhatsNewCardTitle = ({ children }: PropsWithChildren) => (
  <span className="text-title-h3 text-main-grey-600">{children}</span>
)

export const WhatsNewCardDescription = ({ children }: PropsWithChildren) => (
  <p className="text-body-base text-main-grey-500">{children}</p>
)
