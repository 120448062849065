export enum TypeFilteringPluginsItem {
  ALL = "ALL",
  ACTIVE = "ACTIVE",
  DEACTIVE = "DEACTIVE",
  NEED_UPDATE = "NEED_UPDATE",
  IGNORE = "IGNORE",
  PROCESS_IN_PROGRESS = "PROCESS_IN_PROGRESS",
}

export const TABS_FILTERING_PLUGINS = [
  {
    value: TypeFilteringPluginsItem.ALL,
    label: "All",
  },
  {
    value: TypeFilteringPluginsItem.NEED_UPDATE,
    label: "Update available",
  },
  {
    value: TypeFilteringPluginsItem.ACTIVE,
    label: "Active",
  },
  {
    value: TypeFilteringPluginsItem.DEACTIVE,
    label: "Inactive",
  },
  {
    value: TypeFilteringPluginsItem.IGNORE,
    label: "Excluded Updates",
  },
  {
    value: TypeFilteringPluginsItem.PROCESS_IN_PROGRESS,
    label: "Process In Progress",
  },
]
