interface Props {
  admin_url: string
  user_login: string
  user_password: string
  api_key: string
}

export const autoInstall = async (data: Props) => {
  try {
    const response = await fetch("/api/auto-install", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        admin_url: data.admin_url,
        user_login: data.user_login,
        user_password: data.user_password,
        api_key: data.api_key,
      }),
    })

    const result = await response.json()
    return {
      success: response.status === 200,
      data: result,
    }
  } catch (error) {
    return {
      success: false,
      code: "unknow",
    }
  }
}
