import MainProjectsTableModel from "@/Core/Models/Projects/MainProjectsTableModel"
import { System } from "@design-system/index"
import { PluginsBulkAction } from "../../BulkActions"
import { SitesSelectorWithPluginsInstalledRow } from "./Row"

export type SiteSelectorWithPluginInstall = MainProjectsTableModel & {
  canBeChecked: boolean
  install_requirements: {
    is_installed: boolean
    wordpress_version_outdated: boolean
    php_version_outdated: boolean
  }
}

interface Props {
  projects?: Array<SiteSelectorWithPluginInstall>
}

const LABELS_COLUMNS = [
  {
    key: "name",
    name: "Name",
  },
  {
    key: "informations",
    name: "Informations",
  },
]

export const SitesSelectorWithPluginsInstalled = ({ projects }: Props) => {
  return (
    <>
      <System.Table.List<SiteSelectorWithPluginInstall>
        columns={LABELS_COLUMNS}
        rows={projects.map((project) => {
          return {
            ...project,
            canBeChecked:
              !project.install_requirements.php_version_outdated &&
              !project.install_requirements.is_installed &&
              !project.install_requirements.wordpress_version_outdated,
          } as SiteSelectorWithPluginInstall
        })}
        withChecked
        bulkActions={
          <PluginsBulkAction.BulkSitesSelectorIntallPluginFromRepositoryByStep />
        }
      >
        {({ item, columns }) => {
          return (
            <SitesSelectorWithPluginsInstalledRow
              item={item}
              columns={columns}
            />
          )
        }}
      </System.Table.List>
    </>
  )
}
