import { SvgProps } from "@design-system/Icon/types"

export const Click = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M2 12C2 11.4477 2.44772 11 3 11H6C6.55228 11 7 11.4477 7 12C7 12.5523 6.55228 13 6 13H3C2.44772 13 2 12.5523 2 12Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M12 2C12.5523 2 13 2.44772 13 3V6C13 6.55228 12.5523 7 12 7C11.4477 7 11 6.55228 11 6V3C11 2.44772 11.4477 2 12 2Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M4.89299 4.89287C5.28351 4.50234 5.91668 4.50234 6.3072 4.89287L8.50721 7.09287C8.89773 7.48339 8.89773 8.11656 8.50721 8.50708C8.11668 8.89761 7.48352 8.89761 7.09299 8.50708L4.89299 6.30708C4.50247 5.91656 4.50247 5.28339 4.89299 4.89287Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M19.1071 4.89287C19.4976 5.28339 19.4976 5.91656 19.1071 6.30708L16.9071 8.50708C16.5165 8.89761 15.8834 8.89761 15.4928 8.50708C15.1023 8.11656 15.1023 7.48339 15.4928 7.09287L17.6928 4.89287C18.0834 4.50234 18.7165 4.50234 19.1071 4.89287Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M8.50721 15.4928C8.89773 15.8834 8.89773 16.5165 8.5072 16.9071L6.3072 19.1071C5.91668 19.4976 5.28351 19.4976 4.89299 19.1071C4.50247 18.7165 4.50247 18.0834 4.89299 17.6928L7.09299 15.4928C7.48352 15.1023 8.11668 15.1023 8.50721 15.4928Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M11.2929 11.2929C11.5607 11.0251 11.9569 10.9315 12.3162 11.0513L21.3162 14.0513C21.6999 14.1792 21.9688 14.5257 21.9975 14.9291C22.0262 15.3326 21.809 15.7135 21.4472 15.8944L17.7454 17.7454L15.8944 21.4472C15.7135 21.809 15.3326 22.0262 14.9291 21.9975C14.5257 21.9688 14.1792 21.6999 14.0513 21.3162L11.0513 12.3162C10.9315 11.9569 11.0251 11.5607 11.2929 11.2929ZM13.5811 13.5811L15.1852 18.3934L16.1056 16.5528C16.2023 16.3593 16.3593 16.2023 16.5528 16.1056L18.3934 15.1852L13.5811 13.5811Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
