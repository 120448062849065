import React, { useState } from "react"
import { System } from "@design-system/index"
import { useGenericModal } from "@design-system/Context/GenericModal/hooks/useGenericModal"

interface Props {
  onConfirm: () => void
  isIgnoredUpdate: boolean
}

export const ModalIgnoreUpdatePlugin = ({
  onConfirm,
  isIgnoredUpdate,
}: Props) => {
  const [loading, setLoading] = useState(false)
  const { closeModal } = useGenericModal()

  return (
    <>
      <div className="text-center">
        <System.Title className="mb-2">Are you sure?</System.Title>
        <System.Text>
          {isIgnoredUpdate ? (
            <>
              By reconsidering this update, you will revert the settings,
              allowing the previously excluded plugin to be updated through WP
              Umbrella once again. Proceed if you wish to enable updates for
              this plugin.
            </>
          ) : (
            <>
              When you exclude an update, we'll ensure that it cannot be
              activated through WP Umbrella. You have the flexibility to
              reconsider and enable these updates at any time.
            </>
          )}
        </System.Text>
      </div>

      <div className="mt-8 flex items-center gap-2">
        <System.Button.Outlined
          onClick={closeModal}
          className="w-full justify-center"
        >
          Cancel
        </System.Button.Outlined>
        <System.Button.Primary
          className="w-full justify-center"
          loading={loading}
          onClick={() => {
            setLoading(true)
            onConfirm()
          }}
        >
          {isIgnoredUpdate ? (
            <>Reconsider Future Updates</>
          ) : (
            <>Exclude Future Updates</>
          )}
        </System.Button.Primary>
      </div>
    </>
  )
}
