import { System } from "@design-system/index"
import { ButtonOrLinkProps } from "@design-system/Button/Default"

export const BulkActionsButton = ({
  children,
  className = "",
  ...rest
}: ButtonOrLinkProps) => {
  return (
    <System.Button.Primary className={className} {...rest}>
      {children}
    </System.Button.Primary>
  )
}
