import { useContext } from "react"
import { TableContext } from "@design-system/Table/contexts/TableContext"
import { TableKey, TableRowProcess } from "@design-system/Table/types"
import { isNil } from "lodash"

export const useTable = () => {
  const context = useContext(TableContext)

  if (!context) {
    throw new Error("useTable must be used within a TableProvider")
  }

  function closeAllRows() {
    context.dispatch({
      type: "CLOSE_ALL_ROWS",
      payload: {},
    })
  }

  function expandAllRows() {
    context.dispatch({
      type: "EXPAND_ALL_ROWS",
      payload: {},
    })
  }

  function checkAllRows() {
    context.dispatch({
      type: "CHECK_ALL_ROWS",
      payload: {},
    })
  }

  function addSetCheckedRows(keys: Set<TableKey>) {
    context.dispatch({
      type: "ADD_SET_CHECKED_ROWS",
      payload: { keys: keys },
    })
  }

  function removeSetCheckedRows(keys: Set<TableKey>) {
    context.dispatch({
      type: "REMOVE_SET_CHECKED_ROWS",
      payload: { keys: keys },
    })
  }

  function clearCheckedRows() {
    context.dispatch({
      type: "CLEAR_ALL_CHECKED_ROWS",
      payload: {},
    })
  }

  function setRowKeys(keys: Set<TableKey>) {
    context.dispatch({
      type: "SET_ROW_KEYS",
      payload: { keys: keys },
    })
  }

  function setRowChildrenKeys(keys: Set<TableKey>) {
    context.dispatch({
      type: "SET_ROW_CHILDREN_KEYS",
      payload: { keys: keys },
    })
  }

  function toggleRowChecked({ key }: { key: TableKey }) {
    context.dispatch({
      type: "TOGGLE_ROW_CHECKED",
      payload: { key: key },
    })
  }

  function toggleRowExpanded({ key }: { key: TableKey }) {
    context.dispatch({
      type: "TOGGLE_ROW_EXPANDED",
      payload: { key: key },
    })
  }

  function setRowProcessingKeys({ keys }: { keys: Set<TableRowProcess> }) {
    context.dispatch({
      type: "SET_ROW_PROCESSING_KEYS",
      payload: keys,
    })
  }

  function toggleRowProcessingKey(payload: TableRowProcess) {
    context.dispatch({
      type: "TOGGLE_ROW_PROCESSING",
      payload: payload,
    })
  }

  function getRowProcessing({ key }: { key: TableKey }) {
    return Array.from(context.state.rowProcessingKeys).find((item) => {
      return item.key === key
    })
  }

  function isRowProcessing({ key }: { key: TableKey }) {
    return !isNil(
      getRowProcessing({
        key: key,
      }),
    )
  }

  function isRowSelected({ key }: { key: TableKey }) {
    return context.state.checkedRows.has(key)
  }

  function isRowExpanded({ key }: { key: TableKey }) {
    return context.state.expandedRows.has(key)
  }

  function addRowProcessingKeys({ keys }: { keys: Set<TableRowProcess> }) {
    context.dispatch({
      type: "ADD_ROW_PROCESSING_KEYS",
      payload: keys,
    })
  }

  return {
    state: context.state,
    setRowKeys,
    setRowChildrenKeys,
    setRowProcessingKeys,
    toggleRowChecked,
    toggleRowExpanded,
    toggleRowProcessingKey,
    checkAllRows,
    addSetCheckedRows,
    removeSetCheckedRows,
    clearCheckedRows,
    closeAllRows,
    expandAllRows,
    isRowProcessing,
    getRowProcessing,
    addRowProcessingKeys,
    isRowSelected,
    isRowExpanded,
  }
}
