import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders"
import { getPrivateApiProjectMaintenanceReportGenerateAndSendPath } from "@/Project/api/routes-maintenance-report-private"

export interface Props {
  projectId: number
  data: {
    email_to: string
    ranges: {
      startDate: Date | string
      endDate: Date | string
    }
  }
}

export const sendManualReport = async ({ projectId, data }: Props) => {
  const headers = await getFetchPrivateHeaders()

  const result = await fetch(
    getPrivateApiProjectMaintenanceReportGenerateAndSendPath({ projectId }),
    {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    },
  )

  return await result.json()
}
