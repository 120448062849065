import React from "react"
import { KeyedMutator } from "swr"
import { useWordPressPlugins } from "@components/Plugin/hooks/useWordPressPlugins"
import { FromAPIWordPressPluginWithProjectApiData } from "@/Plugin/types/plugin-from-api"
import WordPressPluginWithProjectModel from "@/Plugin/models/WordPressPluginWithProjectModel"

interface Props {
  children: (props: {
    plugins: WordPressPluginWithProjectModel[]
    mutate: KeyedMutator<{
      items: {
        data: FromAPIWordPressPluginWithProjectApiData[]
        code: string
      }
      total: number
    }>
  }) => React.ReactNode
  isImmutable?: boolean
}

export function AllPlugins({ children, isImmutable = false }: Props) {
  const { data, mutate } = useWordPressPlugins({
    isImmutable,
    suspense: true,
  })

  return (
    <>
      {children({
        plugins: data,
        mutate: mutate,
      })}
    </>
  )
}
