import classNames from "classnames"
import { isNil } from "lodash"
import React from "react"
import Loader from "../Icons/Loader"

interface Props {
  children: React.ReactNode
  disabled?: boolean
  primary?: boolean
  black?: boolean
  warning?: boolean
  loading?: boolean
  indigo?: boolean
  red?: boolean
  white?: boolean
  type?: string
  className?: string
  href?: string
  as?: string
  style?: object
  large?: boolean
  small?: boolean
  onClick?: Function
  target?: string
}

export const getClassNameButton = ({
  indigo = true,
  red = false,
  loading = false,
  disabled = false,
  className = "",
  large = false,
  small = false,
}: {
  indigo?: boolean
  red?: boolean
  loading?: boolean
  disabled?: boolean
  large?: boolean
  small?: boolean
  className?: string
} = {}) =>
  classNames(
    {
      "bg-indigo-600 hover:bg-indigo-700 text-white focus:ring-indigo-500 border-transparent":
        indigo && !red && !loading,
      "bg-indigo-500 text-white focus:ring-indigo-500 border-transparent":
        indigo && !red && loading,
      "bg-red-600 hover:bg-red-700 text-white focus:ring-red-500 border-transparent":
        red && !disabled,
      "cursor-not-allowed": disabled || loading,
      "bg-gray-50": disabled && !indigo,
      "bg-white": !indigo && !red,
      "px-2 py-1": small && !large,
      "px-4 py-2": !small && !large,
      "px-8 py-4": large,
      "inline-flex items-center border rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 cursor-pointer transition hover:shadow":
        true,
    },
    className,
  )

const Button = ({
  children,
  indigo = true,
  red = false,
  loading = false,
  disabled = false,
  className = "",
  as = null,
  large = false,
  small = false,
  ...rest
}: Props) => {
  //@ts-ignore
  const Element = isNil(as) ? "button" : as

  const getColorLoader = () => {
    if (indigo || red) {
      return "#ffffff"
    }
    return "#000000"
  }

  return (
    // @ts-ignore
    <Element
      className={getClassNameButton({
        indigo,
        red,
        loading,
        disabled,
        className,
        large,
        small,
      })}
      disabled={loading || disabled}
      {...rest}
    >
      {loading && (
        <div
          className="mr-2"
          style={{
            display: loading ? "inline" : "none",
          }}
        >
          <Loader color={getColorLoader()} className="h-5 w-5" />
        </div>
      )}
      {children}
    </Element>
  )
}

export default Button
