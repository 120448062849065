import { System } from "@design-system/index"
import React from "react"

interface Step {
  label: string
  onClick?: () => void
  current?: boolean
  href?: string
}

interface Props {
  className?: string
  items: Array<Step>
}

export const StepList = ({ items = [], className = "" }: Props) => {
  let currentStep = items.findIndex((item) => {
    return item.current
  })

  if (!currentStep) {
    currentStep = 0
  }

  if (!items.length) {
    return null
  }

  let loopOverCurrentStep = false

  return (
    <>
      <System.Step.Navigation className={className}>
        {items.map((item, index) => {
          if (index === currentStep) {
            loopOverCurrentStep = true

            return (
              <React.Fragment key={`step_${index}`}>
                <System.Step.CurrentButton
                  href={item.href}
                  onClick={item.onClick}
                >
                  {item.label}
                </System.Step.CurrentButton>
              </React.Fragment>
            )
          }

          return (
            <React.Fragment key={`step_${index}`}>
              {loopOverCurrentStep ? (
                <System.Step.NextButton href={item.href} onClick={item.onClick}>
                  {item.label}
                </System.Step.NextButton>
              ) : (
                <System.Step.PreviousButton
                  href={item.href}
                  onClick={item.onClick}
                >
                  {item.label}
                </System.Step.PreviousButton>
              )}
            </React.Fragment>
          )
        })}
      </System.Step.Navigation>
    </>
  )
}
