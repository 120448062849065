import { Menu, Transition } from "@headlessui/react"
import { Button, System } from "@design-system/index"
import classNames from "classnames"
import { Fragment } from "react"

interface Props {
  label: string | React.ReactNode
  items: {
    key: string
    label: string | React.ReactNode
    onClick: ({ key }: { key: string | boolean | number }) => void
  }[]
  itemsSelected?: string[]
  width?: string
  index?: number
  buttonStyle?: "primary" | "secondary"
}

export function DropdownElement({
  items,
  itemsSelected = [],
  label = "Add filter",
  width = "w-56",
  index = 50,
  buttonStyle = "primary",
}: Props) {
  return (
    <Menu
      as="div"
      className="relative inline-block text-left"
      style={{
        zIndex: index,
      }}
    >
      <div className="flex items-center gap-4">
        {buttonStyle === "primary" && (
          <Button variant="outline" type="button" asChild size="sm">
            <Menu.Button>{label}</Menu.Button>
          </Button>
        )}
        {buttonStyle === "secondary" && (
          <Menu.Button as={System.Button.Default}>
            <System.Svg.Plus
              className="h-4 w-4 opacity-50"
              aria-hidden="true"
            />
            {label}
          </Menu.Button>
        )}
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={classNames(
            {
              [width]: width,
            },
            "absolute right-0 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
          )}
        >
          {items.map((item) => {
            return (
              <Menu.Item key={item.key}>
                {() => {
                  return (
                    <div
                      onClick={() => {
                        if (itemsSelected.includes(item.key)) {
                          return
                        }
                        item.onClick({ key: item.key })
                      }}
                      className={classNames(
                        {
                          "bg-gray-100 opacity-60": itemsSelected.includes(
                            item.key,
                          ),
                        },
                        "group flex cursor-pointer items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100",
                      )}
                    >
                      {item.label}
                    </div>
                  )
                }}
              </Menu.Item>
            )
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
