import { System } from "@design-system/index"
import React, { useState } from "react"
import { TableColumn, TableRowProcess } from "@design-system/Table/types"
import WordPressRepositoryPluginModel from "@/Core/Models/Plugins/model"
import { PluginImage } from "@components/Plugin/components/PluginImage"
import { NewExternalLink } from "@ui/ExternalLink/NewExternalLink"
import { getDefaultClassNameText } from "@ui/System/Text"
import { SVG } from "@svg/index"
import { ActiveInstalls } from "@components/Plugin/components/Table/components/ActiveInstalls"
import { StarRating } from "@components/Plugin/components/StarRating"
import { Requirements } from "@components/Plugin/components/Table/components/Requirements"
import { useTable } from "@design-system/Table/hooks/useTable"
import useNotifications from "@hooks/notifications/useNotifications"
import { ProcessTasksType } from "@prisma/client"
import { STRATEGY_INSTALL_PLUGIN } from "constants/bulk-install-plugins"
import { PluginsModal } from "@components/Plugin/components/Modal"
import { getWordPressPluginDownloadPackage } from "@helpers/getWordPressPluginDownloadPackage"
import { PluginsAction } from "@components/Plugin/actions"
import { createEntityIdFromPluginProcess } from "@components/Plugin/helpers/createEntityIdFromPluginProcess"

interface Props {
  item: {
    id: string
    canBeChecked: boolean
  } & WordPressRepositoryPluginModel
  columns?: Array<TableColumn>
  projectId: number
}

export const PluginRow = ({ item, columns = [], projectId }: Props) => {
  const { isRowProcessing, getRowProcessing, addRowProcessingKeys } = useTable()

  const { handleNotifySuccess } = useNotifications()

  const [modalData, setModalData] = useState({
    open: false,
  })

  const handleConfirmAddPlugins = async ({
    mustClearCache,
    mustBeActivate,
  }) => {
    const data = []

    data.push({
      type: ProcessTasksType.ADD_PLUGIN,
      entities: {
        id: createEntityIdFromPluginProcess({
          slug: item.slug,
          projectId: projectId,
        }),
        name: item.name,
        plugin: item.slug,
        version: item.version,
        must_be_activate: mustBeActivate,
        plugin_uri: getWordPressPluginDownloadPackage({
          slug: item.slug,
          version: item.version,
        }),
        from: STRATEGY_INSTALL_PLUGIN.WORDPRESS_REPOSITORY,
      },
      projectId: projectId,
    })

    const response = await PluginsAction.addPlugins({
      data,
      projectsClearCache: mustClearCache
        ? [
            {
              projectId: projectId,
            },
          ]
        : [],
    })

    handleNotifySuccess({
      title: "We have started the installation",
      message: <>We will inform you when it’s done.</>,
    })

    if (response.code === "success") {
      addRowProcessingKeys({
        keys: response.rowProcessings,
      })
    }
  }

  const isInProcess = isRowProcessing({
    key: createEntityIdFromPluginProcess({
      slug: item.slug,
      projectId: projectId,
    }),
  })

  let itemInProcess: TableRowProcess
  if (isInProcess) {
    itemInProcess = getRowProcessing({
      key: createEntityIdFromPluginProcess({
        slug: item.slug,
        projectId: projectId,
      }),
    })
  }

  const getTextInProcess = ({ item }: { item: TableRowProcess }) => {
    if (item.task.state === "waiting") {
      return "Processing..."
    }

    return "Installing..."
  }

  return (
    <System.Table.Row key={`row_${item.id}`} item={item}>
      <div>
        <System.Table.InnerRowWithActions
          rowKey={item.id}
          item={item}
          columns={columns}
          isDisabled={!item.canBeChecked || isInProcess}
        >
          <label
            htmlFor={`checkbox_${item.id}`}
            className="inline-block cursor-pointer truncate"
          >
            <div className="flex items-center gap-4">
              <PluginImage slug={item.slug} />
              <div className="flex flex-col gap-1">
                <NewExternalLink
                  href={`https://wordpress.org/plugins/${item.slug}`}
                  className={getDefaultClassNameText({
                    className:
                      "inline-flex items-center gap-2 text-indigo-500 underline",
                  })}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        item.name.length > 35
                          ? item.name.substr(0, 35) + "…"
                          : item.name,
                    }}
                  ></span>
                  <SVG.ExternalLink className="h-4 w-4" />
                </NewExternalLink>
                <System.Text className="text-sm text-gray-500">
                  {item.version}
                </System.Text>
              </div>
            </div>
          </label>
          <div className="flex-1">
            <ActiveInstalls amount={item.active_installs} />
            <StarRating percentage={item.rating} />
          </div>
          {item.is_installed && !isInProcess && (
            <>
              <div className="flex flex-1 flex-wrap items-center">
                <div className="inline-flex flex-none items-center gap-2 rounded-full">
                  <div className="flex items-center justify-center rounded-full border border-green-100 bg-green-50 p-2">
                    <SVG.Check className="h-4 w-4 text-green-600" />
                  </div>
                  <System.Text className="text-sm">
                    Plugin already installed 👍
                  </System.Text>
                </div>
              </div>
            </>
          )}

          {item.php_version_outdated &&
            !item.is_installed &&
            !item.wordpress_version_outdated &&
            !isInProcess && (
              <>
                <div className="flex flex-1 flex-wrap items-center">
                  <div className="inline-flex flex-none items-center gap-2 rounded-full">
                    <div className="flex items-center justify-center rounded-full border border-yellow-100 bg-yellow-50 p-2">
                      <System.Svg.AlertTriangle className="h-4 w-4 text-yellow-600" />
                    </div>
                    <System.Text className="text-sm">
                      Your PHP version is outdated to install this plugin
                    </System.Text>
                  </div>
                </div>
              </>
            )}

          {item.wordpress_version_outdated &&
            !item.is_installed &&
            !item.php_version_outdated &&
            !isInProcess && (
              <>
                <div className="flex flex-1 flex-wrap items-center">
                  <div className="inline-flex flex-none items-center gap-2 rounded-full">
                    <div className="flex items-center justify-center rounded-full border border-yellow-100 bg-yellow-50 p-2">
                      <System.Svg.AlertTriangle className="h-4 w-4 text-yellow-600" />
                    </div>
                    <System.Text className="text-sm">
                      Your WordPress version is outdated to install this plugin
                    </System.Text>
                  </div>
                </div>
              </>
            )}

          {item.wordpress_version_outdated &&
            item.php_version_outdated &&
            !item.is_installed &&
            !isInProcess && (
              <>
                <div className="flex-3 flex flex-wrap items-center">
                  <div className="inline-flex flex-none items-center gap-2 rounded-full">
                    <div className="flex items-center justify-center rounded-full border border-yellow-100 bg-yellow-50 p-2">
                      <System.Svg.AlertTriangle className="h-4 w-4 text-yellow-600" />
                    </div>
                    <System.Text className="text-sm">
                      Your WordPress and PHP versions are outdated to install
                      this plugin
                    </System.Text>
                  </div>
                </div>
              </>
            )}

          {!isInProcess && item.canBeChecked && (
            <>
              <div className="flex-1">
                <Requirements
                  wordpress={item.requires}
                  php={item.requires_php}
                />
              </div>
              <div className="ml-auto flex-1">
                <System.Button.Outlined
                  className="inline-flex gap-2"
                  onClick={() => {
                    setModalData({
                      open: true,
                    })
                  }}
                >
                  <SVG.Download className="h-4 w-4" />
                  Install
                </System.Button.Outlined>
              </div>
            </>
          )}
          {isInProcess && (
            <div className="flex-1">
              {itemInProcess.task.finished_at === null && (
                <System.Table.CellInProgress
                  id={item.id}
                  task={itemInProcess.task}
                  text={getTextInProcess({
                    item: itemInProcess,
                  })}
                />
              )}
              {itemInProcess.task.finished_at !== null &&
                itemInProcess.task.code === "success" && (
                  <System.Table.CellProcessFinished
                    id={item.id}
                    text={"Installed!"}
                  />
                )}
              {itemInProcess.task.finished_at !== null &&
                itemInProcess.task.code !== "success" && (
                  <System.Table.CellProcessFailed
                    id={item.id}
                    text={"Install failed!"}
                    projectId={projectId}
                    withOneClickAdmin
                  />
                )}
            </div>
          )}
        </System.Table.InnerRowWithActions>
      </div>

      {modalData.open && (
        <PluginsModal.ConfirmInstallPlugin
          open={modalData.open}
          setOpen={() => {
            setModalData({ open: false })
          }}
          handleConfirm={handleConfirmAddPlugins}
          pluginsSelected={[
            {
              ...item,
              from: STRATEGY_INSTALL_PLUGIN.WORDPRESS_REPOSITORY,
            },
          ]}
        />
      )}
    </System.Table.Row>
  )
}
