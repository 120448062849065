import { SvgProps } from "@design-system/Icon/types"

export const WhiteLabel = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeWidth={strokeWidth}
        d="M4.99997 7.19997C4.99997 6.6165 5.23176 6.05692 5.64434 5.64434C6.05692 5.23176 6.6165 4.99997 7.19997 4.99997H8.19997C8.78087 4.99964 9.33806 4.76957 9.74997 4.35997L10.45 3.65997C10.6544 3.45437 10.8975 3.29121 11.1652 3.17988C11.4329 3.06855 11.72 3.01123 12.01 3.01123C12.2999 3.01123 12.587 3.06855 12.8547 3.17988C13.1225 3.29121 13.3655 3.45437 13.57 3.65997L14.27 4.35997C14.682 4.76997 15.24 4.99997 15.82 4.99997H16.82C17.4034 4.99997 17.963 5.23176 18.3756 5.64434C18.7882 6.05692 19.02 6.6165 19.02 7.19997V8.19997C19.02 8.77997 19.25 9.33797 19.66 9.74997L20.36 10.45C20.5656 10.6544 20.7287 10.8975 20.8401 11.1652C20.9514 11.4329 21.0087 11.72 21.0087 12.01C21.0087 12.2999 20.9514 12.587 20.8401 12.8547C20.7287 13.1225 20.5656 13.3655 20.36 13.57L19.66 14.27C19.2504 14.6819 19.0203 15.2391 19.02 15.82V16.82C19.02 17.4034 18.7882 17.963 18.3756 18.3756C17.963 18.7882 17.4034 19.02 16.82 19.02H15.82C15.2391 19.0203 14.6819 19.2504 14.27 19.66L13.57 20.36C13.3655 20.5656 13.1225 20.7287 12.8547 20.8401C12.587 20.9514 12.2999 21.0087 12.01 21.0087C11.72 21.0087 11.4329 20.9514 11.1652 20.8401C10.8975 20.7287 10.6544 20.5656 10.45 20.36L9.74997 19.66C9.33806 19.2504 8.78087 19.0203 8.19997 19.02H7.19997C6.6165 19.02 6.05692 18.7882 5.64434 18.3756C5.23176 17.963 4.99997 17.4034 4.99997 16.82V15.82C4.99964 15.2391 4.76957 14.6819 4.35997 14.27L3.65997 13.57C3.45437 13.3655 3.29121 13.1225 3.17988 12.8547C3.06855 12.587 3.01123 12.2999 3.01123 12.01C3.01123 11.72 3.06855 11.4329 3.17988 11.1652C3.29121 10.8975 3.45437 10.6544 3.65997 10.45L4.35997 9.74997C4.76957 9.33806 4.99964 8.78087 4.99997 8.19997V7.19997Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        strokeWidth={strokeWidth}
        d="M9 12L11 14L15 10"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
