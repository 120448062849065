import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders"
import { getPrivateApiOneProjectVisualRegressionSetttingsPath } from "@/Project/api/routes-visual-regression-private"

export const saveSetting = async ({
  projectId,
  data,
}: {
  projectId: number
  data: {
    url: string
    frequency_in_hours: number
    threshold: number
    clip?: {
      x: number
      y: number
      width: number
      height: number
    }
  }
}) => {
  const headers = await getFetchPrivateHeaders()

  const result = await fetch(
    getPrivateApiOneProjectVisualRegressionSetttingsPath({ projectId }),
    {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    },
  )

  return await result.json()
}
