import NextLink, { LinkProps as NextLinkProp } from "next/link"
import classNames from "classnames"
import { isEmpty } from "lodash"

export type LinkSize = "xsmall" | "small" | "medium" | "large"

export interface LinkProps extends NextLinkProp {
  className?: string
  children: React.ReactNode
  rel?: React.AnchorHTMLAttributes<string>["rel"]
  target?: React.AnchorHTMLAttributes<string>["target"]
  size?: LinkSize
  disabled?: boolean
  naked?: boolean
}

export const Link = ({
  children,
  className = "",
  href,
  rel,
  target,
  size = "medium",
  disabled = false,
  naked = false,
  ...rest
}: LinkProps) => {
  const classes = classNames(
    {
      "text-indigo-600 hover:text-indigo-700 focus:ring-indigo-500 underline":
        isEmpty(className) && !naked,
      "text-sm": size === "small" && !naked,
      "text-xs": size === "xsmall" && !naked,
    },
    className,
  )

  let TagName = href ? NextLink : "span"

  if (disabled) {
    TagName = "span"
  }

  return (
    <TagName
      href={href}
      target={target}
      rel={rel}
      className={classes}
      {...rest}
    >
      {children}
    </TagName>
  )
}
