import {
  TableChildrenProp,
  TableColumn,
  TableRow,
} from "@design-system/Table/types"
import { System } from "@design-system/index"
import { isFunction } from "lodash"
import { Fragment } from "react"

interface Props<T, C = {}> {
  children: TableChildrenProp<T>
  rows?: Array<TableRow<T, C>>
  columns?: Array<TableColumn>
  columnsClassname?: string
  isStacked?: boolean
}

export const TableBodyWithRows = <T, C = {}>({
  rows = [],
  columns = [],
  columnsClassname = "",
  children,
  isStacked = false,
}: Props<T, C>) => {
  if (rows.length === 0) {
    return null
  }

  return (
    <System.Table.Body isStacked={isStacked}>
      {rows.map((item) => {
        return (
          <Fragment key={`row_${item.id}`}>
            {isFunction(children)
              ? children({
                  item: item,
                  columns: columns,
                  columnsClassname: columnsClassname,
                })
              : children}
          </Fragment>
        )
      })}
    </System.Table.Body>
  )
}
