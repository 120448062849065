import classNames from "classnames"

interface Props {
  small?: boolean
  className?: string
  children: React.ReactNode
  as?: React.ElementType
}

const Title = ({
  children,
  className = "",
  small = false,
  as = "h2",
}: Props) => {
  const Element = as
  return (
    <Element
      className={classNames(
        {
          "text-xl": !small,
          "text-lg": small,
        },
        "font-bold",
        className,
      )}
    >
      {children}
    </Element>
  )
}

export default Title
