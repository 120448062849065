import { Dispatch } from "react"

export interface StateGenericStep {
  steps: {
    [key: string]: {
      current: string
      data: any
    }
  }[]
}

export interface ContextGenericStep {
  state: StateGenericStep
  dispatch: Dispatch<ActionGenericStep>
}

export enum TypeGenericActionGenericStep {
  SET_CURRENT_STEP,
  SET_DATA_STEP,
}

export type ActionGenericStep =
  | {
      type: TypeGenericActionGenericStep.SET_CURRENT_STEP
      payload?: {
        key: string
        current: string
        data?: any
      }
    }
  | {
      type: TypeGenericActionGenericStep.SET_DATA_STEP
      payload?: {
        key: string
        data: any
      }
    }
