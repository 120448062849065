import { SvgProps } from "@design-system/Icon/types"

export const Receipt = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          strokeWidth={strokeWidth}
          fill="currentColor"
          fillRule="evenodd"
          d="M7 4C6.73478 4 6.48043 4.10536 6.29289 4.29289C6.10536 4.48043 6 4.73478 6 5V19.1315L7.4453 18.1679C7.84192 17.9035 8.37004 17.9558 8.70711 18.2929L10 19.5858L11.2929 18.2929C11.6834 17.9024 12.3166 17.9024 12.7071 18.2929L14 19.5858L15.2929 18.2929C15.63 17.9558 16.1581 17.9035 16.5547 18.1679L18 19.1315V5C18 4.73478 17.8946 4.48043 17.7071 4.29289C17.5196 4.10536 17.2652 4 17 4H7ZM4.87868 2.87868C5.44129 2.31607 6.20435 2 7 2H17C17.7956 2 18.5587 2.31607 19.1213 2.87868C19.6839 3.44129 20 4.20435 20 5V21C20 21.3688 19.797 21.7077 19.4719 21.8817C19.1467 22.0557 18.7522 22.0366 18.4453 21.8321L16.1274 20.2868L14.7071 21.7071C14.3166 22.0976 13.6834 22.0976 13.2929 21.7071L12 20.4142L10.7071 21.7071C10.3166 22.0976 9.68342 22.0976 9.29289 21.7071L7.87258 20.2868L5.5547 21.8321C5.24784 22.0366 4.8533 22.0557 4.52814 21.8817C4.20298 21.7077 4 21.3688 4 21V5C4 4.20435 4.31607 3.44129 4.87868 2.87868ZM8 7C8 6.44772 8.44772 6 9 6H15C15.5523 6 16 6.44772 16 7C16 7.55228 15.5523 8 15 8H9C8.44772 8 8 7.55228 8 7ZM8 11C8 10.4477 8.44772 10 9 10H15C15.5523 10 16 10.4477 16 11C16 11.5523 15.5523 12 15 12H9C8.44772 12 8 11.5523 8 11ZM12 15C12 14.4477 12.4477 14 13 14H15C15.5523 14 16 14.4477 16 15C16 15.5523 15.5523 16 15 16H13C12.4477 16 12 15.5523 12 15Z"
          clipRule="evenodd"
        />
      </g>
    </svg>
  )
}
