import { SvgProps } from "@design-system/Icon/types"

export const Shield = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M11.3375 2.25105C11.7158 1.91632 12.2844 1.91632 12.6627 2.25105C14.8039 4.14541 17.5985 5.13173 20.4544 5.00105C20.9145 4.97999 21.3296 5.27601 21.4595 5.71797C21.9509 7.38957 22.1012 9.14289 21.9016 10.8737C21.702 12.6046 21.1565 14.2777 20.2975 15.7935C19.4385 17.3094 18.2836 18.6371 16.9013 19.6978C15.519 20.7585 13.9377 21.5305 12.2512 21.968C12.0865 22.0107 11.9137 22.0107 11.749 21.968C10.0625 21.5305 8.48111 20.7585 7.09885 19.6978C5.71658 18.6371 4.56164 17.3094 3.70265 15.7935C2.84366 14.2777 2.29815 12.6046 2.09854 10.8737C1.89893 9.14289 2.04928 7.38957 2.54067 5.71797C2.67059 5.27601 3.08562 4.97999 3.54579 5.00105C6.40169 5.13173 9.19628 4.14541 11.3375 2.25105ZM4.27211 7.01064C4.00893 8.20149 3.94525 9.42959 4.08537 10.6446C4.25427 12.1092 4.71586 13.5249 5.4427 14.8075C6.16953 16.0902 7.14679 17.2136 8.3164 18.1111C9.41716 18.9558 10.6671 19.5841 12.0001 19.9637C13.333 19.5841 14.583 18.9558 15.6838 18.1111C16.8534 17.2136 17.8306 16.0902 18.5575 14.8075C19.2843 13.5249 19.7459 12.1092 19.9148 10.6446C20.0549 9.42959 19.9912 8.20149 19.728 7.01064C16.927 6.96258 14.2157 6.01037 12.0001 4.29724C9.78442 6.01037 7.07316 6.96258 4.27211 7.01064Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M10.5859 9.58579C10.9609 9.21071 11.4696 9 12.0001 9C12.5305 9 13.0392 9.21071 13.4143 9.58579C13.7894 9.96086 14.0001 10.4696 14.0001 11C14.0001 11.5304 13.7894 12.0391 13.4143 12.4142C13.0392 12.7893 12.5305 13 12.0001 13C11.4696 13 10.9609 12.7893 10.5859 12.4142C10.2108 12.0391 10.0001 11.5304 10.0001 11C10.0001 10.4696 10.2108 9.96086 10.5859 9.58579Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M12.0001 11C12.5524 11 13.0001 11.4477 13.0001 12V14.5C13.0001 15.0523 12.5524 15.5 12.0001 15.5C11.4478 15.5 11.0001 15.0523 11.0001 14.5V12C11.0001 11.4477 11.4478 11 12.0001 11Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
