import { ApexOptions } from "apexcharts"
import dynamic from "next/dynamic"

const Chart = dynamic(() => import("react-apexcharts"), { ssr: false })

type PercentageValue = number

interface Props {
  options?: ApexOptions
  value: PercentageValue
  threshold: PercentageValue
}

export function PercentageChart({ options, value, threshold }: Props) {
  let valueColor = "#FF3333"
  let textColor = "#CC0000"

  if (value >= threshold) {
    valueColor = "#00CC66"
    textColor = "#008800"
  }

  const chartSeries = [
    {
      name: "Uptime Rate",
      group: "stacked",
      data: [
        {
          x: "x",
          y: value,
          goals: [
            {
              name: "marker",
              value: value,
              strokeColor: valueColor,
            },
            {
              name: "maximum",
              value: 100,
              strokeWidth: 0,
              strokeColor: "transparent",
            },
          ],
        },
      ],
    },
  ]

  const chartOptions = {
    chart: {
      zoom: {
        enabled: false,
      },
      offsetX: 0,
      offsetY: 0,
      selection: {
        enabled: false,
      },
      toolbar: {
        show: false,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: [textColor],
        background: [valueColor],
        fontSize: "14px",
      },
      formatter: function (val) {
        return `${val}%`
      },
      offsetX: 0,
    },
    grid: {
      show: false,
      row: {
        opacity: 1,
        colors: ["#F9FAFB"],
      },
      column: {
        opacity: 0,
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        barHeight: "100%",
        horizontal: true,
      },
    },
    fill: {
      colors: [`${valueColor}1a`],
    },
    // Disabled tooltip inside the chart.
    tooltip: {
      custom: () => {
        return null
      },
    },
    xaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      labels: {
        show: false,
      },
    },
    legends: {
      itemMargin: {
        horizontal: 0,
        vertical: 0,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    stroke: {
      show: false,
    },
    ...options,
  }

  return (
    <Chart
      className="-my-7 w-full"
      // @ts-ignore
      options={chartOptions}
      series={chartSeries}
      type="bar"
      height={90}
      width="100%"
    />
  )
}
