import classNames from "classnames"
import { System } from "@design-system/index"

interface Website {
  name: string
  base_url: string
}

interface Props {
  site: Website
  className?: string
  titleBold?: boolean
}

export const SitePreview = ({
  site,
  className = "",
  titleBold = true,
}: Props) => {
  const classes = classNames("flex items-center gap-2", className)

  return (
    <div className={classes}>
      <System.Site.Favicon url={site.base_url} />
      <div className="flex flex-col gap-px">
        {titleBold ? (
          <System.Strong>{site.name}</System.Strong>
        ) : (
          <System.Text>{site.name}</System.Text>
        )}
        <System.ExternalLink size="xsmall" href={site.base_url}>
          {site.base_url}
        </System.ExternalLink>
      </div>
    </div>
  )
}
