import { getPrivateApiWordPressPluginsPath } from "@/Plugin/api/routes-private"
import { SafeUpdatePluginStatus } from "@components/index"
import { WordPressPluginComponents } from "@components/Plugin/components"
import { ProjectPluginInstance } from "@components/Plugin/components/Table/AllPlugins"
import { useBulkPluginProcessesStore } from "@components/Plugin/components/Table/AllPlugins/useBulkPluginProcessesStore"
import { useProcessPendingAndLastFinishedContext } from "@components/Processes/contexts/ProcessPendingAndLastFinishedContext/hooks/useProcessPendingAndLastFinishedContext"
import { useProgressTasksContext } from "@components/Processes/contexts/ProgressTasksContext/hooks/useProgressTasksContext"
import { InfoCircle } from "@design-system/Icon/components/InfoCircle"
import {
  Button,
  Checkbox,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Link,
  System,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tag,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@design-system/index"
import { Loader } from "@design-system/Loader"
import { SiteFavicon } from "@design-system/Site/Favicon"
import { TableRowProcess } from "@design-system/Table/types"
import { cn } from "@helpers/utils"
import { CheckCircleIcon } from "@heroicons/react/20/solid"
import { useDeletePlugin } from "@hooks/useDeletePlugin"
import { useRollbackPlugin } from "@hooks/useRollbackPlugin"
import { useToggleIgnoreUpdatePlugin } from "@hooks/useToggleIgnoreUpdatePlugin"
import { useTogglePluginActivation } from "@hooks/useTogglePluginActivation"
import { Check } from "lucide-react"
import { useEffect, useMemo } from "react"
import { mutate } from "swr"
import { useBulkPlugins } from "./useBulkPluginsStore"

interface BulkDataPluginInstancesProps {
  data: ProjectPluginInstance[]
}

export const BulkPluginGroupTable = ({
  data,
}: BulkDataPluginInstancesProps) => {
  const { getIsPluginInstanceSelected, toggleSelectedInstance } =
    useBulkPlugins()
  return (
    <Table>
      <TableBody>
        {data.map((projectPluginInstance) => (
          <TableRow
            key={projectPluginInstance.id}
            data-state={
              getIsPluginInstanceSelected(projectPluginInstance.id) &&
              "selected"
            }
            className="relative"
          >
            <TableCell className="!w-2 !p-0">
              <RowIndicator />
            </TableCell>

            <TableCell className="w-8 pl-6">
              <div className="flex items-center">
                <Checkbox
                  checked={getIsPluginInstanceSelected(
                    projectPluginInstance.id,
                  )}
                  onCheckedChange={() =>
                    toggleSelectedInstance(projectPluginInstance.id)
                  }
                  aria-label="Select row"
                />
              </div>
            </TableCell>

            <TableCell className="w-[400px]">
              <PluginNameAndActions
                projectPluginInstance={projectPluginInstance}
              />
            </TableCell>

            <TableCell>
              <PluginVersion projectPluginInstance={projectPluginInstance} />
            </TableCell>

            <TableCell>
              <PluginStatusOrActions
                projectPluginInstance={projectPluginInstance}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

interface CellProps {
  projectPluginInstance: ProjectPluginInstance
}

export const PluginNameAndActions = ({ projectPluginInstance }: CellProps) => {
  const { toggleSelectedInstance } = useBulkPlugins()
  return (
    <div
      className={cn("flex items-center gap-2", {
        "opacity-50": !projectPluginInstance.is_active,
      })}
    >
      <span
        className="cursor-pointer"
        onClick={() => toggleSelectedInstance(projectPluginInstance.id)}
      >
        <SiteFavicon
          url={projectPluginInstance.base_url}
          classNameContainer="size-8 !p-0"
          className="!size-8"
        />
      </span>

      <div className="flex flex-col">
        <span
          className="cursor-pointer select-none text-body-sm font-semibold text-primary-darkest"
          onClick={() => toggleSelectedInstance(projectPluginInstance.id)}
        >
          {projectPluginInstance.name}
        </span>
        <Link
          href={projectPluginInstance.base_url}
          size="xs"
          variant="neutral"
          className="inline-flex items-center gap-2 !font-normal"
          target="_blank"
        >
          <TooltipProvider delayDuration={150}>
            <Tooltip>
              <TooltipTrigger>
                <span className="max-w-[240px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {projectPluginInstance.base_url}
                </span>
              </TooltipTrigger>
              <TooltipContent>{projectPluginInstance.base_url}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <System.Svg.ArrowUpRight size={16} className="text-primary-darkest" />
        </Link>
      </div>
    </div>
  )
}

export const PluginVersion = ({ projectPluginInstance }: CellProps) => {
  const process = useBulkPluginProcessesStore((state) =>
    state.getCurrentPluginProcess(projectPluginInstance.id),
  )
  const processType = process?.type
  const processState = process?.task.state
  const newVersion = projectPluginInstance.new_version

  return (
    <div
      className={cn("flex", {
        "opacity-70": !projectPluginInstance.is_active,
      })}
    >
      <div
        className={
          "grid grid-cols-[minmax(85px,_1fr),_minmax(16px,_auto),_minmax(85px,_1fr)] items-center gap-4"
        }
      >
        {projectPluginInstance.pluginUpdateData.hasNeedUpdate() && (
          <span className="text-right text-body-sm text-main-grey-400">
            {projectPluginInstance.version}
          </span>
        )}

        {(!projectPluginInstance.pluginUpdateData.hasNeedUpdate() ||
          (!projectPluginInstance.pluginUpdateData.hasNeedUpdate() &&
            processType === "UPDATE_PLUGIN" &&
            !newVersion)) && (
          <>
            <span className="flex items-center justify-end gap-1 text-body-sm text-success-base">
              {projectPluginInstance.version}
            </span>

            <CheckCircleIcon className="size-[16px] text-success-base" />
          </>
        )}
        {projectPluginInstance.pluginUpdateData.hasNeedUpdate() && (
          <System.Svg.ChevronsRight size={16} />
        )}

        <div>
          {process?.type === "UPDATE_PLUGIN" &&
            (processState === "active" || processState === "waiting") &&
            !!newVersion && (
              <Tag variant="info" size="sm">
                <Loader size={16} />
                {newVersion}
              </Tag>
            )}

          {process?.type === "UPDATE_PLUGIN" &&
            processState === "completed" &&
            !!newVersion && (
              <Tag variant="success" size="sm">
                <Check size={13} />
                {newVersion}
              </Tag>
            )}

          {process?.type !== "UPDATE_PLUGIN" &&
            projectPluginInstance.pluginUpdateData.hasNeedUpdate() &&
            !!newVersion && (
              <Tag variant="darkGrey" size="sm">
                <System.Svg.Update size={16} />
                {newVersion}
              </Tag>
            )}
        </div>
      </div>
    </div>
  )
}

const PluginStatusOrActions = ({ projectPluginInstance }: CellProps) => {
  const process = useBulkPluginProcessesStore((state) =>
    state.getCurrentPluginProcess(projectPluginInstance.id),
  )

  useEffect(() => {
    if (process?.task.state === "completed") {
      mutate(getPrivateApiWordPressPluginsPath())
    }
  }, [process?.task.state])

  if (process) {
    return <PluginProcessStatus process={process} />
  }

  return <PluginActions projectPluginInstance={projectPluginInstance} />
}

const PluginProcessStatus = ({ process }: { process: TableRowProcess }) => {
  if (process.type === "UPDATE_PLUGIN") {
    return (
      <div className="flex w-full flex-1 shrink-0 items-center justify-end gap-2">
        {!process.task.detailsResult?.is_rollback_successful && (
          <SafeUpdatePluginStatus
            type={process.type}
            task={process.task}
            steps={process.task.steps ?? []}
          />
        )}

        {process.task?.detailsResult?.rollback_information?.needed &&
          process.task?.detailsResult?.is_rollback_successful && (
            <>
              <Tag
                variant="darkGrey"
                size="xs"
                className="h-7 shrink-0 gap-2 px-4"
              >
                <System.Svg.Sync size={16} />

                <span className="text-inherit">Update rolled back</span>
              </Tag>

              <Link
                variant="neutral"
                data-for={`go_to_${process.task.projectId}`}
                size="sm"
                className="gap-2"
                href={`/projects/${process.task.projectId}/wordpress/login`}
                target="_blank"
              >
                <System.Svg.WordPress className="h-4 w-4 opacity-50" />
                Admin
                <System.Svg.External className="h-4 w-4 opacity-50" />
              </Link>
            </>
          )}
      </div>
    )
  }

  if (process.task.state === "active") {
    return (
      <div className="text-right">
        {process.type === "DELETE_PLUGIN" && (
          <Tag variant="info" size="sm">
            <Loader size={16} />
            Deleting plugin
          </Tag>
        )}

        {process.type === "ACTIVATE_PLUGIN" && (
          <Tag variant="info" size="sm">
            <Loader size={16} />
            Activating plugin
          </Tag>
        )}

        {process.type === "DEACTIVATE_PLUGIN" && (
          <Tag variant="info" size="sm">
            <Loader size={16} />
            Deactivating plugin
          </Tag>
        )}

        {process.type === "ROLLBACK_PLUGIN" && (
          <Tag variant="info" size="sm">
            <Loader size={16} />
            Rolling back plugin
          </Tag>
        )}
      </div>
    )
  }

  if (process.task.state === "completed" || process.task.state === "failed") {
    return (
      <div className="flex w-full items-center justify-end">
        <SafeUpdatePluginStatus
          type={process.type}
          task={process.task}
          steps={process.task.steps ?? []}
        />
      </div>
    )
  }

  return null
}

const PluginActions = ({ projectPluginInstance }: CellProps) => {
  const { toggleIgnoreUpdate } = useToggleIgnoreUpdatePlugin()
  const { rollbackPlugin } = useRollbackPlugin()
  const { togglePluginActivation } = useTogglePluginActivation()
  const { deletePlugin } = useDeletePlugin()
  const { pluginGroups } = useBulkPlugins()

  const { mutate: mutatePendingTask } =
    useProcessPendingAndLastFinishedContext()
  const { mutate: mutateProgressTask } = useProgressTasksContext()

  const plugin = useMemo(
    () =>
      pluginGroups.find(
        (group) => group.key === projectPluginInstance.key_plugin,
      ),
    [pluginGroups, projectPluginInstance.key_plugin],
  )
  if (!plugin) {
    return null
  }

  return (
    <div className="flex items-center justify-end gap-4">
      {!projectPluginInstance.is_active && (
        <Tag variant="darkGrey" size="sm" className="opacity-70">
          Inactive
        </Tag>
      )}
      {projectPluginInstance.pluginUpdateData.hasNeedUpdate() &&
        projectPluginInstance.pluginUpdateData.canBeSelectedForUpdate() &&
        !projectPluginInstance.pluginUpdateData.isIgnoredUpdate() && (
          <WordPressPluginComponents.Action.UpdatePlugin
            plugin={{
              id: projectPluginInstance.id,
              is_active: projectPluginInstance.is_active,
              key_plugin: plugin.key,
              name: plugin.name,
              plugin: plugin.key,
              version: projectPluginInstance.version,
              new_version: String(projectPluginInstance.new_version),
            }}
            projectId={projectPluginInstance.projectId}
            button="Outlined"
            onSuccess={() => {
              mutatePendingTask()
              mutateProgressTask()
            }}
          >
            Update
          </WordPressPluginComponents.Action.UpdatePlugin>
        )}

      {projectPluginInstance.need_update &&
        !projectPluginInstance.pluginUpdateData.packageIsAvailable() && (
          <div className="flex gap-1 text-body-sm text-main-grey-400">
            Update unavailable
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <InfoCircle className="size-4" />
                </TooltipTrigger>

                <TooltipContent>
                  The update package is unavailable. Please check your license
                  status if you are using a premium plugin.
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        )}

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon">
            <span className="sr-only">Open menu</span>
            <System.Svg.Actions className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem
            onClick={() =>
              togglePluginActivation({
                plugin: {
                  id: projectPluginInstance.id,
                  key: plugin.key,
                  name: plugin.name,
                  version: projectPluginInstance.version,
                  is_active:
                    projectPluginInstance.pluginUpdateData.canBeActivated(),
                },
                projectId: projectPluginInstance.projectId,
                onSuccess: () => {
                  mutatePendingTask()
                  mutateProgressTask()
                },
              })
            }
            disabled={
              !projectPluginInstance.pluginUpdateData.canBeActivated() &&
              !projectPluginInstance.pluginUpdateData.canBeDeactivated()
            }
          >
            {projectPluginInstance.pluginUpdateData.canBeActivated()
              ? "Activate"
              : "Deactivate"}
          </DropdownMenuItem>

          <DropdownMenuItem
            onClick={() =>
              toggleIgnoreUpdate({
                plugin: { id: projectPluginInstance.pluginId },
                projectId: projectPluginInstance.projectId,
                isIgnoredUpdate:
                  projectPluginInstance.pluginUpdateData.isIgnoredUpdate(),
                onSuccess: () => mutate(getPrivateApiWordPressPluginsPath()),
              })
            }
          >
            {projectPluginInstance.pluginUpdateData.isIgnoredUpdate()
              ? "Reconsider Update"
              : "Exclude Update"}
          </DropdownMenuItem>

          <DropdownMenuItem
            onClick={() =>
              rollbackPlugin({
                plugin: {
                  id: projectPluginInstance.id,
                  is_active: projectPluginInstance.is_active,
                  key: plugin.key,
                  name: plugin.name,
                  version: projectPluginInstance.version,
                  slug: plugin.slug,
                },
                projectId: projectPluginInstance.projectId,
                onSuccess: () => {
                  mutateProgressTask()
                  mutatePendingTask()
                },
              })
            }
          >
            Rollback
          </DropdownMenuItem>

          {projectPluginInstance.pluginUpdateData.canBeDeleted() && (
            <DropdownMenuItem
              onClick={() =>
                deletePlugin({
                  plugin: {
                    id: projectPluginInstance.id,
                    key: plugin.key,
                    name: plugin.name,
                    plugin: plugin.key,
                    version: projectPluginInstance.version,
                  },
                  projectId: projectPluginInstance.projectId,
                  onSuccess: () => {
                    mutatePendingTask()
                    mutateProgressTask()
                  },
                })
              }
            >
              Delete
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

const RowIndicator = () => (
  <svg
    width="8"
    height="65"
    viewBox="0 0 8 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.83409e-06 65L0 3.23926e-08L0.999998 0L1 65H3.83409e-06Z"
      fill="#F5F5F5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 30V3.23926e-08L1.04348 0V30C1.04348 30.5523 1.27707 31 1.56522 31H8V32H1.56522C0.700772 32 0 31.6569 0 30Z"
      fill="#F5F5F5"
    />
  </svg>
)
