import { getPrivateApiOneProjectPath } from "./routes-private"

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/visual-regression
 */
const getPrivateApiOneProjectVisualRegressionPath = ({
  projectId,
}: {
  projectId: string | number
}) => {
  return `${getPrivateApiOneProjectPath({ projectId })}/visual-regression`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/visual-regression/settings
 */
export const getPrivateApiOneProjectVisualRegressionSetttingsPath = ({
  projectId,
}: {
  projectId: string | number
}) => {
  return `${getPrivateApiOneProjectVisualRegressionPath({
    projectId,
  })}/settings`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/visual-regression/scan-url
 */
export const getPrivateApiOneProjectVisualRegressionScanPath = ({
  projectId,
}: {
  projectId: string | number
}) => {
  return `${getPrivateApiOneProjectVisualRegressionPath({
    projectId,
  })}/scan-url`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/visual-regression/toggle
 */
export const getPrivateApiOneProjectVisualRegressionTogglePath = ({
  projectId,
}: {
  projectId: string | number
}) => {
  return `${getPrivateApiOneProjectVisualRegressionPath({
    projectId,
  })}/toggle`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/visual-regression/cancel-edit
 */
export const getPrivateApiOneProjectVisualRegressionCancelEditPath = ({
  projectId,
}: {
  projectId: string | number
}) => {
  return `${getPrivateApiOneProjectVisualRegressionPath({
    projectId,
  })}/cancel-edit`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/visual-regression/comparison-results/:resultId
 */
export const getPrivateApiOneProjectVisualRegressionResultsPath = ({
  projectId,
  resultId,
}: {
  projectId: string | number
  resultId: string
}) => {
  return `${getPrivateApiOneProjectVisualRegressionPath({
    projectId,
  })}/comparison-results/${resultId}`
}
