import classNames from "classnames"
import { ReactNode } from "react"
import { System } from "@design-system/index"
import { TableKey, TableRow } from "@design-system/Table/types"
import { isFunction, memoize } from "lodash"
import { useTable } from "@design-system/Table/hooks/useTable"
import { allKeysAreSelected } from "@design-system/Table/helpers/allKeysAreSelected"
import { useFormContext } from "react-hook-form"
import { Table } from ".."

interface Props<T, C> {
  rowKey: TableKey
  item: TableRow<T, C>
  className?: string
  children: ReactNode
  isChecked?: boolean
  isDisabled?: boolean
  isExpanded?: boolean
  isStacked?: boolean
  onChecked?: () => void
  onExpanded?: () => void
}

const hasLeastOneKeySelected = memoize(
  ({ selected, keys }: { selected: Set<TableKey>; keys: Set<TableKey> }) => {
    if (selected.size === 0) {
      return false
    }
    if (keys.size === 0) {
      return false
    }
    return Array.from(keys).some((key) => selected.has(key))
  },
)

const TableCheckboxWithFormContext = ({ id, isDisabled }) => {
  const { register } = useFormContext()

  return (
    <Table.Checkbox id={id} isDisabled={isDisabled} register={register(id)} />
  )
}

export const TableInnerRow = <T, C = {}>({
  item,
  children,
  className = "",
  isDisabled = false,
  isChecked = false,
  isExpanded = false,
  isStacked = false,
  onChecked = undefined,
  onExpanded = undefined,
}: Props<T, C>) => {
  const withChecked = !!onChecked
  const withExpanded = !!onExpanded

  const {
    state: { withCheckedChildren, checkedRows, withFormContext },
  } = useTable()

  const classes = classNames(
    "gap-4 p-4 w-full transition duration-300 ease-in-out",
    {
      "border-gray-100": !isStacked && !isChecked,
      "bg-primary-lightest": isChecked,
      "border-indigo-100": !isStacked && isChecked,
      "grid items-center": className.includes("grid-cols-"),
      "flex items-center": !className.includes("grid-cols-"),
    },
    className,
  )

  const canExpand = !(
    isDisabled ||
    !item?.children ||
    item?.children.length === 0
  )

  const buttonClasses = classNames(
    "flex-none transition transform-gpu duration-300 ease-in-out -ml-2",
    {
      "rotate-90": isExpanded && canExpand,
    },
  )

  let allKeysSelected, hasLeastOneSelected
  if (withCheckedChildren) {
    const keys = item.children.reduce((acc, curr) => {
      acc.add(curr.id)
      return acc
    }, new Set<TableKey>())

    allKeysSelected = allKeysAreSelected({
      keys: keys,
      selected: checkedRows,
    })

    hasLeastOneSelected =
      withCheckedChildren &&
      hasLeastOneKeySelected({
        keys: keys,
        selected: checkedRows,
      })
  }

  return (
    <div className={classes}>
      {(withChecked || withExpanded) && (
        <div className="flex items-center gap-4">
          {withExpanded && (
            <System.Button.Default
              iconOnly
              disabled={!canExpand}
              onClick={onExpanded}
              className={buttonClasses}
            >
              <System.Svg.ChevronRight className="h-4 w-4" />
            </System.Button.Default>
          )}
          {withChecked &&
            (withFormContext ? (
              <TableCheckboxWithFormContext
                id={`checkbox_${item.id}`}
                isDisabled={isDisabled}
              />
            ) : (
              <Table.Checkbox
                id={`checkbox_${item.id}`}
                isDisabled={isDisabled}
                isChecked={isChecked || allKeysSelected}
                isIndeterminate={hasLeastOneSelected && !allKeysSelected}
                onChange={onChecked}
              />
            ))}
        </div>
      )}
      {isFunction(children)
        ? children({
            item: item,
            isChecked: isChecked,
            isExpanded: isExpanded,
          })
        : children}
    </div>
  )
}
