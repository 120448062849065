import ModalDefault from "@components/Modals/Default"
import React, { useState } from "react"
import System from "@ui/System"
import { PluginImage } from "@components/Plugin/components/PluginImage"
import CheckboxInline from "@ui/CheckboxInline"
import { STRATEGY_INSTALL_PLUGIN } from "constants/bulk-install-plugins"

type PluginSelected = {
  name: string
  requires?: number
  requires_php?: number
  tested?: number
  version?: string | number
  slug: string
  php_version_outdated?: boolean
  is_installed?: boolean
  plugin_uri?: string
  from: STRATEGY_INSTALL_PLUGIN
}

interface Props {
  open: boolean
  setOpen: (open: boolean) => void
  handleConfirm: ({
    mustBeActivate,
    mustClearCache,
  }: {
    mustBeActivate: boolean
    mustClearCache: boolean
  }) => Promise<void>
  pluginsSelected: PluginSelected[]
}
export const ModalConfirmInstallPlugin = ({
  open,
  setOpen,
  handleConfirm,
  pluginsSelected,
}: Props) => {
  const [mustClearCache, setMustClearCache] = useState(false)
  const [mustBeActivate, setMustBeActivate] = useState(true)

  const title =
    pluginsSelected.length === 1
      ? "Are you sure you want to install this plugin?"
      : "Are you sure you want to install these plugins?"
  const titleActivate =
    pluginsSelected.length === 1
      ? "Activate plugin after installation"
      : "Activate plugins after installation"

  return (
    <ModalDefault
      open={open}
      handleClose={() => {
        setOpen(false)
      }}
      handleConfirm={() => {
        handleConfirm({ mustBeActivate, mustClearCache })
        setOpen(false)
      }}
      title={title}
    >
      <div className="divide-y">
        {pluginsSelected.map((plugin: PluginSelected) => {
          return (
            <div
              key={plugin.slug}
              className="flex-2 flex items-center gap-4 py-3"
            >
              <PluginImage slug={plugin.slug} />

              <div className="flex flex-col gap-1">
                {plugin.from ===
                  STRATEGY_INSTALL_PLUGIN.WORDPRESS_REPOSITORY && (
                  <>
                    <span
                      className="font-medium"
                      dangerouslySetInnerHTML={{
                        __html: plugin.name,
                      }}
                    ></span>
                    <System.Text className="text-sm text-gray-500">
                      {plugin.version}
                    </System.Text>
                  </>
                )}
                {plugin.from === STRATEGY_INSTALL_PLUGIN.URL && (
                  <>
                    <span className="font-medium">
                      {plugin.plugin_uri.length > 40
                        ? plugin.plugin_uri.substr(0, 40) + "…"
                        : plugin.plugin_uri}
                    </span>
                  </>
                )}
                {plugin.from === STRATEGY_INSTALL_PLUGIN.ZIP && (
                  <>
                    <span className="font-medium">
                      {plugin.plugin_uri.split("/").pop()}
                    </span>
                  </>
                )}
              </div>
            </div>
          )
        })}
      </div>
      <div className="mt-4 flex items-center gap-2">
        <CheckboxInline
          id="modal_install_plugins_must_activate"
          checked={mustBeActivate}
          onChange={() => setMustBeActivate(!mustBeActivate)}
          className="cursor-pointer"
        />
        <label
          htmlFor="modal_install_plugins_must_activate"
          className="cursor-pointer text-sm text-gray-500"
        >
          {titleActivate}
        </label>
      </div>
      <div className="mt-4 flex items-center gap-2">
        <CheckboxInline
          id="modal_install_plugins_must_clear_cache"
          checked={mustClearCache}
          onChange={() => setMustClearCache(!mustClearCache)}
          className="cursor-pointer"
        />
        <label
          htmlFor="modal_install_plugins_must_clear_cache"
          className="cursor-pointer text-sm text-gray-500"
        >
          Clear cache after installation
        </label>
      </div>
    </ModalDefault>
  )
}
