import { Alert } from "./Alert"
import { Avatar } from "./Avatar"
import { Badges } from "./Badges"
import { Button } from "./Button"
import { ButtonDropdown } from "./ButtonDropdown"
import { Code } from "./Code"
import { Comboboxes } from "./Comboboxes"
import { Content } from "./Content"
import { Context } from "./Context"
import { CopyToClipboard } from "./CopyToClipboard"
import { EmptyState } from "./EmptyState"
import { ExternalLink } from "./ExternalLink"
import { Form } from "./Form"
import { GroupStack } from "./GroupStack"
import { IconBullet } from "./IconBullet"
import { Link } from "./Link"
import { Modal } from "./Modal"
import { Pagination } from "./Pagination/components"
import { Plugin } from "./Plugin"
import { Site } from "./Site"
import { Strong } from "./Strong"
import { SuspenseFallback } from "./SuspenseFallback"
import { Icon } from "./Icon"
import { Tab } from "./Tab"
import { Table } from "./Table/components"
import { Text } from "./Text"
import { Theme } from "./Theme"
import { Title } from "./Title"
import { UpgradeVersion } from "./UpgradeVersion"
import { Step } from "./Step"
import { Container } from "./Container"
import { Uptime } from "./Uptime"
import { VisualRegression } from "@design-system/VisualRegression"
import { Performance } from "./Performance"
import { Loader } from "./Loader"

export const System = {
  Alert,
  Avatar,
  Badges,
  Button,
  ButtonDropdown,
  Code,
  Container,
  Comboboxes,
  Context,
  Content,
  CopyToClipboard,
  EmptyState,
  ExternalLink,
  Form,
  GroupStack,
  IconBullet,
  Link,
  Loader,
  Modal,
  Pagination,
  Performance,
  Plugin,
  Site,
  Strong,
  Step,
  Svg: Icon,
  SuspenseFallback,
  Tab,
  Table,
  Text,
  Theme,
  Title,
  UpgradeVersion,
  Uptime,
  VisualRegression,
}

export * from "./RadioGroup"
export * from "./Checkbox"
export * from "./Tag"
export * from "./Input"
export * from "./WhatsNewCard"
export * from "./ScoredReview"
export * from "./Logo"
export * from "./New"
