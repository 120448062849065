import React, { useState } from "react"
import { ProcessTasksType } from "@prisma/client"
import WordPressRepositoryPluginModel from "@/Core/Models/Plugins/model"
import { STRATEGY_INSTALL_PLUGIN } from "constants/bulk-install-plugins"
import useNotifications from "@hooks/notifications/useNotifications"
import { System } from "@design-system/index"
import { useTable } from "@design-system/Table/hooks/useTable"
import { PluginsModal } from "@components/Plugin/components/Modal"
import { SVG } from "@svg/index"
import { getWordPressPluginDownloadPackage } from "@helpers/getWordPressPluginDownloadPackage"
import { PluginsAction } from "@components/Plugin/actions"
import { createEntityIdFromPluginProcess } from "@components/Plugin/helpers/createEntityIdFromPluginProcess"

interface Props {
  projectId: number
  plugins: WordPressRepositoryPluginModel[]
}

export function IntallPluginFromRepository({ projectId, plugins }: Props) {
  const [modalData, setModalData] = useState({
    open: false,
  })

  const { state, addRowProcessingKeys, clearCheckedRows } = useTable()
  const { checkedRows } = state

  const { handleNotifySuccess } = useNotifications()

  if (checkedRows.size === 0) {
    return null
  }

  const getPluginsFromSelectedValues = () => {
    return plugins
      .filter((item: WordPressRepositoryPluginModel) => {
        return checkedRows.has(
          createEntityIdFromPluginProcess({
            slug: item.slug,
            projectId: projectId,
          }),
        )
      })
      .map((item: WordPressRepositoryPluginModel) => {
        return {
          ...item,
          from: STRATEGY_INSTALL_PLUGIN.WORDPRESS_REPOSITORY,
        }
      })
  }

  const handleConfirmAddPlugins = async ({
    mustClearCache,
    mustBeActivate,
  }) => {
    const data = []

    plugins
      .filter((item: WordPressRepositoryPluginModel) => {
        return checkedRows.has(
          createEntityIdFromPluginProcess({
            slug: item.slug,
            projectId: projectId,
          }),
        )
      })
      .map((plugin: WordPressRepositoryPluginModel) => {
        data.push({
          type: ProcessTasksType.ADD_PLUGIN,
          entities: {
            id: createEntityIdFromPluginProcess({
              slug: plugin.slug,
              projectId: projectId,
            }),
            name: plugin.name,
            plugin: plugin.slug,
            version: plugin.version,
            must_be_activate: mustBeActivate,
            plugin_uri: getWordPressPluginDownloadPackage({
              slug: plugin.slug,
              version: plugin.version,
            }),
            from: STRATEGY_INSTALL_PLUGIN.WORDPRESS_REPOSITORY,
          },
          projectId: projectId,
        })
      })

    const response = await PluginsAction.addPlugins({
      data,
      projectsClearCache: mustClearCache
        ? [
            {
              projectId: projectId,
            },
          ]
        : [],
    })

    handleNotifySuccess({
      title: "We have started the installation",
      message: <>We will inform you when it’s done.</>,
    })

    if (response.code === "success") {
      addRowProcessingKeys({
        keys: response.rowProcessings,
      })

      clearCheckedRows()
    }
  }

  const ACTIONS = [
    {
      label: (
        <>
          <SVG.Download className="h-4 w-4" />
          Install
        </>
      ),
      key: "install",
      onClick: async () => {
        setModalData({
          open: true,
        })
      },
    },
  ]

  return (
    <>
      <System.Table.BulkActions.List
        actions={ACTIONS}
        isShowing={checkedRows.size > 0}
      />
      {modalData.open && (
        <PluginsModal.ConfirmInstallPlugin
          open={modalData.open}
          setOpen={() => {
            setModalData({ open: false })
          }}
          handleConfirm={handleConfirmAddPlugins}
          pluginsSelected={getPluginsFromSelectedValues()}
        />
      )}
    </>
  )
}
