import { System } from "@design-system/index"
import dynamic from "next/dynamic"
import { PropsWithChildren } from "react"
import classNames from "classnames"

const Chart = dynamic(() => import("react-apexcharts"), { ssr: false })

export enum PerformanceLegend {
  RED = "red",
  YELLOW = "yellow",
  GREEN = "green",
}

const ICONS = {
  [PerformanceLegend.RED]: ({ className }) => (
    <svg
      className={className}
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 0L0.0717964 12H13.9282L7 0Z" fill="currentColor" />
    </svg>
  ),
  [PerformanceLegend.YELLOW]: ({ className }) => (
    <svg
      className={className}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="14" height="14" fill="currentColor" />
    </svg>
  ),
  [PerformanceLegend.GREEN]: ({ className }) => (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="currentColor" />
    </svg>
  ),
}

const COLORS = {
  [PerformanceLegend.RED]: "text-red-500",
  [PerformanceLegend.YELLOW]: "text-orange-400",
  [PerformanceLegend.GREEN]: "text-green-500",
}

interface Props extends PropsWithChildren {
  variant: PerformanceLegend
  className?: string
}

export function Legend({ className, variant, children }: Props) {
  const Icon = ICONS[variant]
  const color = COLORS[variant]

  const classnames = classNames("inline-flex items-center gap-2", className)

  return (
    <System.Text as="span" className={classnames}>
      <Icon className={color} />
      {children}
    </System.Text>
  )
}
