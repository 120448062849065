import qs from "query-string"
import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders"
import { getPrivateApiOneProjectVisualRegressionScanPath } from "@/Project/api/routes-visual-regression-private"

export const scanUrl = async ({
  projectId,
  url,
}: {
  projectId: number
  url: string
}) => {
  const fullUrl = qs.stringifyUrl({
    url: getPrivateApiOneProjectVisualRegressionScanPath({ projectId }),
    query: {
      url: url,
    },
  })

  const headers = await getFetchPrivateHeaders()

  const result = await fetch(fullUrl, {
    method: "GET",
    headers: headers,
  })

  return await result.json()
}
