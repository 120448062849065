import { SvgProps } from "@design-system/Icon/types"

export const Update = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M14.7549 5.53076C13.0391 4.80013 11.1034 4.78101 9.3736 5.47759C8.86129 5.68389 8.27874 5.43582 8.07244 4.92351C7.86615 4.4112 8.11421 3.82866 8.62652 3.62236C10.8484 2.72764 13.3347 2.75221 15.5385 3.69065C17.7422 4.6291 19.483 6.40455 20.3777 8.62644C21.2724 10.8483 21.2478 13.3346 20.3094 15.5384C19.7301 16.8987 18.8319 18.0826 17.7067 19H20.0001C20.5523 19 21.0001 19.4477 21.0001 20C21.0001 20.5523 20.5523 21 20.0001 21H15.0001C14.4478 21 14.0001 20.5523 14.0001 20V19.4733C13.9997 19.4579 13.9997 19.4426 14.0001 19.4273V15C14.0001 14.4477 14.4478 14 15.0001 14C15.5523 14 16.0001 14.4477 16.0001 15V17.7827C17.0841 17.0329 17.9447 15.9868 18.4693 14.7548C19.1999 13.0391 19.219 11.1034 18.5224 9.37351C17.8259 7.64367 16.4706 6.26139 14.7549 5.53076Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M5.63006 6.15997C6.18234 6.15997 6.63006 6.60769 6.63006 7.15997V7.16997C6.63006 7.72226 6.18234 8.16997 5.63006 8.16997C5.07777 8.16997 4.63006 7.72226 4.63006 7.16997V7.15997C4.63006 6.60769 5.07777 6.15997 5.63006 6.15997Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M4.06006 9.99997C4.61234 9.99997 5.06006 10.4477 5.06006 11V11.01C5.06006 11.5623 4.61234 12.01 4.06006 12.01C3.50777 12.01 3.06006 11.5623 3.06006 11.01V11C3.06006 10.4477 3.50777 9.99997 4.06006 9.99997Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M4.63006 14.1C5.18234 14.1 5.63006 14.5477 5.63006 15.1V15.11C5.63006 15.6623 5.18234 16.11 4.63006 16.11C4.07777 16.11 3.63006 15.6623 3.63006 15.11V15.1C3.63006 14.5477 4.07777 14.1 4.63006 14.1Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M7.16006 17.37C7.71234 17.37 8.16006 17.8177 8.16006 18.37V18.38C8.16006 18.9323 7.71234 19.38 7.16006 19.38C6.60777 19.38 6.16006 18.9323 6.16006 18.38V18.37C6.16006 17.8177 6.60777 17.37 7.16006 17.37Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M11.0001 18.94C11.5523 18.94 12.0001 19.3877 12.0001 19.94V19.95C12.0001 20.5023 11.5523 20.95 11.0001 20.95C10.4478 20.95 10.0001 20.5023 10.0001 19.95V19.94C10.0001 19.3877 10.4478 18.94 11.0001 18.94Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
