import { SvgProps } from "@design-system/Icon/types"

export const Alert = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12V16C9 16.5523 8.55228 17 8 17C7.44772 17 7 16.5523 7 16V12C7 10.6739 7.52678 9.40215 8.46447 8.46447C9.40215 7.52678 10.6739 7 12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12V16C17 16.5523 16.5523 17 16 17C15.4477 17 15 16.5523 15 16V12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M12 2C12.5523 2 13 2.44772 13 3V4C13 4.55228 12.5523 5 12 5C11.4477 5 11 4.55228 11 4V3C11 2.44772 11.4477 2 12 2ZM4.89289 4.89289C5.28342 4.50237 5.91658 4.50237 6.30711 4.89289L7.00711 5.59289C7.39763 5.98342 7.39763 6.61658 7.00711 7.00711C6.61658 7.39763 5.98342 7.39763 5.59289 7.00711L4.89289 6.30711C4.50237 5.91658 4.50237 5.28342 4.89289 4.89289ZM19.1071 4.89289C19.4976 5.28342 19.4976 5.91658 19.1071 6.30711L18.4071 7.00711C18.0166 7.39763 17.3834 7.39763 16.9929 7.00711C16.6024 6.61658 16.6024 5.98342 16.9929 5.59289L17.6929 4.89289C18.0834 4.50237 18.7166 4.50237 19.1071 4.89289ZM2 12C2 11.4477 2.44772 11 3 11H4C4.55228 11 5 11.4477 5 12C5 12.5523 4.55228 13 4 13H3C2.44772 13 2 12.5523 2 12ZM19 12C19 11.4477 19.4477 11 20 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H20C19.4477 13 19 12.5523 19 12Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M5.58579 15.5858C5.96086 15.2107 6.46957 15 7 15H17C17.5304 15 18.0391 15.2107 18.4142 15.5858C18.7893 15.9609 19 16.4696 19 17V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V17C5 16.4696 5.21071 15.9609 5.58579 15.5858ZM17 17L7 17V19H17V17Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
