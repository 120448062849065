import classNames from "classnames"
import { isNil } from "lodash"
import { InputHTMLAttributes } from "react"
import { RegisterOptions, useFormContext } from "react-hook-form"

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
  name: string
  register?: Function
  registerOptions?: RegisterOptions
  className?: string
  rows?: number
}

const FieldTextarea = ({
  register,
  name,
  registerOptions = {},
  className = "",
  rows = 6,
  ...rest
}: Props) => {
  const context = useFormContext()

  if (isNil(register)) {
    return (
      <textarea
        rows={rows}
        className={classNames(
          "block w-full rounded-lg border border-gray-200 transition focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2 sm:text-sm",
          className,
        )}
        {...context.register(name, registerOptions)}
        {...rest}
      />
    )
  }
  return (
    <textarea
      rows={rows}
      className={classNames(
        "block w-full rounded-lg border border-gray-300 focus:border-sky-500 focus:ring-sky-500 sm:text-sm",
        className,
      )}
      {...register(name, registerOptions)}
      {...rest}
    />
  )
}

export default FieldTextarea
