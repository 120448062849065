import classNames from "classnames"
import Link from "next/link"
import { System } from "@design-system/index"
import { ElementType, ReactNode } from "react"

type LabelSize = "small" | "medium" | "large"

interface Props {
  color?: string
  name?: string
  as?: ElementType
  className?: string
  children?: ReactNode
  iconOnly?: boolean
  size?: LabelSize
  textOnly?: boolean
  onDelete?: () => void
}

export const SiteLabel = ({
  color = "text-indigo-600",
  as = "span",
  name = "",
  children,
  size = "medium",
  className = "",
  onDelete,
  iconOnly = false,
  textOnly = false,
  ...rest
}: Props) => {
  const labelSmallClasses = classNames({
    "text-xs gap-1": size === "small",
    "px-2 py-1": size === "small" && !iconOnly,
    "p-2": size === "small" && iconOnly,
  })

  const labelMediumClasses = classNames({
    "text-sm gap-2": size === "medium",
    "p-4 py-2": size === "medium" && !iconOnly,
    "p-3": size === "medium" && iconOnly,
  })

  const labelLargeClasses = classNames({
    "text-base gap-2": size === "large",
    "p-4 py-2": size === "large" && !iconOnly,
    "p-3": size === "large" && iconOnly,
  })

  const classes = classNames(
    "rounded-full inline-flex items-center gap-2 text-base leading-6 bg-white/50 text-gray-900",
    {
      "text-sm w-10 h-10 justify-center": textOnly,
      "border-gray-200 border ring-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 transition hover:border-gray-300 hover:bg-gray-50 cursor":
        as === "a",
    },
    labelSmallClasses,
    labelMediumClasses,
    labelLargeClasses,
    className,
  )

  let TagName = as

  if (as === "a") {
    TagName = Link
  }

  return (
    <TagName className={classes} {...rest}>
      {!textOnly && (
        <System.Svg.Label
          filled
          className={classNames("h-5 w-5 flex-none", {
            [color]: !color.startsWith("#"),
          })}
          style={color.startsWith("#") ? { color: color } : undefined}
        />
      )}
      {(!iconOnly || textOnly) && (
        <span className="flex-none">{children || name}</span>
      )}
      {!iconOnly && !!onDelete && (
        <System.Button.Outlined
          onClick={onDelete}
          iconOnly
          className={classNames("flex-none", {
            "-mr-1 !p-1": size === "small",
            "-mr-2": size === "medium",
          })}
        >
          <System.Svg.Cross className="h-3 w-3 flex-none" strokeWidth={2.5} />
        </System.Button.Outlined>
      )}
    </TagName>
  )
}
