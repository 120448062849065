import { getProjectPath } from "."

export interface PropsParams {
  idProject: number
}

interface Props {
  name?: string
  hosting?: string
  rest_url?: string
  user_id_wp_login?: number | string
  http_auth_user?: string
  http_auth_password?: string
  starred?: boolean
}

export const updateProject = async (params: PropsParams, data: Props) => {
  const result = await fetch(getProjectPath(params.idProject), {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })

  return await result.json()
}
