import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders"
import { getPrivateApiOneProjectVisualRegressionTogglePath } from "@/Project/api/routes-visual-regression-private"

export const toggleSetting = async ({ projectId }: { projectId: number }) => {
  const headers = await getFetchPrivateHeaders()

  const result = await fetch(
    getPrivateApiOneProjectVisualRegressionTogglePath({ projectId }),
    {
      method: "POST",
      headers: headers,
    },
  )

  return await result.json()
}
