import WordPressRepositoryPluginModel from "@/Core/Models/Plugins/model"
import { System } from "@design-system/index"
import { BulkWordPressRepositoryByStepRow } from "./Row"

interface Props {
  plugins?: WordPressRepositoryPluginModel[]
}

type Item = {
  id: string
} & WordPressRepositoryPluginModel

const LABELS_COLUMNS = [
  {
    key: "name",
    name: "Name",
  },
  {
    key: "rating",
    name: "Rating",
  },
  {
    key: "requirements",
    name: "Requirements",
  },
  {
    key: "action",
    name: "",
  },
]

export const BulkWordPressRepositoryByStep = ({ plugins }: Props) => {
  return (
    <>
      <System.Table.List<Item>
        columns={LABELS_COLUMNS}
        columnsClassname="grid-cols-[1fr_1fr_1fr_1fr]"
        rows={plugins.map((plugin) => {
          return {
            id: `${plugin.slug}`,
            ...plugin,
          } as Item
        })}
      >
        {({ item, columns }) => {
          return (
            <BulkWordPressRepositoryByStepRow item={item} columns={columns} />
          )
        }}
      </System.Table.List>
    </>
  )
}
