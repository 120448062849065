import { SvgProps } from "@design-system/Icon/types"

export const PlayerPlay = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        strokeWidth={strokeWidth}
        stroke="none"
        d="M0 0h24v24H0z"
        fill="none"
      />
      <path strokeWidth={strokeWidth} d="M7 4v16l13 -8z" />
    </svg>
  )
}
