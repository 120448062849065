import { getPrivateApiOneProjectTestFtpConnectionPath } from "@/Project/api/routes-private"
import { BackupFtpInputs } from "../../../../components/Forms/Projects/Backups/Ftp/types/form-types"
import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders"

export const testFtpConnection = async (
  projectId: number,
  { data }: { data: BackupFtpInputs },
) => {
  const headers = await getFetchPrivateHeaders()
  try {
    const result = await fetch(
      getPrivateApiOneProjectTestFtpConnectionPath({ projectId }),
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          data: data,
        }),
      },
    )

    return await result.json()
  } catch (error) {
    return {
      code: "fetch_error",
    }
  }
}
