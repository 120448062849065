import { SvgProps } from "@design-system/Icon/types"

export const CompetitorManageWp = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeWidth={strokeWidth}
        d="M22.3332 0C10.185 0 0.333984 9.8504 0.333984 21.9986C0.333984 34.1509 10.185 43.9988 22.3332 43.9988C34.4849 43.9988 44.3334 34.1509 44.3334 21.9986C44.3331 9.85009 34.4849 0 22.3332 0ZM22.3342 41.9719C11.3042 41.9719 2.3612 33.0286 2.3612 21.9986C2.3612 10.9708 11.3042 2.0269 22.3342 2.0269C33.3645 2.0269 42.3059 10.9708 42.3059 21.9986C42.3059 33.0286 33.3645 41.9719 22.3342 41.9719Z"
        fill="currentColor"
      />
      <path
        strokeWidth={strokeWidth}
        d="M36.8941 10.9297L28.2465 20.2043L23.9498 12.7338L17.2732 20.8128L13.8166 15.5807L5.23291 28.0674C7.79218 35.0109 14.4682 39.9646 22.3005 39.9646C32.3444 39.9646 40.4844 31.8214 40.4844 21.7769C40.4844 17.7127 39.1497 13.9584 36.8941 10.9297Z"
        fill="currentColor"
      />
    </svg>
  )
}
