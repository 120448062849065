import { TableRowProcess } from "@design-system/Table/types"
import { create } from "zustand"

interface BulkProjectPluginProcessesState {
  processes: Set<TableRowProcess>
  setProcesses: (processes: Set<TableRowProcess>) => void

  getCurrentPluginProcess: (
    id: TableRowProcess["key"],
  ) => TableRowProcess | undefined

  addProcess: (process: TableRowProcess) => void
  removeProcess: (processKey: TableRowProcess["key"]) => void

  getOngoingProcessesForRowCount: (pluginKey) => number
}

export const useBulkPluginProcessesStore =
  create<BulkProjectPluginProcessesState>((set, get) => ({
    processes: new Set(),
    setProcesses: (processes) => set({ processes: processes }),

    // TODO: abstract this into a function that can be used in both useBulkPluginProcessesStore and useSingleProjectPluginProcessesStore
    getCurrentPluginProcess: (key) => {
      const processes = get().processes
      const processesForPlugin = [...processes].filter(
        (process) => process.key === key,
      )
      if (processesForPlugin.length === 0) {
        return undefined
      }

      if (processesForPlugin.length > 1) {
        const ongoingProcess = processesForPlugin.find(
          (process) => process.task.state === "active",
        )
        if (ongoingProcess) {
          return ongoingProcess
        }
      }

      return processesForPlugin[0]
    },

    addProcess: (process) =>
      set((state) => ({
        processes: new Set(state.processes).add(process),
      })),
    removeProcess: (processKey) =>
      set((state) => {
        const updatedProcesses = new Set(state.processes)
        updatedProcesses.forEach((process) => {
          if (process.key === processKey) {
            updatedProcesses.delete(process)
          }
        })
        return { processes: updatedProcesses }
      }),

    getOngoingProcessesForRowCount: (pluginKey) => {
      const processes = get().processes
      return [...processes].filter(
        (process) =>
          process.key.toString().includes(pluginKey) &&
          process.task.state === "active",
      ).length
    },
  }))
