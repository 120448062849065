import { ProcessComponents } from "@components/Processes"
import { System } from "@design-system/index"
import { RequestCacheClearFormInputs } from "@components/Processes/components/Form/types"
import { useGenericModal } from "@design-system/Context/GenericModal/hooks/useGenericModal"
import { useState } from "react"

interface Props {
  onConfirm: (props: RequestCacheClearFormInputs) => void
}

export function ModalBulkActivatePlugin({ onConfirm }: Props) {
  const { closeModal } = useGenericModal()

  const [loading, setLoading] = useState(false)

  return (
    <>
      <div className="text-center">
        <System.Title className="mb-2">Are you sure?</System.Title>
        <System.Text>
          Please confirm activation of the plugins you have selected.
        </System.Text>
      </div>
      <ProcessComponents.Form.ResquestCacheClear.Provider>
        <ProcessComponents.Form.ResquestCacheClear.Form
          onSubmit={(values) => {
            setLoading(true)
            onConfirm(values)
          }}
        />
        <div className="mt-8 flex items-center gap-2">
          <System.Button.Outlined
            onClick={closeModal}
            className="w-full justify-center"
          >
            Cancel
          </System.Button.Outlined>
          <System.Form.Submit
            form="request-cache-clear"
            className="w-full justify-center"
            loading={loading}
          >
            Activate Selected Plugins
          </System.Form.Submit>
        </div>
      </ProcessComponents.Form.ResquestCacheClear.Provider>
    </>
  )
}
