import { cn } from "@helpers/utils"
import classNames from "classnames"
import { get } from "lodash"
import { MouseEventHandler } from "react"

interface Props {
  children: React.ReactNode
  className?: string
  large?: boolean
  as?: keyof JSX.IntrinsicElements | React.ComponentType
  onClick?: MouseEventHandler<any>
}

export const getDefaultClassNameText = (props?: {
  className?: string
  large?: boolean
}) =>
  classNames(
    {
      "text-sm": !get(props, "large", false),
    },
    "text-gray-800",
    get(props, "className", ""),
  )

const Text = ({
  children,
  className = "",
  large = false,
  as: Tag = "p",
  onClick,
}: Props) => {
  return (
    <Tag
      className={cn(
        getDefaultClassNameText({
          large,
        }),
        className,
      )}
      onClick={onClick}
    >
      {children}
    </Tag>
  )
}

export default Text
