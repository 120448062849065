import { createContext, Dispatch, ReactNode, useReducer } from "react"
import { TableState, TableAction, tableReducer } from "../reducers/tableReducer"
import { TableKey } from "../types"

export const TableContext = createContext<{
  state: TableState
  dispatch: Dispatch<TableAction>
}>(null)

interface Props {
  children: ReactNode
  withExpanded?: boolean
  withChecked?: boolean
  isStacked?: boolean
  withCheckedChildren?: boolean
  withExpandCloseAll?: boolean
  expandedRows?: Set<TableKey>
  withFormContext?: boolean
}

export const TableProvider = ({
  children,
  withChecked = false,
  withCheckedChildren = false,
  withExpanded = false,
  withExpandCloseAll = false,
  expandedRows = new Set(),
  isStacked = false,
  withFormContext = false,
}: Props) => {
  const initialState: TableState = {
    withExpandCloseAll: withExpandCloseAll,
    withChecked: withChecked,
    withCheckedChildren: withCheckedChildren,
    withExpanded: withExpanded,
    isStacked: isStacked,
    rowKeys: new Set(),
    rowChildrenKeys: new Set(),
    expandedRows: expandedRows,
    checkedRows: new Set(),
    rowProcessingKeys: new Set(),
    withFormContext: withFormContext,
  }

  const [state, dispatch] = useReducer(tableReducer, initialState)

  return (
    <TableContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </TableContext.Provider>
  )
}
