import { isNil } from "lodash"
import { InputHTMLAttributes } from "react"
import { RegisterOptions, useFormContext } from "react-hook-form"
import { getClassNameInput } from "../../../../ui/Input"

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  register?: Function
  registerOptions?: RegisterOptions
  className?: string
  required?: boolean
  group?: boolean
  disabled?: boolean
  error?: boolean
}

const FieldInput = ({
  register,
  name,
  registerOptions = {},
  group = false,
  disabled = false,
  className = "",
  error = false,
  ...rest
}: Props) => {
  const context = useFormContext()
  if (isNil(register)) {
    return (
      <input
        className={getClassNameInput({
          error,
          disabled,
          group,
          className,
        })}
        disabled={disabled}
        {...context.register(name, registerOptions)}
        {...rest}
      />
    )
  }

  return (
    <input
      className={getClassNameInput({
        error,
        disabled,
        group,
        className,
      })}
      {...register(name, registerOptions)}
      {...rest}
      disabled={disabled}
    />
  )
}

export default FieldInput
