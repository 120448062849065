import { SvgProps } from "@design-system/Icon/types"

export const SpeedTest = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M12 5.00012C10.4178 5.00012 8.87104 5.46931 7.55545 6.34836C6.23986 7.22741 5.21448 8.47683 4.60898 9.93863C4.00347 11.4004 3.84504 13.009 4.15372 14.5608C4.46239 16.1127 5.22431 17.5381 6.34312 18.6569C6.73364 19.0475 6.73363 19.6806 6.34311 20.0712C5.95258 20.4617 5.31942 20.4617 4.92889 20.0711C3.53038 18.6726 2.57798 16.8908 2.19214 14.951C1.8063 13.0112 2.00434 11.0005 2.76122 9.17326C3.5181 7.34601 4.79983 5.78423 6.44431 4.68542C8.0888 3.58661 10.0222 3.00012 12 3.00012C13.9778 3.00012 15.9112 3.58661 17.5557 4.68542C19.2002 5.78423 20.4819 7.34601 21.2388 9.17326C21.9957 11.0005 22.1937 13.0112 21.8079 14.951C21.422 16.8908 20.4696 18.6726 19.0711 20.0711C18.6806 20.4617 18.0474 20.4617 17.6569 20.0712C17.2664 19.6806 17.2664 19.0475 17.6569 18.6569C18.7757 17.5381 19.5376 16.1127 19.8463 14.5608C20.155 13.009 19.9965 11.4004 19.391 9.93863C18.7855 8.47683 17.7601 7.22741 16.4446 6.34836C15.129 5.46931 13.5822 5.00012 12 5.00012Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M16.7071 8.29294C17.0976 8.68346 17.0976 9.31663 16.7071 9.70715L12.7071 13.7072C12.3166 14.0977 11.6834 14.0977 11.2929 13.7072C10.9024 13.3166 10.9024 12.6835 11.2929 12.2929L15.2929 8.29294C15.6834 7.90241 16.3166 7.90241 16.7071 8.29294Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
