import { createSchema } from "morphism"

export interface SourceWordPressRepositoryPlugin {
  name: string
  active_installs: number
  author: string
  author_profile: string
  homepage: string
  rating: number
  requires: number
  requires_php: number
  short_description: number
  tested: number
  version: number
  slug: string
  sections: {
    description: string
    changelog: string
  }
  versions: {
    [key: string]: string
  }
}

export interface TargetWordPressRepositoryPlugin {
  name: string
  active_installs: number
  author: string
  author_profile: string
  homepage: string
  rating: number
  requires: number
  requires_php: number
  short_description: number
  tested: number
  version: number
  slug: string
  sections: {
    description: string
    changelog: string
  }
  versions: {
    [key: string]: string
  }
}

export const SchemaWordPressRepositoryPluginModel = createSchema<
  TargetWordPressRepositoryPlugin,
  SourceWordPressRepositoryPlugin
>({
  name: "name",
  active_installs: "active_installs",
  author: "author",
  author_profile: "author_profile",
  homepage: "homepage",
  rating: "rating",
  requires: "requires",
  requires_php: "requires_php",
  short_description: "short_description",
  tested: "tested",
  version: "version",
  slug: "slug",
  versions: "versions",
  sections: "sections",
})
