import { useState } from "react"

function useLocalStorage<Data>(
  key: string,
  initialValue?: Data,
): [Data, (value: Function | string | number | Data) => void, () => void] {
  const [storedValue, setStoredValue] = useState<Data>(() => {
    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      return initialValue
    }
  })

  const setValue = (value: any) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {}
  }

  const removeValue = () => {
    window.localStorage.removeItem(key)
  }

  return [storedValue, setValue, removeValue]
}

export default useLocalStorage
