import { getPrivateApiOneProjectPath } from "./routes-private"

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/backups
 */
export const getPrivateApiOneProjectBackupsPath = ({
  projectId,
}: {
  projectId: number | string
}) => {
  return `${getPrivateApiOneProjectPath({
    projectId,
  })}/backups`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/backups/scan-check-connection
 */
export const getPrivateApiOneProjectScanCheckConnectionBackupPath = ({
  projectId,
}: {
  projectId: number | string
}) => {
  return `${getPrivateApiOneProjectBackupsPath({
    projectId,
  })}/scan-check-connection`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/restores
 */
export const getPrivateApiOneProjectRestoresPath = ({
  projectId,
}: {
  projectId: number | string
}) => {
  return `${getPrivateApiOneProjectPath({
    projectId,
  })}/restores`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/backups/settings
 */
export const getPrivateApiOneProjectBackupSettingsPath = ({
  projectId,
}: {
  projectId: number | string
}) => {
  return `${getPrivateApiOneProjectBackupsPath({
    projectId,
  })}/settings`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/backups/:backupId
 */
export const getPrivateApiOneProjectOneBackupPath = ({
  projectId,
  backupId,
}: {
  projectId: number | string
  backupId: string
}) => {
  return `${getPrivateApiOneProjectBackupsPath({
    projectId,
  })}/${backupId}`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/backups/:backupId/request-restore
 */
export const getPrivateApiOneProjectRequestRestoredBackupV4Path = ({
  projectId,
  backupId,
}: {
  projectId: number | string
  backupId: string
}) => {
  return `${getPrivateApiOneProjectOneBackupPath({
    projectId,
    backupId,
  })}/request-restore`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/backups/limit-manual-backup
 */
export const getPrivateApiOneProjectCountLimitManualBackupPath = ({
  projectId,
}: {
  projectId: number | string
}) => {
  return `${getPrivateApiOneProjectBackupsPath({
    projectId,
  })}/count-limit-manual-backup`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/backups/running-backup-process
 */
export const getPrivateApiOneProjectBackupRunningProcessPath = ({
  projectId,
}: {
  projectId: number | string
}) => {
  return `${getPrivateApiOneProjectBackupsPath({
    projectId,
  })}/running-backup-process`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/backups/:backupId/stop-current-backup
 */
export const getPrivateApiProjectStopCurrentBackupPath = ({
  projectId,
  backupId,
}: {
  projectId: number
  backupId: string
}) => {
  return `${getPrivateApiOneProjectOneBackupPath({
    projectId,
    backupId,
  })}/stop-current-backup`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/backups/:backupId/request-download
 */
export const getPrivateApiRequestBackupDownloadPath = ({
  projectId,
  backupId,
}: {
  projectId: number
  backupId: string
}) => {
  return `${getPrivateApiOneProjectOneBackupPath({
    projectId,
    backupId,
  })}/request-download`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/backups/:backupId/database
 */
export const getPrivateApiBackupDownloadLinkDatabasePath = ({
  projectId,
  backupId,
}: {
  projectId: number
  backupId: string
}) => {
  return `${getPrivateApiOneProjectOneBackupPath({
    projectId,
    backupId,
  })}/database`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/backups/:backupId/file
 */
export const getPrivateApiBackupDownloadLinkFilePath = ({
  projectId,
  backupId,
}: {
  projectId: number
  backupId: string
}) =>
  `${getPrivateApiOneProjectOneBackupPath({
    projectId,
    backupId,
  })}/file`

/**
 * @returns  https://private-api.wp-umbrella.com/projects/:projectId/restores/running-restore-process
 */
export const getPrivateApiProjectBackupsRunningRestoreProcess = ({
  projectId,
}: {
  projectId: number | string
}): string => {
  return `${getPrivateApiOneProjectRestoresPath({
    projectId,
  })}/running-restore-process`
}

/**
 * @returns  https://private-api.wp-umbrella.com/projects/:projectId/restores/running-restore-process
 */
export const getPrivateApiProjectStopCurrentRestorationPath = ({
  projectId,
  restoredBackupId,
}: {
  projectId: number | string
  restoredBackupId: string
}): string => {
  return `${getPrivateApiOneProjectRestoresPath({
    projectId,
  })}/${restoredBackupId}/stop-current-restoration`
}
