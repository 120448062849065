import { getPremiumPluginIfIsPremium } from "@/Manager/Plugins/constants/PremiumPlugins"
import ModalDefault from "@components/Modals/Default"
import { getLinkChangelog } from "@components/Plugin/helpers/getLinkChangelog"
import { Button, System } from "@design-system/index"
import { Suspense, useMemo, useState } from "react"
import { PluginChangelogData } from "./components/Data"
import Link from "next/link"

interface Props {
  plugin: {
    key: string
    name: string
    slug: string
  }
}

export const PluginChangelog = ({ plugin }: Props) => {
  const pluginIsPremium = useMemo(
    () => getPremiumPluginIfIsPremium(plugin.key),
    [plugin],
  )

  const [open, setOpen] = useState(false)

  return (
    <>
      <Button
        onClick={(event) => {
          if (!pluginIsPremium) {
            event.preventDefault()
            setOpen(true)
            return
          }
        }}
        size="minimal"
        variant="minimal"
        className="text-body-xs font-semibold"
        asChild
      >
        <Link
          href={getLinkChangelog({
            item: plugin,
            pluginIsPremium,
          })}
          target="_blank"
        >
          Changelog
        </Link>
      </Button>
      {open && (
        <ModalDefault
          handleClose={() => {
            setOpen(false)
          }}
          handleConfirm={() => {
            setOpen(false)
          }}
          open={open}
          title="Changelog"
          textButtonCancel="Close"
          viewButtonConfirm={false}
          size={"xl"}
        >
          <Suspense fallback={<System.SuspenseFallback.Default />}>
            <PluginChangelogData
              item={{
                slug: plugin.slug,
              }}
            />
          </Suspense>
        </ModalDefault>
      )}
    </>
  )
}
