import { Button, System } from "@design-system/index"
import { cn } from "@helpers/utils"
import useNotifications from "@hooks/notifications/useNotifications"
import { cva, VariantProps } from "class-variance-authority"
import { useEffect, useRef, useState } from "react"

const textFieldDisplayVariants = cva("overflow-ellipsis", {
  variants: {
    size: {
      default: "text-body-base",
      sm: "text-body-sm",
    },
  },
  defaultVariants: {
    size: "default",
  },
})

interface TextFieldDisplayProps
  extends VariantProps<typeof textFieldDisplayVariants> {
  className?: string
  children?: string
}

export const TextFieldCopyToClipboard = ({
  className,
  children,
  size,
}: TextFieldDisplayProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const { handleNotifySuccess } = useNotifications()

  const [buttonLength, setButtonLength] = useState(0)
  useEffect(() => {
    if (!buttonRef.current) {
      return
    }

    setButtonLength(buttonRef.current.offsetWidth)
  }, [buttonRef])

  const [copied, setCopied] = useState(false)
  useEffect(() => {
    if (!copied) {
      return
    }

    const timeout = setTimeout(() => {
      setCopied(false)
    }, 4000)

    return () => {
      clearTimeout(timeout)
    }
  }, [copied])

  return (
    <div
      className={cn(
        "flex items-center justify-between gap-2 rounded-full border py-2 pl-6 pr-2 font-medium",
        className,
      )}
    >
      <span
        className={cn(textFieldDisplayVariants({ size }))}
        style={{
          // Note: there might be a simpler way to do this
          maxWidth: `calc(100% - ${buttonLength}px)`,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {children}
      </span>
      <Button
        onClick={() => {
          setCopied(true)
          navigator.clipboard.writeText(children)
          handleNotifySuccess({
            title: "API key copied to clipboard",
          })
        }}
        ref={buttonRef}
        size={size}
      >
        {!copied && (
          <>
            <System.Svg.CopyPaste size={size === "default" ? 21 : 16} />
            Copy API Key
          </>
        )}

        {copied && (
          <>
            <span
              className={
                "flex items-center justify-center rounded-full border border-success-base bg-main-white p-0.5"
              }
            >
              <System.Svg.Check
                size={size === "default" ? 18 : 14}
                className="text-success-base"
              />
            </span>
            Copied!
          </>
        )}
      </Button>
    </div>
  )
}
