import ContactSupportOrDocumentation from "@components/ContactSupportOrDocumentation"
import {
  AdvancedSafeUpdateValidation,
  PluginUpdateType,
} from "@components/Plugin/actions/createProcessTaskUpdatePlugin"
import { UpdatePluginFormInputs } from "@components/Processes/components/Form/types"
import { useGenericModal } from "@design-system/Context/GenericModal/hooks/useGenericModal"
import {
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  RadioGroup,
  RadioGroupItem,
  System,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@design-system/index"
import { zodResolver } from "@hookform/resolvers/zod"
import { PropsWithChildren, useState } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { useDefaultPluginUpdateValues } from "./defaultPluginUpdateValuesStore"

export const updatePluginFormSchema = z.object({
  updateType: z.nativeEnum(PluginUpdateType),
  advancedSafeUpdateValidation: z
    .nativeEnum(AdvancedSafeUpdateValidation)
    .optional(),
  needCacheClear: z.boolean().optional(),
})

export type UpdatePluginFormValues = z.infer<typeof updatePluginFormSchema>

interface Props {
  onConfirm: (props: UpdatePluginFormInputs) => void
  isBulkUpdate?: boolean
}

export const ModalUpdatePlugin = ({ onConfirm, isBulkUpdate }: Props) => {
  const { closeModal } = useGenericModal()

  const { defaultValues, setDefaultValues } = useDefaultPluginUpdateValues()

  const [loading, setLoading] = useState(false)

  const form = useForm<UpdatePluginFormValues>({
    resolver: zodResolver(updatePluginFormSchema),
    defaultValues: isBulkUpdate ? defaultValues.bulk : defaultValues.single,
  })

  const onSubmit = (values: UpdatePluginFormValues) => {
    setLoading(true)

    if (isBulkUpdate) {
      setDefaultValues.bulk(values)
    } else {
      setDefaultValues.single(values)
    }

    onConfirm({
      needCacheClear: values.needCacheClear,
      updateType: values.updateType,
      advancedSafeUpdateValidation: values.advancedSafeUpdateValidation,
    })
  }

  const ClearCacheFormItem = () => (
    <FormField
      control={form.control}
      name="needCacheClear"
      render={({ field }) => (
        <FormItem className="flex items-center gap-2 space-y-0">
          <FormControl>
            <Checkbox checked={field.value} onCheckedChange={field.onChange} />
          </FormControl>

          <FormLabel className="text-body-sm font-medium text-primary-darkest">
            Clear cache after update.
          </FormLabel>

          <ContactSupportOrDocumentation articleId="62330378af6f8700f6db0382">
            Learn more
          </ContactSupportOrDocumentation>
        </FormItem>
      )}
    />
  )

  const OptionTitle = ({ children }: PropsWithChildren) => (
    <h4 className="text-title-h4 text-primary-darkest">{children}</h4>
  )

  const OptionSubtitle = ({ children }: PropsWithChildren) => (
    <p className="text-body-sm text-gray-500">{children}</p>
  )

  const OptionDetails = ({ children }: PropsWithChildren) => (
    <div className="space-y-2">{children}</div>
  )

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <span className="text-title-h3 text-primary-darkest">
          How do you want to update?
        </span>

        <System.Button.Default iconOnly onClick={closeModal} className="!p-0">
          <System.Svg.Cross className="text-primary-darkest" />
        </System.Button.Default>
      </div>

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-4"
        >
          <FormField
            control={form.control}
            name="updateType"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Tabs
                    className="space-y-2"
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <TabsList className="grid w-full grid-cols-3">
                      <TabsTrigger value={PluginUpdateType.QUICK_UPDATE}>
                        <System.Svg.Clock size={17} />
                        Quick Update
                      </TabsTrigger>

                      <TabsTrigger value={PluginUpdateType.SAFE_UPDATE}>
                        <System.Svg.Database size={17} />
                        Classic Safe Update
                      </TabsTrigger>

                      <TabsTrigger
                        value={PluginUpdateType.ADVANCED_SAFE_UPDATE}
                        disabled
                      >
                        <System.Svg.CameraCheck size={17} />
                        Advanced Safe Update (soon)
                      </TabsTrigger>
                    </TabsList>

                    <div className="rounded-lg border border-primary-lightest p-4">
                      <TabsContent
                        value={PluginUpdateType.QUICK_UPDATE}
                        className="space-y-4"
                      >
                        <OptionDetails>
                          <OptionTitle>Quick update process</OptionTitle>
                          <OptionSubtitle>
                            We use WordPress's classic bulk_upgrade function to
                            update your websites and plugins, ensuring a faster
                            process that mirrors WordPress's native update
                            functionality.
                          </OptionSubtitle>
                        </OptionDetails>

                        <ClearCacheFormItem />
                      </TabsContent>

                      <TabsContent
                        value={PluginUpdateType.SAFE_UPDATE}
                        className="space-y-4"
                      >
                        <OptionDetails>
                          <OptionTitle>
                            Classic safe update process (recommended)
                          </OptionTitle>
                          <OptionSubtitle>
                            Before each update, we create a restore point and
                            monitor the process. If your site goes down, it will
                            automatically be restored to its previous state. To
                            ensure this works, make sure to set up the
                            restoration settings in WP Umbrella.
                          </OptionSubtitle>
                        </OptionDetails>

                        <ClearCacheFormItem />
                      </TabsContent>

                      <TabsContent
                        value={PluginUpdateType.ADVANCED_SAFE_UPDATE}
                        className="space-y-4"
                      >
                        <OptionDetails>
                          <OptionTitle>
                            Advanced safe update process
                          </OptionTitle>
                          <OptionSubtitle>
                            Just like a classic safe update, but with visual
                            regression testing. We take screenshots of your site
                            before and after the update. You'll need to decide
                            whether we should automatically roll back in case of
                            a visual discrepancy or if you prefer to manually
                            validate the screenshots.
                          </OptionSubtitle>
                        </OptionDetails>

                        <FormField
                          control={form.control}
                          name="advancedSafeUpdateValidation"
                          render={({ field }) => (
                            <FormItem>
                              <FormControl>
                                <RadioGroup
                                  onValueChange={field.onChange}
                                  defaultValue={field.value}
                                  className="flex flex-col space-y-2"
                                >
                                  <FormItem className="flex items-start gap-2 space-y-0">
                                    <FormControl>
                                      <RadioGroupItem
                                        value={
                                          AdvancedSafeUpdateValidation.MANUAL
                                        }
                                      />
                                    </FormControl>
                                    <FormLabel className="space-y-1 text-body-sm">
                                      <span className="font-semibold">
                                        Manual Validation (recommended)
                                      </span>
                                      <p className="font-medium text-gray-500">
                                        Review the screenshots that potentially
                                        show bugs, then validate the rollback.
                                      </p>
                                    </FormLabel>
                                  </FormItem>
                                  <FormItem className="flex items-start gap-2 space-y-0">
                                    <FormControl>
                                      <RadioGroupItem
                                        value={
                                          AdvancedSafeUpdateValidation.AUTOMATIC
                                        }
                                      />
                                    </FormControl>
                                    <FormLabel className="space-y-1 text-body-sm">
                                      <span className="font-semibold">
                                        Automatic Rollback
                                      </span>
                                      <p className="font-medium text-gray-500">
                                        We'll automatically roll back the update
                                        in case of glitches.
                                      </p>
                                    </FormLabel>
                                  </FormItem>
                                </RadioGroup>
                              </FormControl>
                            </FormItem>
                          )}
                        />
                      </TabsContent>
                    </div>
                  </Tabs>
                </FormControl>
              </FormItem>
            )}
          />
          <div className="flex w-full justify-end gap-2">
            <System.Button.Default
              type="button"
              size="medium"
              onClick={closeModal}
            >
              Cancel
            </System.Button.Default>

            <System.Button.Primary
              loading={loading}
              size="medium"
              type="submit"
            >
              <System.Svg.Update /> Update Plugins
            </System.Button.Primary>
          </div>
        </form>
      </Form>
    </div>
  )
}
