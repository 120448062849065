import { isBoolean, isEmpty, isNil } from "lodash"
import { RuleType } from "@prisma/client"

export interface JSONProjectPluginUpdateData {
  need_update: any
  is_active: boolean
  new_version?: 0 | string
  can_update?: boolean
  PluginRule: {
    type: RuleType
  }[]
  key_plugin: string
  hasNeedUpdate(): boolean
  canBeActivated(): boolean
  canBeDeactivated(): boolean
  canBeDeleted(): boolean
  canBeSelectedForUpdate(): boolean
  isIgnoredUpdate(): boolean
  packageIsAvailable(): boolean
}

class ProjectPluginUpdateData implements JSONProjectPluginUpdateData {
  need_update: any
  new_version?: 0 | string
  can_update?: boolean
  is_active: boolean
  key_plugin: string
  PluginRule: { type: RuleType }[]

  constructor({
    need_update,
    can_update = true,
    new_version,
    is_active,
    PluginRule,
    key_plugin,
  }: {
    need_update: any
    can_update?: boolean
    new_version?: 0 | string
    is_active: boolean
    PluginRule: { type: RuleType }[]
    key_plugin: string
  }) {
    this.need_update = need_update
    this.can_update = can_update
    this.new_version = new_version
    this.is_active = is_active
    this.PluginRule = PluginRule
    this.key_plugin = key_plugin
  }

  hasNeedUpdate(): boolean {
    if (!this.need_update || isEmpty(this.need_update)) {
      return false
    }

    return !isNil(this.need_update?.new_version)
  }

  canBeActivated(): boolean {
    return !this.is_active
  }
  canBeDeactivated(): boolean {
    if (this.key_plugin === "wp-health/wp-health.php") {
      return false
    }
    return this.is_active
  }

  canBeDeleted(): boolean {
    return !this.is_active
  }

  packageIsAvailable(): boolean {
    if (isBoolean(this?.need_update?.package)) {
      return this?.need_update?.package
    }

    return (
      isNil(this?.need_update?.package) || !isEmpty(this?.need_update?.package)
    )
  }

  canBeSelectedForUpdate(): boolean {
    return this.packageIsAvailable() && !this.isIgnoredUpdate()
  }

  isIgnoredUpdate(): boolean {
    if (!this.PluginRule || isEmpty(this.PluginRule)) {
      return false
    }

    return (
      this.PluginRule.filter((rule) => rule.type === RuleType.IGNORE_UPDATE)
        .length > 0
    )
  }
}

export default ProjectPluginUpdateData
