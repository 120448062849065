import { SVG } from "@svg/index"
import React from "react"

export const StarRating = ({ percentage, numberMaxOfStars = 5 }) => {
  const oneStarPercentage = 100 / numberMaxOfStars
  const stars = percentage / oneStarPercentage
  const floorStars = Math.floor(stars) // Get the number of full stars
  const roundStars = Math.round(stars)

  return (
    <div className="inline-flex items-center">
      {Array.from({ length: numberMaxOfStars }).map((_, index) => (
        <span key={index}>
          {(index === floorStars && stars - floorStars > 0) ||
          (index === roundStars && roundStars - stars >= 0.5) ? (
            <SVG.StarHalfFilled className="h-4 w-4 text-yellow-500" />
          ) : index < stars ? (
            <SVG.Star className="h-4 w-4 text-yellow-500" />
          ) : (
            <SVG.StarEmpty className="h-4 w-4 text-yellow-500" />
          )}
        </span>
      ))}
    </div>
  )
}
