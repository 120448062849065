const SuspenseFallbackDefault = () => {
  return (
    <div className="flex animate-pulse space-x-4">
      <div className="flex-1 py-1">
        <div className="h-2 rounded bg-gray-400"></div>
      </div>
    </div>
  )
}

export default SuspenseFallbackDefault
