import { SvgProps } from "@design-system/Icon/types"

export const ThumbUp = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          strokeWidth={strokeWidth}
          fill="currentColor"
          fillRule="evenodd"
          d="M13 5C12.7348 5 12.4804 5.10536 12.2929 5.29289C12.1054 5.48043 12 5.73478 12 6V7C12 8.32608 11.4732 9.59785 10.5355 10.5355C9.83715 11.2339 8.95344 11.7044 8 11.899V17C8 17.5304 8.21071 18.0391 8.58579 18.4142C8.96086 18.7893 9.46957 19 10 19H17C17.0283 19 17.0566 19.0012 17.0848 19.0036C17.1715 19.011 17.323 18.9864 17.5155 18.7939C17.7146 18.5948 17.911 18.2537 18.0229 17.7866L18.9966 12.9179C18.9773 12.6827 18.8752 12.461 18.7071 12.2929C18.5196 12.1054 18.2652 12 18 12H15C14.4477 12 14 11.5523 14 11V6C14 5.73478 13.8946 5.48043 13.7071 5.29289C13.5196 5.10536 13.2652 5 13 5ZM7.6 20.2C7.54354 20.2753 7.48152 20.3469 7.41421 20.4142C7.03914 20.7893 6.53043 21 6 21H4C3.46957 21 2.96086 20.7893 2.58579 20.4142C2.21071 20.0391 2 19.5304 2 19V12C2 11.4696 2.21071 10.9609 2.58579 10.5858C2.96086 10.2107 3.46957 10 4 10H7C7.79565 10 8.55871 9.68393 9.12132 9.12132C9.68393 8.55871 10 7.79565 10 7V6C10 5.20435 10.3161 4.44129 10.8787 3.87868C11.4413 3.31607 12.2044 3 13 3C13.7956 3 14.5587 3.31607 15.1213 3.87868C15.6839 4.44129 16 5.20435 16 6V10H18C18.7957 10 19.5587 10.3161 20.1213 10.8787C20.6839 11.4413 21 12.2043 21 13C21 13.0659 20.9935 13.1315 20.9806 13.1961L19.9806 18.1961C19.9784 18.2069 19.9761 18.2176 19.9736 18.2282C19.7973 18.9805 19.45 19.6878 18.9298 20.2081C18.4118 20.726 17.7232 21.0492 16.9633 21H10C9.12953 21 8.28831 20.7163 7.6 20.2ZM6 12H4V19H6V12Z"
          clipRule="evenodd"
        />
      </g>
    </svg>
  )
}
