import { SvgProps } from "@design-system/Icon/types"

export const Refresh = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M7.42485 4.23531C9.15319 3.23682 11.1619 2.83429 13.1415 3.08973C15.1211 3.34516 16.9619 4.24439 18.3802 5.6489C19.7984 7.0534 20.7156 8.88526 20.9904 10.8623C21.0664 11.4093 20.6846 11.9144 20.1375 11.9904C19.5905 12.0665 19.0854 11.6846 19.0094 11.1376C18.795 9.59508 18.0794 8.16582 16.9729 7.07C15.8663 5.97417 14.4301 5.27258 12.8856 5.07328C11.341 4.87399 9.77381 5.18805 8.42531 5.96709C7.53804 6.47968 6.77784 7.17486 6.19103 7.99995H7.99988C8.55216 7.99995 8.99988 8.44767 8.99988 8.99995C8.99988 9.55224 8.55216 9.99995 7.99988 9.99995H4.52225C4.5071 10.0003 4.49191 10.0003 4.4767 9.99995H3.99988C3.44759 9.99995 2.99988 9.55224 2.99988 8.99995V4.99995C2.99988 4.44767 3.44759 3.99995 3.99988 3.99995C4.55216 3.99995 4.99988 4.44767 4.99988 4.99995V6.27281C5.67843 5.46243 6.49748 4.77106 7.42485 4.23531Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M3.86223 12.0095C4.40926 11.9334 4.91434 12.3153 4.99036 12.8623C5.20473 14.4048 5.92031 15.8341 7.02689 16.9299C8.13346 18.0257 9.56963 18.7273 11.1142 18.9266C12.6587 19.1259 14.226 18.8119 15.5744 18.0328C16.4617 17.5202 17.2219 16.825 17.8087 16H15.9999C15.4476 16 14.9999 15.5522 14.9999 15C14.9999 14.4477 15.4476 14 15.9999 14H19.4775C19.4927 13.9996 19.5078 13.9996 19.5231 14H19.9999C20.5522 14 20.9999 14.4477 20.9999 15V19C20.9999 19.5522 20.5522 20 19.9999 20C19.4476 20 18.9999 19.5522 18.9999 19V17.7271C18.3213 18.5375 17.5023 19.2288 16.5749 19.7646C14.8466 20.7631 12.8379 21.1656 10.8582 20.9102C8.87861 20.6547 7.03788 19.7555 5.61959 18.351C4.20131 16.9465 3.28415 15.1146 3.0094 13.1376C2.93338 12.5906 3.3152 12.0855 3.86223 12.0095Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
