import { isFunction } from "lodash"
import { System } from "@design-system/index"
import { TableColumn, TableKey, TableRow } from "@design-system/Table/types"
import { useTable } from "@design-system/Table/hooks/useTable"
import { getColumnsClassnames } from "@design-system/Table/getColumnsClassnames"
import { allKeysAreSelected } from "@design-system/Table/helpers/allKeysAreSelected"
import { TABLE_ID_SEPARATOR } from "@design-system/Table/constants/table-id-separator"

type ChildFunction<T> = (props: {
  item: T
  isChecked: boolean
  isExpanded: boolean
}) => JSX.Element
type ChildrenProp<T> = React.ReactNode | ChildFunction<T>

interface Props<Row, ChildrenRow> {
  rowKey: TableKey
  item: TableRow<Row, ChildrenRow>
  columns?: Array<TableColumn>
  className?: string
  children: ChildrenProp<Row>
  isDisabled?: boolean
}

function filterById({ set, parentId }): Set<TableKey> {
  const result = new Set<TableKey>()
  for (const item of set) {
    if (
      item === parentId ||
      item.startsWith(`${parentId}${TABLE_ID_SEPARATOR}`)
    ) {
      result.add(item)
    }
  }
  return result
}

export const TableInnerRowWithActions = <Row, ChildrenRow = {}>({
  rowKey,
  item,
  columns,
  children,
  className = "",
  isDisabled = false,
}: Props<Row, ChildrenRow>) => {
  const {
    state,
    toggleRowChecked,
    toggleRowExpanded,
    addSetCheckedRows,
    removeSetCheckedRows,
  } = useTable()
  const {
    withChecked,
    withExpanded,
    checkedRows,
    expandedRows,
    withCheckedChildren,
    rowChildrenKeys,
    isStacked,
  } = state

  const isChecked = checkedRows.has(rowKey)
  const isExpanded = expandedRows.has(rowKey)
  const onChecked = () => {
    if (withCheckedChildren) {
      const childrenKeys = filterById({
        set: rowChildrenKeys,
        parentId: rowKey,
      })

      const allSelected = allKeysAreSelected({
        selected: checkedRows,
        keys: childrenKeys,
      })

      if (!allSelected) {
        addSetCheckedRows(childrenKeys)
      } else {
        removeSetCheckedRows(childrenKeys)
      }
    } else {
      toggleRowChecked({ key: rowKey })
    }
  }
  const onExpanded = () => {
    toggleRowExpanded({ key: rowKey })
  }

  const classes = getColumnsClassnames(columns, className)

  return (
    <System.Table.InnerRow
      rowKey={rowKey}
      item={item}
      isDisabled={isDisabled}
      isStacked={isStacked}
      onChecked={withChecked && onChecked}
      isChecked={isChecked}
      onExpanded={withExpanded && onExpanded}
      isExpanded={isExpanded}
      className={classes}
    >
      {isFunction(children)
        ? children({
            item: item,
            isChecked: isChecked,
            isExpanded: isExpanded,
          })
        : children}
    </System.Table.InnerRow>
  )
}
