import { getPrivateApiWordPressPluginsPath } from "@/Plugin/api/routes-private"
import WordPressPluginWithProjectModel, {
  SchemaJSONProjectPluginApiDataToWordPressPluginWithProjectModel,
} from "@/Plugin/models/WordPressPluginWithProjectModel"

import { FromAPIWordPressPluginWithProjectApiData } from "@/Plugin/types/plugin-from-api"
import fetcherPrivateApiUmbrella from "@helpers/fetcherPrivateApiUmbrella"
import { isEmpty, isNil } from "lodash"
import { morphism } from "morphism"
import useSWR from "swr"
import useSWRImmutable from "swr/immutable"

interface Props {
  suspense?: boolean
  isImmutable?: boolean
  options?: {
    refreshInterval?: number
  }
}

export function useWordPressPlugins(
  { suspense, options, isImmutable = false }: Props = {
    suspense: true,
  },
) {
  const callback = isImmutable ? useSWRImmutable : useSWR

  const url = getPrivateApiWordPressPluginsPath()

  const { data: response, ...rest } = callback<{
    items: {
      data: FromAPIWordPressPluginWithProjectApiData[]
      code: string
    }
    total: number
  }>(url, {
    suspense: suspense,
    fetcher: fetcherPrivateApiUmbrella(),
    ...options,
  })

  const {
    items: { code, data },
  } = response || {}

  if (isNil(data) || isEmpty(data) || code !== "success") {
    return {
      data: [] as WordPressPluginWithProjectModel[],
      ...rest,
    }
  }

  return {
    data: morphism(
      SchemaJSONProjectPluginApiDataToWordPressPluginWithProjectModel,
      data,
      WordPressPluginWithProjectModel,
    ),
    ...rest,
  }
}
