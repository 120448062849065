import {
  DatabaseOptimizationEntity,
  PropsCreateProcessTasks,
  SynchroniseProjectsEntity,
  UpdateCoreEntity,
  UpdatePluginEntity,
  UpdateThemeEntity,
} from "../../Manager/Processes/ProcessCreateManager"
import { ProcessDataInterface } from "../../Repositories/Process/createProcessWithTasks"

export interface PropsCreateProcessTasksApi extends PropsCreateProcessTasks {
  entities:
    | UpdatePluginEntity
    | UpdateCoreEntity
    | UpdateThemeEntity
    | SynchroniseProjectsEntity
    | DatabaseOptimizationEntity
}

interface Props {
  tasks: Array<PropsCreateProcessTasksApi>
  data?: ProcessDataInterface
}

export const createProcess = async (props: Props) => {
  try {
    const response = await fetch(`/api/processes`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(props),
    })

    return await response.json()
  } catch (error) {
    return null
  }
}
