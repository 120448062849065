export const getPluginInfoPath = (slug: string) =>
  `/api/wordpress-plugin-repository/${slug}`

export const getPluginRepositoryInformation = async ({
  slug,
}: {
  slug: string
}) => {
  const result = await fetch(getPluginInfoPath(slug), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })

  return await result.json()
}
