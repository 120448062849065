import { PluginsAction } from "@components/Plugin/actions"
import { ResponseCreateProcessTaskUpdatePlugin } from "@components/Plugin/actions/createProcessTaskUpdatePlugin"
import { UpdatePluginFormInputs } from "@components/Processes/components/Form/types"
import { SystemButtonTypes } from "@design-system/Button/types"
import { useGenericModal } from "@design-system/Context/GenericModal/hooks/useGenericModal"
import { System } from "@design-system/index"
import useNotifications from "@hooks/notifications/useNotifications"
import { isNil } from "lodash"
import { WordPressPluginComponents } from "../.."

interface Props {
  plugin: {
    id: string
    is_active: boolean
    plugin: string
    key_plugin: string
    name: string
    version: string
    new_version: string
  }
  entities?: {
    [key: string]: any
  }
  projectId: number
  onSuccess?: (props: ResponseCreateProcessTaskUpdatePlugin) => void
  children: React.ReactNode
  button?: SystemButtonTypes
  withIcon?: boolean
  className?: string
}

export const PluginUpdateAction = ({
  plugin,
  projectId,
  onSuccess,
  entities,
  children,
  button = "Primary",
  withIcon = true,
  className = "",
}: Props) => {
  const Button = System.Button[button]

  const { openModal, closeModal } = useGenericModal()

  const { handleNotifySuccess } = useNotifications()

  const handleConfirm = async ({
    advancedSafeUpdateValidation,
    needCacheClear,
    updateType,
  }: UpdatePluginFormInputs) => {
    const response = await PluginsAction.updatePlugin({
      data: {
        entities: {
          id: plugin.id,
          is_active: plugin.is_active,
          plugin: plugin.key_plugin,
          name: plugin.name,
          old_version: plugin.version,
          version: plugin.new_version,
          update_type: updateType,
          advanced_safe_update_validation: advancedSafeUpdateValidation,
          ...entities,
        },
        projectId,
      },
      projectsClearCache: needCacheClear
        ? [
            {
              projectId,
            },
          ]
        : [],
    })

    closeModal()

    handleNotifySuccess({
      title: "We have started the update",
      message: <>We will inform you when it's done.</>,
    })

    if (response.code === "success" && !isNil(onSuccess)) {
      onSuccess(response)
    }
  }

  return (
    <>
      <Button
        className={className}
        onClick={() => {
          openModal({
            size: "md",
            component: (
              <WordPressPluginComponents.Modal.UpdatePlugin
                onConfirm={handleConfirm}
              />
            ),
          })
        }}
        size="small"
      >
        {withIcon && <System.Svg.Update className="h-4 w-4 opacity-50" />}
        {children}
      </Button>
    </>
  )
}
