import { Actions } from "./Actions"
import { Activity } from "./Activity"
import { AddRole } from "./AddRole"
import { AddWorkspace } from "./AddWorkspace"
import { AddressBook } from "./AddressBook"
import { Adjustments } from "./Adjustments"
import { Airplane } from "./Airplane"
import { AlertTriangle } from "./AlertTriangle"
import { Alert } from "./Alert"
import { ArrowBarBoth } from "./ArrowBarBoth"
import { ArrowBarLeft } from "./ArrowBarLeft"
import { ArrowBarRight } from "./ArrowBarRight"
import { ArrowUpRight } from "./ArrowUpRight"
import { AtNot } from "./AtNot"
import { At } from "./At"
import { Avatar } from "./Avatar"
import { Award } from "./Award"
import { Backup } from "./Backup"
import { Bell } from "./Bell"
import { BottomSubMenuIcon } from "./BottomSubMenuIcon"
import { Bug } from "./Bug"
import { BuildingStore } from "./BuildingStore"
import { Bulb } from "./Bulb"
import { BulkManagement } from "./BulkManagement"
import { Bulk } from "./Bulk"
import { Calendar } from "./Calendar"
import { CameraCheck } from "./CameraCheck"
import { CameraQuestion } from "./CameraQuestion"
import { Card } from "./Card"
import { CheckCircle } from "./CheckCircle"
import { Check } from "./Check"
import { CheckupList } from "./CheckupList"
import { ChevronDown } from "./ChevronDown"
import { ChevronLeft } from "./ChevronLeft"
import { ChevronRight } from "./ChevronRight"
import { ChevronUp } from "./ChevronUp"
import { ChevronsRight } from "./ChevronsRight"
import { CircleMinus } from "./CircleMinus"
import { Click } from "./Click"
import { Clock } from "./Clock"
import { CloseAll } from "./CloseAll"
import { Coin } from "./Coin"
import { CompetitorInfiniteWp } from "./CompetitorInfiniteWp"
import { CompetitorMainWp } from "./CompetitorMainWp"
import { CompetitorManageWp } from "./CompetitorManageWp"
import { CompetitorNone } from "./CompetitorNone"
import { CompetitorOther } from "./CompetitorOther"
import { CompetitorWpRemote } from "./CompetitorWpRemote"
import { CompetitorWpmuDev } from "./CompetitorWpmuDev"
import { CopyPaste } from "./CopyPaste"
import { Copy } from "./Copy"
import { CreditCard } from "./CreditCard"
import { CrossCircle } from "./CrossCircle"
import { Cross } from "./Cross"
import { Database } from "./Database"
import { DeviceFloppy } from "./DeviceFloppy"
import { Directory } from "./Directory"
import { DiscountCheck } from "./DiscountCheck"
import { Dollar } from "./Dollar"
import { Dot } from "./Dot"
import { Download } from "./Download"
import { DragAndDrop } from "./DragAndDrop"
import { Edit } from "./Edit"
import { Email } from "./Email"
import { ExpandAll } from "./ExpandAll"
import { External } from "./External"
import { EyeClosed } from "./EyeClosed"
import { EyeOpen } from "./EyeOpen"
import { Feather } from "./Feather"
import { File } from "./File"
import { FilterOff } from "./FilterOff"
import { Filter } from "./Filter"
import { Folder } from "./Folder"
import { G2 } from "./G2"
import { Gift } from "./Gift"
import { Github } from "./Github"
import { Gmail } from "./Gmail"
import { GoogleColor } from "./GoogleColor"
import { Google } from "./Google"
import { GripVertical } from "./GripVertical"
import { HeartFilled } from "./HeartFilled"
import { HeartOutlined } from "./HeartOutlined"
import { Hierarchy } from "./Hierarchy"
import { Home } from "./Home"
import { Image } from "./Image"
import { InfoCircle } from "./InfoCircle"
import { Info } from "./Info"
import { InviteUser } from "./InviteUser"
import { Invite } from "./Invite"
import { Key } from "./Key"
import { Label } from "./Label"
import { Layout } from "./Layout"
import { Link } from "./Link"
import { LoaderUdisabled } from "./LoaderUdisabled"
import { Loader } from "./Loader"
import { Logo } from "./Logo"
import { Logout } from "./Logout"
import { Mailchimp } from "./Mailchimp"
import { Mailgun } from "./Mailgun"
import { Mailjet } from "./Mailjet"
import { Merge } from "./Merge"
import { Message } from "./Message"
import { Minus } from "./Minus"
import { Monitoring } from "./Monitoring"
import { Network } from "./Network"
import { Newspapaer } from "./Newspapaer"
import { NotAt } from "./NotAt"
import { Note } from "./Note"
import { Outlook } from "./Outlook"
import { Patchstack } from "./Patchstack"
import { Pause } from "./Pause"
import { Phone } from "./Phone"
import { Php } from "./Php"
import { Play } from "./Play"
import { PlayerPause } from "./PlayerPause"
import { PlayerPlay } from "./PlayerPlay"
import { PlugOff } from "./PlugOff"
import { Plug } from "./Plug"
import { Plugin } from "./Plugin"
import { PlusCircle } from "./PlusCircle"
import { Plus } from "./Plus"
import { Post } from "./Post"
import { Puzzle } from "./Puzzle"
import { QuestionCircle } from "./QuestionCircle"
import { Question } from "./Question"
import { Quote } from "./Quote"
import { Receipt } from "./Receipt"
import { Refresh } from "./Refresh"
import { Report } from "./Report"
import { Restore } from "./Restore"
import { Road } from "./Road"
import { Scan } from "./Scan"
import { Search } from "./Search"
import { Security } from "./Security"
import { Send } from "./Send"
import { Settings } from "./Settings"
import { Share } from "./Share"
import { Shield } from "./Shield"
import { Signature } from "./Signature"
import { Site } from "./Site"
import { Sitemap } from "./Sitemap"
import { SlackUncolor } from "./SlackUncolor"
import { Slack } from "./Slack"
import { Sort } from "./Sort"
import { SpeedTest } from "./SpeedTest"
import { StarFilled } from "./StarFilled"
import { StarOutline } from "./StarOutline"
import { Star } from "./Star"
import { Support } from "./Support"
import { SwitchHorizontal } from "./SwitchHorizontal"
import { Sync } from "./Sync"
import { Tag } from "./Tag"
import { Team } from "./Team"
import { Theme } from "./Theme"
import { ThumbUp } from "./ThumbUp"
import { Tools } from "./Tools"
import { TopMiddleSubMenuIcon } from "./TopMiddleSubMenuIcon"
import { Trash } from "./Trash"
import { TrendDown } from "./TrendDown"
import { TrendUp } from "./TrendUp"
import { TrendingDown } from "./TrendingDown"
import { TrendingUp } from "./TrendingUp"
import { Trustpilot } from "./Trustpilot"
import { Update } from "./Update"
import { User } from "./User"
import { WPUmbrella } from "./WPUmbrella"
import { WarningCircle } from "./WarningCircle"
import { Warning } from "./Warning"
import { WhiteLabel } from "./WhiteLabel"
import { WordPressOutlined } from "./WordPressOutlined"
import { WordPress } from "./WordPress"
import { WorkspaceAdd } from "./WorkspaceAdd"
import { Zip } from "./Zip"

export const Icon = {
  Actions,
  Activity,
  AddRole,
  AddWorkspace,
  AddressBook,
  Adjustments,
  Airplane,
  AlertTriangle,
  Alert,
  ArrowBarBoth,
  ArrowBarLeft,
  ArrowBarRight,
  ArrowUpRight,
  AtNot,
  At,
  Avatar,
  Award,
  Backup,
  Bell,
  BottomSubMenuIcon,
  Bug,
  BuildingStore,
  Bulb,
  BulkManagement,
  Bulk,
  Calendar,
  CameraCheck,
  CameraQuestion,
  Card,
  CheckCircle,
  Check,
  CheckupList,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronsRight,
  CircleMinus,
  Click,
  Clock,
  CloseAll,
  Coin,
  CompetitorInfiniteWp,
  CompetitorMainWp,
  CompetitorManageWp,
  CompetitorNone,
  CompetitorOther,
  CompetitorWpRemote,
  CompetitorWpmuDev,
  CopyPaste,
  Copy,
  CreditCard,
  CrossCircle,
  Cross,
  Database,
  DeviceFloppy,
  Directory,
  DiscountCheck,
  Dollar,
  Dot,
  Download,
  DragAndDrop,
  Edit,
  Email,
  ExpandAll,
  External,
  EyeClosed,
  EyeOpen,
  Feather,
  File,
  FilterOff,
  Filter,
  Folder,
  G2,
  Gift,
  Github,
  Gmail,
  GoogleColor,
  Google,
  GripVertical,
  HeartFilled,
  HeartOutlined,
  Hierarchy,
  Home,
  Image,
  InfoCircle,
  Info,
  InviteUser,
  Invite,
  Key,
  Label,
  Layout,
  Link,
  LoaderUdisabled,
  Loader,
  Logo,
  Logout,
  Mailchimp,
  Mailgun,
  Mailjet,
  Merge,
  Message,
  Minus,
  Monitoring,
  Network,
  Newspapaer,
  NotAt,
  Note,
  Outlook,
  Patchstack,
  Pause,
  Phone,
  Php,
  Play,
  PlayerPause,
  PlayerPlay,
  PlugOff,
  Plug,
  Plugin,
  PlusCircle,
  Plus,
  Post,
  Puzzle,
  QuestionCircle,
  Question,
  Quote,
  Receipt,
  Refresh,
  Report,
  Restore,
  Road,
  Scan,
  Search,
  Security,
  Send,
  Settings,
  Share,
  Shield,
  Signature,
  Site,
  Sitemap,
  SlackUncolor,
  Slack,
  Sort,
  SpeedTest,
  StarFilled,
  StarOutline,
  Star,
  Support,
  SwitchHorizontal,
  Sync,
  Tag,
  Team,
  Theme,
  ThumbUp,
  Tools,
  TopMiddleSubMenuIcon,
  Trash,
  TrendDown,
  TrendUp,
  TrendingDown,
  TrendingUp,
  Trustpilot,
  Update,
  User,
  WPUmbrella,
  WarningCircle,
  Warning,
  WhiteLabel,
  WordPressOutlined,
  WordPress,
  WorkspaceAdd,
  Zip,
}
