import { AlertTriangleFilled } from "@svg/AlertTriangleFilled"
import { Download } from "@svg/Download"
import { Check } from "@svg/Check"
import { ExternalLink } from "@svg/ExternalLink"
import { Star } from "@svg/Star"
import { StarEmpty } from "@svg/StarEmpty"
import { StarHalfFilled } from "@svg/StarHalfFilled"
import { Link } from "@svg/Link"
import { Zip } from "@svg/Zip/index"

export const SVG = {
  AlertTriangleFilled: AlertTriangleFilled,
  Check: Check,
  Download: Download,
  ExternalLink: ExternalLink,
  Link: Link,
  Star: Star,
  StarEmpty: StarEmpty,
  StarHalfFilled: StarHalfFilled,
  Zip: Zip,
}
