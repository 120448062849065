import { SvgProps } from "@design-system/Icon/types"

export const Calendar = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        strokeWidth={strokeWidth}
        stroke="none"
        d="M0 0h24v24H0z"
        fill="none"
      />
      <path
        strokeWidth={strokeWidth}
        d="M10.5 21h-4.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v3"
      />
      <path strokeWidth={strokeWidth} d="M16 3v4" />
      <path strokeWidth={strokeWidth} d="M8 3v4" />
      <path strokeWidth={strokeWidth} d="M4 11h10" />
      <path
        strokeWidth={strokeWidth}
        d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"
      />
      <path strokeWidth={strokeWidth} d="M18 16.5v1.5l.5 .5" />
    </svg>
  )
}
