import { SystemSvgTypes } from "@design-system/Icon/types"
import classNames from "classnames"
import { isNil } from "lodash"
import ReactTooltip from "react-tooltip"
import { System } from ".."

interface Props {
  tipId?: string | null
  tip?: React.ReactNode | string | null
  leftVersion: string
  rightVersion?: React.ReactNode | string
  color?: "blue" | "green" | "gray" | "default"
  classNameLeft?: string
  iconName?: SystemSvgTypes
}

export const UpgradeVersion = ({
  tip = null,
  tipId = null,
  leftVersion,
  rightVersion = null,
  color = "default",
  classNameLeft = "",
  iconName = "Update",
}: Props) => {
  const Icon = !isNil(iconName) ? System.Svg[iconName] : null

  return (
    <>
      <System.Text
        className={classNames(classNameLeft)}
        data-tip={!isNil(tip)}
        data-for={tipId}
      >
        {leftVersion}
      </System.Text>
      {!isNil(tip) && (
        <ReactTooltip id={tipId} effect="solid">
          {tip}
        </ReactTooltip>
      )}
      {!isNil(rightVersion) && (
        <>
          <System.Svg.ChevronsRight className="h-4 w-4 text-gray-300" />
          <span
            className={classNames(
              {
                "border border-gray-200 bg-gray-50 text-gray-600":
                  color === "gray",
                "bg-blue-50 text-blue-600": color === "blue",
                "bg-green-50 text-green-600": color === "green",
              },
              "inline-flex items-center gap-2 rounded-full px-2 py-1",
            )}
          >
            {Icon && <Icon className="h-4 w-4" />}
            {rightVersion}
          </span>
        </>
      )}
    </>
  )
}
