import { autoInstall } from "./autoInstall"
import { createProject } from "./createProject"
import { deleteProject } from "./deleteProject"
import { createProjectEmailsNotification } from "./emailsNotification"
import { updateProjectWebhookSlack } from "./webhookSlack"
import { upsertBackupSettings } from "./upsertBackupSettings"
import { postBackupNow } from "./postBackupNow"
import { updateProject } from "./updateProject"
import { getProjectDetail } from "./getProjectDetail"
import { SortByProject } from "../../../contexts/FiltersProjectsContext"
import { isNil } from "lodash"
import qs from "query-string"
import { SingleProjectDetailModelQuery } from "../../Core/Models/Projects/SingleProjectDetailModel"
import { getProjectAdminPath } from "@/Project/api/routes"

export const getProjectBackupsAdminPath = ({
  projectId,
}: {
  projectId: number
}) => {
  return `${getProjectAdminPath({ projectId })}/backups`
}

export const getProjectPath = (
  idProject: number | string,
  params?: SingleProjectDetailModelQuery,
): string => {
  let path = `/api/projects/${idProject}`

  if (!isNil(params)) {
    path = qs.stringifyUrl({
      url: path,
      query: params,
    })
  }

  return path
}

export const getProjectsDetailPath = (): string => {
  return `/api/projects/details`
}

export const getProjectDetailPath = (idProject: number | string): string => {
  return `/api/projects/${idProject}/details`
}

export interface PropsPathGetProjects {
  sortBy: SortByProject
  search?: string
  perPage: number
  page: number
}

/**
 * /api/projects
 */
export const getProjectsPath = (): string => {
  return `/api/projects`
}

const FetchApiProject = {
  createProject,
  deleteProject,
  autoInstall,
  createProjectEmailsNotification,
  updateProjectWebhookSlack,
  upsertBackupSettings,
  updateProject,
  postBackupNow,
  getProjectDetail,
}

export default FetchApiProject
