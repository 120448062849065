import { System } from "@design-system/index"
import { UptimeTimelineContainer } from "../UptimeTimelineContainer"

export interface TimelineItemSwitchedMonitor {
  user: string
  region: string
  date: string
}

interface Props {
  data: TimelineItemSwitchedMonitor
}

export function UptimeTimelineSwitchedMonitor({ data }: Props) {
  const { user, region, date } = data
  return (
    <UptimeTimelineContainer>
      <span className="mx-1 flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-4 ring-white">
        <System.Svg.SwitchHorizontal className="h-4 w-4 text-gray-900" />
      </span>
      <System.Text>
        <System.Strong>{user}</System.Strong> switched to{" "}
        <System.Uptime.MonitorRegion>{region}</System.Uptime.MonitorRegion>{" "}
        monitor region
      </System.Text>
      <System.Text className="whitespace-nowrap">{date}</System.Text>
    </UptimeTimelineContainer>
  )
}
