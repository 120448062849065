import { System } from "@design-system/index"
import { cn } from "@helpers/utils"
import { PropsWithChildren } from "react"

interface ReviewProps extends PropsWithChildren {
  className?: string
}

export const Review = ({ className, children }: ReviewProps) => {
  return (
    <div
      className={cn(
        "space-y-6 rounded-lg border-2 border-primary-lighter bg-primary-lightest p-10",
        className,
      )}
    >
      {children}
    </div>
  )
}

export const ReviewContent = ({ children }: PropsWithChildren) => {
  return <div className="space-y-3">{children}</div>
}

export const ReviewHighlight = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex gap-3">
      <div className="min-h-full w-1 bg-primary-base" />
      <span className="text-body-base font-medium italic text-main-grey-600">
        {children}
      </span>
    </div>
  )
}

export const ReviewText = ({ children }: PropsWithChildren) => {
  return <p className="text-body-base italic text-main-grey-500">{children}</p>
}

export const ReviewAuthor = ({ children }: PropsWithChildren) => {
  return <div className="flex items-center gap-4">{children}</div>
}

export const ReviewAuthorAvatar = ({
  src,
  alt,
}: {
  src: string
  alt: string
}) => {
  return (
    <img
      src={src}
      alt={alt}
      className="h-14 w-14 rounded-full border border-gray-300"
    />
  )
}

export const ReviewAuthorIdentity = ({ children }: PropsWithChildren) => {
  return <div className="flex items-center gap-2">{children}</div>
}

export const ReviewAuthorDetails = ({ children }: PropsWithChildren) => {
  return <div className="space-y-2">{children}</div>
}

export const ReviewAuthorDetailsSeparator = () => {
  return <span className="text-body-base text-main-grey-500">·</span>
}

export const ReviewAuthorName = ({ children }: PropsWithChildren) => {
  return (
    <span className="text-body-base font-semibold text-main-grey-600">
      {children}
    </span>
  )
}

export const ReviewAuthorRole = ({ children }: PropsWithChildren) => {
  return <span className="text-body-base text-main-grey-500">{children}</span>
}

export const ReviewAuthorStats = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex items-center gap-1 text-body-sm font-semibold text-primary-base">
      <System.Svg.Award size={17} />
      {children}
    </div>
  )
}
