import { SvgProps } from "@design-system/Icon/types"

export const Home = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M11.2929 2.29289C11.6834 1.90237 12.3166 1.90237 12.7071 2.29289L21.7071 11.2929C21.9931 11.5789 22.0787 12.009 21.9239 12.3827C21.7691 12.7564 21.4045 13 21 13H19C18.4477 13 18 12.5523 18 12C18 11.5712 18.2699 11.2054 18.6491 11.0633L12 4.41421L5.35094 11.0633C5.73013 11.2054 6.00003 11.5712 6.00003 12C6.00003 12.5523 5.55231 13 5.00003 13H3.00003C2.59557 13 2.23093 12.7564 2.07615 12.3827C1.92137 12.009 2.00692 11.5789 2.29292 11.2929L11.2929 2.29289Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M5.00003 11C5.55231 11 6.00003 11.4477 6.00003 12V19C6.00003 19.2652 6.10539 19.5196 6.29292 19.7071C6.48046 19.8946 6.73481 20 7.00003 20H17C17.2652 20 17.5196 19.8946 17.7071 19.7071C17.8947 19.5196 18 19.2652 18 19V12C18 11.4477 18.4477 11 19 11C19.5523 11 20 11.4477 20 12V19C20 19.7957 19.684 20.5587 19.1213 21.1213C18.5587 21.6839 17.7957 22 17 22H7.00003C6.20438 22 5.44132 21.6839 4.87871 21.1213C4.3161 20.5587 4.00003 19.7957 4.00003 19V12C4.00003 11.4477 4.44774 11 5.00003 11Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M11 14C10.7348 14 10.4805 14.1054 10.2929 14.2929C10.1054 14.4804 10 14.7348 10 15V21C10 21.5523 9.55231 22 9.00003 22C8.44774 22 8.00003 21.5523 8.00003 21V15C8.00003 14.2043 8.3161 13.4413 8.87871 12.8787C9.44132 12.3161 10.2044 12 11 12H13C13.7957 12 14.5587 12.3161 15.1213 12.8787C15.684 13.4413 16 14.2043 16 15V21C16 21.5523 15.5523 22 15 22C14.4477 22 14 21.5523 14 21V15C14 14.7348 13.8947 14.4804 13.7071 14.2929C13.5196 14.1054 13.2652 14 13 14H11Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
