import { createBulkProcessTask } from "./createBulkProcessTask"
import { createProcessTaskActivatePlugin } from "./createProcessTaskActivatePlugin"
import { createProcessTaskAddPlugins } from "./createProcessTaskAddPlugins"
import { createProcessTaskDeactivatePlugin } from "./createProcessTaskDeactivatePlugin"
import { createProcessTaskDeletePlugin } from "./createProcessTaskDeletePlugin"
import { createProcessTaskRollbackPlugin } from "./createProcessTaskRollbackPlugin"
import { createProcessTaskUpdatePlugin } from "./createProcessTaskUpdatePlugin"

export const PluginsAction = {
  addPlugins: createProcessTaskAddPlugins,
  updatePlugin: createProcessTaskUpdatePlugin,
  activatePlugin: createProcessTaskActivatePlugin,
  deactivatePlugin: createProcessTaskDeactivatePlugin,
  deletePlugin: createProcessTaskDeletePlugin,
  bulkTask: createBulkProcessTask,
  rollbackPlugin: createProcessTaskRollbackPlugin,
}
