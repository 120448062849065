import { SvgProps } from "@design-system/Icon/types"

export const CompetitorNone = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width={size}
        height={size}
        rx="21.5"
        fill="currentColor"
      ></rect>
      <path
        strokeWidth={strokeWidth}
        d="M26.1228 26.0915L25.9458 26.2685C25.4104 26.8254 24.7692 27.2699 24.0599 27.5761C23.3505 27.8822 22.5873 28.0438 21.8147 28.0513C21.0422 28.0589 20.2759 27.9123 19.5607 27.6202C18.8455 27.328 18.1958 26.8962 17.6495 26.3499C17.1032 25.8036 16.6713 25.1539 16.3792 24.4387C16.087 23.7235 15.9405 22.9572 15.948 22.1846C15.9556 21.4121 16.1172 20.6488 16.4233 19.9395C16.7294 19.2302 17.1739 18.589 17.7308 18.0535L17.8898 17.8945"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        strokeWidth={strokeWidth}
        d="M14 30L17.5 26.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        strokeWidth={strokeWidth}
        d="M25 14L21.5 17.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        strokeWidth={strokeWidth}
        d="M30 19L26.5 22.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        strokeWidth={strokeWidth}
        d="M13 13L31 31"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
