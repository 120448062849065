import { STRATEGY_INSTALL_PLUGIN } from "constants/bulk-install-plugins"
import { useContext } from "react"
import { PluginsContext } from "../contexts"
import {
  BulkInstallPlugin,
  TypeInstallPluginsAction,
} from "../contexts/BulkInstallPluginsContext"

export function useFilteringBulkInstallPlugins() {
  const { state, dispatch } = useContext(
    PluginsContext.BulkInstallPlugins.Context,
  )

  function handleUpdateFrom({ value }: { value: STRATEGY_INSTALL_PLUGIN }) {
    dispatch({
      type: TypeInstallPluginsAction.SET_FROM,
      payload: value,
    })
  }

  function handleUpdatePlugins({ value }: { value: BulkInstallPlugin[] }) {
    dispatch({
      type: TypeInstallPluginsAction.SET_PLUGINS,
      payload: value,
    })
  }

  function resetPlugins() {
    dispatch({
      type: TypeInstallPluginsAction.RESET_PLUGINS,
      payload: null,
    })
  }

  return {
    state,
    handleUpdateFrom,
    handleUpdatePlugins,
    resetPlugins,
  }
}
