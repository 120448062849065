import {
  StateGenericModal,
  ActionGenericModal,
  TypeGenericActionGenericModal,
} from "@design-system/Context/GenericModal/types"
import { get } from "lodash"
import { Reducer } from "react"

export const genericModalReducer: Reducer<
  StateGenericModal,
  ActionGenericModal
> = (state: StateGenericModal, action: ActionGenericModal) => {
  switch (action.type) {
    case TypeGenericActionGenericModal.OPEN_MODAL: {
      return {
        ...state,
        isOpen: true,
        component: action.payload.component,
        size: get(action, "payload.size", "default"),
        withPadding: get(action, "payload.withPadding", true),
        hideOverflow: get(action, "payload.hideOverflow", true),
      }
    }
    case TypeGenericActionGenericModal.CLOSE_MODAL: {
      return {
        ...state,
        isOpen: false,
      }
    }

    default:
      return state
  }
}
