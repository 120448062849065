import { PluginsAction } from "@components/Plugin/actions"
import { ResponseCreateProcessTaskUpdatePlugin } from "@components/Plugin/actions/createProcessTaskUpdatePlugin"
import { WordPressPluginComponents } from "@components/Plugin/components"
import { RequestCacheClearFormInputs } from "@components/Processes/components/Form/types"
import { useGenericModal } from "@design-system/Context/GenericModal/hooks/useGenericModal"
import useNotifications from "@hooks/notifications/useNotifications"
import { isNil } from "lodash"
import { useState } from "react"

interface DeletePluginProps {
  plugin: {
    id: string
    plugin: string
    key: string
    name: string
    version: string
  }
  entities?: {
    [key: string]: any
  }
  projectId: number
  onSuccess?: (props: ResponseCreateProcessTaskUpdatePlugin) => void
}

export const useDeletePlugin = () => {
  const { openModal, closeModal } = useGenericModal()
  const { handleNotifySuccess } = useNotifications()

  const deletePlugin = ({
    plugin,
    projectId,
    entities,
    onSuccess,
  }: DeletePluginProps) => {
    const onSubmit = async (values: RequestCacheClearFormInputs) => {
      const response = await PluginsAction.deletePlugin({
        data: {
          entities: {
            id: plugin.id,
            plugin: plugin.key,
            name: plugin.name,
            version: plugin.version,
            ...entities,
          },
          projectId: projectId,
        },
        projectsClearCache: values.needCacheClear ? [{ projectId }] : [],
      })

      handleNotifySuccess({
        title: "We have started the deletion process",
        message: <>We will inform you when it's done.</>,
      })

      if (response.code === "success" && !isNil(onSuccess)) {
        onSuccess(response)
      }

      closeModal()
    }

    openModal({
      component: (
        <WordPressPluginComponents.Modal.DeletePlugin
          pluginName={`${plugin.name} ${plugin.version}`}
          onConfirm={onSubmit}
        />
      ),
    })
  }

  return { deletePlugin }
}
