import { getPrivateApiWordPressPluginsPath } from "@/Plugin/api/routes-private"
import WordPressPluginWithProjectModel from "@/Plugin/models/WordPressPluginWithProjectModel"
import { JSONWordPressPluginProjectItem } from "@/Plugin/types/plugin-models"
import { useProcessPendingAndLastFinishedContext } from "@components/Processes/contexts/ProcessPendingAndLastFinishedContext/hooks/useProcessPendingAndLastFinishedContext"
import { BulkTablePlugins } from "@design-system/index"
import { useBulkPlugins } from "@design-system/New/BulkTable/BulkTablePlugins/useBulkPluginsStore"
import { TableRowProcess } from "@design-system/Table/types"
import usePrevious from "@hooks/usePrevious"
import { isNil } from "lodash"
import { useEffect, useMemo } from "react"
import { mutate } from "swr"
import { BulkPluginsBottomBar } from "./BulkPluginsActionBar"
import { useBulkPluginProcessesStore } from "./useBulkPluginProcessesStore"

interface Props {
  plugins?: WordPressPluginWithProjectModel[]
  pendingProcesses: Set<TableRowProcess>
  progressTasks?: Set<TableRowProcess>
}

export type ProjectPluginInstance = {
  id: string
} & JSONWordPressPluginProjectItem

export type PluginGroup = {
  id: string
  children: ProjectPluginInstance[]
} & WordPressPluginWithProjectModel

export const AllPlugins = ({
  plugins,
  pendingProcesses,
  progressTasks,
}: Props) => {
  const { finished } = useProcessPendingAndLastFinishedContext()
  const { setPluginGroups } = useBulkPlugins()
  const previousFinishedTask = usePrevious(finished)

  useEffect(() => {
    if (isNil(previousFinishedTask) && !isNil(finished)) {
      mutate(getPrivateApiWordPressPluginsPath())
    }
  }, [finished])

  const pluginGroups = useMemo(
    () =>
      plugins?.map(
        (plugin) =>
          ({
            ...plugin,
            id: plugin.key,
          }) as PluginGroup,
      ),

    [plugins],
  )

  useEffect(() => {
    setPluginGroups(pluginGroups ?? [])
  }, [pluginGroups])

  const setProcesses = useBulkPluginProcessesStore(
    (state) => state.setProcesses,
  )

  useEffect(() => {
    setProcesses(
      new Set([...(pendingProcesses || []), ...(progressTasks || [])]),
    )
  }, [pendingProcesses, progressTasks, setProcesses])

  return (
    <>
      <BulkTablePlugins pluginGroups={pluginGroups ?? []} />
      <BulkPluginsBottomBar plugins={pluginGroups} />
    </>
  )
}
