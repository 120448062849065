import { SystemSvgTypes } from "@design-system/Icon/types"
import classNames from "classnames"
import { System } from ".."

interface Props {
  icon: SystemSvgTypes
  color?: "indigo" | "green" | "red" | "white" | "light-indigo" | "light-gray"
  size?: "default" | "md" | "sm"
  className?: string
}

export const IconBullet = ({
  icon,
  color = "indigo",
  size = "default",
  className = "",
}: Props) => {
  const Icon = System.Svg[icon]
  return (
    <figure
      className={classNames(
        {
          "bg-white text-gray-400": color === "light-gray",
          "bg-white text-gray-900": color === "white",
          "bg-indigo-600 text-white": color === "indigo",
          "bg-indigo-50 text-indigo-600": color === "light-indigo",
          "bg-green-50 text-green-600": color === "green",
          "bg-red-50 text-red-600": color === "red",
          "p-3": size === "default" && icon !== "Info",
          "p-2": size === "md" && icon !== "Info",
          "p-1": (size === "md" && icon === "Info") || size === "sm",
        },
        "inline-flex rounded-full font-bold",
        className,
      )}
    >
      <Icon
        className={classNames({
          "h-6 w-6":
            (size === "default" && icon !== "Info") ||
            (size === "md" && icon === "Info"),
          "h-4 w-4": size === "md" && icon !== "Info",
          "h-3 w-3": size === "sm",
        })}
      />
    </figure>
  )
}
