import { SvgProps } from "@design-system/Icon/types"

export const Bug = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M12 6C11.4696 6 10.9609 6.21071 10.5858 6.58579C10.2107 6.96086 10 7.46957 10 8V9C10 9.55228 9.55228 10 9 10C8.44772 10 8 9.55228 8 9V8C8 6.93913 8.42143 5.92172 9.17157 5.17157C9.92172 4.42143 10.9391 4 12 4C13.0609 4 14.0783 4.42143 14.8284 5.17157C15.5786 5.92172 16 6.93913 16 8V9C16 9.55228 15.5523 10 15 10C14.4477 10 14 9.55228 14 9V8C14 7.46957 13.7893 6.96086 13.4142 6.58579C13.0391 6.21071 12.5304 6 12 6Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M7.16818 8.44495C7.35368 8.16695 7.6658 8 8 8H16C16.3342 8 16.6463 8.16695 16.8318 8.44495C17.5267 9.4863 17.9296 10.6949 17.9985 11.945C17.9995 11.9633 18 11.9816 18 12V15C18 16.5913 17.3679 18.1174 16.2426 19.2426C15.1174 20.3679 13.5913 21 12 21C10.4087 21 8.88258 20.3679 7.75736 19.2426C6.63214 18.1174 6 16.5913 6 15V12C6 11.9816 6.00051 11.9633 6.00152 11.945C6.07043 10.6949 6.47332 9.4863 7.16818 8.44495ZM8.56713 10C8.23703 10.6267 8.04293 11.3181 8 12.0288V15C8 16.0609 8.42143 17.0783 9.17157 17.8284C9.92172 18.5786 10.9391 19 12 19C13.0609 19 14.0783 18.5786 14.8284 17.8284C15.5786 17.0783 16 16.0609 16 15V12.0288C15.9571 11.3181 15.763 10.6267 15.4329 10H8.56713Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M2 13C2 12.4477 2.44772 12 3 12H7C7.55228 12 8 12.4477 8 13C8 13.5523 7.55228 14 7 14H3C2.44772 14 2 13.5523 2 13Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M16 13C16 12.4477 16.4477 12 17 12H21C21.5523 12 22 12.4477 22 13C22 13.5523 21.5523 14 21 14H17C16.4477 14 16 13.5523 16 13Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M12 13C12.5523 13 13 13.4477 13 14V20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20V14C11 13.4477 11.4477 13 12 13Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M8.20862 16.4874C8.49173 16.9616 8.33681 17.5755 7.86261 17.8586L4.51261 19.8586C4.03841 20.1417 3.42448 19.9868 3.14138 19.5126C2.85827 19.0384 3.01319 18.4245 3.48739 18.1414L6.83739 16.1414C7.31159 15.8583 7.92551 16.0132 8.20862 16.4874Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M15.7914 16.4874C16.0745 16.0132 16.6884 15.8583 17.1626 16.1414L20.5126 18.1414C20.9868 18.4245 21.1417 19.0384 20.8586 19.5126C20.5755 19.9868 19.9616 20.1417 19.4874 19.8586L16.1374 17.8586C15.6632 17.5755 15.5083 16.9616 15.7914 16.4874Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M3.15773 6.46095C3.45544 5.99577 4.07388 5.86002 4.53905 6.15773L8.28905 8.55773C8.75423 8.85544 8.88998 9.47388 8.59227 9.93905C8.29456 10.4042 7.67612 10.54 7.21095 10.2423L3.46095 7.84227C2.99577 7.54456 2.86002 6.92612 3.15773 6.46095Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M20.8423 6.46095C21.14 6.92612 21.0042 7.54456 20.5391 7.84227L16.7891 10.2423C16.3239 10.54 15.7054 10.4042 15.4077 9.93905C15.11 9.47388 15.2458 8.85544 15.7109 8.55773L19.4609 6.15773C19.9261 5.86002 20.5446 5.99577 20.8423 6.46095Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
