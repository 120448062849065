import { ModalActivatePlugin } from "./ActivatePlugin"
import { ModalBulkActivatePlugin } from "./BulkActivatePlugin"
import { ModalBulkDeactivatePlugin } from "./BulkDeactivatePlugin"
import { ModalBulkDeletePlugin } from "./BulkDeletePlugin"
import { ModalUpdatePlugin } from "./UpdatePlugin/ModalUpdatePlugin"
import { ModalDeactivatePlugin } from "./DeactivatePlugin"
import { ModalDeletePlugin } from "./DeletePlugin"
import { ModalIgnoreUpdatePlugin } from "./IgnoreUpdatePlugin"
import { ModalBulkConfirmation } from "./ModalBulkConfirmation"
import { ModalConfirmInstallPlugin } from "./ModalConfirmInstallPlugin"
import { ModalRollbackPlugin } from "./RollbackPlugin"

export const PluginsModal = {
  ConfirmInstallPlugin: ModalConfirmInstallPlugin,
  BulkConfirmation: ModalBulkConfirmation,
  ActivatePlugin: ModalActivatePlugin,
  DeactivatePlugin: ModalDeactivatePlugin,
  DeletePlugin: ModalDeletePlugin,
  UpdatePlugin: ModalUpdatePlugin,
  IgnoreUpdatePlugin: ModalIgnoreUpdatePlugin,
  RollbackPlugin: ModalRollbackPlugin,
  BulkActivatePlugin: ModalBulkActivatePlugin,
  BulkDeactivatePlugin: ModalBulkDeactivatePlugin,
  BulkDeletePlugin: ModalBulkDeletePlugin,
}
