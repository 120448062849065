import { SvgProps } from "@design-system/Icon/types"

export const CompetitorMainWp = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeWidth={strokeWidth}
        d="M22 44C34.1503 44 44 34.1503 44 22C44 9.84974 34.1503 0 22 0C9.84974 0 0 9.84974 0 22C0 34.1503 9.84974 44 22 44Z"
        fill="currentColor"
      />
      <path
        strokeWidth={strokeWidth}
        d="M22.0001 13.2004C24.4302 13.2004 26.4001 11.2304 26.4001 8.80039C26.4001 6.37034 24.4302 4.40039 22.0001 4.40039C19.57 4.40039 17.6001 6.37034 17.6001 8.80039C17.6001 11.2304 19.57 13.2004 22.0001 13.2004Z"
        fill="currentColor"
      />
      <path
        strokeWidth={strokeWidth}
        d="M29.48 35.2004L22 39.6004L14.52 35.2004L22 4.40039L29.48 35.2004Z"
        fill="currentColor"
      />
    </svg>
  )
}
