import { Fragment, useRef, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import CheckboxInline from "../../../../../ui/CheckboxInline"
import ContactSupportOrDocumentation from "@components/ContactSupportOrDocumentation"

interface Props {
  open: boolean
  setOpen: Function
  modalProps: {
    handleConfirm: Function
    message?: string
    textButtonConfirm?: string
  }
  canClearCache?: boolean
}

export function ModalBulkConfirmation({
  open,
  setOpen,
  modalProps,
  canClearCache,
}: Props) {
  const cancelButtonRef = useRef()
  const [checked, setCheck] = useState(false)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-y-auto"
        style={{
          zIndex: 1000,
        }}
        //@ts-ignore
        initialFocus={cancelButtonRef}
        open={open}
        //@ts-ignore
        onClose={setOpen}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Are you sure ?
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      {modalProps.message}
                    </p>
                  </div>
                  {canClearCache && (
                    <div className="mt-4 rounded border border-indigo-200 bg-indigo-50 p-2 py-4 text-center">
                      <InformationCircleIcon className="mx-auto mb-2 h-8 w-8 text-indigo-500" />

                      <div className="text-center text-sm">
                        <p>
                          We can also clear the cache of your site(s) after the
                          update.
                        </p>
                        <ContactSupportOrDocumentation articleId="62330378af6f8700f6db0382">
                          Learn more.
                        </ContactSupportOrDocumentation>
                      </div>
                      <div
                        className="mt-2 inline-flex cursor-pointer items-center justify-center gap-2 text-sm"
                        onClick={() => setCheck(!checked)}
                      >
                        <CheckboxInline
                          checked={checked}
                          onChange={() => setCheck(!checked)}
                        />
                        Yeah 🤟, let{`'`}s do it!
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <button
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                  onClick={() => {
                    setOpen(false)
                    modalProps.handleConfirm({
                      clear_cache: checked,
                    })
                  }}
                >
                  {modalProps.textButtonConfirm}
                </button>
                <button
                  id="cancel"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                  onClick={() => setOpen(false)}
                  //@ts-ignore
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
