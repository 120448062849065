import classNames from "classnames"
import { ButtonOrLinkProps } from "@design-system/Button/Default"
import { System } from "@design-system/index"
import { isNil } from "lodash"

interface Props extends ButtonOrLinkProps {
  count?: number
  isCurrent?: boolean
}

export const TabButton = ({
  children,
  className = "",
  count,
  isCurrent = false,
  ...rest
}: Props) => {
  const classes = classNames(
    "flex whitespace-nowrap border-b-2 -mb-[2px] py-3 px-1 text-sm font-semibold rounded-none",
    {
      "border-indigo-500 text-indigo-600": isCurrent,
      "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700":
        !isCurrent,
    },
    className,
  )
  return (
    <System.Button.Default
      className={classes}
      {...rest}
      aria-current={isCurrent ? "page" : undefined}
    >
      {children}
      {!isNil(count) && (
        <span className="text-sm font-normal text-gray-500">({count})</span>
      )}
    </System.Button.Default>
  )
}
