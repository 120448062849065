import dynamic from "next/dynamic"
import { ApexOptions } from "apexcharts"

const Chart = dynamic(() => import("react-apexcharts"), { ssr: false })

type PageSpeedValue = number

interface Props {
  options?: ApexOptions
  value: PageSpeedValue
  size?: number
}

export function PageSpeedChart({ options, value, size = 156 }: Props) {
  let backgroundColor = "transparent"
  let fillColor = "transparent"
  let labelColor = "#111"

  if (value < 49) {
    backgroundColor = "#FF33331A" // red-500;
    fillColor = "#FF3333" // red-500;
    labelColor = "#CC0000" // red-700;
  }

  if (value > 49 && value < 89) {
    backgroundColor = "#FFAA331A" // orange-500;
    fillColor = "#FFAA33" // orange-500;
    labelColor = "#C33300" // orange-700;
  }

  if (value > 89) {
    backgroundColor = "#00CC661A" // green-500;
    fillColor = "#00CC66" // green-500;
    labelColor = "#008800" // green-700;
  }

  const chartSeries = [value]

  const chartOptions = {
    plotOptions: {
      radialBar: {
        inverseOrder: false,
        dataLabels: {
          name: {
            show: false,
            fontSize: 0,
            color: "transparent",
          },
          value: {
            offsetY: 10,
            fontSize: `${size / 4.875}px`,
            fontWeight: "500",
            color: labelColor,
            formatter: function (val) {
              return parseInt(val)
            },
          },
        },
        track: {
          background: "transparent",
        },
        hollow: {
          margin: 0,
          size: "68%",
          position: "front",
          background: backgroundColor,
          opacity: 0.1,
        },
      },
    },
    stroke: {
      lineCap: "round",
    },
    fill: {
      colors: fillColor,
    },
    ...options,
  }

  return (
    <Chart
      // @ts-ignore
      options={chartOptions}
      series={chartSeries}
      type="radialBar"
      height={size}
      width={size}
    />
  )
}
