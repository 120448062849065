import { createContext, ReactNode, Reducer, useReducer } from "react"
import {
  ActionGenericApplication,
  ContextGenericApplication,
  StateGenericApplication,
} from "./types"
import { genericApplicationReducer } from "./reducers/genericApplicationReducer"

const initialState: StateGenericApplication = {
  container: {
    className: "",
  },
}

export const GenericApplicationContext =
  createContext<ContextGenericApplication>(null)

interface Props {
  children: ReactNode
}

export const GenericApplicationProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer<
    Reducer<StateGenericApplication, ActionGenericApplication>
  >(genericApplicationReducer, initialState)

  return (
    <GenericApplicationContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </GenericApplicationContext.Provider>
  )
}

export default {
  Context: GenericApplicationContext,
  Provider: GenericApplicationProvider,
}
