import { PluginsAction } from "@components/Plugin/actions"
import { ResponseCreateProcessTaskUpdatePlugin } from "@components/Plugin/actions/createProcessTaskUpdatePlugin"
import { RequestCacheClearFormInputs } from "@components/Processes/components/Form/types"
import { useGenericModal } from "@design-system/Context/GenericModal/hooks/useGenericModal"
import useNotifications from "@hooks/notifications/useNotifications"
import { isNil } from "lodash"
import { WordPressPluginComponents } from "@components/Plugin/components"
import { useState } from "react"

interface TogglePluginActivationProps {
  plugin: {
    id: string
    is_active: boolean
    key: string
    name: string
    version: string
  }
  projectId: number
  onSuccess?: (props: ResponseCreateProcessTaskUpdatePlugin) => void
  entities?: { [key: string]: any }
}

export const useTogglePluginActivation = () => {
  const { openModal, closeModal } = useGenericModal()
  const { handleNotifySuccess } = useNotifications()
  const [loading, setLoading] = useState(false)

  const togglePluginActivation = ({
    plugin,
    projectId,
    onSuccess,
    entities,
  }: TogglePluginActivationProps) => {
    const action = plugin.is_active
      ? PluginsAction.deactivatePlugin
      : PluginsAction.activatePlugin

    const handleConfirm = async (values: RequestCacheClearFormInputs) => {
      setLoading(true)
      const response = await action({
        data: {
          entities: {
            id: plugin.id,
            plugin: plugin.key,
            name: plugin.name,
            version: plugin.version,
            ...entities,
          },
          projectId: projectId,
        },
        projectsClearCache: values.needCacheClear ? [{ projectId }] : [],
      })

      closeModal()

      handleNotifySuccess({
        title: plugin.is_active
          ? "We have started the deactivation process"
          : "We have started the activation process",
        message: <>We will inform you when it's done.</>,
      })

      if (response.code === "success" && !isNil(onSuccess)) {
        onSuccess(response)
      }
      setLoading(false)
    }

    openModal({
      component: plugin.is_active ? (
        <WordPressPluginComponents.Modal.DeactivatePlugin
          onConfirm={handleConfirm}
          loading={loading}
        />
      ) : (
        <WordPressPluginComponents.Modal.ActivatePlugin
          onConfirm={handleConfirm}
          loading={loading}
        />
      ),
    })
  }

  return { togglePluginActivation }
}
