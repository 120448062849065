import { SvgProps } from "@design-system/Icon/types"

export const Phone = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          strokeWidth={strokeWidth}
          fill="currentColor"
          fillRule="evenodd"
          d="M5 5C4.73478 5 4.48043 5.10536 4.29289 5.29289C4.11141 5.47438 4.00688 5.71844 4.00033 5.97437C4.23032 9.6183 5.78159 13.0537 8.36394 15.6361C10.9463 18.2184 14.3817 19.7697 18.0256 19.9997C18.2816 19.9931 18.5256 19.8886 18.7071 19.7071C18.8946 19.5196 19 19.2652 19 19V15.677L15.4193 14.2448L14.3575 16.0145C14.0897 16.4608 13.5244 16.6271 13.0577 16.3969C10.6887 15.2285 8.77146 13.3113 7.60314 10.9423C7.37294 10.4756 7.53924 9.91027 7.9855 9.64251L9.75523 8.58067L8.32297 5H5ZM2.87868 3.87868C3.44129 3.31607 4.20435 3 5 3H9C9.4089 3 9.77661 3.24895 9.92848 3.62861L11.9285 8.62861C12.1108 9.08432 11.9354 9.60497 11.5145 9.85749L9.8405 10.8619C10.6644 12.2055 11.7945 13.3356 13.1381 14.1595L14.1425 12.4855C14.395 12.0646 14.9157 11.8892 15.3714 12.0715L20.3714 14.0715C20.751 14.2234 21 14.5911 21 15V19C21 19.7957 20.6839 20.5587 20.1213 21.1213C19.5587 21.6839 18.7957 22 18 22C17.9798 22 17.9595 21.9994 17.9393 21.9982C13.7948 21.7463 9.88576 19.9863 6.94972 17.0503C4.01369 14.1142 2.25371 10.2052 2.00184 6.06066C2.00061 6.04046 2 6.02023 2 6C2 5.20435 2.31607 4.44129 2.87868 3.87868Z"
          clipRule="evenodd"
        />
      </g>
    </svg>
  )
}
