import { type ClassValue, clsx } from "clsx"
import { extendTailwindMerge } from "tailwind-merge"
import config from "tailwind.config"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const twMerge = extendTailwindMerge({
  override: {
    classGroups: {
      "font-size": Object.keys(config.theme.extend.fontSize).map(
        (key) => `text-${key}`,
      ),
    },
  },
})
