import { SvgProps } from "@design-system/Icon/types"

export const SwitchHorizontal = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        strokeWidth={strokeWidth}
        stroke="none"
        d="M0 0h24v24H0z"
        fill="none"
      />
      <path strokeWidth={strokeWidth} d="M16 3l4 4l-4 4" />
      <path strokeWidth={strokeWidth} d="M10 7l10 0" />
      <path strokeWidth={strokeWidth} d="M8 13l-4 4l4 4" />
      <path strokeWidth={strokeWidth} d="M4 17l9 0" />
    </svg>
  )
}
