/* eslint-disable react/jsx-no-target-blank */
import React, { AnchorHTMLAttributes } from "react"
import classNames from "classnames"

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode
  className: string
}

export const NewExternalLink = ({
  children,
  className = "",
  ...rest
}: Props) => {
  return (
    <a
      className={classNames(className)}
      {...rest}
      target="_blank"
      rel="nofollow noreferrer"
    >
      {children}
    </a>
  )
}
