import System from "@ui/System"
import { isEmpty } from "lodash"
import { SVGWordPress } from "@svg/WordPress"
import { SVGPhp } from "@svg/Php"
import React from "react"

interface Props {
  wordpress: number
  php: number
}

export function Requirements({ wordpress, php }: Props) {
  return (
    <div className="flex flex-col gap-2">
      <System.Text className="text-sm text-gray-500">
        Requires at least:
      </System.Text>
      <div className="flex items-center gap-4">
        {!isEmpty(wordpress) && (
          <div className="inline-flex items-center gap-2 rounded-full bg-gray-100 px-2 py-1">
            <SVGWordPress className="h-4 w-4" />
            <System.Text>
              <strong>{wordpress}</strong>
            </System.Text>
          </div>
        )}
        {!isEmpty(php) && (
          <div className="inline-flex items-center gap-2 rounded-full bg-gray-100 px-2 py-1">
            <SVGPhp className="h-4 w-4" />
            <System.Text>
              <strong>{php}</strong>
            </System.Text>
          </div>
        )}
      </div>
    </div>
  )
}
