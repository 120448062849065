export default {
  production: false,
  base_url: "http://localhost:3000",
  api_url: "http://localhost:3001",
  private_api_url: "http://localhost:4000",
  api: {
    client_id: "development",
    client_secret: "development",
    scope: "application",
  },
  meilisearch_public_url: "http://localhost:7700",
  stripe: {
    webhooks: {
      invoices: "whsec_ypyiVJCQianmwwdO4o8BAmriP1CAJBz7",
    },
    hourly_backup: [
      {
        slug: "wpu_hourly_backup_1",
        id: "price_1LSe4zIcL5h0EadwHtxqEj6M",
        active: true,
        price: 2.49,
        name: "Hourly Backup",
      },
    ],
    pay_as_you_go: [
      {
        slug: "wpu_monthly_payg_1",
        active: false,
        id: "price_1KExeDIcL5h0EadwC6oHnzKx",
        type: "month",
        price: 1.49,
        name: "Premium",
        limit_monitor: 1,
        limit_projects: 10000,
        max_regions: 16,
        limit_history: 60,
        limit_email_notifications: 10,
      },
      {
        slug: "wpu_monthly_payg_2",
        active: true,
        id: "price_1LFE2UIcL5h0EadwXDDHj9ZZ",
        type: "month",
        price: 1.99,
        name: "Premium",
        limit_monitor: 1,
        limit_projects: 10000,
        max_regions: 16,
        limit_history: 60,
        limit_email_notifications: 10,
      },
      {
        slug: "wpu_monthly_payg_3",
        active: false,
        id: "price_1PqWI3IcL5h0EadwxNudISRE",
        type: "month",
        price: 2.49,
        name: "Premium",
        limit_monitor: 1,
        limit_projects: 10000,
        max_regions: 16,
        limit_history: 60,
        limit_email_notifications: 10,
      },
    ],
    plans: [
      {
        slug: "free",
        id: "free",
        type: "month",
        price: 0,
        limit_monitor: 1,
        limit_projects: 1,
        name: "Free",
        max_regions: 1,
        limit_history: 7,
        limit_email_notifications: 1,
      },
      {
        slug: "wpu_1",
        active: true,
        id: "price_1JJI4qIcL5h0Eadw1a1Sikyp",
        type: "month",
        price: 4.99,
        name: "WP Umbrella (1 site)",
        limit_monitor: 1,
        limit_projects: 1,
        max_regions: 16,
        limit_history: 30,
        limit_email_notifications: 10,
      },
      {
        slug: "wpu_3",
        active: true,
        id: "price_1JJI69IcL5h0EadwzoI9mWJp",
        type: "month",
        price: 9.99,
        name: "WP Umbrella (3 sites)",
        limit_monitor: 1,
        limit_projects: 3,
        max_regions: 16,
        limit_history: 60,
        limit_email_notifications: 10,
      },
      {
        slug: "wpu_10",
        active: true,
        id: "price_1JJI6zIcL5h0EadwSgsv8wnc",
        type: "month",
        price: 24.99,
        name: "WP Umbrella (10 sites)",
        limit_monitor: 1,
        limit_projects: 10,
        max_regions: 16,
        limit_history: 60,
        limit_email_notifications: 10,
      },
      {
        slug: "wpu_50",
        active: true,
        id: "price_1JhV0dIcL5h0Eadw4WeIZ4dE",
        type: "month",
        price: 249.99,
        name: "WP Umbrella (unlimited)",
        limit_monitor: 1,
        limit_projects: 5000,
        max_regions: 16,
        limit_history: 90,
        limit_email_notifications: 10,
      },
      {
        slug: "wpu_1",
        active: true,
        id: "price_1JJI4qIcL5h0EadwwoRuatA0",
        type: "annual",
        price: 49.99,
        name: "WP Umbrella (1 site)",
        limit_monitor: 1,
        limit_projects: 1,
        max_regions: 16,
        limit_history: 30,
        limit_email_notifications: 10,
      },
      {
        slug: "wpu_3",
        active: true,
        id: "price_1JJI69IcL5h0Eadw2pr4yZMY",
        type: "annual",
        price: 99.99,
        name: "WP Umbrella (3 sites)",
        limit_monitor: 1,
        limit_projects: 3,
        max_regions: 16,
        limit_history: 60,
        limit_email_notifications: 10,
      },
      {
        slug: "wpu_10",
        active: true,
        id: "price_1JJI6zIcL5h0Eadw6c1J2NUu",
        type: "annual",
        price: 249.99,
        name: "WP Umbrella (10 sites)",
        limit_monitor: 1,
        limit_projects: 10,
        max_regions: 16,
        limit_history: 90,
        limit_email_notifications: 10,
      },
      {
        slug: "wpu_50",
        active: true,
        id: "price_1JhV0dIcL5h0EadwTYjT69nS",
        type: "annual",
        price: 2499.99,
        name: "WP Umbrella (unlimited)",
        limit_monitor: 1,
        limit_projects: 5000,
        max_regions: 16,
        limit_history: 90,
        limit_email_notifications: 10,
      },
      {
        slug: "test",
        active: false,
        id: "price_HMWcpqzzYXAdm2",
        type: "month",
        price: 2,
        name: "Test",
        limit_monitor: 1,
        limit_projects: 1,
        limit_history: 30,
        limit_email_notifications: 4,
        max_regions: 16,
      },
      {
        slug: "standard",
        active: false,
        id: "price_HMWcmaUe5j26kv",
        type: "month",
        price: 40,
        name: "Standard",
        limit_monitor: 15,
        limit_projects: 5,
        limit_history: 60,
        limit_email_notifications: 10,
        max_regions: 16,
      },
    ],
  },
}
