import { SVGAdminPlugin } from "@svg/AdminPlugin"
import { System } from "@design-system/index"
import { isEmpty } from "lodash"

interface Props {
  query?: string
}

export function PluginsEmptyState({ query = "" }: Props) {
  return (
    <div className="rounded-lg border border-dashed bg-gray-50/50 p-8 text-center">
      <SVGAdminPlugin className="mx-auto h-12 w-12 text-indigo-400" />
      <System.Title className="my-4">No plugin available</System.Title>
      {isEmpty(query) ? (
        <>
          <System.Text>
            Please make sure your websites are connected or check if you wrote
            the plugin name correct
          </System.Text>
        </>
      ) : (
        <System.Text>
          You try to search for this plugin name: <strong>{query}</strong>. It
          seems that it does not exist in our list.
        </System.Text>
      )}
    </div>
  )
}
