import { SvgProps } from "@design-system/Icon/types"

export const QuestionCircle = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#a)">
        <g>
          <path
            fill="#E7E6EA"
            d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
          />
          <path
            fill="#0D093F"
            fillRule="evenodd"
            d="M11.6234 7.49909C11.0923 7.49909 10.6019 7.68095 10.2562 7.97724C9.91411 8.27047 9.7496 8.64113 9.7496 8.99815C9.7496 9.4121 9.41402 9.74768 9.00006 9.74768C8.58611 9.74768 8.25053 9.4121 8.25053 8.99815C8.25053 8.16243 8.63879 7.38922 9.28064 6.83907C9.91892 6.29197 10.763 6.00002 11.6234 6.00002H12.373C13.2334 6.00002 14.0775 6.29197 14.7157 6.83907C15.3521 7.38451 15.7392 8.14921 15.7458 8.9767C15.7776 9.61842 15.6025 10.2536 15.2459 10.7885C14.8937 11.3168 14.3832 11.7193 13.7882 11.9386C13.5176 12.0742 13.2457 12.3412 13.0426 12.7472C12.8333 13.1659 12.7244 13.6815 12.747 14.213C12.7646 14.6266 12.4436 14.9761 12.03 14.9937C11.6165 15.0113 11.2669 14.6903 11.2493 14.2767C11.2167 13.5106 11.3704 12.7397 11.7018 12.0768C12.0327 11.4151 12.5389 10.8683 13.1791 10.5681C13.2018 10.5575 13.225 10.548 13.2486 10.5397C13.5552 10.4319 13.8184 10.2274 13.9986 9.95701C14.1789 9.68663 14.2664 9.36502 14.248 9.04059C14.2472 9.02646 14.2468 9.0123 14.2468 8.99815C14.2468 8.64113 14.0823 8.27047 13.7402 7.97724C13.3945 7.68095 12.9041 7.49909 12.373 7.49909H11.6234Z"
            clipRule="evenodd"
          />
          <path
            fill="#0D093F"
            fillRule="evenodd"
            d="M11.9982 16.4934C12.4122 16.4934 12.7478 16.829 12.7478 17.243V17.2505C12.7478 17.6644 12.4122 18 11.9982 18C11.5843 18 11.2487 17.6644 11.2487 17.2505V17.243C11.2487 16.829 11.5843 16.4934 11.9982 16.4934Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
