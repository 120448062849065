import { SvgProps } from "@design-system/Icon/types"

export const Post = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      version="1.1"
      width={size}
      height={size}
      viewBox="0 0 20 20"
    >
      <g></g>
      <path
        strokeWidth={strokeWidth}
        d="M10.44 3.020l1.82-1.82 6.36 6.35-1.83 1.82q-0.78-0.52-1.75-0.425t-1.66 0.785l-0.75 0.75q-0.68 0.69-0.775 1.655t0.425 1.755l-1.83 1.82-2.41-2.41-2.8 2.79q-0.54 0.54-2.035 1.55t-1.765 0.74 0.74-1.775 1.54-2.035l2.79-2.79-2.41-2.42 1.83-1.82q0.78 0.52 1.75 0.425t1.65-0.785l0.75-0.75q0.69-0.68 0.785-1.65t-0.425-1.76z"
        fill="currentColor"
      ></path>
    </svg>
  )
}
