import { getProjectPath } from "."
import { getFetchHeaders } from ".."
import qs from "query-string"

export const deleteProject = async (idProject: number, data) => {
  const headers = await getFetchHeaders({ withCsrfToken: true })

  const url = qs.stringifyUrl({
    url: getProjectPath(idProject),
    query: data,
  })

  const result = await fetch(url, {
    method: "DELETE",
    headers: headers,
  })

  return await result.json()
}
