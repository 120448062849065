import { System } from "@design-system/index"
import React from "react"
import { TableColumn } from "@design-system/Table/types"
import WordPressRepositoryPluginModel from "@/Core/Models/Plugins/model"
import { PluginImage } from "@components/Plugin/components/PluginImage"
import { NewExternalLink } from "@ui/ExternalLink/NewExternalLink"
import { getDefaultClassNameText } from "@ui/System/Text"
import { SVG } from "@svg/index"
import { ActiveInstalls } from "@components/Plugin/components/Table/components/ActiveInstalls"
import { StarRating } from "@components/Plugin/components/StarRating"
import { Requirements } from "@components/Plugin/components/Table/components/Requirements"
import { useFilteringBulkInstallPlugins } from "@components/Plugin/hooks/useFilteringBulkInstallPlugins"
import { STRATEGY_INSTALL_PLUGIN } from "constants/bulk-install-plugins"
import { getWordPressPluginDownloadPackage } from "@helpers/getWordPressPluginDownloadPackage"

interface Props {
  item: {
    id: string
  } & WordPressRepositoryPluginModel
  columns?: Array<TableColumn>
}

export const BulkWordPressRepositoryByStepRow = ({
  item,
  columns = [],
}: Props) => {
  const { handleUpdatePlugins } = useFilteringBulkInstallPlugins()
  return (
    <System.Table.Row key={`row_${item.id}`} item={item}>
      <System.Table.InnerRowWithActions
        rowKey={item.id}
        item={item}
        columns={columns}
        className="grid-cols-[1fr_1fr_1fr_1fr]"
      >
        <label
          htmlFor={`checkbox_${item.id}`}
          className="inline-block cursor-pointer truncate"
        >
          <div className="flex items-center gap-4">
            <PluginImage slug={item.slug} />
            <div className="flex flex-col gap-1">
              <NewExternalLink
                href={`https://wordpress.org/plugins/${item.slug}`}
                className={getDefaultClassNameText({
                  className:
                    "inline-flex items-center gap-2 text-indigo-500 underline",
                })}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      item.name.length > 35
                        ? item.name.substr(0, 35) + "…"
                        : item.name,
                  }}
                ></span>
                <SVG.ExternalLink className="h-4 w-4" />
              </NewExternalLink>
              <System.Text className="text-sm text-gray-500">
                {item.version}
              </System.Text>
            </div>
          </div>
        </label>
        <div className="flex-1">
          <ActiveInstalls amount={item.active_installs} />
          <StarRating percentage={item.rating} />
        </div>

        <div className="flex-1">
          <Requirements wordpress={item.requires} php={item.requires_php} />
        </div>
        <div className="ml-auto flex-1">
          <System.Button.Outlined
            className="inline-flex gap-2"
            onClick={() => {
              handleUpdatePlugins({
                value: [
                  {
                    from: STRATEGY_INSTALL_PLUGIN.WORDPRESS_REPOSITORY,
                    plugin_uri: getWordPressPluginDownloadPackage({
                      slug: item.slug,
                      version: item.version,
                    }),
                    name: item.name,
                    slug: item.slug,
                    is_installed: item.is_installed,
                    php_version_outdated: item.php_version_outdated,
                    requires: item.requires,
                    requires_php: item.requires_php,
                    tested: item.tested,
                    version: item.version,
                  },
                ],
              })
            }}
          >
            <SVG.Download className="h-4 w-4" />
            Select Sites
          </System.Button.Outlined>
        </div>
      </System.Table.InnerRowWithActions>
    </System.Table.Row>
  )
}
