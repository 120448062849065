export const createProjectPath = () => `/api/projects`

interface Props {
  base_url: string
  name?: string
  hosting?: string
}

export const createProject = async (data: Props) => {
  const result = await fetch(createProjectPath(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })

  return await result.json()
}
