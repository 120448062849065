import classNames from "classnames"
import {
  TableColumn,
  TableRow as TableRowType,
} from "@design-system/Table/types"
import { ReactNode } from "react"

interface Props<T, C> {
  item: TableRowType<T, C>
  columns?: Array<TableColumn>
  className?: string
  isStacked?: boolean
  children: ReactNode
}

export const TableRow = <T, C = {}>({
  children,
  isStacked = false,
  className = "",
}: Props<T, C>) => {
  const classes = classNames(
    "bg-white w-full",
    {
      "border-t border-t-main-grey-200 first:border-b-main-grey-100 last:border-b-main-grey-100":
        !isStacked,
    },
    className,
  )

  return <div className={classes}>{children}</div>
}
