import { ProcessTasksType } from "@prisma/client"

interface Props {
  type: ProcessTasksType
  state: "failed" | "success" | "progress"
}
export const getTextPluginProcessing = ({ type, state }: Props) => {
  if (state === "success") {
    switch (type) {
      case ProcessTasksType.ACTIVATE_PLUGIN:
        return "Activated!"
      case ProcessTasksType.DEACTIVATE_PLUGIN:
        return "Deactivated!"
      case ProcessTasksType.UPDATE_PLUGIN:
      case ProcessTasksType.UPDATE_PLUGINS:
        return "Updated!"
      case ProcessTasksType.DELETE_PLUGIN:
        return "Deleted!"
      case ProcessTasksType.ROLLBACK_PLUGIN:
        return "Rollback finished!"
      default:
        return "Finished!"
    }
  }

  if (state === "failed") {
    switch (type) {
      case ProcessTasksType.ACTIVATE_PLUGIN:
        return "Activation failed"
      case ProcessTasksType.DEACTIVATE_PLUGIN:
        return "Deactivation failed"
      case ProcessTasksType.UPDATE_PLUGIN:
      case ProcessTasksType.UPDATE_PLUGINS:
        return "Update failed"
      case ProcessTasksType.DELETE_PLUGIN:
        return "Delete failed"
      case ProcessTasksType.ROLLBACK_PLUGIN:
        return "Rollback failed"
      default:
        return "Failed"
    }
  }

  switch (type) {
    case ProcessTasksType.ACTIVATE_PLUGIN:
      return "Activating..."
    case ProcessTasksType.DEACTIVATE_PLUGIN:
      return "Deactivating..."
    case ProcessTasksType.UPDATE_PLUGIN:
    case ProcessTasksType.UPDATE_PLUGINS:
      return "Updating..."
    case ProcessTasksType.DELETE_PLUGIN:
      return "Deleting..."
    case ProcessTasksType.ROLLBACK_PLUGIN:
      return "Rollback in progress.."
    default:
      return "Processing..."
  }
}
