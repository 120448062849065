import { Context } from "@design-system/Context"
import { useContext } from "react"
import {
  TypeGenericFilteringItemsTabs,
  TypeGenericFilteringItemsWithTabsAction,
} from "../index"

export function useGenericFilteringItemsWithTabs() {
  const { state, dispatch } = useContext(
    Context.GenericFilteringItemsWithTabs.Context,
  )

  function handleChangeTab({
    value,
  }: {
    value: TypeGenericFilteringItemsTabs
  }) {
    dispatch({
      type: TypeGenericFilteringItemsWithTabsAction.SET_TAB,
      payload: value,
    })
  }

  function handleChangeQuery({ value }: { value: string }) {
    dispatch({
      type: TypeGenericFilteringItemsWithTabsAction.SET_QUERY,
      payload: value,
    })
  }

  return {
    state,
    handleChangeTab,
    handleChangeQuery,
  }
}
