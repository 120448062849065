"use client"

import * as RadioGroupPrimitive from "@radix-ui/react-radio-group"
import * as React from "react"

import { cn } from "@helpers/utils"
import { type VariantProps } from "class-variance-authority"
import { RadioGroupContext } from "."
import { System } from ".."
import { radioGroupItemCardVariants } from "./RadioGroupItemCard.variants"

interface RadioGroupItemCardProps
  extends React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>,
    VariantProps<typeof radioGroupItemCardVariants> {
  children?: React.ReactNode
}

const RadioGroupItemCard = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  RadioGroupItemCardProps
>(({ children, className, size, ...props }, ref) => {
  const { size: contextSize } = React.useContext(RadioGroupContext)

  const sizeUsed = size ?? contextSize
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(radioGroupItemCardVariants({ size, className }))}
      {...props}
    >
      <div
        className={cn("flex flex-grow flex-col items-start", {
          "gap-4": sizeUsed === "default",
          "gap-2": sizeUsed === "sm",
        })}
      >
        {children}
      </div>
      <div
        className={cn(
          "aspect-square shrink-0 rounded-full border border-main-grey-300 text-primary-base group-hover:border-primary-lighter group-data-[state=checked]:border-primary-base",
          {
            "size-5": sizeUsed === "default",
            "size-4": sizeUsed === "sm",
          },
        )}
      >
        <RadioGroupPrimitive.Indicator className="flex size-full items-center justify-center rounded-full">
          <span className="flex size-full items-center justify-center rounded-full bg-current">
            <System.Svg.Check
              size={sizeUsed === "default" ? 12 : 9}
              className="text-white"
            />
          </span>
        </RadioGroupPrimitive.Indicator>
      </div>
    </RadioGroupPrimitive.Item>
  )
})
RadioGroupItemCard.displayName = "RadioGroupItemCard"

export { RadioGroupItemCard }
