import { isEmpty, isNil } from "lodash"

interface Props {
  item: {
    slug: string
  }
  pluginIsPremium: {
    changelog: string
  }
}

export const getLinkChangelog = ({ item, pluginIsPremium }: Props) => {
  if (isNil(pluginIsPremium)) {
    return `https://wordpress.org/plugins/${item.slug}/#developers`
  }

  if (isEmpty(pluginIsPremium.changelog)) {
    return `https://wordpress.org/plugins/${item.slug}/#developers`
  }

  return pluginIsPremium.changelog
}
