import { System } from "@design-system/index"
import { ReactNode } from "react"
import classNames from "classnames"

interface Props {
  children: ReactNode
  className?: string
  size?: "small" | "default"
}

export const Avatar = ({ children, className, size }: Props) => {
  const classes = classNames(
    {
      "h-12 w-12": size === "default",
      "h-8 w-8": size === "small",
    },
    "flex-none inline-flex items-center justify-center rounded-full bg-gray-100 border-2 border-white",
    className,
  )

  return (
    <System.Title as="span" className={classes}>
      {children}
    </System.Title>
  )
}
