import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders"
import { getPrivateApiOneProjectOneNotePath } from "@/Project/api/routes-private"

interface Props {
  projectId: string | number
  noteId: string
}

export const deleteNote = async (data: Props) => {
  const headers = await getFetchPrivateHeaders()
  const result = await fetch(
    getPrivateApiOneProjectOneNotePath({
      projectId: data.projectId,
      noteId: data.noteId,
    }),
    {
      method: "DELETE",
      headers: headers,
    },
  )

  return await result.json()
}
