import FetchApiProcess from "@/FetchApi/Process"
import { DataClearCache } from "@/Repositories/Process/createProcessWithTasks"
import { TableRowProcess } from "@design-system/Table/types"
import { ProcessTasksType } from "@prisma/client"
import { get, isNil } from "lodash"

export interface ResponseCreateProcessTaskRollbackPlugin {
  code: string
  data: {
    id: string
    ProcessTask: Array<{
      projectId: number
      retry: number
      type: ProcessTasksType
      entities?: any
      state?: any
    }>
  }
  rowProcessings: Set<TableRowProcess>
}

export const createProcessTaskRollbackPlugin = async ({
  data,
  projectsClearCache,
}: {
  data: {
    entities: {
      id: string
      plugin: string
      name: string
      is_active: boolean
      must_be_activate: boolean
      version: string
      rollback_version: string
    }
    projectId: number
  }
  projectsClearCache: Array<DataClearCache>
}): Promise<ResponseCreateProcessTaskRollbackPlugin> => {
  const response = await FetchApiProcess.createProcess({
    tasks: [
      {
        ...data,
        type: ProcessTasksType.ROLLBACK_PLUGIN,
      },
    ],
    data: {
      clear_cache: projectsClearCache,
    },
  })

  let code = null
  if (!isNil(response)) {
    code = response.code
  }

  const lines = new Set<TableRowProcess>()
  if (code === "success") {
    lines.add({
      key: data.entities.id,
      type: ProcessTasksType.ROLLBACK_PLUGIN,
      task: {
        finished_at: null,
        state: "waiting",
        code: null,
        entities: data.entities,
      },
    })
  }

  return {
    code: response.code,
    data: get(response, "data", null),
    rowProcessings: lines,
  }
}
