import Config from "@environments/data"

/**
 * @returns https://private-api.wp-umbrella.com/processes
 */
export const getPrivateApiProcessesPath = () => {
  return `${Config.private_api_url}/processes`
}

/**
 * @returns https://private-api.wp-umbrella.com/process-tasks
 */
export const getPrivateApiProcessTasksPath = () => {
  return `${Config.private_api_url}/process-tasks`
}

/**
 * @returns https://private-api.wp-umbrella.com/process-tasks/{processTaskId}/screenshots
 */
export const getPrivateApiScreenshotsProcessTasksPath = ({
  processTaskId,
}: {
  processTaskId: string
}) => {
  return `${getPrivateApiProcessTasksPath()}/${processTaskId}/screenshots`
}

/**
 * @returns https://private-api.wp-umbrella.com/processes/pending-with-last-finished
 */
export const getPrivateApiProcessesPendingWithLastFinishedPath = () => {
  return `${getPrivateApiProcessesPath()}/pending-with-last-finished`
}

/**
 * @returns https://private-api.wp-umbrella.com/processes/progress-tasks
 */
export const getPrivateApiProgressTasksPath = () => {
  return `${getPrivateApiProcessesPath()}/progress-tasks`
}
