import FieldCheckboxInline from "./CheckboxInline"
import FieldInput from "./Input"
import FieldInputRadio from "./InputRadio"
import { Label } from "./Label"
import FieldSelect from "./Select"
import Textarea from "./Textarea"

export const FormComponents = {
  Input: FieldInput,
  Radio: FieldInputRadio,
  Select: FieldSelect,
  CheckboxInline: FieldCheckboxInline,
  Label: Label,
  Textarea: Textarea,
}
