import { STRIPE_ITEMS } from "../../Constants/StripeItems"

interface Props {
  params?: {
    items?: Array<STRIPE_ITEMS>
    quantity_hourly_backup?: number
  }
}

interface Return {
  url: string
  id: string
}

export const createStripeSession = async ({
  params,
}: Props): Promise<Return> => {
  const response = await fetch("/api/customers/sessions", {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
    },
  })

  return await response.json()
}
