import Config from "@environments/data"

/**
 * @returns https://private-api.wp-umbrella.com/projects
 */
export const getPrivateApiProjectsPath = () => {
  return `${Config.private_api_url}/projects`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId
 */
export const getPrivateApiOneProjectPath = ({
  projectId,
}: {
  projectId: string | number
}) => {
  return `${getPrivateApiProjectsPath()}/${projectId}`
}
/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/test-ftp-connection
 */
export const getPrivateApiOneProjectTestFtpConnectionPath = ({
  projectId,
}: {
  projectId: string | number
}) => {
  return `${getPrivateApiOneProjectPath({ projectId })}/test-ftp-connection`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/head-detail
 */
export const getPrivateApiOneProjectHeadDetailPath = ({
  projectId,
}: {
  projectId: string | number
}) => {
  return `${getPrivateApiOneProjectPath({ projectId })}/head-detail`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/resync
 */
export const getProjectsResyncPath = (): string => {
  return `${getPrivateApiProjectsPath()}/resync`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/request-lighthouse
 */
export const getPrivateApiOneProjectScanLighthousePath = ({
  projectId,
}: {
  projectId: string | number
}) => {
  return `${getPrivateApiOneProjectPath({ projectId })}/request-lighthouse`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/plugins/:pluginId/ignore-update
 */
export const getPrivateApiOneProjectPluginIgnoreUpdatePath = ({
  projectId,
  pluginId,
}: {
  projectId: number | string
  pluginId: string
}) => {
  return `${getPrivateApiOneProjectPath({
    projectId,
  })}/plugins/${pluginId}/ignore-update`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/themes/:themeId/ignore-update
 */
export const getPrivateApiOneProjectThemeIgnoreUpdatePath = ({
  projectId,
  themeId,
}: {
  projectId: number | string
  themeId: string
}) => {
  return `${getPrivateApiOneProjectPath({
    projectId,
  })}/themes/${themeId}/ignore-update`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/wordpress
 */
export const getPrivateApiOneProjectWordPressPath = ({
  projectId,
}: {
  projectId: number
}) =>
  `${getPrivateApiOneProjectPath({
    projectId,
  })}/wordpress`

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/wordpress/data
 */
export const getPrivateApiOneProjectWordPressDataPath = ({
  projectId,
}: {
  projectId: number
}) => {
  return `${getPrivateApiOneProjectWordPressPath({
    projectId,
  })}/data`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/wordpress/directories
 */
export const getPrivateApiOneProjectWordPressDirectoriesPath = ({
  projectId,
}: {
  projectId: number
}) => {
  return `${getPrivateApiOneProjectWordPressPath({
    projectId,
  })}/directories`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/wordpress/informations
 */
export const getPrivateApiOneProjectWordPressInformationsPath = ({
  projectId,
}: {
  projectId: number
}) => {
  return `${getPrivateApiOneProjectWordPressPath({
    projectId,
  })}/informations`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/wordpress/plugins
 */
export const getPrivateApiOneProjectWordPressPluginsPath = ({
  projectId,
}: {
  projectId: number
}) => {
  return `${getPrivateApiOneProjectWordPressPath({
    projectId,
  })}/plugins`
}
/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/wordpress/plugins/sync
 */
export const getPrivateApiOneProjectWordPressPluginsInSynchronizationPath = ({
  projectId,
}: {
  projectId: number
}) => {
  return `${getPrivateApiOneProjectWordPressPluginsPath({
    projectId,
  })}/sync`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/wordpress/sitehealth
 */
export const getPrivateApiOneProjectWordPressSiteHealthPath = ({
  projectId,
}: {
  projectId: number
}) => {
  return `${getPrivateApiOneProjectWordPressPath({
    projectId,
  })}/sitehealth`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/wordpress/sizes
 */
export const getPrivateApiOneProjectWordPressSizesPath = ({
  projectId,
}: {
  projectId: number
}) => {
  return `${getPrivateApiOneProjectWordPressPath({
    projectId,
  })}/sizes`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/wordpress/tables
 */
export const getPrivateApiOneProjectWordPressTablesPath = ({
  projectId,
}: {
  projectId: number
}) => {
  return `${getPrivateApiOneProjectWordPressPath({
    projectId,
  })}/tables`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/wordpress/themes
 */
export const getPrivateApiOneProjectWordPressThemesPath = ({
  projectId,
}: {
  projectId: number
}) => {
  return `${getPrivateApiOneProjectWordPressPath({
    projectId,
  })}/themes`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/wordpress/users
 */
export const getPrivateApiOneProjectWordPressUsersPath = ({
  projectId,
}: {
  projectId: number
}) => {
  return `${getPrivateApiOneProjectWordPressPath({
    projectId,
  })}/users`
}

/**
 * @returns  https://private-api.wp-umbrella.com/projects/:projectId/restores
 */
export const getPrivateApiProjectRestoresPath = ({
  projectId,
}: {
  projectId: number | string
}): string => {
  return `${getPrivateApiOneProjectPath({
    projectId,
  })}/restores`
}
/**
 * @returns  https://private-api.wp-umbrella.com/projects/:projectId/restores/stop
 */
export const getPrivateApiProjectStopRestorationPath = ({
  projectId,
}: {
  projectId: number | string
}): string => {
  return `${getPrivateApiProjectRestoresPath({
    projectId,
  })}/stop`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/notes/count
 */
export const getPrivateApiOneProjectNotesCountPath = ({
  projectId,
}: {
  projectId: string | number
}) => {
  return `${getPrivateApiOneProjectPath({ projectId })}/notes/count`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/notes
 */
export const getPrivateApiOneProjectNotesPath = ({
  projectId,
}: {
  projectId: string | number
}) => {
  return `${getPrivateApiOneProjectPath({ projectId })}/notes`
}

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/notes/:noteId
 */
export const getPrivateApiOneProjectOneNotePath = ({
  projectId,
  noteId,
}: {
  projectId: string | number
  noteId: string
}) => {
  return `${getPrivateApiOneProjectPath({ projectId })}/notes/${noteId}`
}
