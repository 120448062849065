import { isNil } from "lodash"
import { InputHTMLAttributes } from "react"
import { RegisterOptions, useFormContext } from "react-hook-form"

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  register?: Function
  registerOptions?: RegisterOptions
  className?: string
  required?: boolean
  group?: boolean
  disabled?: boolean
  error?: boolean
}

const FieldInputRadio = ({
  register,
  name,
  registerOptions = {},
  ...rest
}: Props) => {
  const context = useFormContext()
  if (isNil(register)) {
    return (
      <input
        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
        type="radio"
        {...context.register(name, registerOptions)}
        {...rest}
      />
    )
  }
  return (
    <input
      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
      type="radio"
      {...register(name, registerOptions)}
      {...rest}
    />
  )
}

export default FieldInputRadio
