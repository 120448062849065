import { SvgProps } from "@design-system/Icon/types"

export const DeviceFloppy = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M6 5C5.73478 5 5.48043 5.10536 5.29289 5.29289C5.10536 5.48043 5 5.73478 5 6V18C5 18.2652 5.10536 18.5196 5.29289 18.7071C5.48043 18.8946 5.73478 19 6 19H18C18.2652 19 18.5196 18.8946 18.7071 18.7071C18.8946 18.5196 19 18.2652 19 18V8.41421L15.5858 5H6ZM3.87868 3.87868C4.44129 3.31607 5.20435 3 6 3H16C16.2652 3 16.5196 3.10536 16.7071 3.29289L20.7071 7.29289C20.8946 7.48043 21 7.73478 21 8V18C21 18.7957 20.6839 19.5587 20.1213 20.1213C19.5587 20.6839 18.7957 21 18 21H6C5.20435 21 4.44129 20.6839 3.87868 20.1213C3.31607 19.5587 3 18.7956 3 18V6C3 5.20435 3.31607 4.44129 3.87868 3.87868Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M9.87868 11.8787C10.4413 11.3161 11.2043 11 12 11C12.7957 11 13.5587 11.3161 14.1213 11.8787C14.6839 12.4413 15 13.2043 15 14C15 14.7957 14.6839 15.5587 14.1213 16.1213C13.5587 16.6839 12.7957 17 12 17C11.2043 17 10.4413 16.6839 9.87868 16.1213C9.31607 15.5587 9 14.7957 9 14C9 13.2043 9.31607 12.4413 9.87868 11.8787ZM12 13C11.7348 13 11.4804 13.1054 11.2929 13.2929C11.1054 13.4804 11 13.7348 11 14C11 14.2652 11.1054 14.5196 11.2929 14.7071C11.4804 14.8946 11.7348 15 12 15C12.2652 15 12.5196 14.8946 12.7071 14.7071C12.8946 14.5196 13 14.2652 13 14C13 13.7348 12.8946 13.4804 12.7071 13.2929C12.5196 13.1054 12.2652 13 12 13Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M8 3C8.55228 3 9 3.44772 9 4V7H13V4C13 3.44772 13.4477 3 14 3C14.5523 3 15 3.44772 15 4V8C15 8.55228 14.5523 9 14 9H8C7.44772 9 7 8.55228 7 8V4C7 3.44772 7.44772 3 8 3Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
