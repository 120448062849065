import qs from "query-string"
import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders"
import { getPrivateApiBackupDownloadLinkFilePath } from "@/Project/api/routes-backup-private"
import { RequestDownloadBackupType } from "@prisma/client"

export const getBackupDownloadLinkFile = async ({
  projectId,
  backupId,
  type,
}: {
  projectId: number
  backupId: string
  type?: RequestDownloadBackupType
}) => {
  const headers = await getFetchPrivateHeaders()

  const url = qs.stringifyUrl({
    url: getPrivateApiBackupDownloadLinkFilePath({ projectId, backupId }),
    query: {
      type: type ?? RequestDownloadBackupType.FULL,
    },
  })

  const result = await fetch(url, {
    method: "GET",
    headers: headers,
  })

  return await result.json()
}
