import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders"
import { getPrivateApiOneProjectPath } from "@/Project/api/routes-private"

export const deleteProject = async ({ projectId }: { projectId: number }) => {
  const headers = await getFetchPrivateHeaders()

  const result = await fetch(getPrivateApiOneProjectPath({ projectId }), {
    method: "DELETE",
    headers: headers,
  })

  return await result.json()
}
