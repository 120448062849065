import { resetPassword } from "./resetPassword"
import { confirmResetPassword } from "./confirmResetPassword"
import { register } from "./register"
import { createStripeSession } from "./createStripeSession"
import { updateStripeCustomer } from "./updateStripeCustomer"

const FetchApiUser = {
  resetPassword,
  confirmResetPassword,
  register,
  createStripeSession,
  updateStripeCustomer,
}

export default FetchApiUser
