import React, { Dispatch } from "react"

export interface StateGenericModal {
  component: React.ReactNode
  isOpen: boolean
  size: "sm" | "default" | "md" | "lg" | "xl" | "full"
  withPadding?: boolean
  hideOverflow?: boolean
}

export interface ContextGenericModal {
  state: StateGenericModal
  dispatch: Dispatch<ActionGenericModal>
}

export enum TypeGenericActionGenericModal {
  OPEN_MODAL,
  CLOSE_MODAL,
}

export type ActionGenericModal =
  | {
      type: TypeGenericActionGenericModal.OPEN_MODAL
      payload: {
        component: React.ReactNode
        size?: "sm" | "default" | "md" | "lg" | "xl" | "full"
        withPadding?: boolean
      }
    }
  | {
      type: TypeGenericActionGenericModal.CLOSE_MODAL
      payload?: {}
    }
