import { ProcessTasksType } from "@prisma/client"
import useSWR, { SWRResponse } from "swr"
import qs from "query-string"
import { getPrivateApiProcessesPendingWithLastFinishedPath } from "@/Process/api/routes-private"
import fetcherPrivateApiUmbrella from "@helpers/fetcherPrivateApiUmbrella"

interface Props {
  suspense?: boolean
  options?: {
    refreshInterval?: number
  }
  projectId?: number
  types: ProcessTasksType[]
  sinceMs?: number
}

export function useProcessesInProgressWithLastFinished<Target = any>({
  suspense = true,
  options,
  projectId,
  types,
  sinceMs = 60000,
}: Props): SWRResponse<Target, any> {
  const url = qs.stringifyUrl({
    url: getPrivateApiProcessesPendingWithLastFinishedPath(),
    query: {
      types: types,
      projectId: projectId,
      sinceMs: sinceMs,
    },
  })

  return useSWR(url, {
    suspense: suspense,
    ...options,
    fetcher: fetcherPrivateApiUmbrella(),
  })
}
