import { getPrivateApiOneProjectPath } from "./routes-private"

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/maintenance-reports
 */
export const getPrivateApiOneProjectMaintenanceReportsPath = ({
  projectId,
}: {
  projectId: string | number
}) => {
  return `${getPrivateApiOneProjectPath({ projectId })}/maintenance-reports`
}

/**
 * https://private-api.wp-umbrella.com/projects/:projectId/maintenance-reports/generate-and-send
 */
export const getPrivateApiProjectMaintenanceReportGenerateAndSendPath = ({
  projectId,
}: {
  projectId: number | string
}): string => {
  return `${getPrivateApiOneProjectMaintenanceReportsPath({
    projectId,
  })}/generate-and-send`
}
