import React from "react"
import NextLink, { LinkProps } from "next/link"
import { isEmpty } from "lodash"

interface Props extends LinkProps {
  className?: string
  children: React.ReactNode
  target?: string
}

const Link = ({ children, className = "", href, ...rest }: Props) => {
  const classes = isEmpty(className) ? "" : className

  return (
    <NextLink className={classes} href={href} {...rest}>
      {children}
    </NextLink>
  )
}

export default Link
