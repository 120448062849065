import { SvgProps } from "@design-system/Icon/types"

export const CameraCheck = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M7.58579 3.58579C7.96086 3.21071 8.46957 3 9 3H15C15.5304 3 16.0391 3.21071 16.4142 3.58579C16.7893 3.96086 17 4.46957 17 5C17 5.26522 17.1054 5.51957 17.2929 5.70711C17.4804 5.89464 17.7348 6 18 6H19C19.7957 6 20.5587 6.31607 21.1213 6.87868C21.6839 7.44129 22 8.20435 22 9V13C22 13.5523 21.5523 14 21 14C20.4477 14 20 13.5523 20 13V9C20 8.73478 19.8946 8.48043 19.7071 8.29289C19.5196 8.10536 19.2652 8 19 8H18C17.2044 8 16.4413 7.68393 15.8787 7.12132C15.3161 6.55871 15 5.79565 15 5L9 5C9 5.79565 8.68393 6.55871 8.12132 7.12132C7.55871 7.68393 6.79565 8 6 8H5C4.73478 8 4.48043 8.10536 4.29289 8.29289C4.10536 8.48043 4 8.73478 4 9V18C4 18.2652 4.10536 18.5196 4.29289 18.7071C4.48043 18.8946 4.73478 19 5 19H11C11.5523 19 12 19.4477 12 20C12 20.5523 11.5523 21 11 21H5C4.20435 21 3.44129 20.6839 2.87868 20.1213C2.31607 19.5587 2 18.7956 2 18V9C2 8.20435 2.31607 7.44129 2.87868 6.87868C3.44129 6.31607 4.20435 6 5 6H6C6.26522 6 6.51957 5.89464 6.70711 5.70711C6.89464 5.51957 7 5.26522 7 5C7 4.46957 7.21071 3.96086 7.58579 3.58579Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M9.17157 10.1716C9.92172 9.42143 10.9391 9 12 9C13.0609 9 14.0783 9.42143 14.8284 10.1716C15.5786 10.9217 16 11.9391 16 13C16 14.0609 15.5786 15.0783 14.8284 15.8284C14.0783 16.5786 13.0609 17 12 17C10.9391 17 9.92172 16.5786 9.17157 15.8284C8.42143 15.0783 8 14.0609 8 13C8 11.9391 8.42143 10.9217 9.17157 10.1716ZM12 11C11.4696 11 10.9609 11.2107 10.5858 11.5858C10.2107 11.9609 10 12.4696 10 13C10 13.5304 10.2107 14.0391 10.5858 14.4142C10.9609 14.7893 11.4696 15 12 15C12.5304 15 13.0391 14.7893 13.4142 14.4142C13.7893 14.0391 14 13.5304 14 13C14 12.4696 13.7893 11.9609 13.4142 11.5858C13.0391 11.2107 12.5304 11 12 11Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M21.7071 16.2929C22.0976 16.6834 22.0976 17.3166 21.7071 17.7071L17.7071 21.7071C17.3166 22.0976 16.6834 22.0976 16.2929 21.7071L14.2929 19.7071C13.9024 19.3166 13.9024 18.6834 14.2929 18.2929C14.6834 17.9024 15.3166 17.9024 15.7071 18.2929L17 19.5858L20.2929 16.2929C20.6834 15.9024 21.3166 15.9024 21.7071 16.2929Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
