import { SvgProps } from "@design-system/Icon/types"

export const PlugOff = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M8.59711 7.18789C8.98763 7.57842 8.98763 8.21158 8.59711 8.60211L8.43811 8.76111L8.4241 8.77484C7.96305 9.21815 7.59505 9.74895 7.34162 10.3362C7.08819 10.9234 6.95442 11.5553 6.94815 12.1949C6.94187 12.8345 7.06322 13.4689 7.30508 14.061C7.54695 14.6531 7.90447 15.191 8.35673 15.6433C8.809 16.0955 9.34692 16.4531 9.93902 16.6949C10.5311 16.9368 11.1655 17.0581 11.8051 17.0519C12.4447 17.0456 13.0766 16.9118 13.6638 16.6584C14.251 16.405 14.7819 16.0369 15.2252 15.5759L15.2389 15.5619L15.4159 15.3849C15.8064 14.9944 16.4396 14.9944 16.8301 15.3849C17.2206 15.7754 17.2206 16.4086 16.8301 16.7991L16.6598 16.9694C16.0336 17.6188 15.2846 18.1372 14.4563 18.4947C13.6248 18.8535 12.7302 19.0429 11.8247 19.0518C10.9192 19.0606 10.021 18.8888 9.18273 18.5464C8.34443 18.204 7.58284 17.6978 6.94252 17.0575C6.3022 16.4172 5.79602 15.6556 5.45359 14.8173C5.11116 13.979 4.93936 13.0808 4.94824 12.1753C4.95713 11.2698 5.14651 10.3752 5.50532 9.54373C5.86278 8.71543 6.38124 7.96643 7.03055 7.34023L7.18289 7.18789C7.57342 6.79737 8.20658 6.79737 8.59711 7.18789Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M8.20711 15.7929C8.59763 16.1834 8.59763 16.8166 8.20711 17.2071L4.70711 20.7071C4.31658 21.0976 3.68342 21.0976 3.29289 20.7071C2.90237 20.3166 2.90237 19.6834 3.29289 19.2929L6.79289 15.7929C7.18342 15.4024 7.81658 15.4024 8.20711 15.7929Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M15.7071 3.29289C16.0976 3.68342 16.0976 4.31658 15.7071 4.70711L12.2071 8.20711C11.8166 8.59763 11.1834 8.59763 10.7929 8.20711C10.4024 7.81658 10.4024 7.18342 10.7929 6.79289L14.2929 3.29289C14.6834 2.90237 15.3166 2.90237 15.7071 3.29289Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M20.7071 8.29289C21.0976 8.68342 21.0976 9.31658 20.7071 9.70711L17.2071 13.2071C16.8166 13.5976 16.1834 13.5976 15.7929 13.2071C15.4024 12.8166 15.4024 12.1834 15.7929 11.7929L19.2929 8.29289C19.6834 7.90237 20.3166 7.90237 20.7071 8.29289Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M2.29289 2.29289C2.68342 1.90237 3.31658 1.90237 3.70711 2.29289L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L2.29289 3.70711C1.90237 3.31658 1.90237 2.68342 2.29289 2.29289Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
