import { SvgProps } from "@design-system/Icon/types"

export const CrossCircle = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <circle cx="12" cy="12" r="10" fill="currentColor" />
        <g>
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M15.8326 8.16737C16.0558 8.39052 16.0558 8.75233 15.8326 8.97549L8.97549 15.8326C8.75233 16.0558 8.39052 16.0558 8.16737 15.8326C7.94421 15.6095 7.94421 15.2477 8.16737 15.0245L15.0245 8.16737C15.2477 7.94421 15.6095 7.94421 15.8326 8.16737Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M8.16737 8.16737C8.39052 7.94421 8.75233 7.94421 8.97549 8.16737L15.8326 15.0245C16.0558 15.2477 16.0558 15.6095 15.8326 15.8326C15.6095 16.0558 15.2477 16.0558 15.0245 15.8326L8.16737 8.97549C7.94421 8.75233 7.94421 8.39052 8.16737 8.16737Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
