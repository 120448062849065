import React from "react"

interface Props {
  children: React.ReactNode
}

function EmptyDefault({ children }: Props) {
  return (
    <>
      <div className="rounded-lg border border-dashed p-8 text-center">
        {children}
      </div>
    </>
  )
}

export default EmptyDefault
