import classNames from "classnames"
import { TableKey } from "@design-system/Table/types"
import { CheckCircleIcon } from "@heroicons/react/24/outline"
import useLocalStorage from "@hooks/useLocalStorage"
import { useEffect } from "react"
import { System } from "@design-system/index"

interface Props {
  id: TableKey
  text?: string
  className?: string
}

export const CellProcessFinished = ({
  id,
  text = "Finished!",
  className = "",
}: Props) => {
  const KEY = `progress_${id}`
  const [progress, setProgress, removeProgress] = useLocalStorage(KEY, 0)

  useEffect(() => {
    removeProgress()

    return () => {
      removeProgress()
    }
  }, [])

  return (
    <>
      <System.Text
        className={classNames(
          "flex items-center gap-2 text-green-600",
          className,
        )}
      >
        <CheckCircleIcon className="h-4 w-4" />
        <strong>{text}</strong>
      </System.Text>
    </>
  )
}
