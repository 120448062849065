export const SystemConstant = {
  MarginTop: "mt-6",
  MarginTopDouble: "mt-12",
  MarginBottom: "mb-6",
  MarginY: "my-6",
  PaddingTop: "pt-6",
  PaddingBottom: "pb-6",
  PaddingRight: "pr-6",
  PaddingLeft: "pl-6",
  PaddingY: "py-6",
  PaddingX: "px-8",
}
