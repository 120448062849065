"use client"

import * as RadioGroupPrimitive from "@radix-ui/react-radio-group"
import * as React from "react"

import { cn } from "@helpers/utils"
import { cva, type VariantProps } from "class-variance-authority"
import { System } from ".."
import { radioGroupItemCardVariants } from "./RadioGroupItemCard.variants"

export const RadioGroupContext = React.createContext<
  VariantProps<typeof radioGroupItemCardVariants>
>({
  size: "default",
})

interface RadioGroupProps
  extends React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>,
    VariantProps<typeof radioGroupItemCardVariants> {}

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  RadioGroupProps
>(({ className, size = "default", ...props }, ref) => {
  return (
    <RadioGroupContext.Provider value={{ size }}>
      <RadioGroupPrimitive.Root
        className={cn("grid gap-2", className)}
        {...props}
        ref={ref}
      />
    </RadioGroupContext.Provider>
  )
})

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(
        "aspect-square size-[17px] rounded-full border border-main-grey-300 text-primary-base ring-offset-background hover:border-primary-lighter focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-base focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:border-primary-lighter",
        className,
      )}
      {...props}
    >
      <RadioGroupPrimitive.Indicator className="flex size-full items-center justify-center rounded-full">
        <span className="flex size-full items-center justify-center rounded-full bg-current">
          <System.Svg.Check size={12} className="text-white" />
        </span>
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitive.Item>
  )
})
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName

const radioGroupItemLabelVariants = cva(
  "font-medium text-primary-darkest group-disabled:cursor-not-allowed cursor-pointer",
  {
    variants: {
      size: {
        default: "text-body-base",
        sm: "text-body-sm",
      },
    },
    defaultVariants: {
      size: undefined,
    },
  },
)

interface RadioGroupItemLabelProps
  extends React.LabelHTMLAttributes<HTMLLabelElement>,
    VariantProps<typeof radioGroupItemLabelVariants> {}

const RadioGroupItemLabel = React.forwardRef<
  HTMLLabelElement,
  RadioGroupItemLabelProps
>(({ children, size, className }, ref) => {
  const { size: contextSize } = React.useContext(RadioGroupContext)
  return (
    <label
      className={cn(
        radioGroupItemLabelVariants({
          size: size ?? contextSize,
          className,
        }),
      )}
      ref={ref}
    >
      {children}
    </label>
  )
})
RadioGroupItemLabel.displayName = "RadioGroupItemLabel"

const radioGroupItemDescriptionVariants = cva("text-main-grey-500 text-left", {
  variants: {
    size: {
      default: "text-body-base",
      sm: "text-body-sm",
    },
  },
  defaultVariants: {
    size: undefined,
  },
})

interface RadioGroupItemDescriptionProps
  extends React.HTMLAttributes<HTMLSpanElement>,
    VariantProps<typeof radioGroupItemDescriptionVariants> {
  children?: React.ReactNode
}

const RadioGroupItemDescription = React.forwardRef<
  HTMLSpanElement,
  RadioGroupItemDescriptionProps
>(({ children, size, className }, ref) => {
  const { size: contextSize } = React.useContext(RadioGroupContext)
  return (
    <span
      className={cn(
        radioGroupItemDescriptionVariants({
          size: size ?? contextSize,
          className,
        }),
      )}
      ref={ref}
    >
      {children}
    </span>
  )
})
RadioGroupItemDescription.displayName = "RadioGroupItemDescription"

export {
  RadioGroup,
  RadioGroupItem,
  RadioGroupItemDescription,
  RadioGroupItemLabel,
}
