import Box from "./Box"
import Container from "./Space"
import Title from "./Title"
import TitleDescription from "./TitleDescription"
import Text from "./Text"
import Link from "./Link"
import classNames from "classnames"

export const getDefaultClassNameLabel = ({
  withoutBold = false,
  required = false,
}) =>
  classNames(
    {
      "after:content-['*'] after:ml-0.5 after:text-red-500 ": required,
      "font-bold": !withoutBold,
    },
    "block text-sm mb-1",
  )

export const getDefaultClassNameLabelDescription = () => `text-sm text-gray-500`

const System = {
  Container: Container,
  Box: Box,
  Title: Title,
  TitleDescription: TitleDescription,
  Text: Text,
  Link: Link,
}

export default System
