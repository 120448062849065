import { SystemSvgTypes } from "@design-system/Icon/types"
import { System } from ".."

interface Props {
  svg: SystemSvgTypes
  title: string
}

export function EmptyState({ svg, title }: Props) {
  const Icon = System.Svg[svg]
  return (
    <div className="rounded-lg border border-dashed bg-gray-50/50 p-8 text-center">
      <Icon className="mx-auto h-12 w-12 text-indigo-400" />
      <System.Title className="my-4">{title}</System.Title>
    </div>
  )
}
