import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form"
import { RequestCacheClearFormInputs } from "../types"
import { FormComponents } from "@components/Forms/components"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import ContactSupportOrDocumentation from "@components/ContactSupportOrDocumentation"

export const RequestCacheClearFormProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const methods = useForm<RequestCacheClearFormInputs>()

  return <FormProvider {...methods}>{children}</FormProvider>
}

interface Props {
  id?: string
  onSubmit: SubmitHandler<RequestCacheClearFormInputs>
}

export const RequestCacheClearForm = ({
  id = "request-cache-clear",
  onSubmit,
}: Props) => {
  const { handleSubmit } = useFormContext<RequestCacheClearFormInputs>()
  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <div className="mt-4 rounded border border-indigo-200 bg-indigo-50 p-2 py-4 text-center">
        <InformationCircleIcon className="mx-auto mb-2 h-8 w-8 text-indigo-500" />

        <div className="text-center text-sm">
          <p>We can also clear the cache of your site(s) after the update.</p>
          <ContactSupportOrDocumentation articleId="62330378af6f8700f6db0382">
            Learn more.
          </ContactSupportOrDocumentation>
        </div>
        <div className="mt-2 inline-flex cursor-pointer items-center justify-center gap-2 text-sm">
          <FormComponents.CheckboxInline
            id="needCacheClear"
            name="needCacheClear"
          >
            Yeah 🤟, let{`'`}s do it!
          </FormComponents.CheckboxInline>
        </div>
      </div>
    </form>
  )
}

export default {
  Provider: RequestCacheClearFormProvider,
  Form: RequestCacheClearForm,
}
