import { SvgProps } from "@design-system/Icon/types"

export const WarningCircle = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <circle cx="12" cy="12" r="10" fill="currentColor" />
        <g>
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M12.0002 10.6209C12.2234 10.6209 12.4043 10.8062 12.4043 11.0348V12.6904C12.4043 12.919 12.2234 13.1043 12.0002 13.1043C11.7771 13.1043 11.5962 12.919 11.5962 12.6904V11.0348C11.5962 10.8062 11.7771 10.6209 12.0002 10.6209Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M11.4223 8.15528C11.5987 8.05348 11.7978 8 12.0002 8C12.2027 8 12.4018 8.05348 12.5782 8.15528C12.7546 8.25708 12.9024 8.40375 13.0073 8.58114L13.0084 8.58296L16.2837 14.1855L16.2871 14.1914C16.3898 14.3738 16.444 14.5804 16.4445 14.7909C16.4451 15.0014 16.3918 15.2084 16.29 15.3912C16.1883 15.5741 16.0416 15.7265 15.8645 15.8333C15.6874 15.9401 15.4861 15.9975 15.2807 15.9999L15.276 16L8.71989 16C8.51433 15.9976 8.31296 15.9401 8.1358 15.8333C7.95865 15.7265 7.81188 15.574 7.7101 15.3911C7.60832 15.2081 7.55508 15.001 7.55567 14.7905C7.55626 14.5799 7.61066 14.3731 7.71346 14.1908L7.71673 14.1851L10.9856 8.59443C10.9881 8.58996 10.9906 8.58553 10.9932 8.58114C11.0981 8.40375 11.2459 8.25708 11.4223 8.15528ZM12.0002 8.8278C11.9367 8.8278 11.8743 8.84458 11.819 8.87651C11.7662 8.90696 11.7216 8.95021 11.689 9.00241C11.6878 9.00452 11.6866 9.00661 11.6854 9.0087L8.41198 14.6071C8.38059 14.6637 8.36399 14.7277 8.3638 14.7928C8.36362 14.8589 8.38032 14.9238 8.41224 14.9812C8.44417 15.0386 8.4902 15.0864 8.54577 15.1199C8.6008 15.1531 8.66329 15.1711 8.72712 15.1722H15.2733C15.337 15.1711 15.3995 15.1531 15.4545 15.1199C15.51 15.0864 15.556 15.0386 15.5879 14.9813C15.6199 14.9239 15.6366 14.859 15.6364 14.793C15.6363 14.7279 15.6197 14.664 15.5884 14.6074L12.3161 9.01008L12.3156 9.00933C12.2828 8.95403 12.2366 8.90829 12.1815 8.87651C12.1262 8.84458 12.0637 8.8278 12.0002 8.8278Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M11.5962 13.9321C11.5962 13.7035 11.7771 13.5182 12.0002 13.5182H12.0043C12.2274 13.5182 12.4083 13.7035 12.4083 13.9321C12.4083 14.1607 12.2274 14.346 12.0043 14.346H12.0002C11.7771 14.346 11.5962 14.1607 11.5962 13.9321Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
