import classNames from "classnames"
import { isNil } from "lodash"
import { InputHTMLAttributes } from "react"
import { RegisterOptions, useFormContext } from "react-hook-form"

interface Props extends InputHTMLAttributes<HTMLSelectElement> {
  options: Array<{
    label: string
    value: string | number
  }>
  register?: Function
  registerOptions?: RegisterOptions
  name: string
  defaultOption?: {
    label: string
    value: string | number
  }
}

const FieldSelect = ({
  register,
  name,
  options,
  defaultOption = null,
  registerOptions = {},
  className = "",
  ...rest
}: Props) => {
  const context = useFormContext()
  const classes = classNames(
    "block w-full pl-3 pr-10 py-2 h-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm rounded-md",
    className,
  )
  if (isNil(register)) {
    return (
      <select
        className={classes}
        {...context.register(name, registerOptions)}
        {...rest}
      >
        {defaultOption && (
          <option value={defaultOption.value}>{defaultOption.label}</option>
        )}
        {options.map((item, key) => (
          <option key={`opt_${name}_${key}`} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    )
  }

  return (
    <select className={classes} {...register(name, registerOptions)} {...rest}>
      {defaultOption && (
        <option value={defaultOption.value}>{defaultOption.label}</option>
      )}
      {options.map((item, key) => (
        <option key={`opt_${name}_${key}`} value={item.value}>
          {item.label}
        </option>
      ))}
    </select>
  )
}

export default FieldSelect
