import { getBackupDownloadLinkDatabase } from "./getBackupDownloadLinkDatabase"
import { getBackupDownloadLinkFile } from "./getBackupDownloadLinkFile"
import { requestManualBackupV4 } from "./requestManualBackupV4"
import { stopCurrentBackup } from "./stopCurrentBackup"
import { upsertBackupSettings } from "./upsertBackupSettings"

export const FetcherProjectBackups = {
  getBackupDownloadLinkDatabase: getBackupDownloadLinkDatabase,
  getBackupDownloadLinkFile: getBackupDownloadLinkFile,
  requestManualBackupV4: requestManualBackupV4,
  stopCurrentBackup: stopCurrentBackup,
  upsertBackupSettings: upsertBackupSettings,
}
