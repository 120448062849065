import { WordPressRepository } from "./WordPressRepository"
import { BulkWordPressRepositoryByStep } from "./BulkWordPressRepositoryByStep"
import { AllPlugins } from "./AllPlugins/BulkPluginsTable"
import { SitesSelectorWithPluginsInstalled } from "./SitesSelectorWithPluginsInstalled"

export const PluginsTable = {
  WordPressRepository: WordPressRepository,
  BulkWordPressRepositoryByStep: BulkWordPressRepositoryByStep,
  SitesSelectorWithPluginsInstalled: SitesSelectorWithPluginsInstalled,
  AllPlugins: AllPlugins,
}
