import { BulkActionsButton } from "./Button"
import { BulkActionsCheckedRows } from "./CheckedRows"
import { BulkActionsContainer } from "./Container"
import { BulkActionsList } from "./List"

export const BulkActions = {
  Button: BulkActionsButton,
  CheckedRows: BulkActionsCheckedRows,
  Container: BulkActionsContainer,
  List: BulkActionsList,
}
