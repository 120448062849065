import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders"
import { getPrivateApiProjectStopCurrentRestorationPath } from "@/Project/api/routes-backup-private"

export const stopCurrentRestoration = async ({
  projectId,
  restoredBackupId,
}: {
  projectId: number
  restoredBackupId: string
}) => {
  const headers = await getFetchPrivateHeaders()

  const result = await fetch(
    getPrivateApiProjectStopCurrentRestorationPath({
      projectId,
      restoredBackupId,
    }),
    {
      method: "POST",
      headers: headers,
    },
  )

  return await result.json()
}
