import classNames from "classnames"

const Box = ({
  children,
  className = "",
  spaceTop = false,
  spaceBottom = false,
  style = {},
}) => {
  return (
    <div
      className={classNames(
        { "mt-8": spaceTop, "mb-8": spaceBottom },
        "rounded-lg bg-white p-8",
        className,
      )}
      style={style}
    >
      {children}
    </div>
  )
}

export default Box
