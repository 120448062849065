import { SvgProps } from "@design-system/Icon/types"

export const Info = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M10.5 6.5C10.5 5.67157 11.1716 5 12 5H12.015C12.8434 5 13.515 5.67157 13.515 6.5C13.515 7.32843 12.8434 8 12.015 8H12C11.1716 8 10.5 7.32843 10.5 6.5Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M9 11C9 10.1716 9.67157 9.5 10.5 9.5H12C12.8284 9.5 13.5 10.1716 13.5 11V15.5C14.3284 15.5 15 16.1716 15 17C15 17.8284 14.3284 18.5 13.5 18.5H12C11.1716 18.5 10.5 17.8284 10.5 17V12.5C9.67157 12.5 9 11.8284 9 11Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
