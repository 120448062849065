import classNames from "classnames"
import { LinkProps } from "next/link"
import { Link } from "@design-system/Link"
import { ButtonHTMLAttributes } from "react"
import { get } from "lodash"

interface ButtonProps {
  className?: ButtonHTMLAttributes<HTMLButtonElement>["className"]
  children: ButtonHTMLAttributes<HTMLButtonElement>["children"]
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"]
  disabled?: ButtonHTMLAttributes<HTMLButtonElement>["disabled"]
}

type ButtonSize = "large" | "medium" | "small"

export interface ButtonOrLinkProps extends Partial<ButtonProps> {
  as?: LinkProps["as"]
  href?: LinkProps["href"]
  target?: React.AnchorHTMLAttributes<string>["target"]
  size?: ButtonSize
  iconOnly?: boolean
  rel?: React.AnchorHTMLAttributes<string>["rel"]
  onClick?: ButtonHTMLAttributes<HTMLButtonElement | HTMLDivElement>["onClick"]
  loading?: boolean
  form?: ButtonHTMLAttributes<HTMLButtonElement>["form"]
  element?: "button" | "div"
}

export const ButtonDefault = ({
  children,
  onClick,
  className = "",
  href = "",
  as = "",
  size = "medium",
  type = "button",
  iconOnly = false,
  disabled = false,
  target = "",
  loading = false,
  ...rest
}: ButtonOrLinkProps) => {
  const buttonSmallClasses = classNames({
    "text-xs gap-1": size === "small",
    "px-2.5 py-1.5": size === "small" && !iconOnly,
    "p-2": size === "small" && iconOnly,
  })

  const buttonMediumClasses = classNames({
    "gap-x-sm text-sm gap-2": size === "medium",
    "p-3 py-1.5": size === "medium" && !iconOnly,
    "p-2.5": size === "medium" && iconOnly,
  })

  const buttonLargeClasses = classNames({
    "text-base gap-2": size === "large",
    "p-4 py-2": size === "large" && !iconOnly,
    "p-3": size === "large" && iconOnly,
  })

  const defaultClasses =
    "inline-flex items-center rounded-full font-semibold focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-indigo-600 focus-visible:ring-offset-2 transition"

  const classes = classNames(
    buttonSmallClasses,
    buttonMediumClasses,
    buttonLargeClasses,
    defaultClasses,
    {
      "text-gray-500 opacity-40 cursor-not-allowed": disabled || loading,
      "cursor-pointer": !disabled && !loading,
    },
    className,
  )

  if (!!href) {
    return (
      <Link
        as={as}
        href={href}
        className={classes}
        disabled={disabled}
        target={target}
        {...rest}
      >
        {children}
      </Link>
    )
  }

  const Button = get(rest, "element", "button")

  return (
    <Button
      onClick={onClick}
      type={type}
      className={classes}
      disabled={disabled || loading}
      {...rest}
    >
      {loading && (
        <svg
          className={classNames(
            {
              "h-4 w-4": true,
            },
            "flex flex-none animate-spin items-center justify-center rounded-full text-xs font-semibold",
          )}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            className="animate-ring-fast"
            x="1"
            y="1"
            width="30"
            height="30"
            rx="15"
            stroke="currentColor"
            strokeWidth="3"
            strokeLinejoin="round"
          />
        </svg>
      )}
      {children}
    </Button>
  )
}
