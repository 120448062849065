import useSWR from "swr"
import qs from "query-string"
import { getPrivateApiProgressTasksPath } from "@/Process/api/routes-private"
import fetcherPrivateApiUmbrella from "@helpers/fetcherPrivateApiUmbrella"
import { isEmpty, isNil } from "lodash"
import { IProgressTask } from "@/Manager/Processes/types/ProgressTask"

interface Props {
  suspense?: boolean
  options?: {
    refreshInterval?: number
  }
  projectId?: number
  shouldFetch?: boolean
}

interface ResponseData {
  [key: string]: IProgressTask[]
}

export function useProcessesProgressTasks({
  suspense = true,
  options,
  projectId,
  shouldFetch = true,
}: Props) {
  const url = qs.stringifyUrl({
    url: getPrivateApiProgressTasksPath(),
    query: {
      projectId: projectId,
    },
  })

  const { data: response, ...rest } = useSWR<{
    code: string
    data: ResponseData
  }>(shouldFetch ? url : null, {
    suspense: suspense,
    ...options,
    fetcher: fetcherPrivateApiUmbrella(),
  })

  const { code, data } = response || {}

  if (isNil(data) || isEmpty(data) || code !== "success") {
    return {
      data: {},
      ...rest,
    }
  }

  return {
    data,
    ...rest,
  }
}
