import { useCallback, useContext } from "react"
import { Context } from "@design-system/Context"

export function useGenericPagination() {
  const { values, actions } = useContext(Context.GenericPagination.Context)

  const getMaxPage = useCallback(() => {
    return Math.ceil(values.total / values.perPage)
  }, [values.total, values.perPage])

  const getStartPage = () => {
    return values.page * values.perPage
  }

  const getPageLimitNumber = () => {
    if (getMaxPage() === values.page) {
      return values.total
    }

    return values.perPage * values.page + values.perPage
  }

  const getOffset = () => {
    return values.page * values.perPage - values.perPage
  }

  return {
    state: {
      page: values.page,
      perPage: values.perPage,
      total: values.total,
      maxPage: getMaxPage(),
      humanPage: values.page + 1,
      optionsPerPage: values.optionsPerPage,
    },
    getStartPage,
    getPageLimitNumber,
    getOffset,
    setTotal: actions.setTotal,
    setPerPage: actions.setPerPage,
    setPage: actions.setPage,
  }
}
