import Config from "@environments/data"
import qsUrl from "query-string"
import fetch from "node-fetch"

interface Props {
  user_id: number | string
  headers: any
}
export const magicLogin = async ({ user_id, headers }: Props) => {
  const path = qsUrl.stringifyUrl({
    url: `${Config.base_url}/api/admin/magic-login`,
    query: {
      user_id: user_id,
    },
  })

  try {
    const response = await fetch(path, {
      method: "POST",
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
    })

    const { success, result } = await response.json()

    return {
      success,
      result,
    }
  } catch (error) {
    console.log("[magicLogin]", error)
    return {
      success: false,
    }
  }
}
