import { SVGNewUmbrella } from "../../../ui/Icons/NewUmbrella"

export const SuspenseFallbackSecurity = () => {
  return (
    <div className="fixed left-0 top-0 flex h-full w-full flex-col items-center justify-center">
      <SVGNewUmbrella className="w-48" />
      <p className="mt-2 text-2xl">Loading in progress...</p>
    </div>
  )
}
