import { SvgProps } from "@design-system/Icon/types"

export const Filter = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          strokeWidth={strokeWidth}
          fill="currentColor"
          fillRule="evenodd"
          d="M3 4C3 3.44772 3.44772 3 4 3H20C20.5523 3 21 3.44772 21 4V6.172C20.9998 6.96753 20.6837 7.73064 20.1211 8.29311C20.1211 8.29314 20.1211 8.29307 20.1211 8.29311L16 12.4142V19C16 19.4304 15.7246 19.8126 15.3162 19.9487L9.31623 21.9487C9.01128 22.0503 8.67606 21.9992 8.41529 21.8112C8.15452 21.6233 8 21.3214 8 21V12.8866L3.78006 8.24467C3.27823 7.69256 3.00008 6.9732 3 6.22711V4ZM5 5V6.22689C5 6.22686 5 6.22693 5 6.22689C5.00005 6.47552 5.09273 6.71533 5.25994 6.89933C5.25992 6.89931 5.25996 6.89935 5.25994 6.89933L9.73994 11.8273C9.90728 12.0114 10 12.2512 10 12.5V19.6126L14 18.2792V12C14 11.7348 14.1054 11.4804 14.2929 11.2929L18.7069 6.87889C18.8944 6.69145 18.9999 6.43712 19 6.172C19 6.17193 19 6.17207 19 6.172V5H5Z"
          clipRule="evenodd"
        />
      </g>
    </svg>
  )
}
