import { scanUrl } from "./scanUrl"
import { saveSetting } from "./saveSetting"
import { toggleSetting } from "./toggleSetting"
import { cancelEditSetting } from "./cancelEditSetting"

export const FetcherProjectVisualRegression = {
  scanUrl: scanUrl,
  saveSetting: saveSetting,
  toggleSetting: toggleSetting,
  cancelEditSetting,
}
