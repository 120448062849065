import { PluginActivateAction } from "./ActivatePlugin"
import { PluginDeactivateAction } from "./DeactivatePlugin"
import { DeletePluginAction } from "./DeletePlugin"
import { RollbackPluginAction } from "./RollbackPlugin"
import { ToggleIgnoreUpdatePluginAction } from "./ToggleIgnoreUpdatePlugin"
import { PluginUpdateAction } from "./UpdatePlugin"

export const PluginActionComponents = {
  UpdatePlugin: PluginUpdateAction,
  RollbackPlugin: RollbackPluginAction,
  ActivatePlugin: PluginActivateAction,
  DeactivatePlugin: PluginDeactivateAction,
  DeletePlugin: DeletePluginAction,
  ToggleIgnoreUpdatePlugin: ToggleIgnoreUpdatePluginAction,
}
