import { SvgProps } from "@design-system/Icon/types"

export const Theme = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M7.58527 14.0576C7.00333 13.9419 6.40013 14.0013 5.85195 14.2284C5.30377 14.4554 4.83524 14.8399 4.50559 15.3333C4.17595 15.8266 4 16.4067 4 17V20H7C7.59334 20 8.17336 19.8241 8.66671 19.4944C9.16006 19.1648 9.54458 18.6962 9.77164 18.148C9.9987 17.5999 10.0581 16.9967 9.94236 16.4147C9.8266 15.8328 9.54088 15.2982 9.12132 14.8787C8.70176 14.4591 8.16721 14.1734 7.58527 14.0576ZM5.08658 12.3806C6.00021 12.0022 7.00555 11.9031 7.97545 12.0961C8.94536 12.289 9.83627 12.7652 10.5355 13.4645C11.2348 14.1637 11.711 15.0546 11.9039 16.0245C12.0969 16.9945 11.9978 17.9998 11.6194 18.9134C11.241 19.827 10.6001 20.6079 9.77785 21.1573C8.95561 21.7068 7.98891 22 7 22H3C2.44772 22 2 21.5523 2 21V17C2 16.0111 2.29324 15.0444 2.84265 14.2221C3.39206 13.3999 4.17295 12.759 5.08658 12.3806Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M21.9908 2.86448C22.0656 3.41167 21.6827 3.91593 21.1355 3.99077C18.4597 4.35677 15.9324 5.43841 13.8203 7.1215C11.7081 8.80459 10.0896 11.0267 9.13552 13.5533C8.94041 14.0699 8.3634 14.3306 7.84672 14.1355C7.33005 13.9404 7.06937 13.3634 7.26448 12.8467C8.34579 9.98328 10.1801 7.46489 12.5739 5.55738C14.9676 3.64987 17.8319 2.42402 20.8645 2.00922C21.4117 1.93438 21.9159 2.31729 21.9908 2.86448Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M21.1355 2.00922C21.6827 2.08407 22.0656 2.58833 21.9908 3.13552C21.576 6.16809 20.3501 9.03241 18.4426 11.4261C16.5351 13.8199 14.0167 15.6542 11.1533 16.7355C10.6366 16.9306 10.0596 16.6699 9.86448 16.1533C9.66937 15.6366 9.93005 15.0596 10.4467 14.8645C12.9733 13.9104 15.1954 12.2919 16.8785 10.1797C18.5616 8.06762 19.6432 5.54027 20.0092 2.86448C20.0841 2.31729 20.5883 1.93438 21.1355 2.00922Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M9.69204 8.58093C9.92349 8.07948 10.5176 7.8606 11.0191 8.09204C13.1781 9.08856 14.9114 10.8219 15.908 12.9809C16.1394 13.4824 15.9205 14.0765 15.4191 14.308C14.9176 14.5394 14.3235 14.3205 14.092 13.8191C13.2948 12.0918 11.9082 10.7052 10.1809 9.90795C9.67948 9.67651 9.4606 9.08238 9.69204 8.58093Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
