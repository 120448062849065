import { SvgProps } from "@design-system/Icon/types"

export const SlackUncolor = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeWidth={strokeWidth}
        d="M16.2734 12V6C16.2734 5.46957 16.0627 4.96086 15.6877 4.58579C15.3126 4.21071 14.8039 4 14.2734 4C13.743 4 13.2343 4.21071 12.8592 4.58579C12.4842 4.96086 12.2734 5.46957 12.2734 6V12H18.2734C18.669 12 19.0557 11.8827 19.3846 11.6629C19.7135 11.4432 19.9698 11.1308 20.1212 10.7654C20.2726 10.3999 20.3122 9.99778 20.235 9.60982C20.1578 9.22186 19.9674 8.86549 19.6877 8.58579C19.4079 8.30608 19.0516 8.1156 18.6636 8.03843C18.2757 7.96126 17.8735 8.00087 17.5081 8.15224C17.1426 8.30362 16.8303 8.55996 16.6105 8.88886C16.3907 9.21776 16.2734 9.60444 16.2734 10"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        strokeWidth={strokeWidth}
        d="M12.2734 16H18.2734C18.8039 16 19.3126 15.7893 19.6877 15.4142C20.0627 15.0391 20.2734 14.5304 20.2734 14C20.2734 13.4696 20.0627 12.9609 19.6877 12.5858C19.3126 12.2107 18.8039 12 18.2734 12H12.2734V18C12.2734 18.3956 12.3907 18.7822 12.6105 19.1111C12.8303 19.44 13.1426 19.6964 13.5081 19.8478C13.8735 19.9991 14.2757 20.0387 14.6636 19.9616C15.0516 19.8844 15.4079 19.6939 15.6877 19.4142C15.9674 19.1345 16.1578 18.7781 16.235 18.3902C16.3122 18.0022 16.2726 17.6001 16.1212 17.2346C15.9698 16.8692 15.7135 16.5568 15.3846 16.3371C15.0557 16.1173 14.669 16 14.2734 16"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        strokeWidth={strokeWidth}
        d="M8.27344 12V18C8.27344 18.5304 8.48415 19.0391 8.85923 19.4142C9.2343 19.7893 9.74301 20 10.2734 20C10.8039 20 11.3126 19.7893 11.6877 19.4142C12.0627 19.0391 12.2734 18.5304 12.2734 18V12H6.27344C5.87788 12 5.4912 12.1173 5.1623 12.3371C4.8334 12.5568 4.57705 12.8692 4.42568 13.2346C4.2743 13.6001 4.2347 14.0022 4.31187 14.3902C4.38904 14.7781 4.57952 15.1345 4.85922 15.4142C5.13893 15.6939 5.4953 15.8844 5.88326 15.9616C6.27122 16.0387 6.67335 15.9991 7.03881 15.8478C7.40426 15.6964 7.71661 15.44 7.93638 15.1111C8.15614 14.7822 8.27344 14.3956 8.27344 14"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        strokeWidth={strokeWidth}
        d="M12.2734 8H6.27344C5.743 8 5.2343 8.21072 4.85922 8.58579C4.48415 8.96086 4.27344 9.46957 4.27344 10C4.27344 10.5304 4.48415 11.0391 4.85922 11.4142C5.2343 11.7893 5.743 12 6.27344 12H12.2734V6C12.2734 5.60444 12.1561 5.21776 11.9364 4.88886C11.7166 4.55996 11.4043 4.30362 11.0388 4.15224C10.6734 4.00087 10.2712 3.96126 9.88326 4.03843C9.49529 4.1156 9.13893 4.30608 8.85922 4.58579C8.57952 4.86549 8.38904 5.22186 8.31187 5.60982C8.2347 5.99778 8.2743 6.39992 8.42568 6.76537C8.57705 7.13082 8.8334 7.44318 9.1623 7.66294C9.4912 7.8827 9.87787 8 10.2734 8"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
