import { Fragment, MouseEventHandler, useRef } from "react"
import { Dialog, Transition } from "@headlessui/react"
import Button from "../../../ui/Button"
import classNames from "classnames"

interface Props {
  open: boolean
  handleClose: MouseEventHandler<HTMLButtonElement>
  handleConfirm: MouseEventHandler<HTMLButtonElement>
  title: string
  textButtonConfirm?: string
  textButtonCancel?: string
  viewButtonCancel?: boolean
  viewButtonConfirm?: boolean
  children?: React.ReactNode | string
  size?: "base" | "lg" | "xl"
  loading?: boolean
}

export default function ModalDefault({
  open,
  handleClose,
  handleConfirm,
  textButtonConfirm = "Confirm",
  textButtonCancel = "Cancel",
  viewButtonCancel = true,
  viewButtonConfirm = true,
  title,
  children,
  size = "base",
  loading = false,
}: Props) {
  const cancelButtonRef = useRef()
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-y-auto"
        style={{
          zIndex: 9000,
        }}
        //@ts-ignore
        initialFocus={cancelButtonRef}
        open={open}
        //@ts-ignore
        onClose={handleClose}
      >
        <div className="block min-h-screen items-center justify-center p-0 px-4 pb-20 pt-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-0 scale-95"
            enterTo="opacity-100 translate-y-0 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 scale-100"
            leaveTo="opacity-0 translate-y-0 scale-95"
          >
            <div
              className={classNames(
                {
                  "max-w-4xl": size === "xl",
                  "max-w-2xl": size === "lg",
                  "max-w-lg": size === "base",
                },
                "inline-block w-full transform overflow-hidden rounded-lg bg-white p-8 text-left align-middle shadow-xl transition-all",
              )}
            >
              <div>
                <Dialog.Title
                  as="h2"
                  className="mb-8 text-center text-lg font-medium leading-6 text-gray-900"
                >
                  {title}
                </Dialog.Title>
                {children}
              </div>
              <div className="mt-8 flex items-center gap-8">
                {viewButtonCancel && (
                  <Button
                    indigo={false}
                    onClick={handleClose}
                    className="flex-1 justify-center"
                  >
                    {textButtonCancel}
                  </Button>
                )}
                {viewButtonConfirm && (
                  <Button
                    onClick={(e) => {
                      handleConfirm(e)
                    }}
                    className="flex-1 justify-center"
                    loading={loading}
                  >
                    {textButtonConfirm}
                  </Button>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
