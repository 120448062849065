import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders"
import {
  DatabaseOptimizationEntity,
  PropsCreateProcessTasks,
  SynchroniseProjectsEntity,
  UpdateCoreEntity,
  UpdatePluginEntity,
  UpdateThemeEntity,
} from "../../Manager/Processes/ProcessCreateManager"
import { ProcessDataInterface } from "../../Repositories/Process/createProcessWithTasks"
import { getPrivateApiProcessesPath } from "../api/routes-private"

export interface PropsCreateProcessTasksApi extends PropsCreateProcessTasks {
  entities:
    | UpdatePluginEntity
    | UpdateCoreEntity
    | UpdateThemeEntity
    | SynchroniseProjectsEntity
    | DatabaseOptimizationEntity
}

interface Props {
  tasks: Array<PropsCreateProcessTasksApi>
  data?: ProcessDataInterface
}

export const createProcess = async (props: Props) => {
  const headers = await getFetchPrivateHeaders()

  const result = await fetch(getPrivateApiProcessesPath(), {
    method: "POST",
    headers: headers,
    body: JSON.stringify(props),
  })

  return await result.json()
}
