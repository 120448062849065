import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form"
import { RollbackPluginFormInputs } from "../types"
import { FormComponents } from "@components/Forms/components"
import React from "react"
import { System } from "@design-system/index"
import classNames from "classnames"

export const RollbackPluginFormProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const methods = useForm<RollbackPluginFormInputs>()

  return <FormProvider {...methods}>{children}</FormProvider>
}

interface Props {
  id?: string
  versions: string[]
  onSubmit: SubmitHandler<RollbackPluginFormInputs>
}

export const RollbackPluginForm = ({
  id = "rollback-plugin",
  onSubmit,
  versions,
}: Props) => {
  const { handleSubmit, watch } = useFormContext<RollbackPluginFormInputs>()

  const versionSelected = watch("version")

  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-6 max-h-[50vh] space-y-4 overflow-y-scroll">
        {versions.map((version) => {
          return (
            <React.Fragment key={version}>
              <FormComponents.Label
                htmlFor={`version-${version}`}
                withoutBold
                className={classNames(
                  "flex cursor-pointer items-center gap-4 rounded-lg border p-4 font-medium",
                  {
                    "border-gray-100": versionSelected !== version,
                    "bg-indigo-50": versionSelected === version,
                  },
                )}
              >
                <FormComponents.Radio
                  key={version}
                  id={`version-${version}`}
                  name="version"
                  value={version}
                />
                <System.Text size="large">{version}</System.Text>
              </FormComponents.Label>
            </React.Fragment>
          )
        })}
      </div>

      <div className="mb-2">
        <FormComponents.CheckboxInline
          id="activatePlugin"
          name="activatePlugin"
          textSize="large"
        >
          Activate plugin after rollback
        </FormComponents.CheckboxInline>
      </div>
      <FormComponents.CheckboxInline
        id="needCacheClear"
        name="needCacheClear"
        textSize="large"
      >
        Clear cache after rollback
      </FormComponents.CheckboxInline>
    </form>
  )
}

export default {
  Provider: RollbackPluginFormProvider,
  Form: RollbackPluginForm,
}
