import { isNil } from "lodash"
import qs from "query-string"
import { getProjectDetailPath } from "."
import { SingleProjectDetailModelQuery } from "../../Core/Models/Projects/SingleProjectDetailModel"

export const getProjectDetail = async (
  idProject: number,
  params?: SingleProjectDetailModelQuery,
) => {
  let path = getProjectDetailPath(Number(idProject))
  if (!isNil(params)) {
    path = qs.stringifyUrl({
      url: path,
      query: params,
    })
  }

  const result = await fetch(path, {
    headers: {
      "Content-Type": "application/json",
    },
  })

  return await result.json()
}
