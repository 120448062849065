import classNames from "classnames"
import { ElementType, ReactNode } from "react"

interface Props {
  className?: string
  children: ReactNode
  as?: ElementType
}

export const titleClassnames = (className = "") => {
  return classNames(
    "text-lg leading-6 text-gray-900",
    {
      "font-semibold": !className.includes("font-"),
    },
    className,
  )
}

export const Title = ({
  children,
  className = "",
  as = "h2",
  ...rest
}: Props) => {
  const Element = as

  return (
    <Element className={titleClassnames(className)} {...rest}>
      {children}
    </Element>
  )
}
