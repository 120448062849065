import { SvgProps } from "@design-system/Icon/types"

export const TopMiddleSubMenuIcon = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  width,
  height,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? size}
      height={height ?? size}
      fill="none"
      viewBox="0 0 8 33"
    >
      <g clipPath="url(#a)">
        <path
          strokeWidth={strokeWidth}
          fill="currentColor"
          fillRule="evenodd"
          d="M1.94654e-06 33L0 0L1 -3.23922e-08L1 33L1.94654e-06 33Z"
          clipRule="evenodd"
        />
        <path
          strokeWidth={strokeWidth}
          fill="currentColor"
          fillRule="evenodd"
          d="M0 15V0H1.04348V15C1.04348 15.2761 1.27707 15.5 1.56522 15.5H8V16.5H1.56522C0.700772 16.5 0 15.8284 0 15Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <rect width={size} height={size} fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  )
}
