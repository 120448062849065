import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"
import { UpdatePluginFormValues } from "./ModalUpdatePlugin"
import {
  AdvancedSafeUpdateValidation,
  PluginUpdateType,
} from "@components/Plugin/actions/createProcessTaskUpdatePlugin"

export enum DefaultPluginUpdateValuesType {
  SINGLE = "single",
  BULK = "bulk",
}

interface DefaultPluginUpdateValuesState {
  [DefaultPluginUpdateValuesType.SINGLE]: UpdatePluginFormValues
  [DefaultPluginUpdateValuesType.BULK]: UpdatePluginFormValues

  setDefaultValues: (
    type: DefaultPluginUpdateValuesType,
    values: UpdatePluginFormValues,
  ) => void
}

export const useDefaultPluginUpdateValuesStore =
  create<DefaultPluginUpdateValuesState>()(
    devtools(
      persist(
        (set) => ({
          single: {
            updateType: PluginUpdateType.SAFE_UPDATE,
            advancedSafeUpdateValidation: AdvancedSafeUpdateValidation.MANUAL,
            needCacheClear: false,
          },
          bulk: {
            updateType: PluginUpdateType.SAFE_UPDATE,
            advancedSafeUpdateValidation: AdvancedSafeUpdateValidation.MANUAL,
            needCacheClear: false,
          },
          setDefaultValues: (type, values) =>
            set(() => ({
              [type]: values,
            })),
        }),
        {
          name: "plugin-update-modal-default-values",
        },
      ),
    ),
  )

export const useDefaultPluginUpdateValues = () => {
  const { single, bulk, setDefaultValues } = useDefaultPluginUpdateValuesStore()

  return {
    defaultValues: { single, bulk },
    setDefaultValues: {
      single: (values: UpdatePluginFormValues) =>
        setDefaultValues(DefaultPluginUpdateValuesType.SINGLE, values),
      bulk: (values: UpdatePluginFormValues) =>
        setDefaultValues(DefaultPluginUpdateValuesType.BULK, values),
    },
  }
}
