import { SiteSelectorWithPluginInstall } from "../components/Table/SitesSelectorWithPluginsInstalled"
import { TabsFilteringProjectsInstallPlugins } from "../constants/TabsFilteringProjectsIntallPlugins"

export function getFilteringProjectsInstallPlugins({
  data,
  filters,
}: {
  data: SiteSelectorWithPluginInstall[]
  filters: {
    tab: string
  }
}): SiteSelectorWithPluginInstall[] {
  switch (filters.tab) {
    case TabsFilteringProjectsInstallPlugins.ALL:
      return data
    case TabsFilteringProjectsInstallPlugins.ELIGIBLE_ONLY:
      return data.filter((project) => {
        return (
          project.install_requirements.is_installed === false &&
          project.install_requirements.wordpress_version_outdated === false &&
          project.install_requirements.php_version_outdated === false
        )
      })
  }
}
