import WordPressRepositoryPluginModel from "@/Core/Models/Plugins/model"
import { PluginsBulkAction } from "@components/Plugin/components/BulkActions"
import { createEntityIdFromPluginProcess } from "@components/Plugin/helpers/createEntityIdFromPluginProcess"
import { System } from "@design-system/index"
import { TableRowProcess } from "@design-system/Table/types"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import classNames from "classnames"
import { isEmpty } from "lodash"
import { PluginRow } from "./Row"

interface Props {
  plugins?: WordPressRepositoryPluginModel[]
  projectId: number
  rowsProcessing: Set<TableRowProcess>
}

type Item = {
  id: string
  canBeChecked: boolean
} & WordPressRepositoryPluginModel

const LABELS_COLUMNS = [
  {
    key: "name",
    name: "Name",
  },
  {
    key: "rating",
    name: "Rating",
  },
  {
    key: "requirements",
    name: "Requirements",
  },
  {
    key: "action",
    name: "",
  },
]

export const WordPressRepository = ({
  plugins,
  projectId,
  rowsProcessing,
}: Props) => {
  return (
    <>
      {!isEmpty(rowsProcessing) && (
        <>
          <div
            className={classNames(
              { "animate-pulse": false },
              "relative mb-4 flex items-center rounded border border-indigo-200 bg-indigo-50 p-2 py-4",
            )}
          >
            <InformationCircleIcon className="mx-4 h-8 w-8 text-indigo-500" />

            <div className="text-sm">
              <p>
                A process in progress! It might takes a few seconds. You can
                stay on the page to see the progress, or leave it with peace of
                mind. <br /> You will be alerted in the notification center 🔔
              </p>
            </div>
          </div>
        </>
      )}
      <System.Table.List<Item>
        columns={LABELS_COLUMNS}
        columnsClassname="grid-cols-[auto_2fr_2fr_1fr_3fr]"
        rows={plugins.map((plugin) => {
          return {
            id: createEntityIdFromPluginProcess({
              slug: plugin.slug,
              projectId: projectId,
            }),
            canBeChecked:
              !plugin.php_version_outdated &&
              !plugin.is_installed &&
              !plugin.wordpress_version_outdated,
            ...plugin,
          } as Item
        })}
        rowsProcessing={rowsProcessing}
        withChecked
        bulkActions={
          <PluginsBulkAction.IntallPluginFromRepository
            projectId={projectId}
            plugins={plugins}
          />
        }
      >
        {({ item, columns }) => {
          return (
            <PluginRow item={item} columns={columns} projectId={projectId} />
          )
        }}
      </System.Table.List>
    </>
  )
}
