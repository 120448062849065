import { PropsCreateProcessTasksApi } from "@/FetchApi/Process/createProcess"
import { UpdatePluginEntity } from "@/Manager/Processes/ProcessCreateManager"
import { DataClearCache } from "@/Repositories/Process/createProcessWithTasks"
import { TableRowProcess } from "@design-system/Table/types"
import { ProcessTasksType } from "@prisma/client"
import { get, isNil } from "lodash"
import { createEntityIdFromPluginProcess } from "../helpers/createEntityIdFromPluginProcess"
import FetcherProcess from "@/Process/Fetcher"

interface Response {
  code: string
  data: {
    id: string
    ProcessTask: Array<{
      projectId: number
      retry: number
      type: ProcessTasksType
      entities?: any
      state?: any
    }>
  }
  rowProcessings: Set<TableRowProcess>
}

interface Props {
  data: Array<
    PropsCreateProcessTasksApi & {
      entities: UpdatePluginEntity
    }
  >
  projectsClearCache: Array<DataClearCache>
}

export const createBulkProcessTask = async ({
  data,
  projectsClearCache,
}: Props): Promise<Response> => {
  const response = await FetcherProcess.createProcess({
    tasks: data,
    data: {
      clear_cache: projectsClearCache,
      can_send_notification: true,
    },
  })

  let code = null
  if (!isNil(response)) {
    code = response.code
  }

  const lines = new Set<TableRowProcess>()
  if (code === "success") {
    data.forEach((item) => {
      if (item.type === ProcessTasksType.UPDATE_PLUGINS) {
        //@ts-ignore
        get(item, "entities", []).forEach((element) => {
          lines.add({
            key: createEntityIdFromPluginProcess({
              slug: element.plugin,
              projectId: item.projectId,
            }),
            type: item.type,
            task: {
              finished_at: null,
              state: "waiting",
              code: null,
              entities: item.entities,
            },
          })
        })
      } else {
        lines.add({
          key: createEntityIdFromPluginProcess({
            slug: item.entities.plugin,
            projectId: item.projectId,
          }),
          type: item.type,
          task: {
            finished_at: null,
            state: "waiting",
            code: null,
            entities: item.entities,
          },
        })
      }
    })
  }

  return {
    code: response.code,
    data: get(response, "data", null),
    rowProcessings: lines,
  }
}
