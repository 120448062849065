import { getPrivateApiProcessPath } from "."

export const getProcess = async ({ id }: { id: string }) => {
  try {
    const data = await fetch(getPrivateApiProcessPath(id), {
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    })
    return await data.json()
  } catch (error) {
    return {}
  }
}
