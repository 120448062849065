import { useState } from "react"
import { System } from "@design-system/index"
import { isEmpty } from "lodash"

interface Tab {
  id: string
  name: string
  count?: number
  render: () => React.ReactNode
}

interface Props {
  className?: string
  tabs: Array<Tab>
  defaultTab?: string
}

export const TabList: React.FC<Props> = ({
  tabs = [],
  defaultTab = "",
  className = "",
}: Props) => {
  if (tabs.length > 0 && isEmpty(defaultTab)) {
    defaultTab = tabs[0].id
  }

  const [currentTab, setCurrentTab] = useState<string>(defaultTab)

  if (!tabs.length) {
    return null
  }

  const currentTabPanel = tabs.find((tab) => tab.id === currentTab)

  return (
    <>
      <System.Tab.Navigation className={className}>
        {tabs.map((tab) => {
          return (
            <System.Tab.Button
              key={tab.id}
              className="text-gray-500 hover:text-gray-700"
              isCurrent={currentTab === tab.id}
              onClick={() => setCurrentTab(tab.id)}
              count={tab.count}
            >
              {tab.name}
            </System.Tab.Button>
          )
        })}
      </System.Tab.Navigation>
      {!!currentTab && currentTabPanel.render()}
    </>
  )
}
