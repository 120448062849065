import { Filters } from "@components/Filters"
import {
  TABS_FILTERING_PLUGINS,
  TypeFilteringPluginsItem,
} from "@components/Plugin/constants/TabsFilteringPlugins"
import { TypeGenericFilteringItemsTabs } from "@design-system/Context/GenericFilteringItemsWithTabs"
import { useGenericFilteringItemsWithTabs } from "@design-system/Context/GenericFilteringItemsWithTabs/hooks/useGenericFilteringItemsWithTabs"
import { System } from "@design-system/index"
import classNames from "classnames"
import { isEmpty } from "lodash"
import React, { useEffect } from "react"

const filterTabsBulk = TABS_FILTERING_PLUGINS.filter(
  (tab) => tab.value !== TypeFilteringPluginsItem.PROCESS_IN_PROGRESS,
)

interface Props<DataFiltering> {
  filteringCallback: (props: {
    data: DataFiltering[]
    filters: { query: string; tab: TypeGenericFilteringItemsTabs }
  }) => DataFiltering[]
}

export function TabsFilteringPlugins<DataFiltering = any>({
  filteringCallback,
}: Props<DataFiltering>) {
  const { state, handleChangeTab, handleChangeQuery } =
    useGenericFilteringItemsWithTabs()

  useEffect(() => {
    const data = filteringCallback({
      data: state.data,
      filters: {
        query: "",
        tab: TypeFilteringPluginsItem.NEED_UPDATE,
      },
    })

    if (isEmpty(data)) {
      return
    }

    handleChangeTab({
      value: TypeFilteringPluginsItem.NEED_UPDATE,
    })
  }, [])

  return (
    <>
      <System.Content.Body>
        <div className="flex items-center justify-between">
          <Filters.SearchBox
            className="w-1/2"
            onClick={({ value }) => {
              handleChangeQuery({
                value,
              })
            }}
            placeholder="Search by name"
          />
          <System.Button.Primary href={"/bulk-actions/plugins/install"}>
            <System.Svg.Plus className="h-4 w-4 opacity-60" />
            Bulk Add Plugin
          </System.Button.Primary>
        </div>
      </System.Content.Body>
      <System.Content.Body withoutPaddingTop>
        <div
          className={classNames(
            "flex w-full items-center justify-between gap-2",
          )}
        >
          <System.Tab.Navigation>
            {filterTabsBulk.map((tab) => {
              const data = filteringCallback({
                data: state.data,
                filters: {
                  query: state.query,
                  tab: tab.value,
                },
              })

              return (
                <System.Tab.Button
                  count={data.length}
                  key={tab.value}
                  className="text-gray-500 hover:text-gray-700"
                  isCurrent={state.tab === tab.value}
                  onClick={() => {
                    handleChangeTab({
                      value: tab.value,
                    })
                  }}
                >
                  {tab.label}
                </System.Tab.Button>
              )
            })}
          </System.Tab.Navigation>
        </div>
      </System.Content.Body>
    </>
  )
}
