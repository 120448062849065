import type { Config } from "tailwindcss"
import type { CustomThemeConfig } from "tailwindcss/types/config"
const { fontFamily } = require("tailwindcss/defaultTheme")

const designSystemTokens: Partial<CustomThemeConfig> = {
  fontSize: {
    "title-h1": ["2.375rem", { lineHeight: "1.2", fontWeight: "bold" }],
    "title-h2": ["1.75rem", { lineHeight: "1.2", fontWeight: "bold" }],
    "title-h3": ["1.3125rem", { lineHeight: "1.2", fontWeight: "bold" }],
    "title-h4": ["1rem", { lineHeight: "1.2", fontWeight: "bold" }],

    "body-xl": ["1.75rem", { lineHeight: "1.3" }],
    "body-lg": ["1.4375rem", { lineHeight: "1.3" }],
    "body-md": ["1.1875rem", { lineHeight: "1.3" }],
    "body-base": ["1rem", { lineHeight: "1.3" }],
    "body-sm": [".8125rem", { lineHeight: "1.3" }],
    "body-xs": [".6875rem", { lineHeight: "1.3" }],
  },
  colors: {
    primary: {
      darkest: "hsl(var(--primary-darkest))",
      darker: "hsl(var(--primary-darker))",
      base: "hsl(var(--primary-base))",
      lighter: "hsl(var(--primary-lighter))",
      "lighter-200": "hsl(var(--primary-lighter-200))",
      lightest: "hsl(var(--primary-lightest))",
    },
    secondary: {
      darkest: "hsl(var(--secondary-darkest))",
      darker: "hsl(var(--secondary-darker))",
      base: "hsl(var(--secondary-base))",
      lighter: "hsl(var(--secondary-lighter))",
      lightest: "hsl(var(--secondary-lightest))",
    },
    main: {
      white: "hsl(var(--main-white))",
      "grey-100": "hsl(var(--main-grey-100))",
      "grey-200": "hsl(var(--main-grey-200))",
      "grey-300": "hsl(var(--main-grey-300))",
      "grey-400": "hsl(var(--main-grey-400))",
      "grey-500": "hsl(var(--main-grey-500))",
      "grey-600": "hsl(var(--main-grey-600))",
    },
    danger: {
      dark: "hsl(var(--danger-dark))",
      base: "hsl(var(--danger-base))",
      light: "hsl(var(--danger-light))",
    },
    alert: {
      dark: "hsl(var(--alert-dark))",
      base: "hsl(var(--alert-base))",
      light: "hsl(var(--alert-light))",
    },
    success: {
      dark: "hsl(var(--success-dark))",
      base: "hsl(var(--success-base))",
      light: "hsl(var(--success-light))",
    },
  },
}

const config = {
  darkMode: ["class"],
  content: [
    "./pages/**/*.{ts,tsx}",
    "./components/**/*.{ts,tsx}",
    "./pages/**/*.{ts,tsx}",
    "./pages/**/*.{ts,tsx}",
    "./components/**/*.{ts,tsx}",
    "./components/**/*.{ts,tsx}",
    "./layouts/**/*.{ts,tsx}",
    "./layouts/**/*.{ts,tsx}",
    "./layouts/**/*.{ts,tsx}",
    "./ui/**/*.{ts,tsx}",
    "./svg/**/*.{ts,tsx}",
    "./ui/**/*.{ts,tsx}",
    "./design-system/**/*.{ts,tsx}",
  ],
  prefix: "",
  theme: {
    container: {
      center: true,
      padding: "2rem",
      screens: {
        "2xl": "1400px",
      },
    },
    extend: {
      backgroundImage: {
        "auth-layout-gradient":
          "radial-gradient(67.32% 74.58% at 100% 101.84%, rgba(80, 77, 226, 0.15) 0%, rgba(221, 220, 250, 0.09) 100%)",
      },
      fontSize: designSystemTokens.fontSize,
      fontFamily: {
        sans: ["var(--font-sans)", ...fontFamily.sans],
      },
      colors: {
        ...designSystemTokens.colors,
        border: "hsl(var(--border))",
        input: "hsl(var(--input))",
        ring: "hsl(var(--ring))",
        background: "hsl(var(--background))",
        foreground: "hsl(var(--foreground))",
        destructive: {
          DEFAULT: "hsl(var(--destructive))",
          foreground: "hsl(var(--destructive-foreground))",
        },
        muted: {
          DEFAULT: "hsl(var(--muted))",
          foreground: "hsl(var(--muted-foreground))",
        },
        accent: {
          DEFAULT: "hsl(var(--accent))",
          foreground: "hsl(var(--accent-foreground))",
        },
        popover: {
          DEFAULT: "hsl(var(--popover))",
          foreground: "hsl(var(--popover-foreground))",
        },
        card: {
          DEFAULT: "hsl(var(--card))",
          foreground: "hsl(var(--card-foreground))",
        },
      },
      borderRadius: {
        lg: "var(--radius)",
        md: "calc(var(--radius) - 2px)",
        sm: "calc(var(--radius) - 4px)",
        xs: "calc(var(--radius) - 6px)",
      },
      keyframes: {
        "accordion-down": {
          from: { height: "0" },
          to: { height: "var(--radix-accordion-content-height)" },
        },
        "accordion-up": {
          from: { height: "var(--radix-accordion-content-height)" },
          to: { height: "0" },
        },
        ring: {
          "0%,100%": {
            "stroke-dasharray": "100",
            "stroke-dashoffset": "20",
          },
          "50%": {
            "stroke-dasharray": "100",
            "stroke-dashoffset": "90",
          },
        },
      },
      animation: {
        "accordion-down": "accordion-down 0.2s ease-out",
        "accordion-up": "accordion-up 0.2s ease-out",
        "ring-fast": "ring 3s cubic-bezier(0.3, 0, 0.8, 1) infinite",
        "pulse-fast": "pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite",
      },
    },
  },
  plugins: [
    require("@tailwindcss/forms"),
    require("@tailwindcss/typography"),
    require("tailwindcss-animate"),
  ],
} satisfies Config

export default config
