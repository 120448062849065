import useSWR from "swr"
import { get, isEmpty, isNil } from "lodash"
import { morphism } from "morphism"
import {
  SchemaWordPressRepositoryPluginModel,
  SourceWordPressRepositoryPlugin,
} from "@/Core/Models/Plugins/schemas"
import WordPressRepositoryPluginModel from "@/Core/Models/Plugins/model"
import qs from "query-string"

interface Props {
  perPage?: number
  page?: number
  search?: string
  options?: {
    suspense?: boolean
  }
}

function useWordPressRepositoryPlugins({ page = 1, search, options }: Props) {
  let path = qs.stringifyUrl({
    url: `/api/wordpress-plugin-repository`,
    query: {
      page: page + 1,
    },
  })

  if (!isEmpty(search)) {
    path = qs.stringifyUrl({
      url: `/api/wordpress-plugin-repository`,
      query: {
        search: search,
      },
    })
  }

  const { data, ...rest } = useSWR<{
    items: SourceWordPressRepositoryPlugin[]
    total: number
  }>(path, {
    suspense: get(options, "suspense", true),
  })

  if (isNil(data)) {
    return {
      data: {
        items: [] as WordPressRepositoryPluginModel[],
        total: 0,
      },
      ...rest,
    }
  }

  const items = get(data, "items", [])

  return {
    data: {
      items: morphism(
        SchemaWordPressRepositoryPluginModel,
        items,
        WordPressRepositoryPluginModel,
      ),
      total: get(data, "total", 0),
    },
    ...rest,
  }
}

export default useWordPressRepositoryPlugins
