import { cva } from "class-variance-authority"

export const tagVariants = cva(
  "inline-flex items-center font-semibold text-inherit",
  {
    variants: {
      variant: {
        default: "bg-primary-lightest text-primary-base",
        danger: "bg-danger-light text-danger-base",
        alert: "bg-alert-light text-alert-base",
        success: "bg-success-light text-success-base",
        info: "bg-secondary-lightest text-secondary-base",
        darkGrey: "bg-main-grey-100 text-primary-darkest",
        lightGrey: "bg-main-grey-100 text-main-grey-500",
      },
      size: {
        default: "p-2 gap-2 rounded text-body-base",
        sm: "px-2 py-1 gap-1.5 rounded text-body-sm",
        xs: "px-1.5 py-1 gap-1 rounded-xs text-body-xs",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
)
