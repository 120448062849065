import { SvgProps } from "@design-system/Icon/types"

export const CompetitorWpRemote = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox="0 0 35 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeWidth={strokeWidth}
        d="M16.2085 0.300781H18.1698C24.2018 0.744855 28.8276 3.63133 31.9731 8.77519C33.4904 11.3286 34.3045 14.2151 34.2675 17.2126C34.3415 18.6558 33.3053 19.914 31.8621 20.0621C30.4929 20.2471 29.2347 19.3589 28.9386 17.9897C28.8646 17.5826 28.8276 17.1756 28.8276 16.7685C28.4945 10.3294 23.0176 5.37062 16.5786 5.74068C11.1387 6.03673 6.58691 10.0334 5.69877 15.4363C5.58775 16.0284 5.55074 16.6205 5.55074 17.2126C5.62475 18.7298 4.44056 19.988 2.92331 20.0621C2.8863 20.0621 2.84929 20.0621 2.81229 20.0621C1.33204 20.0621 0.11084 18.8779 0.11084 17.3976C0.11084 17.3236 0.11084 17.2496 0.11084 17.1386C0.11084 13.586 1.29504 10.1814 3.40439 7.33195C6.43889 3.22427 10.5466 0.892879 15.6164 0.337787L16.2085 0.300781Z"
        fill="currentColor"
      />
      <path
        strokeWidth={strokeWidth}
        d="M21.1303 44.2262H11.0646C9.51035 44.2262 8.43717 43.375 8.06711 42.0058C7.69705 40.4886 8.65921 38.9343 10.1765 38.5642C10.3245 38.5272 10.4725 38.4902 10.6575 38.4902H31.1959C32.6762 38.4902 33.7494 39.2304 34.1194 40.5626C34.5635 42.0428 33.7494 43.6341 32.2321 44.0782C32.0101 44.1522 31.751 44.1892 31.529 44.1892C28.5685 44.2262 25.571 44.2262 22.6105 44.2262H21.1303Z"
        fill="currentColor"
      />
      <path
        strokeWidth={strokeWidth}
        d="M21.0936 36.1226H10.8799C9.54768 36.1226 8.36349 35.1974 8.06744 33.8652C7.77139 32.57 8.4375 31.2378 9.6587 30.6457C10.0288 30.4606 10.4358 30.3496 10.8429 30.3496H31.4553C33.0466 30.4236 34.2678 31.7188 34.2308 33.3101C34.1938 34.8274 32.9726 36.0115 31.4553 36.0856C27.9767 36.1226 24.5352 36.1226 21.0936 36.1226Z"
        fill="currentColor"
      />
      <path
        strokeWidth={strokeWidth}
        d="M21.0933 28.0172H10.9166C9.32538 28.0542 8.03017 26.759 7.99316 25.1677C7.99316 25.1677 7.99316 25.1677 7.99316 25.1307C7.99316 23.5765 9.25137 22.2812 10.8056 22.2812C10.8426 22.2812 10.8796 22.2812 10.8796 22.2812H31.344C32.9353 22.2812 34.1935 23.5765 34.1935 25.1307C34.1935 26.4629 33.2684 27.6101 31.9731 27.9062C31.7511 27.9432 31.5291 27.9802 31.307 27.9802L21.0933 28.0172Z"
        fill="currentColor"
      />
      <path
        strokeWidth={strokeWidth}
        d="M2.81247 30.5723C4.36672 30.5723 5.62493 31.7935 5.62493 33.3477C5.62493 34.902 4.36672 36.1602 2.81247 36.1972C1.25821 36.1972 0 34.902 0 33.3847C0 31.7935 1.25821 30.5723 2.81247 30.5723Z"
        fill="currentColor"
      />
      <path
        strokeWidth={strokeWidth}
        d="M2.81247 22.4668C4.32972 22.4668 5.58793 23.688 5.62493 25.2423C5.62493 26.7965 4.36672 28.0547 2.81247 28.0917C1.25821 28.0917 0 26.8335 0 25.2793C0 23.725 1.29521 22.4668 2.81247 22.4668Z"
        fill="currentColor"
      />
      <path
        strokeWidth={strokeWidth}
        d="M2.81247 38.6758C4.36672 38.6758 5.62493 39.934 5.62493 41.4882C5.62493 43.0425 4.36672 44.3007 2.81247 44.3007C1.25821 44.2637 0 43.0055 0 41.4512C0 39.897 1.29521 38.6758 2.81247 38.6758Z"
        fill="currentColor"
      />
    </svg>
  )
}
