interface Props {
  params?: {
    buy_like_company: boolean
    vat_number?: string
    company_name?: string
    country?: string
  }
}

export const updateStripeCustomer = async ({ params }: Props) => {
  const response = await fetch("/api/customers/billing-details", {
    method: "PUT",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
    },
  })

  return await response.json()
}
