import { FetcherProject } from "@/Project/Fetcher"
import { WordPressPluginComponents } from "@components/Plugin/components"
import { useGenericModal } from "@design-system/Context/GenericModal/hooks/useGenericModal"
import useNotifications from "@hooks/notifications/useNotifications"
import { isNil } from "lodash"

interface ToggleIgnoreUpdatePluginProps {
  plugin: {
    id: string
  }
  projectId: number
  onSuccess?: (props: any) => void
  isIgnoredUpdate: boolean
}

export const useToggleIgnoreUpdatePlugin = () => {
  const { openModal, closeModal } = useGenericModal()
  const { handleNotifySuccess } = useNotifications()

  const toggleIgnoreUpdate = ({
    plugin,
    projectId,
    onSuccess,
    isIgnoredUpdate,
  }: ToggleIgnoreUpdatePluginProps) => {
    const onSubmit = async () => {
      const response = await FetcherProject.Plugin.toggleIgnorePluginUpdate({
        pluginId: plugin.id,
        projectId: projectId,
      })

      const isIgnoreResponse = response.data?.ignore
      handleNotifySuccess({
        title: isIgnoreResponse ? "Update excluded" : "Update reconsidered",
        message: isIgnoreResponse
          ? "This plugin will not be updated."
          : "You will be able to update this plugin again.",
      })

      if (response.code === "success" && !isNil(onSuccess)) {
        onSuccess(response)
      }

      closeModal()
    }

    openModal({
      component: (
        <WordPressPluginComponents.Modal.IgnoreUpdatePlugin
          onConfirm={onSubmit}
          isIgnoredUpdate={!!isIgnoredUpdate}
        />
      ),
    })
  }

  return { toggleIgnoreUpdate }
}
