import { SvgProps } from "@design-system/Icon/types"

export const WorkspaceAdd = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeWidth={strokeWidth}
        d="M3 12C3 13.657 7.03 15 12 15C13.116 15 14.185 14.932 15.172 14.808M20.896 12.458C20.9632 12.3146 20.9987 12.1584 21 12"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        strokeWidth={strokeWidth}
        d="M20.9839 12.546C21.0947 10.723 20.6482 8.90942 19.7037 7.3462C18.7592 5.78298 17.3615 4.5441 15.6962 3.79412C14.0309 3.04414 12.1769 2.81856 10.3803 3.14734C8.58379 3.47612 6.92979 4.3437 5.63804 5.63484C4.34629 6.92598 3.47792 8.57957 3.14829 10.376C2.81866 12.1723 3.04336 14.0265 3.79255 15.6922C4.54174 17.3578 5.77996 18.7561 7.34273 19.7013C8.9055 20.6465 10.7189 21.0939 12.5419 20.984"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        strokeWidth={strokeWidth}
        d="M16 19H22"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        strokeWidth={strokeWidth}
        d="M19 16V22"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
