import React, { Suspense, useState } from "react"
import { System } from "@design-system/index"
import { useGenericModal } from "@design-system/Context/GenericModal/hooks/useGenericModal"

import { RollbackPluginFormInputs } from "@components/Processes/components/Form/types"
import { isNil } from "lodash"
import { useWordPressRepositoryPluginInformation } from "@components/Plugin/hooks/useWordPressRepositoryPluginInformation"
import { ProcessComponents } from "@components/Processes"

interface Props {
  onConfirm: (props: RollbackPluginFormInputs) => void
  slug: string
  name: string
}

const Changelog = ({ slug }: { slug: string }) => {
  const { data } = useWordPressRepositoryPluginInformation({
    slug,
  })

  if (isNil(data) || isNil(data.sections)) {
    return (
      <System.Text>
        We could not find the changelog of this plugin. It is possible that this
        is a premium plugin.
      </System.Text>
    )
  }

  return (
    <div
      className="prose"
      dangerouslySetInnerHTML={{ __html: data.sections.changelog }}
    />
  )
}

const ChooseVersion = ({
  slug,
  onConfirm,
}: {
  slug: string
  onConfirm: (props: RollbackPluginFormInputs) => void
}) => {
  const { data } = useWordPressRepositoryPluginInformation({
    slug,
  })

  return (
    <ProcessComponents.Form.RollbackPlugin.Provider>
      <ProcessComponents.Form.RollbackPlugin.Form
        onSubmit={onConfirm}
        versions={data.getLatestVersions({
          maximum: 10,
        })}
      />
    </ProcessComponents.Form.RollbackPlugin.Provider>
  )
}

export const ModalRollbackPlugin = ({ onConfirm, slug, name }: Props) => {
  const { closeModal } = useGenericModal()
  const [loading, setLoading] = useState(false)

  return (
    <>
      <div className="flex">
        <div className="w-3/5 p-8 pb-24">
          <div className="mb-4 flex items-center gap-2">
            <System.IconBullet size="md" icon="Update" />
            <System.Title>
              Which version of the {name} shall we rollback to?
            </System.Title>
          </div>
          <Suspense fallback={<System.SuspenseFallback.Default />}>
            <ChooseVersion
              slug={slug}
              onConfirm={(values) => {
                setLoading(true)
                onConfirm(values)
              }}
            />
          </Suspense>
          <div className="absolute bottom-0 left-0 mt-8 flex w-full items-center justify-start gap-4 border-t bg-white px-8 py-4">
            <System.Button.Default
              onClick={closeModal}
              className="justify-center"
            >
              Cancel
            </System.Button.Default>
            <System.Form.Submit
              form="rollback-plugin"
              className="justify-center"
              loading={loading}
            >
              <System.Svg.Check className="h-4 w-4 opacity-50" />
              Rollback Plugin
            </System.Form.Submit>
          </div>
        </div>
        <div className="relative z-10 w-2/5 bg-gray-50 p-8">
          <System.Title className="mb-8">Changelog</System.Title>

          <Suspense fallback={<System.SuspenseFallback.Default />}>
            <div className="max-h-[70vh] overflow-y-auto">
              <Changelog slug={slug} />
            </div>
          </Suspense>
        </div>
      </div>
    </>
  )
}
