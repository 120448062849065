import { SystemConstant } from "@design-system/constants"
import classNames from "classnames"
import { ReactNode } from "react"

interface Props {
  children: ReactNode
  className?: string
  withoutPaddinBottom?: boolean
  withoutPaddingTop?: boolean
  withoutPaddingX?: boolean
}

export const ContentBody = ({
  children,
  className = "",
  withoutPaddinBottom = false,
  withoutPaddingTop = false,
  withoutPaddingX = false,
  ...rest
}: Props) => {
  const classes = classNames(
    {
      [SystemConstant.PaddingBottom]: !withoutPaddinBottom,
      [SystemConstant.PaddingTop]: !withoutPaddingTop,
      [SystemConstant.PaddingX]: !withoutPaddingX,
    },
    "w-full bg-white",
    className,
  )
  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  )
}
