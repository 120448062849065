import { isNil } from "lodash"
import { useEffect, useState } from "react"
import SuspenseFallbackDefault from "../../../../../SuspenseFallback/Default"
import FetcherWordPressPlugin from "@/Plugin/Fetcher"

interface Props {
  item: {
    slug: string
  }
}

export function PluginChangelogData({ item }: Props) {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (isNil(data)) {
      return
    }

    setLoading(false)
  }, [data])

  useEffect(() => {
    const fetchData = async () => {
      const result =
        await FetcherWordPressPlugin.getPluginRepositoryInformation({
          slug: item.slug,
        })

      setData(result)
    }

    fetchData()
  }, [])

  if (loading) {
    return <SuspenseFallbackDefault />
  }

  if (!loading && (isNil(data) || isNil(data.sections))) {
    return (
      <div className="text-center">
        We could not find the changelog of this plugin. It is possible that this
        is a premium plugin.
      </div>
    )
  }

  return (
    <>
      <div
        className="prose"
        dangerouslySetInnerHTML={{ __html: data.sections.changelog }}
      />
    </>
  )
}
