import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders"
import { getPrivateApiOneProjectSecurityPath } from "@/Project/api/routes-security-private"

interface Props {
  projectId: string | number
}

export const scanProjectVulnerabilities = async (data: Props) => {
  const headers = await getFetchPrivateHeaders()
  const result = await fetch(
    getPrivateApiOneProjectSecurityPath({
      projectId: data.projectId,
    }),
    {
      method: "POST",
      headers: headers,
    },
  )

  return await result.json()
}
