import { PluginActionComponents } from "./Action"
import { PluginsBulkAction } from "./BulkActions"
import { PluginChangelog } from "./Changelog"
import { PluginsEmptyState } from "./EmptyState"
import { FetchPlugins } from "./Fetch"
import { PluginsFilters } from "./Filters"
import { PluginsModal } from "./Modal"
import { PluginImage } from "./PluginImage"
import { Requirements } from "./Requirements"
import { StarRating } from "./StarRating"
import { PluginsTable } from "./Table"
import { TableRowProcessing } from "./Table/components/TableRowProcessing"

export const WordPressPluginComponents = {
  Action: PluginActionComponents,
  BulkActions: PluginsBulkAction,
  Changelog: PluginChangelog,
  EmptyState: PluginsEmptyState,
  Fetch: FetchPlugins,
  Filter: PluginsFilters,
  Modal: PluginsModal,
  PluginImage: PluginImage,
  StarRating: StarRating,
  Requirements: Requirements,
  Table: PluginsTable,
  TableRowProcessing: TableRowProcessing,
}
