import { SvgProps } from "@design-system/Icon/types"

export const Email = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M5.00011 6C4.73489 6 4.48054 6.10536 4.293 6.29289C4.10546 6.48043 4.00011 6.73478 4.00011 7V17C4.00011 17.2652 4.10546 17.5196 4.293 17.7071C4.48054 17.8946 4.73489 18 5.00011 18H19.0001C19.2653 18 19.5197 17.8946 19.7072 17.7071C19.8948 17.5196 20.0001 17.2652 20.0001 17V7C20.0001 6.73478 19.8948 6.48043 19.7072 6.29289C19.5197 6.10536 19.2653 6 19.0001 6H5.00011ZM2.87879 4.87868C3.4414 4.31607 4.20446 4 5.00011 4H19.0001C19.7958 4 20.5588 4.31607 21.1214 4.87868C21.684 5.44129 22.0001 6.20435 22.0001 7V17C22.0001 17.7957 21.684 18.5587 21.1214 19.1213C20.5588 19.6839 19.7958 20 19.0001 20H5.00011C4.20446 20 3.44139 19.6839 2.87879 19.1213C2.31618 18.5587 2.00011 17.7956 2.00011 17V7C2.00011 6.20435 2.31618 5.44129 2.87879 4.87868Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M2.16806 6.4453C2.47441 5.98577 3.09528 5.8616 3.55481 6.16795L12.0001 11.7981L20.4454 6.16795C20.9049 5.8616 21.5258 5.98577 21.8322 6.4453C22.1385 6.90483 22.0143 7.5257 21.5548 7.83205L12.5548 13.8321C12.2189 14.056 11.7813 14.056 11.4454 13.8321L2.44541 7.83205C1.98588 7.5257 1.8617 6.90483 2.16806 6.4453Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
