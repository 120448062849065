import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders"
import { getPrivateApiOneProjectBackupSettingsPath } from "@/Project/api/routes-backup-private"
import { BackupScheduleInputs } from "@components/Forms/Projects/Backups/Schedule/types/form-types"
import { BackupSettingsInputs } from "@components/Forms/Projects/Backups/Settings/types/form-types"

interface BackupAllInputs extends BackupScheduleInputs, BackupSettingsInputs {}

export const upsertBackupSettings = async (
  projectId: number,
  {
    data,
    type,
  }: {
    data: BackupSettingsInputs | BackupScheduleInputs | BackupAllInputs
    type: "settings" | "schedule" | "all"
  },
) => {
  const headers = await getFetchPrivateHeaders()

  try {
    const result = await fetch(
      getPrivateApiOneProjectBackupSettingsPath({ projectId }),
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          data: data,
          type: type,
        }),
      },
    )

    return await result.json()
  } catch (error) {
    return {
      code: "fetch_error",
    }
  }
}
