import { SvgProps } from "@design-system/Icon/types"

export const At = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M8.46446 8.46443C9.40215 7.52675 10.6739 6.99997 12 6.99997C13.3261 6.99997 14.5979 7.52675 15.5355 8.46443C16.4732 9.40212 17 10.6739 17 12C17 13.3261 16.4732 14.5978 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46446 15.5355C7.52678 14.5978 7 13.3261 7 12C7 10.6739 7.52678 9.40212 8.46446 8.46443ZM12 8.99997C11.2043 8.99997 10.4413 9.31604 9.87868 9.87865C9.31607 10.4413 9 11.2043 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2043 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2043 14.6839 10.4413 14.1213 9.87865C13.5587 9.31604 12.7956 8.99997 12 8.99997Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M8.61493 2.57877C10.6368 1.85139 12.8393 1.79464 14.896 2.41692C16.9527 3.0392 18.7542 4.30741 20.0338 6.03369C21.3133 7.75997 22.0028 9.85248 22 12.0013L21 12H22V12.0013V13.5C22 14.4282 21.6312 15.3185 20.9749 15.9748C20.3185 16.6312 19.4283 17 18.5 17C17.5717 17 16.6815 16.6312 16.0251 15.9748C15.3687 15.3185 15 14.4282 15 13.5V12C15 11.4477 15.4477 11 16 11C16.5523 11 17 11.4477 17 12V13.5C17 13.8978 17.158 14.2793 17.4393 14.5606C17.7206 14.8419 18.1022 15 18.5 15C18.8978 15 19.2794 14.8419 19.5607 14.5606C19.842 14.2793 20 13.8978 20 13.5V11.9987C20.0022 10.2797 19.4506 8.60565 18.427 7.22463C17.4034 5.84361 15.9621 4.82904 14.3168 4.33122C12.6714 3.83339 10.9095 3.87879 9.29194 4.46069C7.67441 5.0426 6.28733 6.13005 5.33618 7.56195C4.38504 8.99386 3.92042 10.6941 4.01116 12.4107C4.10189 14.1273 4.74316 15.769 5.83996 17.0927C6.93676 18.4164 8.43075 19.3515 10.1006 19.7597C11.7705 20.1678 13.5274 20.0272 15.1111 19.3587C15.6199 19.1439 16.2065 19.3823 16.4213 19.8911C16.6361 20.3999 16.3977 20.9865 15.8889 21.2013C13.9093 22.0369 11.7131 22.2126 9.62577 21.7025C7.53843 21.1923 5.67095 20.0233 4.29995 18.3688C2.92895 16.7142 2.12737 14.662 2.01395 12.5162C1.90052 10.3705 2.4813 8.24522 3.67023 6.45534C4.85916 4.66546 6.59301 3.30614 8.61493 2.57877Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
