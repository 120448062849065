import { TableProvider } from "@design-system/Table/contexts/TableContext"
import {
  TableChildrenProp,
  TableColumn,
  TableKey,
  TableRow,
  TableRowProcess,
} from "@design-system/Table/types"
import { TableKeys } from "@design-system/Table/components/Keys"
import { ReactNode } from "react"

interface Props<Row, ChildrenRow = {}> {
  children: TableChildrenProp<Row>
  columns: Array<TableColumn>
  rows: Array<TableRow<Row, ChildrenRow>>
  rowsProcessing?: Set<TableRowProcess>
  bulkActions?: ReactNode
  withChecked?: boolean
  withCheckedChildren?: boolean
  withExpandCloseAll?: boolean
  withExpanded?: boolean
  isStacked?: boolean
  withFormContext?: boolean
  columnsClassname?: string
  expandedRows?: Set<TableKey>
}

export const TableList = <Row, ChildrenRow = {}>({
  children,
  columns,
  columnsClassname = "",
  rows,
  rowsProcessing,
  bulkActions = null,
  withChecked = false,
  withCheckedChildren = false,
  withExpanded = false,
  withExpandCloseAll = false,
  isStacked = false,
  withFormContext = false,
  expandedRows = new Set(),
}: Props<Row, ChildrenRow>) => {
  return (
    <TableProvider
      withChecked={withChecked}
      withCheckedChildren={withCheckedChildren}
      withExpanded={withExpanded}
      withExpandCloseAll={withExpandCloseAll}
      isStacked={isStacked}
      withFormContext={withFormContext}
      expandedRows={expandedRows}
    >
      <TableKeys
        rows={rows}
        rowsProcessing={rowsProcessing}
        columns={columns}
        columnsClassname={columnsClassname}
      >
        {children}
      </TableKeys>
      {bulkActions}
    </TableProvider>
  )
}
