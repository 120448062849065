"use client"

import * as RadioGroupPrimitive from "@radix-ui/react-radio-group"
import * as React from "react"

import { SystemSvgTypes } from "@design-system/Icon/types"
import { cn } from "@helpers/utils"
import { cva, type VariantProps } from "class-variance-authority"
import { System } from ".."
import { RadioGroupItemLabel } from "./RadioGroup"

const radioGroupItemLabeledVariants = cva(
  "flex gap-2 rounded-full cursor-pointer items-center border border-main-grey-200 hover:border-primary-lighter data-[state=checked]:border-primary-base data-[state=checked]:bg-primary-lightest transition-all bg-main-white group ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-base focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
  {
    variants: {
      size: {
        default: "pl-6 pr-4 py-3",
        sm: "px-4 py-2 pr-2",
      },
    },
    defaultVariants: {
      size: "default",
    },
  },
)

interface RadioGroupItemLabeledProps
  extends React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>,
    VariantProps<typeof radioGroupItemLabeledVariants> {
  children?: React.ReactNode
  icon?: SystemSvgTypes
}

const RadioGroupItemLabeled = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  RadioGroupItemLabeledProps
>(({ icon, children, className, size = "default", ...props }, ref) => {
  const Icon = System.Svg[icon]
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(radioGroupItemLabeledVariants({ size, className }))}
      {...props}
    >
      {icon ? <Icon size={21} /> : null}
      <RadioGroupItemLabel htmlFor={props.id} size={size}>
        {children}
      </RadioGroupItemLabel>
      <div
        className={cn(
          "aspect-square rounded-full border border-main-grey-300 text-primary-base group-hover:border-primary-lighter group-data-[state=checked]:border-primary-base",
          {
            "size-5": size === "default",
            "size-4": size === "sm",
          },
        )}
      >
        <RadioGroupPrimitive.Indicator className="flex size-full items-center justify-center rounded-full">
          <span className="flex size-full items-center justify-center rounded-full bg-current">
            <System.Svg.Check
              size={size === "default" ? 12 : 9}
              className="text-white"
            />
          </span>
        </RadioGroupPrimitive.Indicator>
      </div>
    </RadioGroupPrimitive.Item>
  )
})
RadioGroupItemLabeled.displayName = "RadioGroupItemLabeled"

export { RadioGroupItemLabeled }
