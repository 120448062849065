import { PropsWithChildren, Reducer, createContext, useReducer } from "react"
import { genericStepReducer } from "./reducers/genericStepReducer"
import {
  ActionGenericStep,
  ContextGenericStep,
  StateGenericStep,
} from "./types"

export const GenericStepContext = createContext<ContextGenericStep>(null)

interface Props extends PropsWithChildren {}

const initialState: StateGenericStep = { steps: [] }

export const GenericStepProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer<
    Reducer<StateGenericStep, ActionGenericStep>
  >(genericStepReducer, initialState)

  return (
    <GenericStepContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </GenericStepContext.Provider>
  )
}

export default {
  Context: GenericStepContext,
  Provider: GenericStepProvider,
}
