import classNames from "classnames"

const Container = ({ children, className = {} }) => {
  return (
    <div
      className={classNames(
        className,
        "mx-auto max-w-screen-2xl px-4 2xl:max-w-[85%]",
      )}
    >
      {children}
    </div>
  )
}

export default Container
