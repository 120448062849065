"use client"

import { cn } from "@helpers/utils"
import * as CheckboxPrimitive from "@radix-ui/react-checkbox"
import * as React from "react"
import { System } from ".."

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      "group peer size-4 shrink-0 rounded-sm border border-main-grey-300 ring-offset-main-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-base focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:border-primary-lighter data-[state=indeterminate]:border-primary-lighter data-[state=checked]:bg-primary-base data-[state=indeterminate]:bg-primary-lightest data-[state=checked]:text-main-white",
      className,
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator className="relative">
      <System.Svg.Check className="absolute left-1/2 top-1/2 size-3 -translate-x-1/2 -translate-y-1/2 transform group-data-[state=indeterminate]:hidden" />
      <System.Svg.Minus className="absolute left-1/2 top-1/2 size-3 -translate-x-1/2 -translate-y-1/2 transform group-data-[state=checked]:hidden" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
))
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
