import { PropsWithChildren } from "react"
import { System } from "@design-system/index"
import classNames from "classnames"

interface Props extends PropsWithChildren {
  className?: string
  isError?: boolean
}

export const FormDescription = ({ isError, className, children }: Props) => {
  const classes = classNames(
    {
      "text-gray-600": !isError,
      "text-red-600": isError,
    },
    className,
  )

  return (
    <System.Text className={classes} size="small">
      {children}
    </System.Text>
  )
}
