import React, { useState } from "react"
import { System } from "@design-system/index"
import { useGenericModal } from "@design-system/Context/GenericModal/hooks/useGenericModal"
import { ProcessComponents } from "@components/Processes"
import { RequestCacheClearFormInputs } from "@components/Processes/components/Form/types"

interface Props {
  onConfirm: (props: RequestCacheClearFormInputs) => void
  pluginName: string
}

export const ModalDeletePlugin = ({ onConfirm, pluginName }: Props) => {
  const { closeModal } = useGenericModal()
  const [loading, setLoading] = useState(false)

  return (
    <>
      <div className="text-center">
        <System.Title className="mb-2">Are you sure ?</System.Title>
        <System.Text>
          We are about to delete <System.Strong>{pluginName}</System.Strong>{" "}
          plugin on your site.
        </System.Text>
      </div>
      <ProcessComponents.Form.ResquestCacheClear.Provider>
        <ProcessComponents.Form.ResquestCacheClear.Form
          onSubmit={(values) => {
            setLoading(true)
            onConfirm(values)
          }}
        />
        <div className="mt-8 flex items-center gap-2">
          <System.Button.Outlined
            onClick={closeModal}
            className="w-full justify-center"
          >
            Cancel
          </System.Button.Outlined>
          <System.Form.Submit
            form="request-cache-clear"
            className="w-full justify-center"
            loading={loading}
          >
            Delete Plugin
          </System.Form.Submit>
        </div>
      </ProcessComponents.Form.ResquestCacheClear.Provider>
    </>
  )
}
