import { LinkProps, LinkSize } from "../Link"
import { ReactNode } from "react"
import classNames from "classnames"
import { System } from "@design-system/index"

interface Props extends LinkProps {
  className?: string
  children: ReactNode
  size?: LinkSize
}

export const ExternalLink = ({
  children,
  className = "",
  href = "",
  size = "small",
  ...rest
}: Props) => {
  const iconClasses = classNames("inline-block", {
    "w-3 h-3": size === "small" || size === "xsmall",
    "w-4 h-4": size === "medium",
  })

  const linkClasses = classNames("inline-flex items-center gap-1", className)

  return (
    <System.Link
      href={href}
      size={size}
      target="_blank"
      rel="noopener noreferrer"
      className={linkClasses}
      {...rest}
    >
      {children}
      <System.Svg.External className={iconClasses} />
    </System.Link>
  )
}
