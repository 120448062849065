import React from "react"
import useProjectPlugins from "@hooks/projects/plugins/useProjectPlugins"
import ProjectPluginsTableModel from "@/Core/Models/Projects/Plugins/ProjectPluginsTableModel"

interface Props {
  children: (props: { plugins: ProjectPluginsTableModel[] }) => React.ReactNode
  projectId: number
  isImmutable?: boolean
}

export function FromOneProject({
  children,
  projectId,
  isImmutable = false,
}: Props) {
  const { data } = useProjectPlugins({
    projectId: projectId,
    isImmutable: isImmutable,
  })

  return (
    <>
      {children({
        plugins: data,
      })}
    </>
  )
}
