import { BulkActionsAllPugins } from "./components/AllPlugins"
import { IntallPluginFromRepository } from "./components/IntallPluginFromRepository"
import { BulkSitesSelectorIntallPluginFromRepositoryByStep } from "./components/BulkSitesSelectorIntallPluginFromRepositoryByStep"

export const PluginsBulkAction = {
  IntallPluginFromRepository: IntallPluginFromRepository,
  BulkSitesSelectorIntallPluginFromRepositoryByStep:
    BulkSitesSelectorIntallPluginFromRepositoryByStep,
  AllPlugins: BulkActionsAllPugins,
}
