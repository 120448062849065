import { DataRedisManagePlugins } from "@/Manager/Processes/types/DataRedisManagePlugins"
import { DataRedisManageThemes } from "@/Manager/Processes/types/DataRedisManageThemes"
import { DataRedisManageWordPressCore } from "@/Manager/Processes/types/DataRedisManageWordPressCore"
import { createContext } from "react"

export interface ProcessPendingAndLastFinishedContextType {
  pending: Array<
    | DataRedisManagePlugins
    | DataRedisManageThemes
    | DataRedisManageWordPressCore
  >
  finished: Array<
    | DataRedisManagePlugins
    | DataRedisManageThemes
    | DataRedisManageWordPressCore
  >
  mutate: () => void
}

export const ProcessPendingAndLastFinishedContext =
  createContext<ProcessPendingAndLastFinishedContextType>(null)
