import classNames from "classnames"
import { isEmpty } from "lodash"
import { useState } from "react"
import { getFaviconByUrl } from "@helpers/getFaviconByUrl"

interface Props {
  url: string
  className?: string
  classNameContainer?: string
}

export const SiteFavicon = ({
  url,
  className = "",
  classNameContainer = "",
  ...rest
}: Props) => {
  const [imageUrl, setImageUrl] = useState(getFaviconByUrl({ url }))

  const onErrorImage = () => {
    setImageUrl("")
  }

  const classes = classNames(
    "object-cover",
    {
      "min-w-7 h-7 w-7": !className.includes("h-") && !className.includes("w-"),
    },
    className,
  )

  return (
    <figure
      className={classNames(
        "overflow-hidden rounded-full bg-white p-1",
        classNameContainer,
      )}
      {...rest}
    >
      {!isEmpty(imageUrl) && (
        <img className={classes} src={imageUrl} onError={onErrorImage} />
      )}
    </figure>
  )
}
