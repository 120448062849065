import React, { useMemo } from "react"
import { ProcessTasksType } from "@prisma/client"
import useNotifications from "@hooks/notifications/useNotifications"
import { System } from "@design-system/index"
import { useTable } from "@design-system/Table/hooks/useTable"
import { IBulkAction } from "@design-system/Table/components/BulkActions/List"
import { find, isEmpty, isNil, memoize } from "lodash"
import {
  PluginGroup,
  ProjectPluginInstance,
} from "@components/Plugin/components/Table/AllPlugins"
import { PluginsAction } from "@components/Plugin/actions"
import { useGenericModal } from "@design-system/Context/GenericModal/hooks/useGenericModal"
import { WordPressPluginComponents } from "@components/Plugin/components"
import {
  RequestCacheClearFormInputs,
  UpdatePluginFormInputs,
} from "@components/Processes/components/Form/types"
import { useBulkPlugins } from "@design-system/New/BulkTable/BulkTablePlugins/useBulkPluginsStore"

interface Props {
  plugins?: PluginGroup[]
}

export const getAllProjects = memoize(
  ({ plugins }: Props): ProjectPluginInstance[] => {
    if (!plugins) {
      return []
    }
    const projects: ProjectPluginInstance[] = []
    for (const plugin of plugins) {
      for (const item of plugin.children) {
        projects.push(item)
      }
    }
    return projects
  },
)

export function BulkActionsAllPugins({ plugins }: Props) {
  const { openModal, closeModal } = useGenericModal()

  const { handleNotifySuccess, handleNotifyError } = useNotifications()
  const { selectedPluginInstances, deselectAllPluginInstances } =
    useBulkPlugins()

  const checkedRows = useMemo(() => {
    const selectedPluginInstancesIds = []
    for (const [key, value] of Object.entries(selectedPluginInstances)) {
      if (value === true) {
        selectedPluginInstancesIds.push(key)
      }
    }
    return new Set(selectedPluginInstancesIds)
  }, [selectedPluginInstances])

  if (checkedRows.size === 0) {
    return null
  }

  const projects = getAllProjects({ plugins })

  const handleConfirmBulkAction = async ({
    advancedSafeUpdateValidation,
    needCacheClear,
    updateType,
    type,
  }: UpdatePluginFormInputs & {
    type: ProcessTasksType
  }) => {
    try {
      const data = Array.from(checkedRows)
        .map((keyItem) => {
          const item = projects.find((plugin) => {
            return plugin.id === keyItem
          })

          if (isNil(item)) {
            return
          }

          // Prevent plugin no need to be activated
          if (
            type === ProcessTasksType.ACTIVATE_PLUGIN &&
            !item.pluginUpdateData.canBeActivated()
          ) {
            return
          }

          // Prevent plugin no need to be deactivated
          if (
            type === ProcessTasksType.DEACTIVATE_PLUGIN &&
            !item.pluginUpdateData.canBeDeactivated()
          ) {
            return
          }

          // Prevent plugin no need to be updated
          if (
            type === ProcessTasksType.UPDATE_PLUGIN &&
            !item.pluginUpdateData.canBeSelectedForUpdate()
          ) {
            return
          }

          // Prevent plugin can't be deleted
          if (
            type === ProcessTasksType.DELETE_PLUGIN &&
            !item.pluginUpdateData.canBeDeleted()
          ) {
            return
          }

          const itemPlugin = find(plugins, { id: item.key_plugin })

          return {
            type: type,
            entities: {
              id: item.id,
              is_active: item.is_active,
              plugin: item.key_plugin,
              name: isNil(itemPlugin) ? item.key_plugin : itemPlugin.name,
              old_version: item.version,
              version: item.new_version,
              update_type: updateType,
              advanced_safe_update_validation: advancedSafeUpdateValidation,
            },
            projectId: item.projectId,
          }
        })
        .filter((item) => !isNil(item))

      const projectsClearCache: Array<{ projectId: number }> = needCacheClear
        ? data.reduce((acc, item) => {
            if (find(acc, { projectId: item.projectId })) {
              return acc
            }

            acc.push({
              projectId: item.projectId,
            })
            return acc
          }, [])
        : []

      const { code } = await PluginsAction.bulkTask({
        data: data,
        projectsClearCache: projectsClearCache,
      })

      if (code === "success") {
        deselectAllPluginInstances()
        handleNotifySuccess({
          title: "We have started the process",
          message: <>We will inform you when it’s done.</>,
        })
      } else {
        handleNotifyError({
          title: "Oups",
          message: <>We were unable to start the process task.</>,
        })
      }
    } catch (error) {
      handleNotifyError({
        title: "Oups",
        message: <>We were unable to start the process task.</>,
      })
    }

    closeModal()
  }

  const ACTIONS = [
    {
      label: (
        <>
          <System.Svg.Update className="h-4 w-4 opacity-50" />
          Update
        </>
      ),
      isDisabled() {
        const data = Array.from(checkedRows)
          .map((keyItem) => {
            const item = projects.find(
              (projectPlugin) => projectPlugin.id === keyItem,
            )

            if (isNil(item)) {
              return
            }

            if (!item.pluginUpdateData.canBeSelectedForUpdate()) {
              return
            }

            if (!item.can_update) {
              return
            }

            return item
          })
          .filter((item) => !isNil(item))

        return isEmpty(data)
      },
      key: ProcessTasksType.UPDATE_PLUGIN,
      onClick: async () => {
        openModal({
          size: "md",
          component: (
            <WordPressPluginComponents.Modal.UpdatePlugin
              isBulkUpdate
              onConfirm={(props) => {
                handleConfirmBulkAction({
                  ...props,
                  type: ProcessTasksType.UPDATE_PLUGIN,
                })
              }}
            />
          ),
        })
      },
    },
    {
      label: (
        <>
          <System.Svg.Plugin className="h-4 w-4 opacity-50" />
          Activate
        </>
      ),
      isDisabled() {
        const data = Array.from(checkedRows)
          .map((keyItem) => {
            const item = projects.find(
              (projectPlugin) => projectPlugin.id === keyItem,
            )

            if (isNil(item)) {
              return
            }

            if (!item.pluginUpdateData.canBeActivated()) {
              return
            }

            return item
          })
          .filter((item) => !isNil(item))

        return isEmpty(data)
      },
      key: ProcessTasksType.ACTIVATE_PLUGIN,
      onClick: async () => {
        openModal({
          component: (
            <WordPressPluginComponents.Modal.BulkActivatePlugin
              onConfirm={(props) => {
                handleConfirmBulkAction({
                  ...props,
                  type: ProcessTasksType.ACTIVATE_PLUGIN,
                })
              }}
            />
          ),
        })
      },
    },
    {
      label: (
        <>
          <System.Svg.PlugOff className="h-4 w-4 opacity-50" />
          Deactivate
        </>
      ),
      isDisabled() {
        const data = Array.from(checkedRows)
          .map((keyItem) => {
            const item = projects.find(
              (projectPlugin) => projectPlugin.id === keyItem,
            )
            if (isNil(item)) {
              return
            }

            if (!item.pluginUpdateData.canBeDeactivated()) {
              return
            }

            return item
          })
          .filter((item) => !isNil(item))

        return isEmpty(data)
      },
      key: ProcessTasksType.DEACTIVATE_PLUGIN,
      onClick: async () => {
        openModal({
          component: (
            <WordPressPluginComponents.Modal.BulkDeactivatePlugin
              onConfirm={(props) => {
                handleConfirmBulkAction({
                  ...props,
                  type: ProcessTasksType.DEACTIVATE_PLUGIN,
                })
              }}
            />
          ),
        })
      },
    },
    {
      key: ProcessTasksType.DELETE_PLUGIN,
      label: (
        <>
          <System.Svg.Trash className="h-4 w-4 opacity-50" />
          Delete
        </>
      ),
      isDisabled() {
        const data = Array.from(checkedRows)
          .map((keyItem) => {
            const item = projects.find(
              (projectPlugin) => projectPlugin.id === keyItem,
            )
            if (isNil(item)) {
              return
            }

            if (!item.pluginUpdateData.canBeDeleted()) {
              return
            }

            return item
          })
          .filter((item) => !isNil(item))

        return isEmpty(data)
      },
      onClick: () => {
        openModal({
          component: (
            <WordPressPluginComponents.Modal.BulkDeletePlugin
              onConfirm={(props) => {
                handleConfirmBulkAction({
                  ...props,
                  type: ProcessTasksType.DELETE_PLUGIN,
                })
              }}
            />
          ),
        })
      },
    },
  ] as IBulkAction[]

  return (
    <>
      <System.Table.BulkActions.List
        actions={ACTIONS}
        isShowing={checkedRows.size > 0}
        size="large"
      />
    </>
  )
}
