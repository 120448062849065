import { SvgProps } from "@design-system/Icon/types"

export const Bulk = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M9.99992 11C9.99992 10.4477 10.4476 10 10.9999 10H12.9999C13.5522 10 13.9999 10.4477 13.9999 11V13C13.9999 13.5523 13.5522 14 12.9999 14H10.9999C10.4476 14 9.99992 13.5523 9.99992 13V11Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M4.86595 13.768C5.34423 13.4919 5.95583 13.6557 6.23198 14.134L7.23198 15.866C7.36459 16.0957 7.40053 16.3687 7.33189 16.6248C7.26325 16.881 7.09565 17.0994 6.86597 17.2321L5.13397 18.2321C4.65568 18.5082 4.04409 18.3443 3.76794 17.866L2.76794 16.134C2.63533 15.9044 2.59939 15.6314 2.66803 15.3752C2.73667 15.119 2.90426 14.9006 3.13394 14.768L4.86595 13.768Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M9.99992 19C9.99992 18.4477 10.4476 18 10.9999 18H12.9999C13.5522 18 13.9999 18.4477 13.9999 19V21C13.9999 21.5523 13.5522 22 12.9999 22H10.9999C10.4476 22 9.99992 21.5523 9.99992 21V19Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M18.3751 13.6681C18.6313 13.5995 18.9043 13.6354 19.134 13.768L20.866 14.768C21.3443 15.0442 21.5081 15.6558 21.232 16.134L20.232 17.866C20.0994 18.0957 19.8809 18.2633 19.6248 18.332C19.3686 18.4006 19.0956 18.3647 18.8659 18.2321L17.1339 17.2321C16.6557 16.9559 16.4918 16.3443 16.7679 15.866L17.7679 14.134C17.9005 13.9043 18.119 13.7367 18.3751 13.6681Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M19.6248 5.6681C19.8809 5.73674 20.0994 5.90434 20.232 6.13402L21.232 7.86602C21.5081 8.34431 21.3443 8.9559 20.866 9.23205L19.134 10.2321C18.9043 10.3647 18.6313 10.4006 18.3751 10.332C18.119 10.2633 17.9005 10.0957 17.7679 9.86604L16.7679 8.13404C16.4918 7.65576 16.6557 7.04416 17.1339 6.76801L18.8659 5.76801C19.0956 5.6354 19.3686 5.59946 19.6248 5.6681Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M9.99992 3C9.99992 2.44772 10.4476 2 10.9999 2H12.9999C13.5522 2 13.9999 2.44772 13.9999 3V5C13.9999 5.55228 13.5522 6 12.9999 6H10.9999C10.4476 6 9.99992 5.55228 9.99992 5V3Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M4.37515 5.6681C4.63133 5.59946 4.90428 5.6354 5.13397 5.76801L6.86597 6.76801C7.34426 7.04416 7.50812 7.65576 7.23198 8.13404L6.23198 9.86604C6.09936 10.0957 5.88094 10.2633 5.62476 10.332C5.36858 10.4006 5.09563 10.3647 4.86595 10.2321L3.13395 9.23205C2.65566 8.9559 2.49179 8.34431 2.76794 7.86602L3.76794 6.13402C3.90055 5.90434 4.11897 5.73674 4.37515 5.6681Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
