import { createSchema } from "morphism"

import {
  FromAPIWordPressPluginProjectItem,
  FromAPIWordPressPluginWithProjectApiData,
} from "../types/plugin-from-api"
import ProjectPluginUpdateData from "./ProjectPluginUpdateData"
import {
  JSONWordPressPluginProjectItem,
  JSONWordPressPluginWithProjectApiData,
} from "../types/plugin-models"
import { createEntityIdFromPluginProcess } from "@components/Plugin/helpers/createEntityIdFromPluginProcess"
import { isNil } from "lodash"

export const SchemaJSONProjectPluginApiDataToWordPressPluginWithProjectModel =
  createSchema<
    WordPressPluginWithProjectModel,
    FromAPIWordPressPluginWithProjectApiData
  >({
    id: "id",
    key: "key",
    name: "name",
    new_version: "new_version",
    requires_php: "requires_php",
    is_premium: "is_premium",
    slug: "slug",
    tested: "tested",
    children: (plugin) => {
      return plugin.projects.map(
        (project: FromAPIWordPressPluginProjectItem) => {
          const pluginUpdateData = new ProjectPluginUpdateData(project)

          pluginUpdateData.hasNeedUpdate = function () {
            return this.need_update
          }

          pluginUpdateData.canBeSelectedForUpdate = function () {
            if (this.new_version === 0 || isNil(this.new_version)) {
              return false
            }

            return (
              this.need_update && this.can_update && !this.isIgnoredUpdate()
            )
          }

          return {
            ...project,
            id: createEntityIdFromPluginProcess({
              slug: plugin.key,
              projectId: project.id,
            }),
            pluginUpdateData,
          }
        },
      )
    },
  })

class WordPressPluginWithProjectModel
  implements JSONWordPressPluginWithProjectApiData
{
  id: string
  key: string
  slug: string
  name: string
  new_version: 0 | string
  requires_php: string
  is_premium?: boolean
  tested: 0 | string
  children: JSONWordPressPluginProjectItem[]
}

export default WordPressPluginWithProjectModel
