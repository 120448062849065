export const SuspenseFallbackSingle = () => {
  return (
    <>
      <div className="w-full rounded-md border border-gray-300 bg-white p-4">
        <div className="flex animate-pulse space-x-4">
          <div className="h-12 w-12 rounded-full bg-gray-300"></div>
          <div className="flex-1 space-y-2 border-r border-gray-200 py-1 pr-4">
            <div className="h-4 w-3/4 rounded bg-gray-300"></div>
            <div className="h-4 rounded bg-gray-300"></div>
            <div>
              <div className="mt-8 h-4 rounded bg-gray-300"></div>
              <div className="mt-2 h-4 rounded bg-gray-300"></div>
            </div>
          </div>

          <div className="flex-1 space-y-2 py-1 pr-4">
            <div className="h-4 w-3/4 rounded bg-gray-300"></div>
            <div className="h-4 rounded bg-gray-300"></div>
            <div>
              <div className="mt-8 h-4 rounded bg-gray-300"></div>
              <div className="mt-2 h-4 rounded bg-gray-300"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
