import { SvgProps } from "@design-system/Icon/types"

export const Github = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#a)">
        <path
          fill="#24292F"
          fillRule="evenodd"
          d="M11.9642 0.244995C5.34833 0.244995 0 5.63275 0 12.2981C0 17.6261 3.42686 22.1362 8.18082 23.7324C8.77518 23.8524 8.9929 23.4731 8.9929 23.154C8.9929 22.8745 8.97331 21.9167 8.97331 20.9188C5.64514 21.6373 4.95208 19.482 4.95208 19.482C4.41722 18.0851 3.62473 17.7261 3.62473 17.7261C2.53543 16.9877 3.70408 16.9877 3.70408 16.9877C4.91241 17.0675 5.54645 18.2249 5.54645 18.2249C6.61592 20.0607 8.33926 19.542 9.03257 19.2226C9.13151 18.4443 9.44865 17.9056 9.78539 17.6063C7.13094 17.3269 4.33812 16.2892 4.33812 11.6594C4.33812 10.3424 4.81322 9.26483 5.56604 8.42679C5.44727 8.12753 5.03118 6.89006 5.68506 5.23381C5.68506 5.23381 6.69527 4.91446 8.97306 6.47104C9.94827 6.2072 10.954 6.07298 11.9642 6.07185C12.9744 6.07185 14.0042 6.21169 14.9552 6.47104C17.2332 4.91446 18.2434 5.23381 18.2434 5.23381C18.8973 6.89006 18.481 8.12753 18.3622 8.42679C19.1349 9.26483 19.5904 10.3424 19.5904 11.6594C19.5904 16.2892 16.7976 17.3068 14.1233 17.6063C14.5592 17.9854 14.9353 18.7037 14.9353 19.8412C14.9353 21.4576 14.9158 22.7548 14.9158 23.1537C14.9158 23.4731 15.1337 23.8524 15.7278 23.7327C20.4818 22.1359 23.9087 17.6261 23.9087 12.2981C23.9282 5.63275 18.5603 0.244995 11.9642 0.244995Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
