import { SystemConstant } from "@design-system/constants"
import { System } from "@design-system/index"
import classNames from "classnames"
import React from "react"

interface Props {
  children?: (props: {
    loading: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    closeModal: () => void
  }) => React.ReactNode
  title?: string
  description?: string | React.ReactNode
  onConfirm: () => void
  textConfirm?: string
  textCancel?: string
}

export const ModalWithButtons = ({
  children,
  title,
  description,
  textConfirm = "Confirm",
  textCancel = "Cancel",
  onConfirm,
}: Props) => {
  return (
    <System.Modal.Default title={title} description={description}>
      {({ closeModal, loading, setLoading }) => (
        <div>
          {children &&
            children({
              loading,
              setLoading,
              closeModal,
            })}
          <div
            className={classNames(
              "flex items-center justify-end gap-4",
              SystemConstant.MarginTop,
            )}
          >
            <System.Button.Outlined
              onClick={closeModal}
              className="justify-center"
            >
              {textCancel}
            </System.Button.Outlined>
            <System.Button.Primary
              onClick={() => {
                setLoading(true)
                onConfirm()
              }}
              className="justify-center"
              loading={loading}
            >
              {textConfirm}
            </System.Button.Primary>
          </div>
        </div>
      )}
    </System.Modal.Default>
  )
}
