import { SvgProps } from "@design-system/Icon/types"

export const AtNot = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeWidth={strokeWidth}
        d="M9.17418 9.17C8.42256 9.91871 7.99914 10.9353 7.99708 11.9962C7.99501 13.0571 8.41448 14.0754 9.16318 14.827C9.91189 15.5786 10.9285 16.002 11.9894 16.0041C13.0503 16.0062 14.0686 15.5867 14.8202 14.838M16.0002 12C16.0002 10.9391 15.5788 9.92172 14.8286 9.17157C14.0785 8.42143 13.061 8 12.0002 8"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        strokeWidth={strokeWidth}
        d="M19.6947 15.697C20.0898 15.482 20.4195 15.1645 20.6491 14.7778C20.8788 14.3912 20.9999 13.9497 20.9997 13.5V12C21.0018 10.462 20.6098 8.94913 19.8609 7.60574C19.1121 6.26235 18.0315 5.13327 16.7222 4.32626C15.413 3.51924 13.9187 3.0612 12.3821 2.99586C10.8455 2.93052 9.31778 3.26005 7.94474 3.953M5.62274 5.636C4.14986 7.1137 3.23613 9.05722 3.03783 11.1342C2.83953 13.2111 3.36898 15.2924 4.53563 17.0221C5.70228 18.7518 7.43364 20.0225 9.43359 20.6168C11.4336 21.211 13.5778 21.092 15.4997 20.28"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        strokeWidth={strokeWidth}
        d="M3 3L21 21"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
