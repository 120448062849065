import { get, isNil } from "lodash"
import { morphism } from "morphism"
import useSWR from "swr"
import useSWRImmutable from "swr/immutable"
import ProjectPluginsTableModel, {
  SchemaProjectPluginsModel,
} from "../../../src/Core/Models/Projects/Plugins/ProjectPluginsTableModel"
import { SourceProjectPluginType } from "../../../src/Core/Models/Plugins/types"
import { getProjectPluginsBasePath } from "../../../src/FetchApi/Project/Plugins"

interface Props {
  projectId: number
  isImmutable?: boolean
}

function useProjectPlugins({ projectId, isImmutable = false }: Props) {
  const callback = isImmutable ? useSWRImmutable : useSWR

  const path = getProjectPluginsBasePath({
    projectId: projectId,
  })

  const { data, ...rest } = callback(path, {
    suspense: true,
  })

  if (isNil(data)) {
    return {
      data: [] as ProjectPluginsTableModel[],
      ...rest,
    }
  }

  const items = get(data, "items", []) as Array<SourceProjectPluginType>

  return {
    data: morphism(SchemaProjectPluginsModel, items, ProjectPluginsTableModel),
    ...rest,
  }
}

export default useProjectPlugins
