import { getProjectPath } from ".."

/**
 * /api/projects/[projectId]/plugins
 */
export const getProjectPluginsBasePath = ({
  projectId,
}: {
  projectId: number | string
}) => `${getProjectPath(projectId)}/plugins`

const FetchApiProjectPlugins = {}

export default FetchApiProjectPlugins
