import { TableRowProcess } from "@design-system/Table/types"
import { System } from "@design-system/index"

interface Props {
  itemInProcess: TableRowProcess
  item: {
    id: number | string
  }
  projectId?: number
  texts: {
    success?: string
    failed?: string
    inProgress?: string
  }
}

export const RowProcessing = ({
  itemInProcess: { task },
  item,
  projectId,
  texts,
}: Props) => {
  const isTaskFinished = task.finished_at
  const isTaskSuccessful = task.code === "success"

  const isInProgress = !isTaskFinished

  const isFinished = isTaskFinished && isTaskSuccessful
  const isFailed = isTaskFinished && !isTaskSuccessful
  return (
    <>
      {isInProgress && (
        <System.Table.CellInProgress
          id={item.id}
          task={task}
          text={texts.inProgress}
        />
      )}

      {isFinished && (
        <System.Table.CellProcessFinished id={item.id} text={texts.success} />
      )}

      {isFailed && (
        <System.Table.CellProcessFailed
          id={item.id}
          text={texts.failed}
          projectId={projectId}
          withOneClickAdmin
        />
      )}
    </>
  )
}
