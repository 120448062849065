import { SvgProps } from "@design-system/Icon/types"

export const Slack = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <path
            fill="#E01E5A"
            d="M5.1401 15.1181C5.1401 16.4977 4.02514 17.6126 2.64561 17.6126C1.26608 17.6126 0.151123 16.4977 0.151123 15.1181C0.151123 13.7386 1.26608 12.6237 2.64561 12.6237H5.1401V15.1181ZM6.38734 15.1181C6.38734 13.7386 7.50231 12.6237 8.88183 12.6237C10.2614 12.6237 11.3763 13.7386 11.3763 15.1181V21.3544C11.3763 22.7339 10.2614 23.8489 8.88183 23.8489C7.50231 23.8489 6.38734 22.7339 6.38734 21.3544V15.1181Z"
          />
          <path
            fill="#36C5F0"
            d="M8.88193 5.10238C7.5024 5.10238 6.38744 3.98742 6.38744 2.60789C6.38744 1.22836 7.5024 0.113403 8.88193 0.113403C10.2615 0.113403 11.3764 1.22836 11.3764 2.60789V5.10238H8.88193ZM8.88193 6.36852C10.2615 6.36852 11.3764 7.48348 11.3764 8.86301C11.3764 10.2425 10.2615 11.3575 8.88193 11.3575H2.62681C1.24728 11.3575 0.132324 10.2425 0.132324 8.86301C0.132324 7.48348 1.24728 6.36852 2.62681 6.36852H8.88193Z"
          />
          <path
            fill="#2EB67D"
            d="M18.8788 8.86301C18.8788 7.48348 19.9938 6.36852 21.3733 6.36852C22.7528 6.36852 23.8678 7.48348 23.8678 8.86301C23.8678 10.2425 22.7528 11.3575 21.3733 11.3575H18.8788V8.86301ZM17.6316 8.86301C17.6316 10.2425 16.5166 11.3575 15.1371 11.3575C13.7575 11.3575 12.6426 10.2425 12.6426 8.86301V2.60789C12.6426 1.22836 13.7575 0.113403 15.1371 0.113403C16.5166 0.113403 17.6316 1.22836 17.6316 2.60789V8.86301Z"
          />
          <path
            fill="#ECB22E"
            d="M15.1371 18.8599C16.5166 18.8599 17.6316 19.9748 17.6316 21.3544C17.6316 22.7339 16.5166 23.8489 15.1371 23.8489C13.7575 23.8489 12.6426 22.7339 12.6426 21.3544V18.8599H15.1371ZM15.1371 17.6126C13.7575 17.6126 12.6426 16.4977 12.6426 15.1181C12.6426 13.7386 13.7575 12.6237 15.1371 12.6237H21.3922C22.7717 12.6237 23.8867 13.7386 23.8867 15.1181C23.8867 16.4977 22.7717 17.6126 21.3922 17.6126H15.1371Z"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <rect width={size} height={size} fill="white" />
        </clipPath>
        <clipPath id="b">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
