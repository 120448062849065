export const resetPassword = async (params) => {
  const result = await fetch(`/api/customers/reset-password`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(params),
  })

  return await result.json()
}
