import { SvgProps } from "@design-system/Icon/types"

export const Tools = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          strokeWidth={strokeWidth}
          fill="currentColor"
          fillRule="evenodd"
          d="M8.37878 3.96444L10.7072 6.29287C10.8947 6.48041 11.0001 6.73476 11.0001 6.99998V9.99998C11.0001 10.5523 10.5524 11 10.0001 11H7.0001C6.73488 11 6.48053 10.8946 6.29299 10.7071L3.96457 8.37866C3.89135 8.89116 3.89773 9.41496 3.98632 9.93127C4.16117 10.9504 4.64765 11.8902 5.37878 12.6213C6.10991 13.3524 7.04972 13.8389 8.0688 14.0138C9.08788 14.1886 10.1361 14.0432 11.0691 13.5976C11.4515 13.415 11.9075 13.4932 12.2072 13.7929L18.2072 19.7929C18.4175 20.0032 18.7027 20.1213 19.0001 20.1213C19.2975 20.1213 19.5827 20.0032 19.793 19.7929C20.0033 19.5826 20.1214 19.2974 20.1214 19C20.1214 18.7026 20.0033 18.4174 19.793 18.2071L13.793 12.2071C13.4933 11.9074 13.4151 11.4514 13.5977 11.069C14.0433 10.136 14.1887 9.08776 14.0139 8.06868C13.839 7.0496 13.3525 6.10979 12.6214 5.37866C11.8903 4.64753 10.9505 4.16104 9.93139 3.9862C9.41508 3.89761 8.89128 3.89123 8.37878 3.96444ZM6.06913 2.59761C7.37537 1.97376 8.84288 1.77022 10.2696 2.015C11.6963 2.25979 13.0121 2.94086 14.0356 3.96444C15.0592 4.98802 15.7403 6.30376 15.9851 7.73048C16.189 8.9193 16.0817 10.1364 15.6793 11.265L21.2072 16.7929C21.7926 17.3782 22.1214 18.1721 22.1214 19C22.1214 19.8278 21.7926 20.6217 21.2072 21.2071C20.6218 21.7924 19.8279 22.1213 19.0001 22.1213C18.1723 22.1213 17.3784 21.7924 16.793 21.2071L11.2651 15.6792C10.1366 16.0816 8.91942 16.1889 7.7306 15.985C6.30388 15.7402 4.98815 15.0591 3.96456 14.0355C2.94098 13.0119 2.25991 11.6962 2.01512 10.2695C1.77034 8.84276 1.97388 7.37525 2.59773 6.06901C2.73764 5.77607 3.01104 5.56927 3.331 5.51438C3.65096 5.45948 3.97765 5.56332 4.20721 5.79287L7.41431 8.99998H9.0001V7.41419L5.79299 4.20708C5.56344 3.97753 5.4596 3.65083 5.5145 3.33087C5.5694 3.01092 5.77619 2.73751 6.06913 2.59761Z"
          clipRule="evenodd"
        />
      </g>
    </svg>
  )
}
