import GenericApplication from "./GenericApplication"
import GenericFilteringItemsWithTabs from "./GenericFilteringItemsWithTabs"
import GenericModal from "./GenericModal"
import GenericPagination from "./GenericPagination"
import GenericSidebar from "./GenericSidebar"
import GenericStep from "./GenericStep"

export const Context = {
  GenericApplication: GenericApplication,
  GenericFilteringItemsWithTabs: GenericFilteringItemsWithTabs,
  GenericPagination: GenericPagination,
  GenericSidebar: GenericSidebar,
  GenericModal: GenericModal,
  GenericStep: GenericStep,
}
