import { System } from "@design-system/index"
import { getThemeFavicon } from "@/Helpers/getThemeFavicon"
interface Props {
  slug: string
  className?: string
  version?: string
}

export const ThemeFavicon = ({
  slug,
  version = "latest",
  className = "",
}: Props) => {
  return (
    <System.Badges.FromWordPressFavicon
      imageUrl={getThemeFavicon({ theme: slug, version: version })}
      className={className}
      defaultIcon={<System.Svg.Theme className="h-5 w-5" />}
    />
  )
}
