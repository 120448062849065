import { SvgProps } from "@design-system/Icon/types"

export const Bell = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M12.0001 4C11.7349 4 11.4805 4.10536 11.293 4.29289C11.1054 4.48043 11.0001 4.73478 11.0001 5C11.0001 5.38669 10.7771 5.73873 10.4276 5.90403C9.44321 6.36948 8.60408 7.09402 8.00009 8C7.40028 8.89972 7.05484 9.94469 7.00009 11.0244V14C7.00009 14.0402 6.99767 14.0803 6.99285 14.1202C6.91259 14.7832 6.70049 15.4222 6.37031 16H17.6299C17.2997 15.4222 17.0876 14.7832 17.0073 14.1202C17.0025 14.0803 17.0001 14.0402 17.0001 14V11.0244C16.9453 9.9447 16.5999 8.89972 16.0001 8C15.3961 7.09402 14.557 6.36948 13.5726 5.90403C13.223 5.73873 13.0001 5.38669 13.0001 5C13.0001 4.73478 12.8947 4.48043 12.7072 4.29289C12.5197 4.10536 12.2653 4 12.0001 4ZM9.87877 2.87868C10.4414 2.31607 11.2044 2 12.0001 2C12.7957 2 13.5588 2.31607 14.1214 2.87868C14.5296 3.28688 14.808 3.80059 14.9301 4.35572C16.0261 4.97224 16.9637 5.83991 17.6642 6.8906C18.4695 8.09857 18.9309 9.50291 18.999 10.9531C18.9997 10.9687 19.0001 10.9844 19.0001 11V13.9354C19.0626 14.3815 19.2248 14.8082 19.475 15.1834C19.7355 15.5742 20.0841 15.8984 20.4928 16.1298C20.888 16.3536 21.0828 16.8156 20.9671 17.2548C20.8514 17.694 20.4542 18 20.0001 18H4.00009C3.54593 18 3.14881 17.694 3.03309 17.2548C2.91738 16.8156 3.11214 16.3536 3.50734 16.1298C3.91604 15.8984 4.2647 15.5742 4.52523 15.1834C4.7754 14.8082 4.93762 14.3815 5.00009 13.9353V11C5.00009 10.9844 5.00046 10.9687 5.00119 10.9531C5.06926 9.50291 5.53068 8.09857 6.33599 6.8906C7.03645 5.83991 7.97412 4.97224 9.07008 4.35572C9.19216 3.80059 9.47057 3.28688 9.87877 2.87868Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M9.00009 16C9.55238 16 10.0001 16.4477 10.0001 17V18C10.0001 18.5304 10.2108 19.0391 10.5859 19.4142C10.9609 19.7893 11.4697 20 12.0001 20C12.5305 20 13.0392 19.7893 13.4143 19.4142C13.7894 19.0391 14.0001 18.5304 14.0001 18V17C14.0001 16.4477 14.4478 16 15.0001 16C15.5524 16 16.0001 16.4477 16.0001 17V18C16.0001 19.0609 15.5787 20.0783 14.8285 20.8284C14.0784 21.5786 13.061 22 12.0001 22C10.9392 22 9.92181 21.5786 9.17166 20.8284C8.42152 20.0783 8.00009 19.0609 8.00009 18V17C8.00009 16.4477 8.44781 16 9.00009 16Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
