import { getPrivateApiOneProjectPath } from "./routes-private"

/**
 * @returns https://private-api.wp-umbrella.com/projects/:projectId/security
 */
export const getPrivateApiOneProjectSecurityPath = ({
  projectId,
}: {
  projectId: string | number
}) => {
  return `${getPrivateApiOneProjectPath({ projectId })}/security`
}
