import classNames from "classnames"
import { FC, InputHTMLAttributes } from "react"

interface Props {
  error?: boolean
  disabled?: boolean
  group?: boolean
  className?: string
}

export const getClassNameInput = (
  { error = false, disabled = false, group = false, className = "" }: Props = {
    error: false,
    disabled: false,
    group: false,
    className: "",
  },
) =>
  classNames(
    {
      "flex-grow block text-sm border-gray-300 rounded-md hover:border-indigo-200 hover:shadow-[0_0_0_1px] hover:shadow-indigo-200 transition":
        true,
      "rounded-none rounded-r-md": group,
      "bg-gray-100 cursor-not-allowed": disabled,
      "border-red-500 focus:border-red-500 focus:ring-red-500": error,
      "focus:ring-indigo-500 focus:border-indigo-500": !error,
    },
    className,
  )

const Input: FC<Props & InputHTMLAttributes<HTMLInputElement>> = ({
  error = false,
  disabled = false,
  group = false,
  className = "",
  ...rest
}) => (
  <input
    disabled={disabled}
    className={getClassNameInput({ error, disabled, group, className })}
    {...rest}
  />
)

export default Input
