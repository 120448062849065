import { PluginGroup } from "@components/Plugin/components/Table/AllPlugins"
import { create } from "zustand"

interface BulkPluginsState {
  pluginGroups: PluginGroup[]
  setPluginGroups: (pluginGroups: PluginGroup[]) => void

  pluginGroupsExpanded: Record<string, boolean>
  expandPluginGroup: (groupId: string) => void
  collapsePluginGroup: (groupId: string) => void
  getIsPluginGroupExpanded: (groupId: string) => boolean
  togglePluginGroupExpanded: (groupId: string) => void
  expandAllPluginGroups: () => void
  toggleExpandAllPluginGroups: () => void
  collapseAllPluginGroups: () => void
  getIsAllPluginGroupsExpanded: () => boolean

  selectedPluginInstances: Record<string, boolean | undefined>
  toggleSelectedInstance: (plugin: string) => void
  selectPluginInstance: (plugin: string) => void
  deselectPluginInstance: (plugin: string) => void
  selectAllPluginInstances: () => void
  deselectAllPluginInstances: () => void
  getIsAllPluginInstancesSelected: () => boolean
  getIsSomePluginInstancesSelected: () => boolean
  toggleAllPluginInstancesSelected: () => void
  getIsPluginInstanceSelected: (plugin: string) => boolean | "indeterminate"

  getHasPluginGroupUpdateAvailable: (groupId: string) => boolean

  selectPluginGroup: (groupId: string) => void
  deselectPluginGroup: (groupId: string) => void
  getIsPluginGroupSelected: (groupId: string) => boolean | "indeterminate"
  togglePluginGroupSelected: (groupId: string) => void
}

export const useBulkPluginsStore = create<BulkPluginsState>()((set, get) => ({
  pluginGroups: [],
  setPluginGroups: (pluginGroups) => set({ pluginGroups }),

  pluginGroupsExpanded: {},
  expandPluginGroup: (groupId) => {
    set((state) => ({
      pluginGroupsExpanded: {
        ...state.pluginGroupsExpanded,
        [groupId]: true,
      },
    }))
  },
  collapsePluginGroup: (groupId) => {
    set((state) => ({
      pluginGroupsExpanded: {
        ...state.pluginGroupsExpanded,
        [groupId]: false,
      },
    }))
  },
  expandAllPluginGroups: () =>
    set((state) => {
      const expanded = state.pluginGroups.reduce(
        (acc, group) => {
          acc[group.id] = true
          return acc
        },
        {} as Record<string, boolean>,
      )
      return { pluginGroupsExpanded: expanded }
    }),
  getIsPluginGroupExpanded: (groupId) => {
    const state = get()
    if (!state.pluginGroupsExpanded[groupId]) {
      return false
    }
    return state.pluginGroupsExpanded[groupId]
  },
  togglePluginGroupExpanded: (groupId) => {
    const state = get()
    if (state.getIsPluginGroupExpanded(groupId)) {
      state.collapsePluginGroup(groupId)
    } else {
      state.expandPluginGroup(groupId)
    }
  },
  toggleExpandAllPluginGroups: () => {
    const state = get()
    if (state.getIsAllPluginGroupsExpanded()) {
      state.collapseAllPluginGroups()
    } else {
      state.expandAllPluginGroups()
    }
  },
  collapseAllPluginGroups: () =>
    set(() => ({
      pluginGroupsExpanded: {},
    })),
  getIsAllPluginGroupsExpanded: () => {
    const state = get()
    return state.pluginGroups.every(
      (group) => state.pluginGroupsExpanded[group.id],
    )
  },

  selectedPluginInstances: {},
  selectPluginInstance: (id) => {
    set((state) => {
      const selected = { ...state.selectedPluginInstances }
      selected[id] = true
      return { selectedPluginInstances: selected }
    })
  },
  deselectPluginInstance: (id) => {
    set((state) => {
      const selected = { ...state.selectedPluginInstances }
      delete selected[id]
      return { selectedPluginInstances: selected }
    })
  },
  getIsPluginInstanceSelected: (id: string) => {
    const state = get()
    if (!state.selectedPluginInstances[id]) {
      return false
    }
    return state.selectedPluginInstances[id]
  },
  toggleSelectedInstance: (id: string) => {
    const state = get()
    if (state.selectedPluginInstances[id]) {
      state.deselectPluginInstance(id)
    } else {
      state.selectPluginInstance(id)
    }
  },
  selectAllPluginInstances: () =>
    set((state) => {
      const ids: string[] = []
      for (const group of state.pluginGroups) {
        for (const plugin of group.children) {
          ids.push(plugin.id)
        }
      }
      const selected = ids.reduce(
        (acc, id) => {
          acc[id] = true
          return acc
        },
        {} as Record<string, boolean>,
      )

      return { selectedPluginInstances: selected }
    }),
  deselectAllPluginInstances: () =>
    set(() => ({
      selectedPluginInstances: {},
    })),
  getIsAllPluginInstancesSelected: () => {
    const state = get()
    return state.pluginGroups.every((group) =>
      group.children.every(
        (plugin) => state.selectedPluginInstances[plugin.id],
      ),
    )
  },
  getIsSomePluginInstancesSelected: () => {
    const state = get()
    return Object.values(state.selectedPluginInstances).some(
      (selected) => selected === true,
    )
  },
  toggleAllPluginInstancesSelected: () => {
    const state = get()
    if (state.getIsAllPluginInstancesSelected()) {
      state.deselectAllPluginInstances()
    } else {
      state.selectAllPluginInstances()
    }
  },

  getHasPluginGroupUpdateAvailable: (groupId) => {
    const state = get()
    return (
      state.pluginGroups
        .find((group) => group.id === groupId)
        ?.children.some((plugin) => plugin.pluginUpdateData.hasNeedUpdate()) ??
      false
    )
  },

  selectPluginGroup: (groupId) => {
    set((state) => {
      const selected = { ...state.selectedPluginInstances }
      state.pluginGroups
        .find((group) => group.id === groupId)
        ?.children.forEach((plugin) => {
          selected[plugin.id] = true
        })
      return { selectedPluginInstances: selected }
    })
  },
  deselectPluginGroup: (groupId) => {
    set((state) => {
      const selected = { ...state.selectedPluginInstances }
      state.pluginGroups
        .find((group) => group.id === groupId)
        ?.children.forEach((plugin) => {
          selected[plugin.id] = false
        })
      return { selectedPluginInstances: selected }
    })
  },
  getIsPluginGroupSelected: (groupId) => {
    const state = get()
    const group = state.pluginGroups.find((group) => group.id === groupId)
    if (!group) {
      return false
    }
    const selectedPlugins = group.children.filter(
      (plugin) => state.selectedPluginInstances[plugin.id],
    )
    if (selectedPlugins.length === 0) {
      return false
    }
    if (selectedPlugins.length === group.children.length) {
      return true
    }
    return "indeterminate"
  },
  togglePluginGroupSelected: (groupId) => {
    const state = get()
    if (state.getIsPluginGroupSelected(groupId) === true) {
      state.deselectPluginGroup(groupId)
    } else {
      state.selectPluginGroup(groupId)
    }
  },
}))

export const useBulkPlugins = () => {
  const pluginGroups = useBulkPluginsStore((state) => state.pluginGroups)
  const setPluginGroups = useBulkPluginsStore((state) => state.setPluginGroups)

  const pluginGroupsExpanded = useBulkPluginsStore(
    (state) => state.pluginGroupsExpanded,
  )
  const toggleExpandAllPluginGroups = useBulkPluginsStore(
    (state) => state.toggleExpandAllPluginGroups,
  )
  const collapseAllPluginGroups = useBulkPluginsStore(
    (state) => state.collapseAllPluginGroups,
  )

  const selectedPluginInstances = useBulkPluginsStore(
    (state) => state.selectedPluginInstances,
  )
  const toggleSelectedInstance = useBulkPluginsStore(
    (state) => state.toggleSelectedInstance,
  )
  const getIsAllPluginGroupsExpanded = useBulkPluginsStore(
    (state) => state.getIsAllPluginGroupsExpanded,
  )

  const getIsAllPluginInstancesSelected = useBulkPluginsStore(
    (state) => state.getIsAllPluginInstancesSelected,
  )
  const getIsSomePluginInstancesSelected = useBulkPluginsStore(
    (state) => state.getIsSomePluginInstancesSelected,
  )
  const toggleAllPluginInstancesSelected = useBulkPluginsStore(
    (state) => state.toggleAllPluginInstancesSelected,
  )
  const getIsPluginInstanceSelected = useBulkPluginsStore(
    (state) => state.getIsPluginInstanceSelected,
  )

  const getHasPluginGroupUpdateAvailable = useBulkPluginsStore(
    (state) => state.getHasPluginGroupUpdateAvailable,
  )

  const togglePluginGroupExpanded = useBulkPluginsStore(
    (state) => state.togglePluginGroupExpanded,
  )
  const getIsPluginGroupExpanded = useBulkPluginsStore(
    (state) => state.getIsPluginGroupExpanded,
  )
  const togglePluginGroupSelected = useBulkPluginsStore(
    (state) => state.togglePluginGroupSelected,
  )
  const getIsPluginGroupSelected = useBulkPluginsStore(
    (state) => state.getIsPluginGroupSelected,
  )

  const selectAllPluginInstances = useBulkPluginsStore(
    (state) => state.selectAllPluginInstances,
  )
  const deselectAllPluginInstances = useBulkPluginsStore(
    (state) => state.deselectAllPluginInstances,
  )

  return {
    pluginGroups,
    setPluginGroups,

    pluginGroupsExpanded,

    collapseAllPluginGroups,
    toggleExpandAllPluginGroups,

    selectedPluginInstances,
    toggleSelectedInstance,

    toggleAllPluginInstancesSelected,
    deselectAllPluginInstances,
    selectAllPluginInstances,

    getIsAllPluginGroupsExpanded,
    getIsAllPluginInstancesSelected,
    getIsSomePluginInstancesSelected,
    getIsPluginInstanceSelected,

    getHasPluginGroupUpdateAvailable,

    togglePluginGroupExpanded,

    getIsPluginGroupExpanded,

    togglePluginGroupSelected,
    getIsPluginGroupSelected,
  }
}
