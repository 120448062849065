import { System } from "@design-system/index"
import React from "react"
import { TableColumn } from "@design-system/Table/types"
import { SVG } from "@svg/index"
import { SiteSelectorWithPluginInstall } from ".."

interface Props {
  item: SiteSelectorWithPluginInstall
  columns?: Array<TableColumn>
}

export const SitesSelectorWithPluginsInstalledRow = ({
  item,
  columns,
}: Props) => {
  let checkboxIsDisabled = false
  if (
    item.install_requirements.is_installed ||
    item.install_requirements.php_version_outdated ||
    item.install_requirements.wordpress_version_outdated
  ) {
    checkboxIsDisabled = true
  }

  return (
    <System.Table.Row key={`row_${item.id}`} item={item}>
      <div>
        <System.Table.InnerRowWithActions
          rowKey={item.id}
          item={item}
          columns={columns}
          isDisabled={checkboxIsDisabled}
        >
          <label
            htmlFor={`checkbox_${item.id}`}
            className="inline-block cursor-pointer truncate"
          >
            <div className="flex items-center gap-4">
              <System.Site.Preview site={item} />
            </div>
          </label>

          {item.install_requirements.is_installed && (
            <>
              <div className="flex flex-1 flex-wrap items-center">
                <div className="inline-flex flex-none items-center gap-2 rounded-full">
                  <div className="flex items-center justify-center rounded-full border border-green-100 bg-green-50 p-2">
                    <SVG.Check className="h-4 w-4 text-green-600" />
                  </div>
                  <System.Text className="text-sm">
                    Plugin already installed 👍
                  </System.Text>
                </div>
              </div>
            </>
          )}

          {item.install_requirements.php_version_outdated &&
            !item.install_requirements.is_installed &&
            !item.install_requirements.wordpress_version_outdated && (
              <>
                <div className="flex flex-1 flex-wrap items-center">
                  <div className="inline-flex flex-none items-center gap-2 rounded-full">
                    <div className="flex items-center justify-center rounded-full border border-yellow-100 bg-yellow-50 p-2">
                      <System.Svg.AlertTriangle className="h-4 w-4 text-yellow-600" />
                    </div>
                    <System.Text className="text-sm">
                      Your PHP version is outdated to install this plugin
                    </System.Text>
                  </div>
                </div>
              </>
            )}

          {item.install_requirements.wordpress_version_outdated &&
            !item.install_requirements.is_installed &&
            !item.install_requirements.php_version_outdated && (
              <>
                <div className="flex flex-1 flex-wrap items-center">
                  <div className="inline-flex flex-none items-center gap-2 rounded-full">
                    <div className="flex items-center justify-center rounded-full border border-yellow-100 bg-yellow-50 p-2">
                      <System.Svg.AlertTriangle className="h-4 w-4 text-yellow-600" />
                    </div>
                    <System.Text className="text-sm">
                      Your WordPress version is outdated to install this plugin
                    </System.Text>
                  </div>
                </div>
              </>
            )}

          {item.install_requirements.wordpress_version_outdated &&
            item.install_requirements.php_version_outdated &&
            !item.install_requirements.is_installed && (
              <>
                <div className="flex-3 flex flex-wrap items-center">
                  <div className="inline-flex flex-none items-center gap-2 rounded-full">
                    <div className="flex items-center justify-center rounded-full border border-yellow-100 bg-yellow-50 p-2">
                      <System.Svg.AlertTriangle className="h-4 w-4 text-yellow-600" />
                    </div>
                    <System.Text className="text-sm">
                      Your WordPress and PHP versions are outdated to install
                      this plugin
                    </System.Text>
                  </div>
                </div>
              </>
            )}
        </System.Table.InnerRowWithActions>
      </div>
    </System.Table.Row>
  )
}
