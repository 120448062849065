import { PluginsAction } from "@components/Plugin/actions"
import { ResponseCreateProcessTaskUpdatePlugin } from "@components/Plugin/actions/createProcessTaskUpdatePlugin"
import { WordPressPluginComponents } from "@components/Plugin/components"
import { RollbackPluginFormInputs } from "@components/Processes/components/Form/types"
import { useGenericModal } from "@design-system/Context/GenericModal/hooks/useGenericModal"
import useNotifications from "@hooks/notifications/useNotifications"
import { isNil } from "lodash"

interface RollbackPluginProps {
  plugin: {
    id: string
    is_active: boolean
    key: string
    name: string
    slug: string
    version: string
  }
  projectId: number
  onSuccess?: (props: ResponseCreateProcessTaskUpdatePlugin) => void
}

export const useRollbackPlugin = () => {
  const { openModal, closeModal } = useGenericModal()
  const { handleNotifySuccess } = useNotifications()

  const rollbackPlugin = ({
    plugin,
    projectId,
    onSuccess,
  }: RollbackPluginProps) => {
    const onSubmit = async (values: RollbackPluginFormInputs) => {
      const response = await PluginsAction.rollbackPlugin({
        data: {
          entities: {
            id: plugin.id,
            is_active: plugin.is_active,
            plugin: plugin.key,
            name: plugin.name,
            must_be_activate: values.activatePlugin,
            rollback_version: values.version,
            version: plugin.version,
          },
          projectId: projectId,
        },
        projectsClearCache: values.needCacheClear ? [{ projectId }] : [],
      })

      closeModal()

      handleNotifySuccess({
        title: "We have started the update",
        message: <>We will inform you when it’s done.</>,
      })

      if (response.code === "success" && !isNil(onSuccess)) {
        onSuccess(response)
      }
    }

    openModal({
      component: (
        <WordPressPluginComponents.Modal.RollbackPlugin
          onConfirm={onSubmit}
          name={plugin.name}
          slug={plugin.slug}
        />
      ),
      size: "xl",
      withPadding: false,
    })
  }

  return { rollbackPlugin }
}
