import { signIn } from "./signIn"
import { startVerification } from "./startVerification"
import { magicLogin } from "./magicLogin"

const FetchApiAuthentification = {
  signIn,
  startVerification,
  magicLogin,
}

export default FetchApiAuthentification
