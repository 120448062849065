import System from "@ui/System"
import React from "react"

function convertToKPlusFormat(num: number): string {
  if (num >= 1000000) {
    // If the number is greater than or equal to 1 million, divide by 1 million and add "M+" suffix
    return (num / 1000000).toFixed(1) + "M+"
  } else if (num >= 1000) {
    // If the number is greater than or equal to 1 thousand, divide by 1 thousand and add "k+" suffix
    return (num / 1000).toFixed(1) + "k+"
  }

  // If the number is less than 1 thousand, return as is
  return num.toString()
}

interface Props {
  amount: number
}

export function ActiveInstalls({ amount }: Props) {
  return (
    <System.Text className="text-sm text-gray-500">
      {convertToKPlusFormat(amount)} active installs
    </System.Text>
  )
}
