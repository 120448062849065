import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "@helpers/utils"

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-full font-medium ring-offset-main-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-base focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        primary: "text-main-white bg-primary-base hover:bg-primary-darker",
        secondary:
          "text-destructive-foreground bg-main-grey-600 hover:bg-main-grey-500",
        outline:
          "text-main-darkest border border-main-grey-200 hover:bg-main-grey-200",
        ghost: "text-main-darkest hover:bg-main-grey-200",
        minimal: "text-primary-base hover:text-primary-darker",
      },
      size: {
        lg: "gap-4 px-7 py-4 text-body-md",
        default: "gap-2 px-5 py-3 text-body-base",
        sm: "gap-1 px-4 py-2 text-body-sm",
        minimal:
          "focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:underline",
        icon: "p-2",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "default",
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  },
)
Button.displayName = "Button"

export { Button, buttonVariants }
