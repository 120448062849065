import { System } from "@design-system/index"
import classNames from "classnames"
import { useState } from "react"

interface Props {
  className?: string
  placeholder?: string
  defaultValue?: string
  onClick: ({ value }: { value: string }) => void
}

export function SearchBox({
  defaultValue,
  className = "",
  placeholder = "",
  onClick,
}: Props) {
  const [search, setSearch] = useState(defaultValue)

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      onClick({
        value: search,
      })
    }
  }

  const classes = classNames(
    "relative flex rounded-md items-stretch focus-within:z-10",
    {
      "w-full": !className.includes("w-"),
    },
    className,
  )

  return (
    <div className={classes}>
      <System.Form.Input
        type="text"
        name="query"
        id="query"
        className="w-full"
        placeholder={placeholder}
        onChange={(e) => setSearch(e.target.value)}
        value={search}
        onKeyDown={handleKeyDown}
      />
      <System.Button.Outlined
        className="absolute right-0 top-0 -ml-px flex-none bg-transparent"
        size="large"
        onClick={() => {
          onClick({
            value: search,
          })
        }}
        iconOnly
      >
        <span className="sr-only">Search</span>
        <System.Svg.Search className="h-4 w-4" />
      </System.Button.Outlined>
    </div>
  )
}
