import { System } from ".."

interface ScoredReviewProps {
  score: number
  children?: React.ReactNode
}

export const ScoredReview = ({ score, children }: ScoredReviewProps) => {
  const roundedScore = Math.round(score)

  return (
    <div className="flex flex-col gap-4">
      <span className="text-body-lg font-semibold">{score}</span>

      <div className="flex gap-1">
        {Array.from({ length: roundedScore }, (_, i) => (
          <System.Svg.StarFilled
            size={16}
            key={i}
            className="text-primary-base"
          />
        ))}
      </div>

      {children ? (
        <div className="flex items-center gap-2 font-medium text-primary-darkest">
          {children}
        </div>
      ) : null}
    </div>
  )
}
