import {
  createContext,
  Fragment,
  ReactNode,
  Reducer,
  useEffect,
  useReducer,
  useRef,
} from "react"
import {
  ActionGenericModal,
  ContextGenericModal,
  StateGenericModal,
} from "./types"
import { genericModalReducer } from "./reducers/genericModalReducer"
import { Dialog, Transition } from "@headlessui/react"
import classNames from "classnames"
import { useGenericModal } from "./hooks/useGenericModal"

const initialState: StateGenericModal = {
  component: null,
  isOpen: false,
  size: "default",
  withPadding: true,
}

export const GenericModalContext = createContext<ContextGenericModal>(null)

interface Props {
  children: ReactNode
}

const GenericModalProviderWithContext = () => {
  const { state, closeModal } = useGenericModal()

  useEffect(() => {
    if (state.isOpen) {
      return
    }

    closeModal()
  }, [state.isOpen])

  const cancelButtonRef = useRef()
  return (
    <Transition.Root show={state.isOpen}>
      <Dialog
        as="div"
        className="relative z-[9999]"
        initialFocus={cancelButtonRef}
        open={state.isOpen}
        onClose={() => {
          closeModal()
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-[9999] w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  {
                    "w-full max-w-[90%]": state.size === "full",
                    "w-full max-w-[80%]": state.size === "xl",
                    "w-full max-w-[60%]": state.size === "lg",
                    "w-full max-w-[50%]": state.size === "md",
                    "min-w-[500px] max-w-[570px]": state.size === "default",
                    "min-w-[400px] max-w-[450px]": state.size === "sm",
                    "p-8": state.withPadding,
                    "overflow-hidden": state.hideOverflow,
                  },
                  "relative rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full",
                )}
              >
                {state.component}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export const GenericModalProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer<
    Reducer<StateGenericModal, ActionGenericModal>
  >(genericModalReducer, initialState)

  return (
    <GenericModalContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
      <GenericModalProviderWithContext />
    </GenericModalContext.Provider>
  )
}

export default {
  Context: GenericModalContext,
  Provider: GenericModalProvider,
}
