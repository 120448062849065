import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders"
import { getPrivateApiOneProjectPluginIgnoreUpdatePath } from "@/Project/api/routes-private"

export const toggleIgnorePluginUpdate = async ({
  projectId,
  pluginId,
}: {
  projectId: number
  pluginId: string
}) => {
  const headers = await getFetchPrivateHeaders()

  const result = await fetch(
    getPrivateApiOneProjectPluginIgnoreUpdatePath({ projectId, pluginId }),
    {
      method: "PUT",
      headers: headers,
    },
  )

  return await result.json()
}
