import { SvgProps } from "@design-system/Icon/types"

export const CompetitorOther = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.16699"
        y="0.5"
        width={size}
        height={size}
        rx="21.5"
        fill="currentColor"
      />
      <path
        strokeWidth={strokeWidth}
        d="M20.4518 16L28.6668 24.215L26.6128 26.269C26.0774 26.8259 25.4362 27.2704 24.7269 27.5765C24.0175 27.8827 23.2543 28.0442 22.4817 28.0518C21.7092 28.0594 20.9429 27.9128 20.2277 27.6207C19.5125 27.3285 18.8627 26.8967 18.3165 26.3504C17.7702 25.8041 17.3383 25.1543 17.0462 24.4391C16.754 23.7239 16.6074 22.9576 16.615 22.1851C16.6226 21.4126 16.7842 20.6493 17.0903 19.94C17.3964 19.2306 17.8409 18.5895 18.3978 18.054L20.4518 16Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        strokeWidth={strokeWidth}
        d="M14.667 30L18.167 26.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        strokeWidth={strokeWidth}
        d="M25.667 14L22.167 17.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        strokeWidth={strokeWidth}
        d="M30.667 19L27.167 22.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
