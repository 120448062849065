import { SvgProps } from "@design-system/Icon/types"

export const Gift = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M2.58579 7.58574C2.96086 7.21067 3.46957 6.99996 4 6.99996H20C20.5304 6.99996 21.0391 7.21067 21.4142 7.58574C21.7893 7.96082 22 8.46952 22 8.99996V11C22 11.5304 21.7893 12.0391 21.4142 12.4142C21.0391 12.7892 20.5304 13 20 13H4C3.46957 13 2.96086 12.7892 2.58579 12.4142C2.21071 12.0391 2 11.5304 2 11V8.99996C2 8.46952 2.21071 7.96081 2.58579 7.58574ZM20 8.99996H4V11H20V8.99996Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M12 6.99996C12.5523 6.99996 13 7.44767 13 7.99996V21C13 21.5522 12.5523 22 12 22C11.4477 22 11 21.5522 11 21V7.99996C11 7.44767 11.4477 6.99996 12 6.99996Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M5 11C5.55228 11 6 11.4477 6 12V19C6 19.2652 6.10536 19.5195 6.29289 19.7071C6.48043 19.8946 6.73478 20 7 20H17C17.2652 20 17.5196 19.8946 17.7071 19.7071C17.8946 19.5195 18 19.2652 18 19V12C18 11.4477 18.4477 11 19 11C19.5523 11 20 11.4477 20 12V19C20 19.7956 19.6839 20.5587 19.1213 21.1213C18.5587 21.6839 17.7957 22 17 22H7C6.20435 22 5.44129 21.6839 4.87868 21.1213C4.31607 20.5587 4 19.7956 4 19V12C4 11.4477 4.44772 11 5 11Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M9.46944 5.01208C8.80934 4.27863 8.12408 3.98923 7.51742 3.9998L7.5 3.99996C7.10218 3.99996 6.72064 4.15799 6.43934 4.4393C6.15804 4.7206 6 5.10213 6 5.49996C6 5.89778 6.15804 6.27931 6.43934 6.56062C6.72064 6.84192 7.10218 6.99996 7.5 6.99996C8.05228 6.99996 8.5 7.44767 8.5 7.99996C8.5 8.55224 8.05228 8.99996 7.5 8.99996C6.57174 8.99996 5.6815 8.63121 5.02513 7.97483C4.36875 7.31845 4 6.42821 4 5.49996C4 4.5717 4.36875 3.68146 5.02513 3.02508C5.67943 2.37078 6.56612 2.00229 7.4912 1.99997C8.81053 1.97987 10.0128 2.62608 10.956 3.67415C11.3497 4.11155 11.6987 4.61777 12 5.17652C12.3013 4.61777 12.6503 4.11155 13.044 3.67415C13.9872 2.62608 15.1895 1.97987 16.5088 1.99997C17.4339 2.00229 18.3206 2.37078 18.9749 3.02508C19.6313 3.68146 20 4.5717 20 5.49996C20 6.42821 19.6313 7.31845 18.9749 7.97483C18.3185 8.63121 17.4283 8.99996 16.5 8.99996C15.9477 8.99996 15.5 8.55224 15.5 7.99996C15.5 7.44767 15.9477 6.99996 16.5 6.99996C16.8978 6.99996 17.2794 6.84192 17.5607 6.56062C17.842 6.27931 18 5.89778 18 5.49996C18 5.10213 17.842 4.7206 17.5607 4.4393C17.2794 4.15799 16.8978 3.99996 16.5 3.99996L16.4826 3.9998C15.8759 3.98923 15.1907 4.27863 14.5306 5.01208C13.8654 5.75118 13.305 6.86255 12.9718 8.23578C12.863 8.68421 12.4614 8.99996 12 8.99996C11.5386 8.99996 11.137 8.68421 11.0282 8.23578C10.695 6.86255 10.1346 5.75118 9.46944 5.01208Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
