import classNames from "classnames"
import { ButtonDefault, ButtonOrLinkProps } from "@design-system/Button/Default"

export const ButtonDanger = ({
  children,
  className = "",
  disabled = false,
  ...rest
}: ButtonOrLinkProps) => {
  const classes = classNames(
    "border-transparent border transition",
    {
      "bg-red-600 hover:bg-red-700 text-white": !disabled,
      "bg-gray-200 border-gray-200": disabled,
    },
    className,
  )

  return (
    <ButtonDefault className={classes} disabled={disabled} {...rest}>
      {children}
    </ButtonDefault>
  )
}
