export default {
  production: false,
  base_url: "https://app-wp-umbrella-com-git-staging-wp-umbrella.vercel.app",
  api_url: "https://api-captain.wp-umbrella.com",
  private_api_url: "https://private-api-thor.wp-umbrella.com",
  api: {
    client_id: "development",
    client_secret: "development",
    scope: "application",
  },
  meilisearch_public_url: "https://search-loki.wp-umbrella.com",
  stripe: {
    webhooks: {
      invoices: "whsec_ypyiVJCQianmwwdO4o8BAmriP1CAJBz7",
    },
    hourly_backup: [
      {
        slug: "wpu_hourly_backup_1",
        id: "price_1LVXInIcL5h0Eadw9W8fAJeO",
        active: true,
        price: 2.49,
        name: "Add-on - Hourly Backup",
      },
    ],
    pay_as_you_go: [
      {
        slug: "wpu_monthly_payg_1",
        active: false,
        id: "price_1KExeDIcL5h0EadwC6oHnzKx",
        type: "month",
        price: 1.49,
        name: "Premium",
        limit_monitor: 1,
        limit_projects: 10000,
        max_regions: 16,
        limit_history: 60,
        limit_email_notifications: 10,
      },
      {
        slug: "wpu_monthly_payg_2",
        active: true,
        id: "price_1LFE2UIcL5h0EadwXDDHj9ZZ",
        type: "month",
        price: 1.99,
        name: "Premium",
        limit_monitor: 1,
        limit_projects: 10000,
        max_regions: 16,
        limit_history: 60,
        limit_email_notifications: 10,
      },
    ],
    plans: [
      {
        slug: "free",
        id: "free",
        type: "month",
        price: 0,
        limit_monitor: 1,
        limit_projects: 1,
        name: "Free",
        max_regions: 1,
        limit_history: 7,
        limit_email_notifications: 1,
      },
    ],
  },
}
