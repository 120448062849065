import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders"
import { getPrivateApiOneProjectThemeIgnoreUpdatePath } from "@/Project/api/routes-private"

export const toggleIgnoreThemeUpdate = async ({
  projectId,
  themeId,
}: {
  projectId: number
  themeId: string
}) => {
  const headers = await getFetchPrivateHeaders()

  const result = await fetch(
    getPrivateApiOneProjectThemeIgnoreUpdatePath({ projectId, themeId }),
    {
      method: "PUT",
      headers: headers,
    },
  )

  return await result.json()
}
