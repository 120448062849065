import { System } from "@design-system/index"
import React from "react"
import { UptimeTimelineContainer } from "../UptimeTimelineContainer"

export interface TimelineItemEmail {
  date: string
}

interface Props {
  data: TimelineItemEmail
}

export function UptimeTimelineEmail({ data }: Props) {
  const { date } = data
  return (
    <UptimeTimelineContainer>
      <span className="mx-1 flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-4 ring-white">
        <System.Svg.Email className="h-4 w-4 text-gray-900" />
      </span>
      <System.Text>
        <System.Strong>WP Umbrella</System.Strong> sent you an e-mail
      </System.Text>
      <System.Text className="whitespace-nowrap">{date}</System.Text>
    </UptimeTimelineContainer>
  )
}
