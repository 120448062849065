import useSWR from "swr"
import { get, isNil } from "lodash"
import { morphism } from "morphism"
import {
  SchemaWordPressRepositoryPluginModel,
  SourceWordPressRepositoryPlugin,
} from "@/Core/Models/Plugins/schemas"
import WordPressRepositoryPluginModel from "@/Core/Models/Plugins/model"
import qs from "query-string"

interface Props {
  slug: string
  options?: {
    suspense?: boolean
  }
}

export function useWordPressRepositoryPluginInformation({
  slug,
  options,
}: Props) {
  const path = qs.stringifyUrl({
    url: `/api/wordpress-plugin-repository/${slug}`,
  })

  const { data, ...rest } = useSWR<SourceWordPressRepositoryPlugin>(path, {
    suspense: get(options, "suspense", true),
  })

  if (isNil(data)) {
    return {
      data: {} as WordPressRepositoryPluginModel,
      ...rest,
    }
  }

  return {
    data: morphism(
      SchemaWordPressRepositoryPluginModel,
      data,
      WordPressRepositoryPluginModel,
    ),
    ...rest,
  }
}
