import { SvgProps } from "@design-system/Icon/types"

export const CopyPaste = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M10 9C9.73478 9 9.48043 9.10536 9.29289 9.29289C9.10536 9.48043 9 9.73478 9 10V18C9 18.2652 9.10536 18.5196 9.29289 18.7071C9.48043 18.8946 9.73478 19 10 19H18C18.2652 19 18.5196 18.8946 18.7071 18.7071C18.8946 18.5196 19 18.2652 19 18V10C19 9.73478 18.8946 9.48043 18.7071 9.29289C18.5196 9.10536 18.2652 9 18 9H10ZM7.87868 7.87868C8.44129 7.31607 9.20435 7 10 7H18C18.7957 7 19.5587 7.31607 20.1213 7.87868C20.6839 8.44129 21 9.20435 21 10V18C21 18.7957 20.6839 19.5587 20.1213 20.1213C19.5587 20.6839 18.7957 21 18 21H10C9.20435 21 8.44129 20.6839 7.87868 20.1213C7.31607 19.5587 7 18.7957 7 18V10C7 9.20435 7.31607 8.44129 7.87868 7.87868Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M3.87868 3.87868C4.44129 3.31607 5.20435 3 6 3H14C14.7956 3 15.5587 3.31607 16.1213 3.87868C16.6839 4.44129 17 5.20435 17 6V8C17 8.55228 16.5523 9 16 9C15.4477 9 15 8.55228 15 8V6C15 5.73478 14.8946 5.48043 14.7071 5.29289C14.5196 5.10536 14.2652 5 14 5H6C5.73478 5 5.48043 5.10536 5.29289 5.29289C5.10536 5.48043 5 5.73478 5 6V14C5 14.2652 5.10536 14.5196 5.29289 14.7071C5.48043 14.8946 5.73478 15 6 15H8C8.55228 15 9 15.4477 9 16C9 16.5523 8.55228 17 8 17H6C5.20435 17 4.44129 16.6839 3.87868 16.1213C3.31607 15.5587 3 14.7956 3 14V6C3 5.20435 3.31607 4.44129 3.87868 3.87868Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
