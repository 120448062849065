import { SvgProps } from "@design-system/Icon/types"

export const NotAt = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M11 7.99994C11 7.44766 11.4477 6.99994 12 6.99994C13.3261 6.99994 14.5978 7.52672 15.5355 8.46441C16.4732 9.40209 17 10.6739 17 11.9999C17 12.5522 16.5523 12.9999 16 12.9999C15.4477 12.9999 15 12.5522 15 11.9999C15 11.2043 14.6839 10.4412 14.1213 9.87862C13.5587 9.31601 12.7956 8.99994 12 8.99994C11.4477 8.99994 11 8.55223 11 7.99994ZM9.88246 8.46421C10.2722 8.85549 10.271 9.48866 9.87971 9.87842C9.31599 10.44 8.99843 11.2024 8.99688 11.9981C8.99533 12.7938 9.30993 13.5575 9.87146 14.1212C10.433 14.6849 11.1955 15.0025 11.9911 15.004C12.7868 15.0056 13.5505 14.691 14.1143 14.1295C14.5055 13.7397 15.1387 13.7409 15.5285 14.1322C15.9182 14.5235 15.917 15.1567 15.5257 15.5464C14.5862 16.4823 13.3134 17.0066 11.9873 17.004C10.6612 17.0015 9.39038 16.4722 8.4545 15.5327C7.51863 14.5931 6.9943 13.3203 6.99688 11.9942C6.99946 10.6681 7.52873 9.39734 8.46825 8.46146C8.85954 8.0717 9.4927 8.07293 9.88246 8.46421Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M7.49443 3.06019C9.02003 2.29024 10.7175 1.9241 12.4248 1.9967C14.1322 2.0693 15.7925 2.57823 17.2472 3.47492C18.7019 4.37161 19.9026 5.62613 20.7346 7.11879C21.5667 8.61144 22.0023 10.2924 22 12.0013L21 11.9999H22V12.0013V13.4995C22.0002 14.129 21.8306 14.7473 21.5092 15.2885C21.1876 15.8298 20.726 16.2743 20.173 16.5753C19.6879 16.8393 19.0806 16.66 18.8166 16.1749C18.5526 15.6898 18.7319 15.0826 19.217 14.8186C19.454 14.6896 19.6518 14.4991 19.7896 14.2671C19.9274 14.0351 20.0001 13.7702 20 13.5004V11.9986C20.0018 10.6315 19.6533 9.28669 18.9877 8.09257C18.3221 6.89844 17.3615 5.89482 16.1977 5.17747C15.034 4.46012 13.7058 4.05298 12.3399 3.9949C10.974 3.93681 9.61603 4.22973 8.39554 4.84569C7.90249 5.09452 7.30107 4.89655 7.05224 4.4035C6.8034 3.91045 7.00138 3.30903 7.49443 3.06019ZM6.32893 4.92768C6.7201 5.31757 6.72113 5.95073 6.33124 6.34189C5.02202 7.6554 4.20981 9.38298 4.03355 11.2291C3.85728 13.0753 4.3279 14.9254 5.36492 16.4629C6.40194 18.0004 7.94093 19.1299 9.71867 19.6581C11.4964 20.1864 13.4025 20.0805 15.1108 19.3588C15.6195 19.1438 16.2062 19.382 16.4211 19.8908C16.6361 20.3995 16.3979 20.9862 15.8892 21.2011C13.7537 22.1033 11.3712 22.2356 9.14901 21.5753C6.92683 20.915 5.0031 19.5031 3.70682 17.5812C2.41055 15.6593 1.82227 13.3468 2.0426 11.0391C2.26293 8.73135 3.27819 6.57188 4.91472 4.92999C5.30461 4.53883 5.93777 4.53779 6.32893 4.92768Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M2.29288 2.29283C2.6834 1.90231 3.31657 1.90231 3.70709 2.29283L21.7071 20.2928C22.0976 20.6834 22.0976 21.3165 21.7071 21.707C21.3166 22.0976 20.6834 22.0976 20.2929 21.707L2.29288 3.70705C1.90235 3.31652 1.90235 2.68336 2.29288 2.29283Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
