import { Combobox, Popover, Transition } from "@headlessui/react"
import { System } from "@design-system/index"
import classNames from "classnames"
import { isEmpty, isNil } from "lodash"
import React, { Fragment, useEffect, useState } from "react"

type Item = {
  label: string
  searchValue: string
  key: string | number | boolean
  onClick: ({ key }: { key: string | number | boolean }) => void
  renderBadge?: () => React.ReactNode
}

interface Props {
  items: Item[]
  label: string
  onRemove: ({ key }: { key: string | number | boolean }) => void
  onClear?: () => void
  index?: number
  itemsSelected?: (string | number | boolean)[]
  placeholder?: string
  position?: "left" | "right"
  icon: React.FC<React.SVGProps<SVGSVGElement>>
  renderNoResults?: ({ query }) => React.ReactNode
  buttonRef?: React.Ref<HTMLButtonElement>
}
export const MenuSelect = React.forwardRef(function MenuSelect(
  {
    items,
    label,
    onRemove,
    index = 1,
    itemsSelected,
    placeholder = "Search...",
    icon: Icon,
    position = "left",
    onClear,
    renderNoResults,
  }: Props,
  ref: React.Ref<HTMLButtonElement>,
) {
  const [query, setQuery] = useState("")

  useEffect(() => {
    return () => {
      setQuery("")
    }
  }, [])

  const filteredItems =
    isEmpty(query) || isNil(query)
      ? items
      : items.filter((item) => {
          return item.searchValue.toLowerCase().includes(query.toLowerCase())
        })

  return (
    <>
      <Popover
        className="relative"
        style={{
          zIndex: 1400 - index,
        }}
      >
        <div
          className={classNames(
            {
              "border-indigo-400 bg-indigo-100": !isEmpty(itemsSelected),
              "border-gray-300 bg-gray-100": isEmpty(itemsSelected),
            },
            "inline-flex items-center gap-2 rounded-full pl-3",
          )}
        >
          <Popover.Button ref={ref}>
            <div className="flex items-center gap-2">
              {Icon && <Icon className="h-4 w-4 opacity-50" />}
              {!isEmpty(itemsSelected) ? (
                <System.Text as="div">
                  <System.Strong>{label}</System.Strong>
                </System.Text>
              ) : (
                <System.Text as="div">{label}</System.Text>
              )}
            </div>
          </Popover.Button>
          <System.Button.Outlined iconOnly onClick={onClear}>
            <System.Svg.Cross className="h-4 w-4" />
          </System.Button.Outlined>
        </div>
        <div className="bg-white">
          <Transition
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            afterLeave={() => {
              setQuery("")
            }}
          >
            <Popover.Panel
              className={classNames(
                {
                  "right-0": position === "right",
                  "left-0": position === "left",
                },
                "absolute z-50 mt-3 w-screen max-w-sm",
              )}
            >
              {() => {
                return (
                  <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="relative bg-white">
                      <Combobox
                        onChange={() => {
                          setQuery("")
                        }}
                      >
                        <div className="px-3 py-3">
                          <System.Form.Input
                            type="search"
                            className="w-full"
                            autoFocus
                            placeholder={placeholder}
                            onChange={(event) => setQuery(event.target.value)}
                          />
                        </div>
                        {itemsSelected &&
                          itemsSelected.map((value) => {
                            const item = items.find(
                              (item) => item.key === value,
                            )
                            if (!item) {
                              return null
                            }

                            return (
                              <React.Fragment key={String(item.key)}>
                                <div
                                  className="group/item relative border-b border-gray-100 px-8 py-2 text-sm text-gray-800 hover:bg-indigo-600"
                                  onClick={() =>
                                    onRemove({
                                      key: item.key,
                                    })
                                  }
                                >
                                  <div className="flex cursor-pointer items-center">
                                    <System.Svg.Check className="absolute left-0 ml-2 h-4 w-4 opacity-50 group-hover/item:text-white" />
                                    <System.Text
                                      size="small"
                                      as="span"
                                      className="group-hover/item:text-white"
                                    >
                                      {item.label}
                                    </System.Text>
                                    {item.renderBadge && item.renderBadge()}
                                    <System.Svg.Cross className="absolute right-0 mr-2 h-4 w-4 group-hover/item:text-white" />
                                  </div>
                                </div>
                              </React.Fragment>
                            )
                          })}
                        {filteredItems.length > 0 && (
                          <Combobox.Options
                            static
                            className="max-h-72 divide-y overflow-y-auto pb-2 text-sm text-gray-800"
                          >
                            {filteredItems.map((item) => {
                              if (
                                itemsSelected &&
                                itemsSelected.includes(item.key)
                              ) {
                                return null
                              }

                              return (
                                <Combobox.Option
                                  key={String(item.key)}
                                  value={item.key}
                                  onClick={() => {
                                    item.onClick({
                                      key: item.key,
                                    })
                                  }}
                                  className={({ active }) =>
                                    classNames(
                                      "group/item flex cursor-pointer select-none flex-wrap items-center px-8 py-2",
                                      active && "bg-indigo-600 text-white",
                                    )
                                  }
                                >
                                  <System.Text
                                    size="small"
                                    as="span"
                                    className="group-hover/item:text-white"
                                  >
                                    {item.label}
                                  </System.Text>
                                  {item.renderBadge && item.renderBadge()}
                                </Combobox.Option>
                              )
                            })}
                          </Combobox.Options>
                        )}

                        {query !== "" && filteredItems.length === 0 && (
                          <>
                            <div className="flex items-center px-8 pb-4 pt-2 text-center">
                              <System.Text>
                                No results match your search
                              </System.Text>
                            </div>
                            {!isNil(renderNoResults) &&
                              renderNoResults({
                                query,
                              })}
                          </>
                        )}
                      </Combobox>
                    </div>
                  </div>
                )
              }}
            </Popover.Panel>
          </Transition>
        </div>
      </Popover>
    </>
  )
})
