import { SvgProps } from "@design-system/Icon/types"

export const Loader = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          strokeWidth={strokeWidth}
          fill="currentColor"
          d="M20.729 14C20.2569 14 19.851 14.322 19.6864 14.7645C18.5496 17.8205 15.5824 20 12.101 20C7.63811 20 4.02021 16.4183 4.02021 12C4.02021 7.58172 7.63811 4 12.101 4C15.5824 4 18.5496 6.17951 19.6864 9.23545C19.851 9.67798 20.2569 10 20.729 10C21.3853 10 21.8786 9.39531 21.6651 8.77466C20.3098 4.83438 16.5397 2 12.101 2C6.52238 2 2 6.47715 2 12C2 17.5228 6.52238 22 12.101 22C16.5397 22 20.3098 19.1656 21.6651 15.2253C21.8786 14.6047 21.3853 14 20.729 14Z"
        />
      </g>
      <defs>
        <radialGradient
          id="a"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(-.44195 -1.08334 7.49402 -3.05718 21.192 11)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F46E5" />
          <stop offset=".515" stopColor="white" />
          <stop offset=".85" stopColor="#A4A0F2" />
        </radialGradient>
      </defs>
    </svg>
  )
}
