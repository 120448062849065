import { get, isNil } from "lodash"
import { InputHTMLAttributes } from "react"
import { RegisterOptions, useFormContext } from "react-hook-form"
import { getClassNameInputCheckbox } from "../../../../ui/CheckboxInline"
import classNames from "classnames"

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  register?: Function
  registerOptions?: RegisterOptions
  className?: string
  required?: boolean
  group?: boolean
  disabled?: boolean
  error?: boolean
  children?: React.ReactNode
  renderLabel?: () => JSX.Element
  textSize?: "default" | "large" | "small"
}

const FieldCheckboxInline = ({
  register,
  name,
  registerOptions = {},
  renderLabel,
  error = false,
  children,
  textSize = "default",
  ...rest
}: Props) => {
  const context = useFormContext()

  let registerInput
  if (isNil(register)) {
    registerInput = context.register(name, registerOptions)
  } else {
    registerInput = register(name, registerOptions)
  }

  return (
    <div className="relative flex items-center">
      <div className="flex items-center">
        <input
          type="checkbox"
          className={getClassNameInputCheckbox({
            error,
          })}
          id={name}
          {...registerInput}
          {...rest}
        />
      </div>
      <div
        className={classNames("ml-3", {
          "text-xs": textSize === "small",
          "text-sm": textSize === "default",
          "text-base": textSize === "large",
        })}
      >
        {!isNil(renderLabel) && (
          <label
            htmlFor={!isNil(get(rest, "id")) ? get(rest, "id") : name}
            className="cursor-pointer font-medium text-gray-700"
          >
            {renderLabel()}
          </label>
        )}
        {isNil(renderLabel) && !isNil(children) && (
          <label
            htmlFor={!isNil(get(rest, "id")) ? get(rest, "id") : name}
            className="cursor-pointer font-medium text-gray-700"
          >
            {children}
          </label>
        )}
      </div>
    </div>
  )
}

export default FieldCheckboxInline
