import { cn } from "@helpers/utils"
import {
  passwordMinLength,
  passwordSpecialCharacters,
} from "@helpers/validatePassword"
import { cva, VariantProps } from "class-variance-authority"
import * as React from "react"
import { System } from ".."
import { PasswordValidity } from "./Input.types"
import { PasswordRequirementsTooltip } from "./PasswordRequirementsTooltip"

const inputVariants = cva(
  "flex w-full rounded-full border border-main-grey-200 bg-main-white !text-primary-darkest ring-offset-main-white font-medium placeholder:text-main-grey-400 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-base focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 focus:border-primary-base invalid:border-danger-base",
  {
    variants: {
      size: {
        default: "pl-6 pr-4 py-3 gap-2 text-body-base",
        sm: "pl-4 pr-2 py-3 gap-1 text-body-sm",
      },
    },
    defaultVariants: {
      size: "default",
    },
  },
)

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "size">,
    VariantProps<typeof inputVariants> {
  showPasswordValidityTooltip?: boolean
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, size, showPasswordValidityTooltip, ...props }, ref) => {
    const [password, setPassword] = React.useState("")
    const [showPassword, setShowPassword] = React.useState(false)
    const [showTooltip, setShowTooltip] = React.useState(false)
    const [passwordValidity, setPasswordValidity] =
      React.useState<PasswordValidity>({
        minLength: false,
        specialChar: false,
        hasNumber: false,
      })

    React.useEffect(() => {
      if (!password) {
        setPasswordValidity({
          minLength: false,
          specialChar: false,
          hasNumber: false,
        })
        return
      }

      setPasswordValidity({
        minLength: password.length >= passwordMinLength,
        specialChar: passwordSpecialCharacters.test(password),
        hasNumber: /\d/.test(password),
      })
    }, [password])

    if (type !== "password" || !showPasswordValidityTooltip) {
      return (
        <input
          type={type}
          className={cn(inputVariants({ size, className }))}
          ref={ref}
          {...props}
        />
      )
    }

    const handleShowPassword = () => {
      setShowPassword((prev) => !prev)
    }

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(e.target.value)
    }

    const handleFocus = () => {
      setShowTooltip(true)
    }

    const handleBlur = () => {
      setShowTooltip(false)
    }

    return (
      <div className="relative">
        <input
          {...props}
          ref={ref}
          className={cn(inputVariants({ size, className }))}
          type={showPassword ? "text" : type}
          onChange={(e) => {
            handlePasswordChange(e)
            props.onChange?.(e)
          }}
          onFocus={(e) => {
            handleFocus()
            props.onFocus?.(e)
          }}
          onBlur={(e) => {
            handleBlur()
            props.onBlur?.(e)
          }}
        />

        <button
          type="button"
          className="absolute right-4 top-1/2 -translate-y-1/2 transform"
          onClick={handleShowPassword}
        >
          {showPassword && (
            <System.Svg.EyeClosed
              className="text-main-grey-400"
              size={size === "default" ? 17 : 14}
            />
          )}

          {!showPassword && (
            <System.Svg.EyeOpen
              className="text-main-grey-400"
              size={size === "default" ? 20 : 17}
            />
          )}
        </button>

        <PasswordRequirementsTooltip
          open={showTooltip}
          passwordValidity={passwordValidity}
        />
      </div>
    )
  },
)

Input.displayName = "Input"
export { Input }
