import { SvgProps } from "@design-system/Icon/types"

export const Logout = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M12 5C11.7348 5 11.4805 5.10536 11.2929 5.29289C11.1054 5.48043 11 5.73478 11 6V8C11 8.55228 10.5523 9 10 9C9.44774 9 9.00003 8.55228 9.00003 8V6C9.00003 5.20435 9.3161 4.44129 9.87871 3.87868C10.4413 3.31607 11.2044 3 12 3H19C19.7957 3 20.5587 3.31607 21.1213 3.87868C21.684 4.44129 22 5.20435 22 6V18C22 18.7957 21.684 19.5587 21.1213 20.1213C20.5587 20.6839 19.7957 21 19 21H12C11.2044 21 10.4413 20.6839 9.87871 20.1213C9.3161 19.5587 9.00003 18.7957 9.00003 18V16C9.00003 15.4477 9.44774 15 10 15C10.5523 15 11 15.4477 11 16V18C11 18.2652 11.1054 18.5196 11.2929 18.7071C11.4805 18.8946 11.7348 19 12 19H19C19.2652 19 19.5196 18.8946 19.7071 18.7071C19.8947 18.5196 20 18.2652 20 18V6C20 5.73478 19.8947 5.48043 19.7071 5.29289C19.5196 5.10536 19.2652 5 19 5H12Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M6.70714 8.29289C7.09766 8.68342 7.09766 9.31658 6.70714 9.70711L5.41424 11H15C15.5523 11 16 11.4477 16 12C16 12.5523 15.5523 13 15 13H3.00003C2.59557 13 2.23093 12.7564 2.07615 12.3827C1.92137 12.009 2.00692 11.5789 2.29292 11.2929L5.29292 8.29289C5.68345 7.90237 6.31661 7.90237 6.70714 8.29289Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M2.29292 11.2929C2.68345 10.9024 3.31661 10.9024 3.70714 11.2929L6.70714 14.2929C7.09766 14.6834 7.09766 15.3166 6.70714 15.7071C6.31661 16.0976 5.68345 16.0976 5.29292 15.7071L2.29292 12.7071C1.9024 12.3166 1.9024 11.6834 2.29292 11.2929Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
