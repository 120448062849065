import { getProjectPath } from "."
import { BackupScheduleInputs } from "../../../components/Forms/Projects/Backups/Schedule/types/form-types"
import { BackupSettingsInputs } from "../../../components/Forms/Projects/Backups/Settings/types/form-types"

export const getProjectBackupSettingsPath = ({ id }: { id: number }) =>
  `${getProjectPath(id)}/backups/settings`

interface BackupAllInputs extends BackupScheduleInputs, BackupSettingsInputs {}

export const upsertBackupSettings = async (
  projectId: number,
  {
    data,
    type,
  }: {
    data: BackupSettingsInputs | BackupScheduleInputs | BackupAllInputs
    type: "settings" | "schedule" | "all"
  },
) => {
  try {
    const result = await fetch(
      getProjectBackupSettingsPath({ id: projectId }),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: data,
          type: type,
        }),
      },
    )

    return await result.json()
  } catch (error) {
    return {
      code: "fetch_error",
    }
  }
}
