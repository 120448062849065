import { createContext, useEffect, useState } from "react"
import useLocalStorage from "../../../../hooks/useLocalStorage"

type Pagination = {
  values: {
    page: number
    perPage: number
    total: number
    optionsPerPage?: Array<{ value: number; label: string }>
  }
  actions: {
    setPage: Function
    setPerPage: Function
    setTotal: Function
  }
}

export const PaginationContext = createContext<Pagination>({
  values: {
    page: 0,
    perPage: 10,
    total: 0,
    optionsPerPage: [],
  },
  actions: {
    setPage: () => {},
    setPerPage: () => {},
    setTotal: () => {},
  },
})

export const PaginationProvider = ({
  children,
  keyStorage,
  optionsPerPage,
  perPage: initialPerPage = 10,
}: {
  children: any
  keyStorage: string
  optionsPerPage?: Array<{ value: number; label: string }>
  perPage?: number
}) => {
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useLocalStorage(keyStorage, initialPerPage)

  useEffect(() => {
    setPage(0)
  }, [perPage])

  return (
    <PaginationContext.Provider
      value={{
        values: {
          page,
          perPage,
          total,
          optionsPerPage,
        },
        actions: {
          setPage,
          setPerPage,
          setTotal,
        },
      }}
    >
      {children}
    </PaginationContext.Provider>
  )
}

export default { PaginationContext, PaginationProvider }
