import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders"
import { getPrivateApiOneProjectNotesPath } from "@/Project/api/routes-private"

interface Props {
  projectId: string | number
  content: string
}

export const createNote = async (data: Props) => {
  const headers = await getFetchPrivateHeaders()
  const result = await fetch(
    getPrivateApiOneProjectNotesPath({
      projectId: data.projectId,
    }),
    {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        content: data.content,
      }),
    },
  )

  return await result.json()
}
