import { SvgProps } from "@design-system/Icon/types"

export const Play = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          strokeWidth={strokeWidth}
          fill="currentColor"
          d="M6 4.00004V20C5.99995 20.178 6.04737 20.3527 6.13738 20.5062C6.22739 20.6597 6.35672 20.7864 6.51202 20.8732C6.66733 20.96 6.84299 21.0038 7.02088 21.0001C7.19878 20.9964 7.37245 20.9453 7.524 20.852L20.524 12.852C20.6696 12.7626 20.7898 12.6373 20.8733 12.4881C20.9567 12.339 21.0005 12.1709 21.0005 12C21.0005 11.8291 20.9567 11.6611 20.8733 11.512C20.7898 11.3628 20.6696 11.2375 20.524 11.148L7.524 3.14804C7.37245 3.0548 7.19878 3.00369 7.02088 2.99997C6.84299 2.99626 6.66733 3.04007 6.51202 3.1269C6.35672 3.21372 6.22739 3.34042 6.13738 3.4939C6.04737 3.64739 5.99995 3.82211 6 4.00004Z"
        />
      </g>
    </svg>
  )
}
