import { getPrivateApiProjectStopRestorationPath } from "@/Project/api/routes-private"
import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders"

interface Props {
  projectId: number
}

export const stopRestore = async ({ projectId }: Props) => {
  try {
    const headers = await getFetchPrivateHeaders()
    const result = await fetch(
      getPrivateApiProjectStopRestorationPath({ projectId }),
      {
        method: "DELETE",
        headers: headers,
      },
    )

    return await result.json()
  } catch (error) {
    return {
      code: "fetch_error",
    }
  }
}
