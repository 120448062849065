import { SvgProps } from "@design-system/Icon/types"

export const Team = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M9.87868 10.8787C10.4413 10.3161 11.2043 10 12 10C12.7957 10 13.5587 10.3161 14.1213 10.8787C14.6839 11.4413 15 12.2043 15 13C15 13.7957 14.6839 14.5587 14.1213 15.1213C13.5587 15.6839 12.7957 16 12 16C11.2043 16 10.4413 15.6839 9.87868 15.1213C9.31607 14.5587 9 13.7957 9 13C9 12.2043 9.31607 11.4413 9.87868 10.8787ZM12 12C11.7348 12 11.4804 12.1054 11.2929 12.2929C11.1054 12.4804 11 12.7348 11 13C11 13.2652 11.1054 13.5196 11.2929 13.7071C11.4804 13.8946 11.7348 14 12 14C12.2652 14 12.5196 13.8946 12.7071 13.7071C12.8946 13.5196 13 13.2652 13 13C13 12.7348 12.8946 12.4804 12.7071 12.2929C12.5196 12.1054 12.2652 12 12 12Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M10 19C9.73478 19 9.48043 19.1054 9.29289 19.2929C9.10536 19.4804 9 19.7348 9 20V21C9 21.5523 8.55228 22 8 22C7.44772 22 7 21.5523 7 21V20C7 19.2043 7.31607 18.4413 7.87868 17.8787C8.44129 17.3161 9.20435 17 10 17H14C14.7957 17 15.5587 17.3161 16.1213 17.8787C16.6839 18.4413 17 19.2043 17 20V21C17 21.5523 16.5523 22 16 22C15.4477 22 15 21.5523 15 21V20C15 19.7348 14.8946 19.4804 14.7071 19.2929C14.5196 19.1054 14.2652 19 14 19H10Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M14.8787 2.87868C15.4413 2.31607 16.2044 2 17 2C17.7956 2 18.5587 2.31607 19.1213 2.87868C19.6839 3.44129 20 4.20435 20 5C20 5.79565 19.6839 6.55871 19.1213 7.12132C18.5587 7.68393 17.7956 8 17 8C16.2044 8 15.4413 7.68393 14.8787 7.12132C14.3161 6.55871 14 5.79565 14 5C14 4.20435 14.3161 3.44129 14.8787 2.87868ZM17 4C16.7348 4 16.4804 4.10536 16.2929 4.29289C16.1054 4.48043 16 4.73478 16 5C16 5.26522 16.1054 5.51957 16.2929 5.70711C16.4804 5.89464 16.7348 6 17 6C17.2652 6 17.5196 5.89464 17.7071 5.70711C17.8946 5.51957 18 5.26522 18 5C18 4.73478 17.8946 4.48043 17.7071 4.29289C17.5196 4.10536 17.2652 4 17 4Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M16 10C16 9.44772 16.4477 9 17 9H19C19.7957 9 20.5587 9.31607 21.1213 9.87868C21.6839 10.4413 22 11.2043 22 12V13C22 13.5523 21.5523 14 21 14C20.4477 14 20 13.5523 20 13V12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11H17C16.4477 11 16 10.5523 16 10Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M4.87868 2.87868C5.44129 2.31607 6.20435 2 7 2C7.79565 2 8.55871 2.31607 9.12132 2.87868C9.68393 3.44129 10 4.20435 10 5C10 5.79565 9.68393 6.55871 9.12132 7.12132C8.55871 7.68393 7.79565 8 7 8C6.20435 8 5.44129 7.68393 4.87868 7.12132C4.31607 6.55871 4 5.79565 4 5C4 4.20435 4.31607 3.44129 4.87868 2.87868ZM7 4C6.73478 4 6.48043 4.10536 6.29289 4.29289C6.10536 4.48043 6 4.73478 6 5C6 5.26522 6.10536 5.51957 6.29289 5.70711C6.48043 5.89464 6.73478 6 7 6C7.26522 6 7.51957 5.89464 7.70711 5.70711C7.89464 5.51957 8 5.26522 8 5C8 4.73478 7.89464 4.48043 7.70711 4.29289C7.51957 4.10536 7.26522 4 7 4Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M5 11C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4 12V13C4 13.5523 3.55228 14 3 14C2.44772 14 2 13.5523 2 13V12C2 11.2044 2.31607 10.4413 2.87868 9.87868C3.44129 9.31607 4.20435 9 5 9H7C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11H5Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
