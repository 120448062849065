import DevConfig from "./dev"
import StagingConfig from "./staging"
import ProdConfig from "./prod"
import crypto from "crypto"
import service from "../service-account.enc"
import serviceDev from "../service-account.dev.enc"
import serviceStaging from "../service-account.staging.enc"
import { isNil } from "lodash"

const DefaultConfig: {
  app_title: string
  domain: string
  email_contact: string
  meilisearch_public_url?: string
  support_contact: string
  max_trial_projects: number

  backups: {
    manual_limit_by_project: number
    manual_limit_days: number
    automatic_limit_days: number
  }
  slack_webhooks: {
    acquisition: string
  }
  sentry: {
    dsn: string
    tracesSampleRate: number
  }
  pay_as_you_go: {
    pricing: {
      monthly: number
    }
    min_quantity: number
  }
  id_plan_hourly_backup_full_access: string
  ssh_key: string
  pages: {
    auth: {
      login: string
      signup: string
      logout: string
    }
  }
} = {
  app_title: "WP Umbrella",
  domain: "wp-umbrella.com",
  email_contact: "contact@wp-umbrella.com",
  support_contact: "support@wp-umbrella.com",
  max_trial_projects: 5,
  backups: {
    manual_limit_by_project: 3,
    manual_limit_days: 50, // Basically it could be 14 days
    automatic_limit_days: 50,
  },
  slack_webhooks: {
    acquisition:
      "https://hooks.slack.com/services/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",
  },
  sentry: {
    dsn: "https://7e03242358ae4882bbb36ca5d010a4e5@o1118876.ingest.sentry.io/6156879",
    tracesSampleRate: process.env.NODE_ENV === "production" ? 0.2 : 1.0,
  },
  pay_as_you_go: {
    pricing: {
      monthly: 1.99,
    },
    min_quantity: 1,
  },
  id_plan_hourly_backup_full_access: "full_access_hourly_backup",
  ssh_key: `ssh-rsa
    AAAAB3NzaC1yc2EAAAADAQABAAACAQC6oAuUSjFYWRmSb7+20b2UPLK3wRpRDTw0C6leP0ULQXVSC6mOMbL4s9qFow4VwpdwC16J/07qDaViF4KB+x9wBhEPoCLreYw1+824C4jDyKS/Eo+ZaUmtEBmBwkPnsCuuaZ0SIai2Im8Z3xR0VvA6IIWJkDO6LMuoyEouZwZMeKC+eti0ElVAPVNupbAVWeWmpUIuDGoxKE60CrAUfqLTLiLZiF6l+n9wXxzAlYMbapyhvxnOwhO5JfXCgYdbUR20dRjEuuOcjvDWSq5AE/J9ptiyeunzXr7AkiBMiHVACf8lJ0TJswMrq8u1A8+AtHWh5/D6duHQYZtvL0cpQ44xr7d+WEOqw0xmNr1mL+ZlCTNQ08ZLudsjfgiaEpTy6CjR8Jip2nyyhWfH+JhyCG/25f6+vNbY5sTW4Yp1W+I/pczKEksiLrMIFT9tbT4mWXkW7SqlrnnyPqxV82RtJ47XtqWQikeJL9KnOIwhF5w76NNOUsAhovJeX2pe0yqBIby+AlRGmqT7fXTNnS4ij/D5uZlnCGWMjgiUI8kap/wFGuMeWyfXKdkrv7aAVD8blAv+Ak75qSJd+ik1qTIZewaguePxLLOJkD//lJ0HSP6389Gu3UK/zic4rZD0edK51LeXfqM+Jj3NfXVUFXVeM52ltkCwK9p5iCUp272xQNSW7w==`,

  pages: {
    auth: {
      login: "/auth/signin",
      logout: "/api/auth/signout",
      signup: "/register",
    },
  },
}

type Environement = {
  meilisearch_public_url: string
  meilisearch: {
    host: string
    master_key: string
    search: {
      key: string
      uid: string
    }
    admin: {
      key: string
      uid: string
    }
  }
  gcloud_storage_bucket_backup: string
  gcloud_storage_bucket_pdf: string
  gcloud_storage_bucket_user: string
  gcloud_oauth: {
    client_id: string
    project_id: string
    auth_uri: string
    token_uri: string
    auth_provider_x509_cert_url: string
    client_secret: string
    redirect_uris: Array<string>
    javascript_origins: Array<string>
  }
  mailgun: {
    public: string
    private: string
  }
  stripe_keys_client: string
  stripe_keys_secret: string
  umbrella_api: {
    client_id: string
    client_secret: string
    scope: string
  }
  customerio: {
    site_id: string
    api_key: string
    app_key: string
  }
}

export const isPreviewEnvironment = () => {
  const domain = getDomain()
  if (
    (!isNil(process.env.PREVIEW) && Number(process.env.PREVIEW) === 1) ||
    (!isNil(domain) && domain.indexOf("vercel.app") > -1)
  ) {
    return true
  }

  return false
}

export const getEnvironmentData = (): Environement => {
  const algorithm = "aes-128-cbc"
  const decipher = crypto.createDecipheriv(
    algorithm,
    process.env.SERVICE_ENCRYPTION_KEY,
    process.env.SERVICE_ENCRYPTION_IV,
  )

  let data = process.env.NODE_ENV === "production" ? service : serviceDev
  if (isPreviewEnvironment()) {
    data = serviceStaging
  }
  let decrypted = decipher.update(data.encrypted, "base64", "utf8")
  decrypted += decipher.final("utf8")
  const dataDecrypted: Environement = JSON.parse(decrypted)

  return dataDecrypted
}

function getDomain() {
  if (typeof window !== "undefined") {
    return window.location.hostname
  }
  return null
}

const getConfig = () => {
  const config = process.env.NODE_ENV === "production" ? ProdConfig : DevConfig

  if (isPreviewEnvironment()) {
    if (typeof window !== "undefined") {
      return {
        ...StagingConfig,
        base_url: window.location.origin,
      }
    }

    return StagingConfig
  }

  return config
}

const config = getConfig()

export default { ...config, ...DefaultConfig }
