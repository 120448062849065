import classNames from "classnames"
import { forwardRef } from "react"

export interface Props {
  className?: string
  children: React.ReactNode
  size: "default" | "large"
}

const BulkActionsContainerElement = (
  { children, size, className = "" }: Props,
  ref,
) => {
  return (
    <div
      ref={ref}
      className={classNames(
        {
          "left-[50%] translate-x-[-50%] transform": size === "default",
          "left-[35%] translate-x-[-15%] transform": size === "large",
        },
        "fixed bottom-8 z-[4444] flex items-center justify-between gap-12 rounded-lg border border-gray-300 bg-white/50 p-4 shadow-lg ring-1 ring-inset ring-white backdrop-blur-lg",
        className,
      )}
    >
      {children}
    </div>
  )
}

export const BulkActionsContainer = forwardRef(BulkActionsContainerElement)
