import { getPluginFavicon } from "@/Helpers/getPluginFavicon"
import { System } from "@design-system/index"

interface Props {
  slug: string
  className?: string
}

export const PluginFavicon = ({ slug, className = "" }: Props) => {
  return (
    <System.Badges.FromWordPressFavicon
      imageUrl={getPluginFavicon({ slug })}
      className={className}
      defaultIcon={<System.Svg.Plugin className="h-5 w-5" />}
    />
  )
}
