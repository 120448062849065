import { TABS_FILTERING_PROJECTS_INSTALL_PLUGINS } from "@components/Plugin/constants/TabsFilteringProjectsIntallPlugins"
import { getFilteringProjectsInstallPlugins } from "@components/Plugin/helpers/getFilteringProjectsInstallPlugins"
import { useGenericFilteringItemsWithTabs } from "@design-system/Context/GenericFilteringItemsWithTabs/hooks/useGenericFilteringItemsWithTabs"
import { System } from "@design-system/index"
import React from "react"

export function TabsFilteringProjectsIntallPlugins() {
  const { state, handleChangeTab } = useGenericFilteringItemsWithTabs()

  return (
    <>
      <div className="my-4 flex w-full items-center justify-between gap-2 px-8">
        <System.Tab.Navigation>
          {TABS_FILTERING_PROJECTS_INSTALL_PLUGINS.map((tab) => {
            const data = getFilteringProjectsInstallPlugins({
              data: state.data,
              filters: {
                tab: tab.value,
              },
            })

            return (
              <System.Tab.Button
                count={data.length}
                key={tab.value}
                className="text-gray-500 hover:text-gray-700"
                isCurrent={state.tab === tab.value}
                onClick={() => {
                  handleChangeTab({
                    value: tab.value,
                  })
                }}
              >
                {tab.label}
              </System.Tab.Button>
            )
          })}
        </System.Tab.Navigation>
      </div>
    </>
  )
}
