import FetchApiAuthentification from "@/FetchApi/Authentification"
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormRootError,
  Input,
  Link,
  System,
} from "@design-system/index"
import { zodResolver } from "@hookform/resolvers/zod"
import { useCsrfToken } from "@hooks/useCsrfToken"
import { useRouter } from "next/router"
import { useEffect, useMemo, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"

const formSchema = z.object({
  email: z.string().email(),
  password: z.string().min(4, "Password is required"),
  twofa_code: z.string().optional(),
  csrfToken: z.string(),
})

export const SignInForm = () => {
  const {
    query: { error: queryError },
  } = useRouter()

  useEffect(() => {
    if (queryError && queryError.length > 0) {
      form.setError("root", {
        message: "Your credentials are incorrect",
      })
    }
  }, [queryError])

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
      csrfToken: "",
      twofa_code: "",
    },
  })

  const csrfToken = useCsrfToken()

  const [checking2FACode, setChecking2FACode] = useState(false)
  const [submitting2FA, setSubmitting2FA] = useState(false)

  const formRef = useRef<HTMLFormElement>(null)

  const onSubmit = async ({ email, password }: z.infer<typeof formSchema>) => {
    if (!formRef.current) {
      return
    }

    const response = await FetchApiAuthentification.startVerification({
      email,
      password,
    })

    if (!response.success) {
      form.setError("root", {
        message: "Your credentials are incorrect",
      })
      return
    }

    if (response.result.need_two_factor_authentication) {
      setChecking2FACode(true)
      return
    }

    formRef.current.submit()
  }

  useEffect(() => {
    if (!csrfToken) {
      return
    }
    form.setValue("csrfToken", csrfToken)
  }, [csrfToken])

  const isSubmitButtonLoading = useMemo(() => {
    if (form.formState.isSubmitting) {
      return true
    }

    if (!checking2FACode) {
      return form.formState.isSubmitSuccessful
    }

    return submitting2FA
  }, [
    form.formState.isSubmitting,
    form.formState.isSubmitSuccessful,
    checking2FACode,
    submitting2FA,
  ])

  return (
    <Form {...form}>
      <form
        onSubmit={
          !checking2FACode
            ? form.handleSubmit(onSubmit)
            : () => {
                setSubmitting2FA(true)
                formRef.current?.submit()
              }
        }
        className="space-y-10"
        action="/api/auth/callback/credentials"
        method="post"
        ref={formRef}
      >
        <FormField
          control={form.control}
          name="csrfToken"
          render={({ field }) => (
            <FormItem hidden>
              <FormControl>
                <Input type="hidden" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="space-y-6">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem hidden={checking2FACode}>
                <FormLabel>Email Address</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter your email"
                    type={"email"}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="space-y-3" hidden={checking2FACode}>
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter your password"
                      type={"password"}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Link href="/reset-password">Forgot your password?</Link>
          </div>

          <FormField
            control={form.control}
            name="twofa_code"
            render={({ field }) => (
              <FormItem hidden={!checking2FACode}>
                <FormLabel>2FA Code</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter your 2FA Code"
                    type={checking2FACode ? "text" : "hidden"}
                    {...field}
                  />
                </FormControl>
                <FormDescription>
                  Enter the code you received by email to validate login.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormRootError />
        </div>

        <Button
          type="submit"
          className="w-full"
          disabled={isSubmitButtonLoading}
        >
          {isSubmitButtonLoading && <System.Loader />}

          {!checking2FACode && "Sign in"}

          {checking2FACode && "Submit 2FA Code"}
        </Button>
      </form>
    </Form>
  )
}
