import {
  StateGenericStep,
  ActionGenericStep,
  TypeGenericActionGenericStep,
} from "@design-system/Context/GenericStep/types"
import { Reducer } from "react"

export const genericStepReducer: Reducer<
  StateGenericStep,
  ActionGenericStep
> = (state: StateGenericStep, action: ActionGenericStep) => {
  switch (action.type) {
    case TypeGenericActionGenericStep.SET_CURRENT_STEP: {
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.payload.key]: {
            current: action.payload.current,
            data: action.payload.data,
          },
        },
      }
    }
    case TypeGenericActionGenericStep.SET_DATA_STEP: {
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.payload.key]: {
            ...state.steps[action.payload.key],
            data: action.payload.data,
          },
        },
      }
    }

    default:
      return state
  }
}
