import { System } from "@design-system/index"
import { Bars4Icon, ChevronRightIcon } from "@heroicons/react/20/solid"
import { BookOpenIcon, RssIcon } from "@heroicons/react/24/outline"

const links = [
  {
    title: "Documentation",
    description: "Find out how our tool works.",
    href: "https://support.wp-umbrella.com",
    icon: BookOpenIcon,
  },
  {
    title: "Product Updates",
    description: "You'll see, we always have something new.",
    icon: Bars4Icon,
    href: "https://changelog.wp-umbrella.com",
  },
  {
    title: "Blog",
    description: "Read our latest news and articles",
    icon: RssIcon,
    href: "https://wp-umbrella.com/blog",
  },
]
const social = [
  {
    name: "Twitter",
    href: "https://twitter.com/wpumbrella",
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
      </svg>
    ),
  },
]

export default function Maintenance() {
  return (
    <div className="bg-white">
      <main className="mx-auto w-full max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 pt-16">
          <img
            className="mx-auto h-12 w-auto"
            src="/images/logo-full.svg"
            alt="Workflow"
          />
        </div>
        <div className="mx-auto max-w-2xl py-16 sm:py-24">
          <div className="text-center">
            <p className="text-sm font-semibold uppercase tracking-wide text-indigo-600">
              Maintenance
            </p>
            <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
              WP Umbrella services will be temporarily unavailable.
            </h1>
            <p className="mt-2 text-lg text-gray-500">
              We are doing everything to get back as soon as possible.
            </p>
            <System.Button.Primary
              href="https://wp-umbrella.hyperping.app/"
              target="_blank"
              className="mt-8"
            >
              See Status Page
            </System.Button.Primary>
          </div>
          <div className="mt-12">
            <h2 className="text-sm font-semibold uppercase tracking-wide text-gray-500">
              In the meantime, take the time to drink a coffee or to consult
              some of our pages
            </h2>
            <ul
              role="list"
              className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200"
            >
              {links.map((link, linkIdx) => (
                <li
                  key={linkIdx}
                  className="relative flex items-start space-x-4 py-6"
                >
                  <div className="flex-shrink-0">
                    <span className="flex h-12 w-12 items-center justify-center rounded-lg bg-indigo-50">
                      <link.icon
                        className="h-6 w-6 text-indigo-700"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="min-w-0 flex-1">
                    <h3 className="text-base font-medium text-gray-900">
                      <span className="rounded-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2">
                        <a
                          href={link.href}
                          className="focus:outline-none"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span
                            className="absolute inset-0"
                            aria-hidden="true"
                          />
                          {link.title}
                        </a>
                      </span>
                    </h3>
                    <p className="text-base text-gray-500">
                      {link.description}
                    </p>
                  </div>
                  <div className="flex-shrink-0 self-center">
                    <ChevronRightIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                </li>
              ))}
            </ul>
            <div className="mt-8">
              <a
                href="https://wp-umbrella.com"
                className="text-base font-medium text-indigo-600 hover:text-indigo-500"
              >
                Or go back home
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
          </div>
        </div>
      </main>
      <footer className="mx-auto w-full max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="border-t border-gray-200 py-12 text-center md:flex md:justify-between">
          <p className="text-base text-gray-400">&copy; WP Umbrella</p>
          <div className="mt-6 flex justify-center space-x-8 md:mt-0">
            {social.map((item, itemIdx) => (
              <a
                key={itemIdx}
                href={item.href}
                className="inline-flex text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
        </div>
      </footer>
    </div>
  )
}
