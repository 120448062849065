import { useCallback, useContext } from "react"
import { PaginationContext } from "../contexts/PaginationContext"

function usePagination() {
  const { values, actions } = useContext(PaginationContext)

  const getMaxPage = useCallback(() => {
    return Math.ceil(values.total / values.perPage)
  }, [values.total, values.perPage])

  const getStartPage = () => {
    if (values.page === 0) {
      return 1
    }

    return values.page * values.perPage
  }

  const getPageLimitNumber = () => {
    if (values.page === 0) {
      return values.perPage
    }

    return values.perPage * values.page + values.perPage
  }

  const getOffset = () => {
    if (values.page === 0) {
      return 0
    }

    return values.page * values.perPage
  }

  return {
    page: values.page,
    perPage: values.perPage,
    total: values.total,
    maxPage: getMaxPage(),
    humanPage: values.page + 1,
    optionsPerPage: values.optionsPerPage,
    getStartPage,
    getPageLimitNumber,
    getOffset,
    actions: {
      setTotal: actions.setTotal,
      setPerPage: actions.setPerPage,
      setPage: actions.setPage,
    },
  }
}

export default usePagination
