import classNames from "classnames"
import { isNil } from "lodash"
import React, { InputHTMLAttributes, useEffect, useRef } from "react"

export enum CheckboxType {
  CHECKED = "CHECKED",
  INDETERMINATE = "INDETERMINATE",
  EMPTY = "EMPTY",
}

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean
  disabled?: boolean
  className?: string
  checkboxState?: CheckboxType
}

export const getClassNameInputCheckbox = (props: Props = {}) => {
  const { className = "" } = props
  return classNames(
    {
      "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer":
        true,
    },
    className,
  )
}

const CheckboxInline = ({
  error,
  disabled,
  className,
  checkboxState,
  ...rest
}: Props) => {
  const checkboxRef = useRef()

  useEffect(() => {
    if (isNil(checkboxRef)) {
      return
    }

    if (checkboxState === CheckboxType.CHECKED) {
      //@ts-ignore
      checkboxRef.current.checked = true
      //@ts-ignore
      checkboxRef.current.indeterminate = false
    } else if (checkboxState === CheckboxType.EMPTY) {
      //@ts-ignore
      checkboxRef.current.checked = false
      //@ts-ignore
      checkboxRef.current.indeterminate = false
    } else if (checkboxState === CheckboxType.INDETERMINATE) {
      //@ts-ignore
      checkboxRef.current.checked = false
      //@ts-ignore
      checkboxRef.current.indeterminate = true
    }
  }, [checkboxState])

  return (
    <input
      type="checkbox"
      className={getClassNameInputCheckbox({
        error,
        disabled,
        className,
      })}
      ref={checkboxRef}
      {...rest}
    />
  )
}

export default CheckboxInline
