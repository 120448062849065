import { TableColumn } from "@design-system/Table/types"
import classNames from "classnames"

const columnsClasses = {
  1: "grid-cols-[auto_1fr]",
  2: "grid-cols-[auto_1fr_1fr]",
  3: "grid-cols-[auto_1fr_1fr_1fr]",
  4: "grid-cols-[auto_1fr_1fr_1fr_1fr]",
}

export const getColumnsClassnames = (
  columns: TableColumn[],
  classes: string = "",
) => {
  if (!columns?.length) {
    return classes
  }

  return classNames(classes, {
    [columnsClasses[columns?.length]]: !classes.includes("grid-cols-"),
  })
}
