import { SvgProps } from "@design-system/Icon/types"

export const Plugin = ({
  strokeWidth = 2,
  size = 24,
  filled = false,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M5.1913 7.80889C5.1913 7.2566 5.63902 6.80889 6.1913 6.80889H17.8091C18.0743 6.80889 18.3286 6.91424 18.5162 7.10178C18.7037 7.28932 18.8091 7.54367 18.8091 7.80889L18.8091 10.7043C18.8254 11.6062 18.6624 12.5024 18.3295 13.3409C17.9953 14.1825 17.4966 14.949 16.8626 15.5956C16.2286 16.2421 15.472 16.7558 14.6371 17.1064C13.8022 17.457 12.9057 17.6376 12.0002 17.6376C11.0946 17.6376 10.1982 17.457 9.36329 17.1064C8.52838 16.7558 7.7718 16.2421 7.13779 15.5956C6.50379 14.949 6.0051 14.1825 5.67091 13.3409C5.33797 12.5024 5.17496 11.6062 5.1913 10.7043L5.1913 7.80889ZM7.1913 8.80889V10.7137L7.19111 10.7333C7.17857 11.3728 7.29368 12.0083 7.52973 12.6028C7.76577 13.1972 8.118 13.7386 8.56581 14.1953C9.01361 14.652 9.548 15.0148 10.1377 15.2624C10.7274 15.5101 11.3606 15.6376 12.0002 15.6376C12.6398 15.6376 13.273 15.5101 13.8627 15.2624C14.4524 15.0148 14.9868 14.652 15.4346 14.1953C15.8824 13.7386 16.2346 13.1972 16.4706 12.6028C16.7067 12.0083 16.8218 11.3728 16.8093 10.7333L16.8091 10.7137V8.80889L7.1913 8.80889Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M11.9998 15.8492C12.5521 15.8492 12.9998 16.297 12.9998 16.8492V21.799C12.9998 22.3513 12.5521 22.799 11.9998 22.799C11.4475 22.799 10.9998 22.3513 10.9998 21.799V16.8492C10.9998 16.297 11.4475 15.8492 11.9998 15.8492Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M8.46467 1.70715C9.01695 1.70715 9.46467 2.15487 9.46467 2.70715V7.6569C9.46467 8.20919 9.01695 8.6569 8.46467 8.6569C7.91238 8.6569 7.46467 8.20919 7.46467 7.6569V2.70715C7.46467 2.15487 7.91238 1.70715 8.46467 1.70715Z"
            clipRule="evenodd"
          />
          <path
            strokeWidth={strokeWidth}
            fill="currentColor"
            fillRule="evenodd"
            d="M15.5355 1.70715C16.0878 1.70715 16.5355 2.15487 16.5355 2.70715V7.6569C16.5355 8.20919 16.0878 8.6569 15.5355 8.6569C14.9832 8.6569 14.5355 8.20919 14.5355 7.6569V2.70715C14.5355 2.15487 14.9832 1.70715 15.5355 1.70715Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
